import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SubmissionsService } from '../../service/submissions.service';
import { Location } from '@angular/common';
import { ReviewsService } from '../../service/reviews.service';
import { CardViewComponent } from '../card-view/card-view.component';
import { InfoService } from '../../service/info.service';
import { NavbarService } from '../../service/navbar.service';
import { Review, Submission } from '../../model/paper';
import { EventsService } from '../../service/events.service';

@Component({
  selector: 'app-review-view',
  templateUrl: './review-view.component.html',
  styleUrls: ['./review-view.component.scss', '../card-view/card-view.component.scss']
})
export class ReviewViewComponent extends CardViewComponent implements OnInit {
  review: Review;

  constructor(
    router: Router, route: ActivatedRoute,
    submissionsService: SubmissionsService, location: Location,
    infoService: InfoService, reviewsService: ReviewsService,
    protected eventsService: EventsService,
    private navbarService: NavbarService
  ) {
    super(router, route, submissionsService, location, infoService, reviewsService);
  }

  ngOnInit() {
    setTimeout(() => {
      this.navbarService.loadingStart();
      this.reviewsService
        .getReview(parseInt(this.route.snapshot.paramMap.get('id'), 10), true)
        .subscribe(review => {
          if (review.submission instanceof Submission) {
            this.continueInit(review);
          } else {
            // TODO: review.submission should be Submission | number. Need to refactor a lot more to allow this.
            this.submissionService.getSubmission(<number><unknown>review.submission).subscribe(s => {
              review.submission = s;
              this.continueInit(review);
            });
          }
        });
    });
  }

  private continueInit(review: Review) {
    this.eventsService
    .getEventByTrack(review.submission.trackID)
    .subscribe(event => {
      this.review = review;
      this.review.submission.event = event;
      this.review.submission.track = event.tracks.find(track => review.submission.trackID === track.id);

      this.navbarService.loadingStop();
    });
  }
}
