import { Component, OnInit } from '@angular/core';
import { DatasetService } from 'src/app/service/dataset.service';
import { InfoService } from 'src/app/service/info.service';
import { EventsService } from '../../service/events.service';
import { Event } from 'src/app/model/event';
import { SmallReview, SmallInfo } from '../../service/dataset.service';
import { UserEventRole } from '../../enum/user.event.role';
import { UserEventAnswer } from '../../enum/user.event.answer';
import { ReviewStatus } from 'src/app/enum/review.status';
import { map } from 'rxjs/operators';

function partition(array, predicate) {
  return array.reduce((acc, item) => predicate(item)
    ? (acc[0].push(item), acc)
    : (acc[1].push(item), acc), [[], []]);
}

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {
  reviewsInvited: Array<SmallReview>;
  reviewsAwaiting: Array<SmallReview>;

  reviewsOtherInvited: Array<SmallReview>;
  reviewsOtherAwaiting: Array<SmallReview>;

  tracksCloseAt: Array<SmallInfo>;
  submissionEventEnd: Array<SmallInfo>;

  trackFilesCloseAt: Array<SmallInfo>;
  eventsWithPendingInvites: Array<Event>;

  reviewColumns = ['status', 'submissionTitle', 'event', 'deadline'];
  OtherReviewColumns = ['status', 'submissionTitle', 'event', 'reviewer', 'deadline'];

  trackColumns = ['status', 'submissionTitle', 'event', 'deadline'];
  fileColumns = ['status', 'submissionTitle', 'event', 'trackFileName', 'deadline'];
  activeSubmissions = ['status', 'submissionTitle', 'event'];

  invitesColumns = ['acronym', 'eventFullName', 'button'];

  constructor(
    private datasetService: DatasetService,
    private infoService: InfoService,
    private eventsService: EventsService
  ) { }

  ngOnInit() {
    this.getReviews(this.infoService.user.id);
    this.getTracks(this.infoService.user.id);
    this.getTrackFiles(this.infoService.user.id);
    this.getPendingInvites(this.infoService.user.id);
  }

  getReviews(userId: number) {
    this.datasetService.reviewsByDueAt(userId).subscribe(reviews => {
      const [ reviewsInvited, reviewsAwaiting ] = partition(reviews, r => r.status === ReviewStatus.NOTIFIED);

      [ this.reviewsInvited, this.reviewsOtherInvited ] = partition(reviewsInvited, r => r.user.id === userId);
      [ this.reviewsAwaiting, this.reviewsOtherAwaiting ] = partition(reviewsAwaiting, r => r.user.id === userId);
    });
  }

  getTracks(userId: number) {
    this.datasetService.submissionByTrackEnd(userId).subscribe(tracksInfo => {
      [ this.tracksCloseAt, this.submissionEventEnd ] = partition(tracksInfo, s => s.closeAt >= this.today);
    });
  }

  getTrackFiles(userId: number) {
    this.datasetService.submissionByTrackFileEnd(userId).subscribe(trackFilesInfo => {
      let addedSubmissions = {};

      this.trackFilesCloseAt = trackFilesInfo.filter(t => {
        const add = !t.hasFile && !addedSubmissions[t.submission.id];  // verify if submissions was already added to the list.
        if (add) {
          addedSubmissions[t.submission.id] = true;
        }
        return add;
      });
    });
  }

  getPendingInvites(userId: number) {
    this.eventsService.getEventByUserRole(userId, UserEventRole.COMMITTEE).pipe(map(e => e.items)).subscribe(invites => {
      this.eventsWithPendingInvites = invites.filter(i => i.userEvent.invitationAnswer === UserEventAnswer.NOT_ANSWERED.toUpperCase());
    });
  }

  get today(): Date {
    return new Date();
  }
}
