import { BaseModel } from './baseModel';
import { User } from './user';
import { Type } from 'class-transformer';

export class PrintingProblem extends BaseModel {
  public description: string;

  @Type(() => User)
  public user: User | number;

  public review: number;

  public causes: Array<string>;
}
