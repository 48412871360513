import { Component, Inject, OnInit, Optional } from '@angular/core';
import { NavbarService } from 'src/app/service/navbar.service';
import { EventsService } from 'src/app/service/events.service';
import { Review, Submission } from 'src/app/model/paper';
import { ReviewsService } from 'src/app/service/reviews.service';
import { PaperReviewHistory } from 'src/app/model/paper-review-history';
import { forkJoin, Observable } from 'rxjs';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { AuthService } from 'src/app/service/auth.service';
import { Event } from 'src/app/model/event';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-paper-review-history',
  templateUrl: './paper-review-history.component.html',
  styleUrls: ['./paper-review-history.component.scss']
})
export class PaperReviewHistoryComponent implements OnInit {
  
  public isSubmissionView: boolean;
  public element: Submission | Review;
  public history: Array<PaperReviewHistory> = [];

  public event: Event;
  public userIsChair = false;
  constructor(
    private route: ActivatedRoute,
    private reviewsService: ReviewsService,
    private submissionsService: SubmissionsService,
    private navbarService: NavbarService,
    private authService: AuthService,
    private eventsService: EventsService,
    @Inject(MAT_DIALOG_DATA) @Optional() public data: { id: number, isSubmissionView: boolean },
  ) { }

  ngOnInit() {
    setTimeout(() => {
      let id = 0;
      if (this.data) {
        id = this.data.id;
        this.isSubmissionView = this.data.isSubmissionView;
      }
      else {
        id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
        this.isSubmissionView = !!this.route.snapshot.url.find(r => r.path === 'submissions');
      }

      this.navbarService.loadingStart();

      forkJoin([this.getElement(id), this.getHistory(id)])
        .subscribe(([element, history]) => {
          this.element = element;
          this.history = history;

          this.getEvent(element).subscribe(event => {
            this.event = event;
            this.authService.directUserIsChair(this.event.id).subscribe(isChair => {
              this.userIsChair = this.authService.userIsAdmin() || isChair;
            });
          });

          this.navbarService.loadingStop();
        });
    });
  }

  getElement(id: number): Observable<Submission> | Observable<Review> {
    if (this.isSubmissionView) {
      return this.submissionsService.getSubmission(id);
    }
    return this.reviewsService.getReview(id);
  }

  getHistory(id: number): Observable<PaperReviewHistory[]> {
    if (this.isSubmissionView) {
      return this.reviewsService.getReviewHistoryBySubmission(id);
    }
    return this.reviewsService.getReviewHistoryByReview(id);
  }

  getEvent(element: Submission | Review): Observable<Event> {
    let track: number;

    if (element instanceof Submission) {
      track = element.trackID;
    } else {
      track = element.submission.trackID;
    }

    return this.eventsService.getEventByTrack(track);
  }
}
