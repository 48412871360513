import { SelectOption } from '../model/select.option';

export enum FormFieldType {
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  TEXT = 'TEXT'
}

export namespace FormFieldType {
  export function toIterable(): Array<string> {
    return Object.keys(FormFieldType).filter(key => typeof FormFieldType[key] !== 'function');
  }

  export function toSelectable(): Array<SelectOption> {
    return FormFieldType.toIterable().map((value, index) => ({
      id: value,
      value: FormFieldType[value]
    }));
  }
}
