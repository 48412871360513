import { Submission, Review } from 'src/app/model/paper';
import { ReviewStatus } from 'src/app/enum/review.status';
import { UserEvent } from 'src/app/model/user.event';
import { TopicInterest } from 'src/app/model/user.event.topics.interest';
import { AssignMotive, Motives, Translations } from 'src/app/enum/assign.motives';

import { UserAssignInfo, SubmissionAssignSuggestions, WeightFunction, AssignmentTestFunction } from './types';


export function topicsMatchFunction(userPreference: UserEvent, submission: Submission, positiveMatchWeight: number = 1, negativeMatchWeight: number = 1): number {
  // Weight function
  let positiveSum = 0, negativeSum = 0;

  userPreference.topicsInterest.forEach(topic => {
    if (topic.interest > TopicInterest.neutral && submission.topics.some(t => t.id === topic.eventTopic.id)) {
      positiveSum++;
    } else
    if (topic.interest < TopicInterest.neutral && submission.topics.some(t => t.id === topic.eventTopic.id)) {
      negativeSum++;
    }
  });
  return positiveMatchWeight*positiveSum - negativeMatchWeight*negativeSum;
}


export function classicAssignmentSuggestion(submissionMatrix: Array<SubmissionAssignSuggestions>,
  numberOfCommittees: number, numberOfReviewsRequired: number) {

  // as in the JEMS1
  const flatMatrix: Array<UserAssignInfo> = [];

  submissionMatrix.forEach(submission => {
    flatMatrix.push(...submission.possiblesAssign);
  });
  // sort by decrescent weight (relevance?)
  flatMatrix.sort((a, b) => b.weight - a.weight);

  const [mMin, mMax] = initMaxReviewsPerUser(submissionMatrix.length, numberOfCommittees, numberOfReviewsRequired);

  const phases = [
    {assignmentTest: firstAssignment, max: mMin, motive: Motives.CLAIM},
    {assignmentTest: secondAssignment, max: mMax, motive: Motives.CLAIM},
    {assignmentTest: thirdAssignment, max: mMin, motive: Motives.INTEREST},
    {assignmentTest: fourthAssignment, max: mMax, motive: Motives.INTEREST}
  ];

  // assignment execution of phases
  phases.forEach( (phase) => {
    flatMatrix.forEach(userAssignInfo => {
      const submissionToAssign = userAssignInfo.submissionToAssign;
      const validReviews = submissionToAssign.submission.reviews.filter(r => (<Review>r).status !== ReviewStatus.DECLINED);
      if (userAssignInfo.elegible
        && (submissionToAssign.suggestedAssign.length + validReviews.length) < numberOfReviewsRequired
        && phase.assignmentTest(userAssignInfo.submission, userAssignInfo.userPreferences, phase.max)) {
        userAssignInfo.elegible = false;
        userAssignInfo.motive = phase.motive;
        userAssignInfo.userPreferences.reviewing.push(userAssignInfo.submission.id);
        userAssignInfo.submissionToAssign.suggestedAssign.push(userAssignInfo);
      }
    });
  });

  return correctOrder(submissionMatrix);
}


function initMaxReviewsPerUser(numberOfSubmissions: number, numberOfCommittees: number, numberOfReviewsRequired: number): [number, number] {
  let mMax: number;
  let mMin = (numberOfSubmissions * numberOfReviewsRequired);

  if (mMin > 0) {
    mMin = mMin / numberOfCommittees;
  }

  if (Math.trunc(mMin) !== mMin) {
    mMin = Math.trunc(mMin);
    mMax = mMin + 1;
  } else {
    mMax = mMin;
  }

  return [mMin, mMax];
}


function correctOrder(matrix: Array<SubmissionAssignSuggestions>): Array<SubmissionAssignSuggestions> {
  matrix.forEach(sub => sub.possiblesAssign.sort((a, b) => a.userPreferences.user.id - b.userPreferences.user.id));
  matrix.sort((a, b) => a.submission.id - b.submission.id);
  return matrix;
}


// AssignmentTestFunction
function firstAssignment(submission: Submission, user: UserEvent, maxReviews: number): boolean {
  if (submission.hasClaim(user.user.id) &&
    !submission.isReviewer(user.user.id) &&
    !submission.isAuthor(user.user.id) &&
    !submission.authors.some(author => user.hasConflict(author.user.id)) &&
    user.reviewing ?.length < maxReviews
  ) {
    return true;
  }

  return false;
}


// AssignmentTestFunction
function secondAssignment(submission: Submission, user: UserEvent, maxReviews: number): boolean {
  if (submission.hasClaim(user.user.id) &&
    !submission.isReviewer(user.user.id) &&
    !submission.isAuthor(user.user.id) &&
    !submission.authors.some(author => user.hasConflict(author.user.id)) &&
    user.reviewing ?.length < maxReviews
  ) {
    return true;
  }

  return false;
}


// AssignmentTestFunction
function thirdAssignment(submission: Submission, user: UserEvent, maxReviews: number): boolean {
  if (!submission.isReviewer(user.user.id) &&
    !submission.isAuthor(user.user.id) &&
    !submission.authors.some(author => user.hasConflict(author.user.id)) &&
    user.reviewing ?.length < maxReviews
  ) {
    return true;
  }

  return false;
}


// AssignmentTestFunction
function fourthAssignment(submission: Submission, user: UserEvent, maxReviews: number): boolean {
  if (!submission.isReviewer(user.user.id) &&
    !submission.isAuthor(user.user.id) &&
    !submission.authors.some(author => user.hasConflict(author.user.id)) &&
    user.reviewing ?.length < maxReviews
  ) {
    return true;
  }

  return false;
}
