import { Component, OnInit } from '@angular/core';
import { EventsService } from 'src/app/service/events.service';
import { Event } from 'src/app/model/event';
import { Router } from '@angular/router';
import { NavbarService } from 'src/app/service/navbar.service';
import { NO_PAGINATION } from 'src/app/model/pagination.options';
import { ShowAllPagedViewDirective } from 'src/app/class/paged-view-all';
import { PreferencesService } from 'src/app/service/preferences.service';
import { LocalCache } from 'src/app/enum/local.cache';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent extends ShowAllPagedViewDirective {
  constructor(
    private eventsService: EventsService,
    public navbarService: NavbarService,
    public preferencesService: PreferencesService
  ) {
    super(preferencesService, LocalCache.OLD_EVENTS);
  }

  load() {
    setTimeout(() => {
      this.getEvents();
    });
  }

  get events(): Array<Event> {
    return <Array<Event>>this.elements;
  }

  getEvents(): void {
    this.navbarService.loadingStart();
    const paginationOptions = NO_PAGINATION;
    paginationOptions.show = this.showAll;
    this.eventsService.getAllEventsWithMinimumDetails(paginationOptions).subscribe(pagination => {
      this.unfilteredElements = pagination as Event[];
      this.elements = this.unfilteredElements;

      this.elements = this.getSubEvents(this.elements);
      this.navbarService.loadingStop();
      window.scroll(0, 0);
    });
  }

  getSubEvents(elements: Event[]) {
    let events: Event[];
    let subEvents: Event[];
    events = elements.filter(event => !event.eventParent);
    subEvents = elements.filter(event => event.eventParent);
    events.map (event => {
      event.subEvents = subEvents.filter(child => child.eventParent == event.id);
    });
    return events;
  }
}
