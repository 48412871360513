import { Type } from "class-transformer";
import { BaseModel } from "./baseModel";
import { Submission } from "./paper";
import { Session } from "./session";
import { File } from "./file";
import { TranslateService } from "@ngx-translate/core";

export enum ProceedingsContentType {
    SESSION = 'SESSION',
    SUBMISSION = 'SUBMISSION',
    FILE = 'FILE',
    WHITE_PAGE = 'WHITE_PAGE',
    INDEX = 'INDEX',
    AUTHOR_INDEX = 'AUTHOR_INDEX'
}

export class ProceedingsContent extends BaseModel {

    constructor(
        type: ProceedingsContentType,
        order: number, 
        eventId: number,
        content: File | Session | Submission
    ) {
        super();
        this.id = undefined;
        this.type = type;
        this.order = order;
        this.eventId = eventId;

        switch(type) {
            case ProceedingsContentType.FILE:
                this.file = content as File;
                break;
            case ProceedingsContentType.SESSION:
                this.session = content as Session;
                break;
            case ProceedingsContentType.SUBMISSION:
                this.submission = content as Submission;
                break;
        }
    }

    

    type: ProceedingsContentType;

    order: number | undefined;

    eventId: number;

    @Type(() => File)
    file: File;

    @Type(() => Session)
    session: Session;
    
    @Type(() => Submission)
    submission: Submission;

    // private translateService: TranslateService;

    // get name(): string {
    //     switch(this.type) {
    //         case ProceedingsContentType.SESSION:
    //             return this.session.title;
    //         case ProceedingsContentType.SUBMISSION:
    //             return this.submission.title;
    //         case ProceedingsContentType.FILE:
    //             return this.file.name;
    //         case ProceedingsContentType.WHITE_PAGE:
    //             return this.translateService.instant('admin.event.publication.proceedings.content.types.white-page');
    //             case ProceedingsContentType.INDEX:
    //             return this.translateService.instant('admin.event.publication.proceedings.content.types.index');
    //             case ProceedingsContentType.AUTHOR_INDEX:
    //             return this.translateService.instant('admin.event.publication.proceedings.content.types.author-index');
    //         default:
    //             return null;
    //     }
    // }

    static convertSessionToProceedingsContent(session: Session, eventId: number, order: number): ProceedingsContent {
        return new ProceedingsContent(ProceedingsContentType.SESSION, order, eventId, session);
    }

    static convertSubmissionToProceedingsContent(submission: Submission, eventId: number, order: number): ProceedingsContent {
        return new ProceedingsContent(ProceedingsContentType.SUBMISSION, order, eventId, submission);
    }

    static convertFileToProceedingsContent(file: File, eventId: number, order: number): ProceedingsContent {
        return new ProceedingsContent(ProceedingsContentType.FILE, order, eventId, file);
    }

}
