export class Response {
  public data: any;

  public status: {
    code: number,
    message: string,
    params: Object
  };

}
