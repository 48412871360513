import { Injectable } from '@angular/core';
import { Router, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ChairOrAdminGuard implements CanActivateChild {
  constructor(public authService: AuthService, public router: Router) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const eventId = route.params.eventId || route.parent.params.eventId;
    return this.authService.directUserIsChair(eventId).pipe(map(isChair => {
      if (!isChair) {
        this.router.navigate(['/']);
      }

      return isChair;
    }));
  }
}
