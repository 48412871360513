import { Injectable } from '@angular/core';
import { Environment } from '../environment/environment';
import { HttpClient, HttpResponse, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { File as FileClass, FileUploadErrors } from '../model/file';
import { Subject } from 'rxjs';
import { FileRules } from '../model/file.rules';
import { FileType } from 'src/app/enum/file.type';
import { Response } from '../model/response';
import { map } from 'rxjs/operators';
import { PDFDocument } from 'pdf-lib';


export interface FileDownloadEvent {
  progress: number,
  response: HttpEvent<Blob>
}

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  constructor(private http: HttpClient) { }

  // New submissions are created as PENDING.
  // If submission is valid and the user has submitted the required files, submission status should be ACTIVE.
  public requiredFilesSubmitted(trackFiles: Array<FileRules>, submittedTrackFiles: Array<FileRules>): boolean {
    const requiredFiles = trackFiles.filter(x => x.required);
    const submittedRequiredFiles = requiredFiles.filter(trackFile => submittedTrackFiles.some(
      submittedTrackFile => submittedTrackFile.id === trackFile.id
    ));
    return requiredFiles.length === submittedRequiredFiles.length;
  }

  uploadSubmissionFile(file: File, submissionID: number, trackFile: FileRules): Observable<number> {
    const validFile = this.validFile(file, trackFile);

    if (validFile !== FileUploadErrors.VALID) {
      const res = new Subject<number>();
      res.error(validFile);

      return res.asObservable();
    } else {
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      formData.append('submission', submissionID.toString());
      formData.append('trackFile', trackFile.id.toString());

      const req = new HttpRequest('POST', `${Environment.urls.API}/core/submission/file/`, formData, {
        reportProgress: true
      });

      const progress = new Subject<number>();
      this.http.request(req).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(100 * event.loaded / event.total);
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {
          progress.complete(); // The upload is complete
        }
      }, error => {
        progress.error(error);
      });

      return progress.asObservable();
    }
  }

  validFile(file: File, trackFile: FileRules): FileUploadErrors {
    if (trackFile.maxSize && trackFile.maxSize !== 0) {
      if (file.size > trackFile.maxSize) {
        return FileUploadErrors.MAX_SIZE;
      }
    }

    if (!trackFile.mimetypes.find(e => e.name === FileType.OTHER)) {
      const correctType = trackFile.mimetypes.find(e => e.name === file.type);

      let isVideo = false;
      if (trackFile.mimetypes.find(e => e.name === FileType.VIDEO)) {
        isVideo = file.type.startsWith('video/');
      }

      if (!correctType && !isVideo) {
        return FileUploadErrors.INVALID_FORMAT;
      }
    }

    return FileUploadErrors.VALID;
  }

  validFileAsync(file: File, trackFile: FileRules): Observable<FileUploadErrors> {
    return new Observable<FileUploadErrors>(observer => {
      if (trackFile.maxSize && trackFile.maxSize !== 0) {
        if (file.size > trackFile.maxSize) {
          return observer.next(FileUploadErrors.MAX_SIZE);
        }
      }

      if (!trackFile.mimetypes.find(e => e.name === FileType.OTHER)) {
        const correctType = trackFile.mimetypes.find(e => e.name === file.type);

        let isVideo = false;
        if (trackFile.mimetypes.find(e => e.name === FileType.VIDEO)) {
          isVideo = file.type.startsWith('video/');
        }

        if (!correctType && !isVideo) {
          return observer.next(FileUploadErrors.INVALID_FORMAT);
        }
      }

      if (file.type != 'application/pdf') return observer.next(FileUploadErrors.VALID); 

      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = async () => {
        const pdf = await PDFDocument.load(reader.result);
        const count = pdf.getPageCount();

        if (count > trackFile.maxPages) {
          return observer.next(FileUploadErrors.MAX_PAGES);
        }

        return observer.next(FileUploadErrors.VALID);
      };
    });
  }

  deleteFile(id: number): Observable<Response> {
    return this.http.delete<Response>(Environment.urls.API + `/core/submission/file/${id}/`);
  }

  downloadFile(fileId: number):  Observable<any> {
      return this.http.get<Response>(Environment.urls.API + `/core/submission/downloadFile/${fileId}/`, {responseType: 'blob' as 'json'});
  }

  downloadFileWithProgress(fileId: number):  Observable<FileDownloadEvent> {
    return this.http.get<Blob>(Environment.urls.API + `/core/submission/downloadFile/${fileId}/`, {
      observe: 'events',
      reportProgress: true,
      responseType: 'blob' as 'json'
    }).pipe(
       map((event) => ({
         progress: this.getPercentage(event),
         response: event
       })
    ));
  }

  downloadTrackFiles(fileURL: string):  Observable<any> {
      return this.http.get<Response>(fileURL, {responseType: 'blob' as 'json'});
  }

  getFileBySubmissionTrackFile(submissionId: number, trackFileId: number): Observable<any> {
    const params = {};
    params['params'] = { submissionId, trackFileId };

    return this.http.get<Response>(Environment.urls.API + `/core/submission/file/getFileBySubmissionTrackFile/`, params).pipe(map(v => v.data));
  }

  private getPercentage(event :any): number | null {
    if(event && event.type === HttpEventType.DownloadProgress && event.total) {
      return Math.round(100 * (event.loaded / event.total));
    }
    return null;
  }
}
