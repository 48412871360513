import { Transform } from 'class-transformer';
import * as dayjs from 'dayjs';
import { BaseModel, toDayjs } from './baseModel';

export class EventInfo extends BaseModel {
  public description: string;
  public city: string;
  public state: string;
  public country: string;
  public timeZone: string;

  @Transform(toDayjs)
  public makePublicAt: dayjs.Dayjs;
  @Transform(toDayjs)
  public beginAt: dayjs.Dayjs;
  @Transform(toDayjs)
  public endAt: dayjs.Dayjs;
  @Transform(toDayjs)
  public tpcInvitationDueAt: dayjs.Dayjs;

  public logo: string;

  constructor() { super(); }
}
