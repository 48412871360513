import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarItem } from '../../model/sidebar-item';

@Component({
  selector: 'app-sidebar-item-admin',
  templateUrl: './sidebar-item-admin.component.html',
  styleUrls: ['./sidebar-item-admin.component.scss']
})
export class SidebarItemAdminComponent implements OnInit {
  @Input() item: SidebarItem;
  @Output() selectedItem = new EventEmitter<SidebarItem>();
  hasCustomIconFont: boolean;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.hasCustomIconFont = typeof this.item.icon !== 'string' && !!this.item.icon.font;
  }

  selectItem(): void {
    this.selectedItem.emit(this.item);
  }
}
