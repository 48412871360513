import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { EmailRegister } from '../../../../model/emailRegister';
import { EmailStatus } from '../../../../enum/emails.status';
import { Event } from 'src/app/model/event';

@Component({
  selector: 'app-email-status',
  templateUrl: './email-status.component.html',
  styleUrls: ['./email-status.component.scss']
})
export class EmailStatusComponent implements OnInit {
  event: Event;
  emailList: Array<EmailRegister>;
  statusIcon: { fontSet: string, fontIcon: string };
  loaded = false;
  eventTimeZone: string;

  constructor(
    private adminService: AdminService,
    private eventService: EventsService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.adminService.progressBar.start();
      this.route.params.subscribe(params => {
        this.event = this.adminService.selectedEvent;
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.eventTimeZone = this.event.eventInfo.timeZone;
          this.eventService.getEmailStatusByEvent(event.id).subscribe(status => {
            this.emailList = status;

            for (var index in this.emailList) {
              this.emailList[index].cc = this.emailList[index].cc.replace(']', '');
              this.emailList[index].cc = this.emailList[index].cc.replace('[', '');
              this.emailList[index].cc = this.emailList[index].cc.replace(/'/g, '');
            }
          }, () => { },
            () => {
              this.adminService.progressBar.stop();
              this.loaded = true;
            });
        });
      });
    });
  }

  toggleDisplayInfo(status: EmailRegister) {
    status.displayInfo = !status.displayInfo;
  }

  getStatusLabel(email: EmailRegister) {
    switch (email.status){
      case EmailStatus.PENDING:
        return 'admin.event.email-status.status.pending';

      case EmailStatus.SENT:
        return 'admin.event.email-status.status.sent';

      case EmailStatus.REJECTED:
        return 'admin.event.email-status.status.rejected'

      default:
        return 'admin.event.email-status.status.undefined'
    }
  }

  getStatusFontIcon(email: EmailRegister){
    return EmailStatus.icon(email.status).fontIcon;
  }

  getStatusFontSet(email: EmailRegister){
    return EmailStatus.icon(email.status).fontSet;
  }
}
