import { Component, Input } from '@angular/core';
import { Review } from 'src/app/model/paper';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-start-edit-review',
  templateUrl: './start-edit-review.component.html',
  styleUrls: ['./start-edit-review.component.scss']
})
export class StartEditReviewComponent {
  @Input()
  public review: Review;

  public get assignedBy(): string {
    return (<User>this.review.assignedBy)?.name;
  }
}
