import { Component, Input, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { FormFieldType } from '../../enum/form.field.type';
import { SelectOption } from 'src/app/model/select.option';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-form-question',
  templateUrl: './form-question.component.html',
  styleUrls: ['./form-question.component.scss']
})
export class FormQuestionComponent {

  @Input() formController: FormGroup;
  @Input() index: number;
  @Input() formType: string;
  @Input() formForm: FormGroup;
  @Input() blockEdition: boolean;
  fieldTypeValues: Array<SelectOption> = FormFieldType.toSelectable();

  constructor(private fb: FormBuilder) {}

  addNewChoice(): void {
    const control = <FormArray>this.formController.get('choices');
    const newControl = this.fb.group({
      description: ['New Choice', [Validators.required, Validators.minLength(1), Validators.maxLength(500)]],
      order: [1],
      value: [0, [Validators.required, Validators.min(0)]],
    });
    control.push(newControl);
  }

  deleteChoice(choiceIndex: number): void {
    let formUpdate = this.formController.controls.choices as FormArray;
    formUpdate.removeAt(choiceIndex);
  }

  deleteQuestion(choiceIndex: number): void {
    let formUpdate = this.formForm.controls.fields as FormArray;
    formUpdate.removeAt(choiceIndex);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.formController.controls['choices']['controls'], event.previousIndex, event.currentIndex);
    moveItemInArray(this.formController.value.choices, event.previousIndex, event.currentIndex);
  }
}
