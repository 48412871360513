import { Component, OnInit } from '@angular/core';
import { Event } from '../../../model/event';
import { AdminService } from '../../../service/admin.service';
import { SidebarItem, AdminSidebarItem } from '../../../model/sidebar-item';
import { Dashboard } from 'src/app/model/dashboard';

class LinkListItem {
  link: string;
  label: string | { full: string, short: string };
  children?: Array<LinkListItem>;
}

@Component({
  selector: 'app-admin-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.scss']
})
export class AdminEventViewComponent implements OnInit {
  event: Event;
  linkList: Array<LinkListItem>;

  dashboards: Array<Dashboard>;
  defaultDashboardIndex: number;

  constructor(private adminService: AdminService) { }

  ngOnInit() {
    setTimeout(() => {
      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.initEventView();
        });
      } else {
        this.initEventView();
      }
    });
  }

  initEventView() {
    this.createLinkList();
    this.adminService.getEventDashboards().subscribe(dashboards => {
      this.dashboards = dashboards;
      this.defaultDashboardIndex = this.dashboards.findIndex(d => d.principal);

      // Assembles a key for each graph title (for translating purposes)
      this.dashboards.map(dashboard => dashboard.graphPosition.map(graph => {
        graph.title='dataset.'+graph.dataset['category']+'.'+graph.dataset['datasetName']
        return graph
      }))
    });
  }

  saveFavoriteDashboard(event: any) {
    const currentIndexTab = event.index;
    this.dashboards.forEach((dashboard, i) => {
      if (dashboard.id) {
        const isPrincipal = i === currentIndexTab;
        this.adminService.updateDashboard(dashboard.id, {
          title: dashboard.title,
          order: dashboard.order,
          principal: isPrincipal
        }).subscribe({
          next: () => {},
          complete: () => this.updateDashboardsLocal(i)
        });
      }
    });
  }
  
  private updateDashboardsLocal(index: number) {
    if (index === this.dashboards.length) {
      this.adminService.getEventDashboards().subscribe(dashboards => {
        this.dashboards = dashboards;
        this.defaultDashboardIndex = this.dashboards.findIndex(d => d.principal);
      });
    }
  }

  createLinkList(): void {
    const items = [...this.adminService.sidebar.topItems, ...this.adminService.sidebar.bottomItems];
    this.linkList = items.map(item => (
      {
        link: item.link,
        label: item.label,
        children: (<AdminSidebarItem[]>item.children) ?.map(e => ({
          link: e.link,
          label: e.label
        }))
      }
    ));
  }
}
