import { Affiliation } from './affiliation';
import { BaseModel } from './baseModel';
import { Type } from 'class-transformer';
import { ProfileTitle } from '../enum/profile.title';
import { ActingArea } from '../enum/acting.area';
import { ViewMode } from '../enum/view.mode';
import { Environment } from '../environment/environment';
import { CountryPipe } from '../pipe/country.pipe';

export const DICT_THEME = {
  LIGHT: 'light-theme',
  DARK: 'dark-theme',
  SYSTEM: 'system-theme'
};
export class Profile extends BaseModel {
  @Type(() => Affiliation)
  affiliation: Affiliation;

  id: number;
  image?: string;
  imageAvatar?: string;
  imageTimestamp?: number = (new Date()).getTime();
  imageAvatarTimestamp?: number = (new Date()).getTime();
  firstAlternativeEmail?: string;
  secondAlternativeEmail?: string;
  publicPageUrl?: string;
  shortBio?: string;
  orcid?: string;
  lattes?: string;
  phoneNumber?: string;
  country: string;
  language: string;

  profileTitle: ProfileTitle;
  actingArea: ActingArea;

  viewMode: ViewMode;
  statusIcon: boolean;
  statusColorAsBackgroundColor: boolean;
  notify: boolean;
  theme: string;

  constructor() { super(); }

  get imageUrl(): string {
    return this.image ? `${Environment.urls.API}${this.image}?${this.imageTimestamp}` : null;
  }

  get imageAvatarUrl(): string {
    return this.imageAvatar ? `${Environment.urls.API}${this.imageAvatar}?${this.imageAvatarTimestamp}` : this.imageUrl;
  }

  get getProfileTitle(): string {
    return ProfileTitle[this.profileTitle];
  }

  get userCountry(): string {
    return this.country ? this.country : null;
  }

  get userAffiliation(): string {
    return this.affiliation ? this.affiliation.name : null;
  }

  get userTheme(): string {
    return DICT_THEME[this.theme];
  }
}
