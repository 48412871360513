import { User } from './user';
import { Type } from 'class-transformer';
import { Track } from './track';
import { Transform } from 'class-transformer';
import * as dayjs from 'dayjs';
import { toDayjs } from './baseModel';
import { FormFieldType } from '../enum/form.field.type';
import { BaseModel } from './baseModel';
import { FormFieldCategory } from '../enum/form-field-category';
import { Submission } from './paper';

export enum CheckListRole {
  author,
  reviewer,
  sessionChair,
  publicationChair,
  chair
}

export enum CheckListAnswerStatus {
  OK = 'OK',
  NOT_FOUND = 'NOT_FOUND',
  UNRELATED = 'UNRELATED',
  DUPLICATED = 'DUPLICATED',
  UNCATEGORIZED = 'UNCATEGORIZED',
  INVALID = 'INVALID',
  DELETING = 'DELETING'
}

export namespace CheckListRole {
  export function toIterable(): Array<string> {
    return Object.keys(CheckListRole).filter(key => typeof CheckListRole[key] !== 'function');
  }

  export function valueToKey(value: number): string {
    return CheckListRole.toIterable().find(v => CheckListRole[v] === value);
  }
}

export class CheckListAnswer extends BaseModel {

  constructor() {
    super();
    this.status = CheckListAnswerStatus.UNCATEGORIZED;
  }

  value: boolean;
  checklistField: number;
  submission: Submission | string;
  submissionId;
  checkedBy: number;
  order: number;
  status: CheckListAnswerStatus;
}

export class CheckListField extends BaseModel {
  @Type(() => Track)
  public track: Track;
  public event: Event;

  public id: number;

  public type: FormFieldType;
  public category: FormFieldCategory;

  public description: string;
  public order: number;

  public visibleTo: {
    author: boolean,
    reviewer: boolean,
    sessionChair: boolean,
    publicationChair: boolean
  };

  public modifiableBy: {
    author: boolean,
    reviewer: boolean,
    sessionChair: boolean,
    publicationChair: boolean
  };

  @Type(() => User)
  public editedBy: User;

  @Type(() => CheckListAnswer)
  public answer?: CheckListAnswer;


  modifiable(role: CheckListRole): boolean {
    // Se usuário é chair (que não está nas opções do checklist), ele pode sempre ver e modificar.
    // Caso não for, verifica para o campo de acordo com o papel do usuário.

    if (role === CheckListRole.chair) {
      return true;
    }

    return this.modifiableBy[CheckListRole.valueToKey(role)];
  }

  userModifiable(userRoles: Array<CheckListRole>): boolean {
    return userRoles.some(role => this.modifiable(role));
  }
}
