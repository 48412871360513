
import { SelectOption } from '../model/select.option';

export enum ReviewStatus {
  ASSIGNED = 'ASSIGNED',
  NOTIFIED = 'NOTIFIED',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED',
  REMINDED = 'REMINDED',
  LATE = 'LATE',
  DELEGATED = 'DELEGATED',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
  DELETED = 'DELETED'
}

export namespace ReviewStatus {
  export function toIterable(): Array<string> {
    return Object.keys(ReviewStatus).filter(key => typeof ReviewStatus[key] !== 'function' && key !== ReviewStatus.DELETED && key !== ReviewStatus.ASSIGNED && key !== ReviewStatus.DELEGATED);
  }

  export function toString(status: ReviewStatus): string {
    return `status.${status}`;
  }

  export function toClass(status: ReviewStatus) {
    return status.toLowerCase();
  }

  export function toSelectable(): Array<SelectOption> {
    return ReviewStatus.toIterable().map(value => ({
      id: value,
      value: ReviewStatus[value]
    }));
  }

  export function icon(status: ReviewStatus): { fontSet: string, fontIcon: string } {
    switch (status) {
      case ReviewStatus.ASSIGNED:
      case ReviewStatus.NOTIFIED:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-envelope'
        };
      case ReviewStatus.DRAFT:
      case ReviewStatus.CONFIRMED:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-check'
        };
      case ReviewStatus.DECLINED:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-times'
        };
      case ReviewStatus.REMINDED:
        return {
          'fontSet': 'far',
          'fontIcon': 'fa-sticky-note'
        };
      case ReviewStatus.LATE:
        return {
          'fontSet': 'far',
          'fontIcon': 'fa-clock'
        };
      case ReviewStatus.DELEGATED:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-user-edit'
        };
      case ReviewStatus.COMPLETED:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-comment-dots'
        };
      // case ReviewStatus.DELETED:
      //   return {
      //     'fontSet': 'fas',
      //     'fontIcon': 'fa-trash'
      //   };
      default:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-ellipsis-h'
        };
    }
  }
}
