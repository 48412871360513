import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NotificationService } from '../../service/notification.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { EventsService } from 'src/app/service/events.service';
import { UserService } from '../../service/user.service';
import { AuthService } from '../../service/auth.service';
import { InfoService } from '../../service/info.service';
import { Event } from '../../model/event';
import { Submission, Review, SubmissionStatus } from 'src/app/model/paper';
import { User } from '../../model/user';
import { NavbarService } from '../../service/navbar.service';
import { Observable, Subscription, of, forkJoin } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-ieee-electronic-copyright',
  templateUrl: './ieee-electronic-copyright.component.html',
  styleUrls: ['./ieee-electronic-copyright.component.scss']
})
export class IeeeElectronicCopyrightComponent implements OnInit, OnDestroy {
  ieeeForm: FormGroup;
  @Input() submission: Submission;
  loaded = false;
  userIsChair = false;
  subscriptions: Subscription[] = [];

  showForm = false;

  constructor(
    private notificationService: NotificationService,
    private submissionsService: SubmissionsService,
    private eventsService: EventsService,
    public authService: AuthService,
    private infoService: InfoService,
    private fb: FormBuilder,
    public navbarService: NavbarService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.navbarService.loadingStart();

      this.route.queryParams.subscribe(({ refreshCache }) => {
        this.submissionsService
          .getSubmission(parseInt(this.route.snapshot.paramMap.get('id'), 10), refreshCache)
          .subscribe(submission => {
            this.eventsService
            .getEventByTrack(submission.trackID)
            .subscribe(event => {
                this.submission = submission;
                this.submission.event = event;
                this.submission.track = event.tracks.find(track => submission.trackID === track.id);

                this.navbarService.loadingStop();

                const parentEvent = Number(this.route.parent.snapshot.params.eventId);
                if (parentEvent && parentEvent !== submission.event.id) {
                  this.router.navigate(['/error/403']);
                }
              },
              () => {},
              () => {
                this.setPermissions();
              });
          });
      });
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.navbarService.loadingStop();
  }

  public get isAuthor(): boolean {
    return this.submission.authors.some(author => author.user.id === this.infoService.user.id);
  }

  private setPermissions(): void {
    this.authService.userIsAdminOrChair(this.submission.event.id)
    .subscribe(isAdminOrChair => {
      this.userIsChair = isAdminOrChair;
    },
    () => {},
    () => {
      if (this.submission && this.submission.status === SubmissionStatus.ACCEPTED && this.submission.event.ieeeCopyrightEnable && (this.userIsChair || this.isAuthor)) {
        this.initForm();
      }
      this.loaded = true;
    });
  }

  initForm(): void {
    this.ieeeForm = this.fb.group({
      PubTitle: [this.submission.event.ieeePubTitle],
      ArtSource: [this.submission.event.ieeeArtSource],
      rtrnurl: [window.location.href.split("/ieeeElectronicCopyright")[0]],
      ArtId: [`${this.submission.id}`],
      ArtTitle: [this.submission.title, [Validators.required, Validators.minLength(2)]],
      AuthName: [this.formatAuthName(), [Validators.required, Validators.minLength(2)]],
      AuthEmail: [this.formatAuthEmail(), [Validators.required, Validators.minLength(2)]]
    });
    this.showForm = true;
    this.navbarService.loadingStop();
  }

  formatAuthName(): string {
    let authNames = '';
    if (this.submission) {
      this.submission.authors.forEach(author => {
        if (authNames === '') {
          authNames = author.user.name;
        } else {
          authNames = `${authNames} and ${author.user.name}`;
        }
      });
    }
    return authNames;
  }

  formatAuthEmail(): string {
    let authEmails = '';
    if (this.submission) {
      this.submission.authors.forEach(author => {
        if (authEmails === '') {
          authEmails = author.user.email;
        } else {
          authEmails = `${authEmails}, ${author.user.email}`;
        }
      });
    }
    return authEmails;
  }

  submit(): void {
    this.navbarService.loadingStart();

    const mapForm = document.createElement('form');
    mapForm.method = 'POST';
    mapForm.action = 'https://ecopyright.ieee.org/ECTT/IntroPage.jsp';
    mapForm.style.display = 'none';
    mapForm.append('Content-Type', 'text/html; charset=UTF-8');

    const mapInput = document.createElement('input');
    mapInput.type = 'hidden';
    mapInput.name = 'PubTitle';
    mapInput.value = this.ieeeForm.get('PubTitle').value;
    mapForm.appendChild(mapInput);

    const mapInput1 = document.createElement('input');
    mapInput1.type = 'hidden';
    mapInput1.name = 'ArtSource';
    mapInput1.value = this.ieeeForm.get('ArtSource').value;
    mapForm.appendChild(mapInput1);

    const mapInput2 = document.createElement('input');
    mapInput2.type = 'hidden';
    mapInput2.name = 'rtrnurl';
    mapInput2.value = this.ieeeForm.get('rtrnurl').value;
    mapForm.appendChild(mapInput2);

    const mapInput3 = document.createElement('input');
    mapInput3.type = 'hidden';
    mapInput3.name = 'ArtId';
    mapInput3.value = this.ieeeForm.get('ArtId').value;
    mapForm.appendChild(mapInput3);

    const mapInput4 = document.createElement('input');
    mapInput4.type = 'hidden';
    mapInput4.name = 'ArtTitle';
    mapInput4.value = this.ieeeForm.get('ArtTitle').value;
    mapForm.appendChild(mapInput4);

    const mapInput5 = document.createElement('input');
    mapInput5.type = 'hidden';
    mapInput5.name = 'AuthName';
    mapInput5.value = this.ieeeForm.get('AuthName').value;
    mapForm.appendChild(mapInput5);

    const mapInput6 = document.createElement('input');
    mapInput6.type = 'hidden';
    mapInput6.name = 'AuthEmail';
    mapInput6.value = this.ieeeForm.get('AuthEmail').value;
    mapForm.appendChild(mapInput6);

    document.body.appendChild(mapForm);
    mapForm.submit();
  }

  cancel(): void {
    this.router.navigate(['/submissions/', this.submission.id]);
  }
}
