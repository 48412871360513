import { Component, OnInit, ElementRef, EventEmitter, Output, ViewChild, Input, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavbarService } from 'src/app/service/navbar.service';
import { SidebarItem, AdminSidebarItem } from '../../model/sidebar-item';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-go-to-element',
  templateUrl: './go-to-element.component.html',
  styleUrls: ['./go-to-element.component.scss'],
})

export class GoToElementComponent implements OnInit {
  @Input() searchForm: FormGroup;
  elementId: number;
  eventId: string;

  @Input() selectedElement: string;

  constructor(
    private navbarService: NavbarService,
    private fb: FormBuilder,
    public router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId');
  }

  goToElement() {
    this.elementId = this.searchForm.value.elementId;
    if (this.elementId) {
      var link = document.createElement('a');
      link.href = "/admin/" + this.eventId + "/"+ this.selectedElement + "/" + this.elementId;
      link.target = '_blank';
      link.click();
    }
  }
}
