import { Component, OnInit } from '@angular/core';
import { UserEventAnswer } from '../../enum/user.event.answer';
import { InfoService } from '../../service/info.service';
import { AuthService } from '../../service/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EventsService } from 'src/app/service/events.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/service/notification.service';
import { Event } from 'src/app/model/event';
import { UserEvent } from 'src/app/model/user.event';
import { User } from 'src/app/model/user';


@Component({
  selector: 'app-confirm-tpcinvite',
  templateUrl: './confirm-tpcinvite.component.html',
  styleUrls: ['./confirm-tpcinvite.component.scss']
})
export class ConfirmTPCInviteComponent implements OnInit {
  form: FormGroup;
  answerValues = UserEventAnswer.toSelectable();
  event: Event;
  permission = false;
  userIsChair = false;
  userInvited: User;
  userLogged: User;

  constructor(
    public fb: FormBuilder,
    public eventsService: EventsService,
    public route: ActivatedRoute,
    public notificationService: NotificationService,
    public infoService: InfoService,
    public authService: AuthService,
    public router: Router
  ) { }

  ngOnInit() {
    this.route.params.subscribe(({ id, eventId }) => {
      this.eventsService.getUserEvent(id).subscribe(userEvent => {
        this.userInvited = userEvent.user;
        if (userEvent.invitationAnswer.toLowerCase() !== UserEventAnswer.NOT_ANSWERED) {
          this.router.navigate(['/404']);
        }
      }, (e) => {
        if (e.status === 404) {
          this.router.navigate(['/404']);
        }
      }, () => {
        this.userLogged = this.infoService.user;
        this.eventsService.getEvent(eventId).subscribe(event => {
          this.event = event;
          this.authService.userIsAdminOrChair(event.id).subscribe(isAdminOrChair => {
            this.userIsChair = isAdminOrChair;
          }, () => {}, () => {
            this.validPermission();
            this.initForm();
            this.answerValues.splice(2, 1);
          });
        });
      });
    });
  }

  initForm(): void {
    this.form = this.fb.group({
      answer: ['', Validators.required]
    });
  }

  validPermission(): void {
    if (this.userIsChair || this.userInvited.id == this.userLogged.id) {
      this.permission = true;
    }
  }

  submitForm(): void {
    const answer = this.form.value.answer.toUpperCase();

    this.route.params.subscribe(({ id, eventId }) => {
      this.eventsService.confirmInvitation(id, answer).subscribe(() => {
        this.notificationService.notify('admin.event.people.tpc.sent-tpc-invitation-response');

        switch (answer.toLowerCase()) {
          case UserEventAnswer.ACCEPTED:
            return this.router.navigate(['/', 'events', this.event.id, 'defineTopics']);
          case UserEventAnswer.REJECTED:
          default:
            return this.router.navigate(['/']);
        }
      });
    });
  }

  setValue(value):void{
    this.form.value.answer = value;
  }
}
