import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NotificationService } from '../../service/notification.service';
import { UserService } from '../../service/user.service';
import { User } from '../../model/user';
import { Event } from '../../model/event';
import { NavbarService } from '../../service/navbar.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  userSignupForm: FormGroup;

  @Output() userCreated = new EventEmitter<User>();
  @Output() userCreateCancel = new EventEmitter();

  constructor(
    private notificationService: NotificationService,
    private userService: UserService,
    private fb: FormBuilder,
    public navbarService: NavbarService
  ) { }

  ngOnInit(): void {
    this.initUserSignupForm();
  }

  initUserSignupForm(): void {
    this.userSignupForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  createUser(): void {
    if (this.userSignupForm.valid) {
      this.navbarService.loadingStart();

      this.notificationService.notify('forms.creating-user',
        { params: { user: this.userSignupForm.value.firstName + ' ' + this.userSignupForm.value.lastName } });

      this.userService.create(this.userSignupForm.value).subscribe(
        user => {
          this.userCreated.emit(user);
          this.userCreateCancel.emit();
          this.navbarService.loadingStop();
          this.userSignupForm.reset();
        },
        error => {
          this.notificationService.notifyError('errors.could-not-create-user');
          this.navbarService.loadingStop();
        }
      );
    }
  }
}
