import { Component, forwardRef, Input } from '@angular/core';
import { TextInputComponent } from '../text-input/text-input.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PasswordInputComponent),
    multi: true
  }]
})
export class PasswordInputComponent extends TextInputComponent {
  @Input() showPasswordIcon = false;
  @Input() required = false;

  passwordHidden = true;

  @Input() showLandingExtraInfo = false;
}
