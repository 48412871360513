import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Affiliation } from '../model/affiliation';
import { Environment } from '../environment/environment';
import { Observable, of } from 'rxjs';
import { Country } from '../model/country';
import { TimeZone } from '../model/timeZone';
import { Response } from '../model/response';
import { UserSearch } from '../model/user.search';
import { SearchResult } from '../model/search.result';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { SearchDomain } from '../enum/search.domain';
import { Event } from '../model/event';
import { State, City } from 'country-state-city';
import * as isoCountry from 'i18n-iso-countries';
const libCountry = require('country-state-city').Country;

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  private availableCountries: Country[];

  constructor(private http: HttpClient) { }

  getAffiliations(search: string = ''): Observable<Affiliation[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/institution/search/?search=${search}`)
      .pipe(map(res => plainToClass(Affiliation, res.data as Affiliation[])));
  }

  getAllAffiliations(): Observable<any> {
    return this.http.get<Response>(`${Environment.urls.API}/core/institution/`);
  }

  search(search: UserSearch): Observable<SearchResult> {
    return this.http.get<Response>(`${Environment.urls.API}/core/search/?query=${search.query}&where=${search.where}`)
      .pipe(map(res => plainToClass(SearchResult, res.data as SearchResult)));
  }

  searchInEvent(search: UserSearch, eventId: number): Observable<SearchResult> {
    return this.http.get<Response>(`${Environment.urls.API}/core/searchInEvent/${eventId}/?query=${search.query}&where=${search.where}`)
      .pipe(map(res => plainToClass(SearchResult, res.data as SearchResult)));
  }

  searchEvent(query: string): Observable<Event[]> {
    return this.search(new UserSearch(query, SearchDomain.EVENT)).pipe(map(e => e.events));
  }

  getCountryList(): Observable<Country[]> {
    // const cache = localStorage.getItem('country_list');
    // if (cache) {
    //   return of(plainToClass(Country, JSON.parse(cache) as Country[]));
    // }

    return this.http.get<Response>(`${Environment.urls.API}/core/countries/`)
      .pipe(map(res => plainToClass(Country, res.data as Country[])))
      .pipe(map(countries => {
        localStorage.setItem('country_list', JSON.stringify(countries));
        return countries;
      }));
  }

  get countries(): Observable<Country[]> {
    if (this.availableCountries?.length > 0) {
      return of(this.availableCountries);
    }

    return this.getCountryList().pipe(map(countries => {
      this.availableCountries = countries;
      return this.availableCountries;
    }));
  }

  public get AllCities() {
    return City.getAllCities();
  }

  public getNameStateByCodeAndCountry(state, country): string {
    return State.getStateByCodeAndCountry(state, country).name;
  }

  public getNameCountryByCode(country): string {
    return libCountry.getCountryByCode(country).name;
  }

  public transformAlpha2ToAlpha3(alpha2): string {
    return isoCountry.alpha2ToAlpha3(alpha2);
  }
}
