import { BaseModel } from './baseModel';

export class Answer extends BaseModel {
  public fieldChoice: number;

  constructor(
    public formField: number,
    public value?: string | number | boolean,
    public review?: number,
    public submission?: number
  ) { super(); }
}
