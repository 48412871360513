import { SelectOption } from '../model/select.option';

export enum ViewMode {
  DEFAULT = 'Default',
  CONFORTABLE = 'Confortable',
  COMPACT = 'Compact'
}

export namespace ViewMode {
  export function toIterable(): Array<string> {
    return Object.keys(ViewMode).filter(key => typeof ViewMode[key] !== 'function');
  }

  export function toSelectable(): Array<SelectOption> {
    return ViewMode.toIterable().map((value, index) => ({
      id: value,
      value: ViewMode[value],
      i18n: 'profile.update.modes.' + value.toLowerCase()
    }));
  }
}
