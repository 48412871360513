import { BaseModel, toDayjs } from "./baseModel";
import { User } from "./user";
import { Type, Transform } from 'class-transformer';
import * as dayjs from 'dayjs';

export enum ProxyHistoryAction {
    ENTERED = 'ENTERED',
    LEFT = 'LEFT'
}

export class ProxyHistory extends BaseModel {

    action: ProxyHistoryAction;

    @Type(() => User)
    admin: User;

    @Type(() => User)
    user: User;

    @Transform(toDayjs)
    createdAt: dayjs.Dayjs;

    host: string;

}
