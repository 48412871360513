import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../service/notification.service';
import { Notification } from '../../model/notification';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  public notifications: Notification[];
  public ready = false;

  constructor(public notificationService: NotificationService) { }

  ngOnInit() {
    this.notificationService.refreshNotifications();
    this.notifications = this.notificationService.data.notifications;
    this.ready = true;
  }

}
