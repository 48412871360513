import { TimezoneService } from "../service/timezone.service";
import { DomService } from "../service/dom.service";
import { FormGroup } from "@angular/forms";
import { ElementRef } from "@angular/core";

export class DateTimePickerHandler {
    public form: FormGroup;
    public clonedForm: FormGroup;
    public timeZoneController: string;
    public dateTimeController: string;
    public timeZoneInput: HTMLInputElement;
    public selector: string;
    public timeZoneEvent: string;
    private selectedTimeZone: string;
    private isViewCalendar: boolean = true;
    private closeButton: HTMLElement;
    private containerTimeZoneInput: Element;

    constructor(
        private tzService: TimezoneService,
        private domService: DomService,
    ) {}

    /* Handler of Time Zone Input */
    public generateInputInDateTime(selector: string, form: FormGroup, clonedForm: FormGroup, timeZoneController: string, dateTimeController: string, timeZoneInput: Element, timeZoneEvent?: string): void {
        this.form = form;
        this.clonedForm = clonedForm;
        this.timeZoneController = timeZoneController;
        this.dateTimeController = dateTimeController;
        this.selector = selector;
        this.timeZoneEvent = timeZoneEvent;
        this.getDateTimeHTML(selector);
        this.containerTimeZoneInput = timeZoneInput;
    }

    public getDateTimeHTML(selector: string, isEventListenerAdded?: boolean): void {
        const catchHTML = setInterval(() => {
            const dateTimeContainer = document.querySelector(selector);
            const periodButton = document.querySelector('.mat-calendar-period-button');

            if (dateTimeContainer) {
                clearInterval(catchHTML);
                this.addTimeZoneInputInHTML(dateTimeContainer);

                const buttonContainer = dateTimeContainer.nextElementSibling;
                this.addButtonClose(buttonContainer);
            }

            if (periodButton && !isEventListenerAdded) {
                this.setTimeZoneInputByPeriodButton(periodButton);
            }
        }, 10);
    }

    public addOnlyButtonClose(selector: string, form: FormGroup, clonedForm: FormGroup, dateController: string, isEventListenerAdded?: boolean): void {
        const catchHTML = setInterval(() => {
            const periodButton = document.querySelector('.mat-calendar-period-button');
            const buttonContainer = document.querySelector(selector);

            if (buttonContainer) {
                clearInterval(catchHTML);
                this.addButtonClose(buttonContainer);

                if (!isEventListenerAdded) {
                    periodButton.addEventListener('click', () => {
                        this.addOnlyButtonClose(selector, form, clonedForm, dateController, true);
                    });
                }

                listenConfirmButton(form, clonedForm, dateController);
            }
        }, 10);

        const listenConfirmButton = (form: FormGroup, clonedForm: FormGroup, dateController: string) => {
            const DateTimeContainer = document.querySelector('ngx-mat-datetime-content');
            const confirmButton = Array.from(DateTimeContainer.querySelectorAll('button')).pop();

            confirmButton.addEventListener('click', () => {
                const dueAt = clonedForm.get(dateController).value;
                const cloneDate = new Date(dueAt);

                form.get(dateController).setValue(cloneDate);
            });
        }
    }

    private addButtonClose(buttonContainer: Element): void {
        if (!this.closeButton) {
            const button = buttonContainer.querySelector('button');
            this.closeButton = button.cloneNode(true) as HTMLElement;
            this.closeButton.removeAttribute('disabled');
            this.closeButton.style.cursor = 'pointer';

            const icon = this.closeButton.querySelector('mat-icon') as HTMLElement;
            icon.textContent = 'close';

            this.closeButton.addEventListener('click', () => {
                this.domService.closeCalendar(true);
            });

            buttonContainer.prepend(this.closeButton);

            return;
        }

        buttonContainer.prepend(this.closeButton);
    }

    private setTimeZoneInputByPeriodButton(periodButton: Element): void {
        periodButton.addEventListener('click', () => {
            this.isViewCalendar = !this.isViewCalendar;

            this.getDateTimeHTML(this.selector, true);
        });
    }

    public addTimeZoneInputInHTML(containerHTML: Element): void {
        if (this.timeZoneInputAlreadyExist(containerHTML)) return;

        containerHTML.appendChild(this.containerTimeZoneInput);
        this.listenButtonConfirmDateTime();
    }

    private timeZoneInputAlreadyExist(container: Element): boolean {
        return container.getElementsByTagName('select').length > 0;
    }

    public listenButtonConfirmDateTime(): void {
        const DateTimeContainer = document.querySelector('ngx-mat-datetime-content');
        const confirmButton = Array.from(DateTimeContainer.querySelectorAll('button')).pop();

        confirmButton.addEventListener('click', () => {
            this.form.get(this.timeZoneController).setValue(this.selectedTimeZone || this.tzService.browserTimeZone);
            
            const dueAt = this.clonedForm.get(this.dateTimeController).value;
            const cloneDate = new Date(dueAt);
            const timeZone = this.form.get(this.timeZoneController).value;

            this.form.get(this.dateTimeController).setValue(this.tzService.convertTimeToBrowserTimezone(cloneDate, timeZone));

        });
    }

    public set timeZoneSelected(timeZone: string) {
        this.selectedTimeZone = timeZone;
    }

    /* Handler of Date Time Mask */
    public removeInputDateTime(component: ElementRef, selectorInput: string, selectorDateTimeMask: string): void {
        const containerInput = component?.nativeElement.querySelector(selectorInput);
        const input = containerInput?.querySelector('input');

        if (input) {
            input.remove();
            this.addDateTimeMaskInInput(component, selectorInput, selectorDateTimeMask);
            return;
        }
    }

    public addDateTimeMaskInInput(component: ElementRef, selectorInput: string, selectorDateTimeMask: string): void {
        const containerInput = component?.nativeElement.querySelector(selectorInput);
        const containerDateTimeMask = component?.nativeElement.querySelector(selectorDateTimeMask);
        
        if (containerDateTimeMask) {
            containerInput.prepend(containerDateTimeMask);
            return;
        }
    }
}
