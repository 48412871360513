import {Component, OnInit, OnChanges, Input, ViewChild} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { AdminService } from 'src/app/service/admin.service';
import { Event } from 'src/app/model/event';
import { AuthService } from 'src/app/service/auth.service';
import { SearchButtonComponent} from '../search-button/search-button.component';
import { InfoService } from 'src/app/service/info.service';

@Component({
  selector: 'app-admin-event-navbar',
  templateUrl: './admin-event-navbar.component.html',
  styleUrls: ['./admin-event-navbar.component.scss']
})
export class AdminEventNavbarComponent implements OnInit{
  @Input() color: ThemePalette = 'primary';
  @Input() event: Event;
  @Input() logo;
  @ViewChild(SearchButtonComponent, { static: true }) searchButton: SearchButtonComponent;

  userIsAdmin = false;

  constructor(
    private adminService: AdminService,
    public authService: AuthService,
    public infoService: InfoService
  ) { }

  ngOnInit(): void {
    this.authService.directUserIsAdmin().subscribe(userIsAdmin => {
      this.userIsAdmin = userIsAdmin;
    });
  }
}
