import { Injectable } from '@angular/core';
import { Event } from '../model/event';
import { Observable, of } from 'rxjs';
import { EventsService } from './events.service';
import { FixedTopProgressBarComponent } from '../component/fixed-top-progress-bar/fixed-top-progress-bar.component';
import { Track } from '../model/track';
import { AdminEventSidebarComponent } from '../component/admin-event-sidebar/admin-event-sidebar.component';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Environment } from '../environment/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Response } from '../model/response';
import { NotifyAuthorsTemplate } from '../model/notifyAuthorsTemplate';
import { plainToClass } from 'class-transformer';
import { LoginResponse } from '../model/login.response';
import { WizardStep } from '../model/wizard';
import { IChartistData } from 'chartist';
import { Dashboard, DashboardDataset, DashboardGraph } from '../model/dashboard';
import { ProxyHistory } from '../model/proxyHistory';
import { MergeUsersRegister } from '../model/merge-users-register';


@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private event: Event;
  private track: Track;
  public eventId: number;
  public trackId: number;

  public progressBar: FixedTopProgressBarComponent;
  public sidebar: AdminEventSidebarComponent;

  constructor(
    private http: HttpClient,
    public eventsService: EventsService
  ) { }

  fetchSelectedEvent(eventId: number) {
    this.eventId = eventId;

    this.progressBar.start();
    this.getEvent()
      .subscribe(event => {
        this.selectedEvent = event;
        this.progressBar.stop();
      });
  }

  fetchSelectedTrack(trackId: number) {
    this.trackId = trackId;

    this.progressBar.start();
    this.getEvent()
      .subscribe(event => {
        this.selectedEvent = event;
        this.progressBar.stop();
      });
  }

  getEvent(): Observable<Event> {
    return this.eventsService.getEvent(this.eventId);
  }

  getEventSettings():  Observable<Event>{
    return this.eventsService.getEventSettings(this.eventId);
  }

  getTrack(): Observable<Track> {
    return this.eventsService.getTrack(this.trackId);
  }

  set selectedEvent(event: Event) {
    this.event = event;
  }

  get selectedEvent(): Event {
    return this.event;
  }

  set selectedTrack(track: Track) {
    this.track = track;
  }

  get selectedTrack(): Track {
    return this.track;
  }

  isEventAdminRoute(route: ActivatedRouteSnapshot): boolean {
    const eventId = !!(route.params.eventId || route.parent.params.eventId);

    let isAdminRoute = false;

    if (route.url.length > 0) {
      isAdminRoute = route.url[0].path === 'admin';
    }

    if (!isAdminRoute && route.parent.url.length > 0) {
      isAdminRoute = route.parent.url[0].path === 'admin';
    }

    return eventId && isAdminRoute;
  }

  isAdministrationRoute(route: ActivatedRouteSnapshot): boolean {
    const hasNoEventId = !(route.params.eventId || route.parent.params.eventId);

    let isAdminRoute = false;

    if (route.url.length > 0) {
      isAdminRoute = route.url[0].path === 'admin';
    }

    if (!isAdminRoute && route.parent.url.length > 0) {
      isAdminRoute = route.parent.url[0].path === 'admin';
    }

    return hasNoEventId && isAdminRoute;
  }

  isUserViewRoute(route: ActivatedRouteSnapshot): boolean {
    const hasNoEventId = !(route.params.eventId || route.parent.params.eventId);

    let isAdminRoute = false;

    if (route.url.length > 0) {
      isAdminRoute = route.url[0].path === 'admin';
    }

    if (!isAdminRoute && route.parent.url.length > 0) {
      isAdminRoute = route.parent.url[0].path === 'admin';
    }

    return hasNoEventId && !isAdminRoute;
  }

  public previewNotifyAuthors(event: number) {
    return this.http.get<Response>(`${Environment.urls.API}/core/notifyAuthors/previewEmails/${event}/`)
      .pipe(map(v => plainToClass(NotifyAuthorsTemplate, v.data as NotifyAuthorsTemplate)));
  }

  public notifyAuthors(submissions_ids: Array<number>): Observable<{ [submission: number]: boolean }> {
    return this.http.post<Response>(`${Environment.urls.API}/core/notifyAuthors/sendEmails/`, { submissions_ids })
      .pipe(map(v => v.data));
  }

  public getMergeUsersRegisters(): Observable<MergeUsersRegister[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/user/mergeUsers/`)
      .pipe(map(v => plainToClass(MergeUsersRegister, v.data as Array<MergeUsersRegister>)));
  }

  public mergeUsers(main_user_id: number, merge_users_id: number[]): Observable<{ [submission: number]: boolean }> {
    const params = {'main_user_id': main_user_id, 'merge_users_id': merge_users_id};
    return this.http.post<Response>(`${Environment.urls.API}/core/user/mergeUsers/`, params)
      .pipe(map(v => v.data));
  }

  public getProxyUser(user: number): Observable<LoginResponse> {
    return this.http.post<Response>(`${Environment.urls.API}/core/admin/proxy/${user}/`, {})
      .pipe(map(v => plainToClass(LoginResponse, v.data as LoginResponse)));
  }

  public clearProxyUser(user: number) {
    return this.http.put<Response>(`${Environment.urls.API}/core/admin/proxy/${user}/`, {})
  }

  public getProxyHistory(): Observable<ProxyHistory[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/admin/proxyHistory/`, {})
      .pipe(map(v => plainToClass(ProxyHistory, v.data as ProxyHistory[])));
  }

  public getWizardSteps(event: number): Observable<WizardStep[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${event}/wizard/`)
      .pipe(map(v => plainToClass(WizardStep, v.data as WizardStep[])));
  }

  public checkWizardTask(event: number, tasks: Array<{ id: number, checked: boolean }>): Observable<WizardStep[]> {
    return this.http.put<Response>(`${Environment.urls.API}/core/event/${event}/wizard/`, tasks)
      .pipe(map(v => plainToClass(WizardStep, v.data as WizardStep[])));
  }

  public getAllDatasets(): Observable<DashboardDataset[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/dataset/`)
      .pipe(map(v => plainToClass(DashboardDataset, v.data as DashboardDataset[])));
  }

  public getDatasetData(dataset: string | string[]): Observable<{ [dataset: string]: IChartistData }> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${this.eventId}/data/`, { params: { dataset } })
      .pipe(map(v => {
        const datasets = {};

        for (const set in v.data) {
          if (Object.prototype.hasOwnProperty.call(v.data, set)) {
            datasets[set] = v.data[set] as IChartistData;
          }
        }

        return datasets;
      }));
  }

  public getEventDashboards(): Observable<Array<Dashboard>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${this.eventId}/dashboard/`)
      .pipe(map(v => plainToClass(Dashboard, v.data as Array<Dashboard>)));
  }

  public createDashboard(dashboard: Dashboard): Observable<Dashboard> {
    const clonedDashboard = JSON.parse(JSON.stringify(dashboard));
    if (clonedDashboard.graphPosition) {
      delete clonedDashboard.graphPosition;
    }

    return this.http.post<Response>(`${Environment.urls.API}/core/dashboard/`, clonedDashboard)
      .pipe(map(v => plainToClass(Dashboard, v.data as Dashboard)));
  }

  public updateDashboard(dashboard: number, body: {
    title?: string, order?: number, principal?: boolean, graphPosition?
  }): Observable<Dashboard> {
    return this.http.put<Response>(`${Environment.urls.API}/core/dashboard/${dashboard}/`, body)
      .pipe(map(v => plainToClass(Dashboard, v.data as Dashboard)));
  }

  public deleteDashboard(dashboard: number) {
    return this.http.delete<Response>(`${Environment.urls.API}/core/dashboard/${dashboard}/`)
      .pipe(map(v => v.data));
  }

  public editDashboardGraphs(dashboard: number, graphs: Array<DashboardGraph>): Observable<Array<DashboardGraph>> {
    return this.http.put<Response>(`${Environment.urls.API}/core/dashboard/${dashboard}/graphList/`, graphs)
      .pipe(map(v => plainToClass(DashboardGraph, v.data as DashboardGraph[])));
  }
}
