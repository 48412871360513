import { Component, OnInit } from '@angular/core';
import { Event } from '../../../../model/event';
import { AdminService } from '../../../../service/admin.service';
import { EventsService } from '../../../../service/events.service';
import { SubmissionsService } from '../../../../service/submissions.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectOption } from 'src/app/model/select.option';
import { NotificationService } from 'src/app/service/notification.service';
import { PaperGroup } from '../../../../model/paper.group';
import { Submission } from '../../../../model/paper';
import { PaperTitlePipe } from '../../../../pipe/paper.title.pipe';

@Component({
  selector: 'app-paper-groups',
  templateUrl: './paper-groups.component.html',
  styleUrls: ['./paper-groups.component.scss']
})
export class PaperGroupsComponent implements OnInit {
  public groups: Array<SelectOption>;
  public submissions: Array<SelectOption>;

  public event: Event;
  public form: FormGroup;

  public selectedGroup: PaperGroup;
  public selectedSubmissions = [];

  public actions: Array<SelectOption> = [
    new SelectOption('REMOVE', 'admin.event.groups.actions.remove'),
    new SelectOption('MOVE', 'admin.event.groups.actions.move')
  ];

  constructor(
    private fb: FormBuilder,
    private adminService: AdminService,
    private eventsService: EventsService,
    private submissionsService: SubmissionsService,
    private notificationService: NotificationService
  ) { }

  public ngOnInit(): void {
    setTimeout(() => {
      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.loadData();
        });
      } else {
        this.loadData();
      }
    });

    this.initForms();
  }

  private loadData(): void {
    this.getPaperGroups();
    this.getSubmissions();
  }

  private getPaperGroups(): void {
    this.eventsService.getEventPaperGroups(this.event.id).subscribe(groups => {
      this.groups = groups.map(group => new SelectOption(group, group.name));
    });
  }

  private getSubmissions(): void {
    this.submissionsService.getAllSubmissionsWithoutPaperGroupByEvent(this.event.id).subscribe(submissions => {
      this.submissions = submissions.map(submission =>
        new SelectOption(submission, PaperTitlePipe.prototype.transform(submission))
      );
    });
  }

  private reorder(): void {
    this.groups = this.groups.sort((a, b) => a.value.localeCompare(b.value));
    this.submissions = this.submissions.sort((a, b) => a.value.localeCompare(b.value));
  }

  public getGroups(): Array<PaperGroup> {
    return this.groups.map(grp => grp.id);
  }

  public addGroup(): void {
    this.eventsService.addEventPaperGroup(this.event.id, this.form.value.group.name).subscribe(group => {
      this.groups.push(new SelectOption(group, group.name));

      this.reorder();
      this.form.reset();
    });
  }

  public removeGroup(group: PaperGroup): void {
    this.eventsService.deleteEventPaperGroup(this.event.id, group.id).subscribe(() => {
      this.groups = this.groups.filter(grp => group !== grp.id);
      this.submissions.push(...group.submissions.map(submission =>
        new SelectOption(submission, PaperTitlePipe.prototype.transform(submission))
      ));

      this.selectedGroup = undefined;
      this.selectedSubmissions = [];
      this.reorder();
    });
  }

  public addSubmissionsToGroup(submissions: Array<Submission>, group: PaperGroup): void {
    submissions.forEach(submission => {
      submission.paperGroup = group.id;

      this.submissionsService.editMinimumSubmission(submission, submission.id).subscribe(() => {
        group.submissions.push(submission);
      });
    });

    this.submissions = this.submissions.filter(submission => !submissions.includes(submission.id));

    this.form.reset();
  }

  public editGroup(): void {
    this.selectedGroup = undefined;
    this.selectedSubmissions = [];
  }

  public removeSubmissionFromGroup(submission: Submission, group: PaperGroup): void {
    submission.paperGroup = null;

    this.submissionsService.editMinimumSubmission(submission, submission.id).subscribe(() => {
      group.submissions = group.submissions.filter(sub => sub !== submission);

      this.submissions.push(new SelectOption(submission, PaperTitlePipe.prototype.transform(submission)));
      this.reorder();
    });
  }

  public removeSubmissionsFromGroup(submissions: Array<Submission>, group: PaperGroup): void {
    submissions.forEach(submission => this.removeSubmissionFromGroup(submission, group));
  }

  private initForms(): void {
    this.form = this.fb.group({
      group: this.fb.group({
        name: ['', [Validators.required, Validators.minLength(5)]]
      }),
      submission: this.fb.group({
        submissions: ['', [Validators.required]],
        group: ['', [Validators.required]],
      }),
      edit: this.fb.group({
        action: ['REMOVE'],
        destination: []
      })
    });
  }

  public doAction(action: string): void {
    switch (action) {
      case 'REMOVE':
        this.removeSubmissionsFromGroup(this.selectedSubmissions, this.selectedGroup);
        break;

      case 'MOVE':
        this.addSubmissionsToGroup(this.selectedSubmissions, this.form.value.edit.destination);
        break;
    }

    this.selectedGroup.submissions = this.selectedGroup.submissions.filter(submission => !this.selectedSubmissions.includes(submission));
    this.selectedSubmissions = [];
  }

  public toggleSubmission(checked: boolean, submission: Submission): void {
    if (checked) {
      this.selectedSubmissions.push(submission);
    } else {
      this.selectedSubmissions = this.selectedSubmissions.filter(sub => sub !== submission);
    }
  }

  public filterGroupFromOptions(group: PaperGroup): Array<SelectOption> {
    return this.groups.filter(option => option.id !== group);
  }
}
