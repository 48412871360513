import { Component, OnInit, ElementRef, EventEmitter, Output, ViewChild, Input, HostBinding } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { UserSearch } from '../../model/user.search';
import { plainToClass } from 'class-transformer';
import { SystemService } from '../../service/system.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AdminService } from '../../service/admin.service';

@Component({
  selector: 'app-search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('true', style({
        'width': window.innerWidth > 1450 ? '60%' : '100%',
        'padding-right': '12px'
      })),
      state('false', style({
        'width': '52px',
        'padding-right': '12px'
      })),
      transition('true => false', animate('200ms ease-in-out')),
      transition('false => true', animate('200ms ease-in-out'))
    ])
  ]
})
export class SearchButtonComponent implements OnInit {
  isVisible = false;
  isVisibleInput = false;
  dropdownOpened = false;
  searchForm: FormGroup;
  lastSearches: Array<UserSearch>;
  @Input() context: String = 'User';
  @Input() eventId: String;
  @ViewChild('input') input: ElementRef;

  @Input() light = false;
  @Output() searchToggled: EventEmitter<{ status: boolean }> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private adminService: AdminService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      input: ['']
    });

    this.lastSearches = localStorage.getItem('last-searchs') ? JSON.parse(localStorage.getItem('last-searchs')).slice(-10) : [];
  }

  toggle(): void {
    this.isVisible = !this.isVisible;
  }

  animationStart(): void {
    if (this.isVisible) {
      this.searchToggled.emit({ status: this.isVisible });
      this.isVisibleInput = this.isVisible;
    }
  }

  animationDone(): void {
    if (this.isVisible) {
      this.input.nativeElement.focus();
      this.dropdownOpened = true;
    }
    this.isVisibleInput = this.isVisible;
    this.searchToggled.emit({ status: this.isVisible });
  }

  search(where: string, query: string = null): void {
    if (query || this.hasInputMinimumLength()) {
      const userSearch = plainToClass(UserSearch, {
        query: query || this.searchForm.get('input').value.trim(),
        where: where
      } as UserSearch);

      this.lastSearches.push(userSearch);
      this.dropdownOpened = false;
      localStorage.setItem('last-searchs', JSON.stringify(this.lastSearches));
      if (this.context === 'admin') {
        const eventId = this.activatedRoute.snapshot.paramMap.get('eventId');

        let event = this.adminService.selectedEvent;
        if (!event) {
          this.adminService.getEvent().subscribe(e => {
            event = e;
            this.navigateToAdminEventSearch(event.id, userSearch);
          });
        } else {
            this.navigateToAdminEventSearch(event.id, userSearch);
        }

      } else {
        this.router.navigate(['/search'], {
          queryParams: userSearch
        });
      }
    }
  }

  navigateToAdminEventSearch(eventId: number, userSearch: UserSearch) {
    this.router.navigate(['/admin', eventId , 'search'], {
      queryParams: userSearch
    });
  }

  get dropdownShouldBeOpened(): boolean {
    return this.dropdownOpened && (this.hasInputMinimumLength() || this.lastSearches.length > 0);
  }

  clearLastSearchs(): void {
    this.lastSearches = [];
    localStorage.removeItem('last-searchs');
  }

  hasInputMinimumLength(): boolean {
    return this.searchForm.get('input').value.trim().length > 2;
  }

  hasInputLength(): boolean {
    return this.searchForm.get('input').value.trim().length;
  }

  clearInput(): void {
    this.searchForm.get('input').setValue('');
  }

  getLastSearchI18n(domain: string): string {
    return `menus.sidebar.${domain === 'all' ? domain : `${domain}s`}`;
  }
}
