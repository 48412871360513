import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment } from '../environment/environment';
import { Observable, of as ObservableFrom } from 'rxjs';
import { Response } from '../model/response';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';

import { EventReviewConfiguration } from '../model/eventReviewConfiguration';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewsConfigurationService {
  constructor(
    private http: HttpClient,
    private cache: CacheService
  ) { }

  getReviewsConfiguration(id: number): Observable<EventReviewConfiguration> {
    if (id) {
      const foundConfig = this.cache.getValue(this.cache.reviewsConfiguration, id.toString());

      if (foundConfig) {
        return ObservableFrom(foundConfig);
      }

      return this.http.get<Response>(`${Environment.urls.API}/core/reviews/configuration/${id}/`)
        .pipe(map(v => plainToClass(EventReviewConfiguration, v.data as EventReviewConfiguration)))
        .pipe(map(v =>
          this.cache.setValue(
            this.cache.reviewsConfiguration,
            v.id.toString(), v
          ).get(v.id.toString())
        ));
    }
  }

  getReviewsConfigurationGlobalBlock(id: number): Observable<EventReviewConfiguration> {
    if (id) {
      return this.http.get<Response>(`${Environment.urls.API}/core/reviews/configuration/globalRB/${id}/`)
        .pipe(map(v => plainToClass(EventReviewConfiguration, v.data as EventReviewConfiguration)));
    }
  }

  editReviewsConfiguration(id: number, params): Observable<EventReviewConfiguration> {
    return this.http.put<Response>(`${Environment.urls.API}/core/reviews/configuration/${id}/`, params)
      .pipe(map( v => plainToClass(EventReviewConfiguration, v.data as EventReviewConfiguration)))
      .pipe(map(reviewConfig => reviewConfig ?
        this.cache.setValue(
          this.cache.reviewsConfiguration,
          reviewConfig.id.toString(), reviewConfig
        ).get(reviewConfig.id.toString()) : undefined
      ));
  }

  editGlobalReviewDueDate(event: number, date: Date): Observable<any> {
    return this.http.put<Response>(`${Environment.urls.API}/core/reviews/updateReviewsDueDate/${event}/`, { date });
  }
}
