import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-form-label-question',
  templateUrl: './custom-form-label-question.component.html',
  styleUrls: ['./custom-form-label-question.component.scss']
})
export class CustomFormLabelQuestionComponent {
  @Input() question: {
    order: number,
    label: {
      short: string,
      full: string
    },
    required: boolean
  };
}
