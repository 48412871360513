import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { EventTopic } from 'src/app/model/eventTopic';

@Component({
  selector: 'app-chip-checkbox',
  templateUrl: './chip-checkbox.component.html',
  styleUrls: ['./chip-checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ChipCheckboxComponent),
    multi: true
  }]
})
export class ChipCheckboxComponent implements OnInit {

  @Input() formGroupController: FormGroup;
  @Input() name: string;

  @Input() value: any;
  @Input() label: string;
  @Input() colorClass: string;

  @Input() icon: { fontSet: string, fontIcon: string };

  stateCtrl: FormControl;
  selectedValues: any[];

  checked = false;

  ngOnInit() {
    setTimeout(() => {

      this.stateCtrl = this.formGroupController.get(this.name) as FormControl;
      this.selectedValues = this.stateCtrl.value as any[];

      this.checkIfSelected(this.selectedValues);

      // the function raise an error of 'this = undefined', and i dont see her neccessity
      // this.stateCtrl.valueChanges.subscribe(this.checkIfSelected);
    });
  }


  private checkIfSelected(values) {
    const found = values.find(v => {
      if (typeof v === 'object' && v !== null) {
        return v.id === this.value.id;
      }
      return v === this.value;
    });
    this.checked = !!found;
  }

  toggle(): void {
    const index = this.selectedValues.findIndex(e => {
      if (typeof e === 'object' && e !== null) {
        return e.id === this.value.id;
      }
      return e === this.value;
    });

    if (index === -1) {
      this.selectedValues.push(this.value);
    } else {
      this.selectedValues.splice(index, 1);
    }

    // this force the update of validity, that not occur otherwise
    this.stateCtrl.updateValueAndValidity();
  }

  isChecked(label: string): boolean {
    return this.selectedValues?.includes(label.toUpperCase());
  }
}
