import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserService } from 'src/app/service/user.service';
import { Affiliation } from 'src/app/model/affiliation';
import { User } from 'src/app/model/user';
import { NotificationService } from 'src/app/service/notification.service';
import { InfoService } from 'src/app/service/info.service';
import { AffiliationSearchComponent } from '../affiliation-search/affiliation-search.component';

@Component({
  selector: 'app-edit-user-affiliation',
  templateUrl: './edit-user-affiliation.component.html',
  styleUrls: ['./edit-user-affiliation.component.scss']
})
export class EditUserAffiliationComponent implements OnInit {
  affiliationForm: FormGroup;
  unknownAffiliation = false;

  @Input() user: User;
  @ViewChild(AffiliationSearchComponent) affiliationSearch: AffiliationSearchComponent;

  constructor(
    public userService: UserService,
    public notificationService: NotificationService,
    public infoService: InfoService,
    public fb: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.user === undefined) {
      this.userService.getUserProfile().subscribe( user => {
        this.user = user;
      });
    }
    this.affiliationForm = this.fb.group({});
  }

  setAffiliationStatus(status) {
    this.unknownAffiliation = status;
  }

  public submit() {
    if (this.affiliationForm.valid) {
      let { affiliation } = this.affiliationForm.value;
      if (this.unknownAffiliation) {
        const { name, alternativeName, acronym, domainEmail } = this.affiliationForm.value;
        return this.userService.createAffiliation({ name, alternativeName, acronym, domainEmail })
          .subscribe(data => {
            affiliation = data;
            if (!affiliation.id) throw new Error('Error creating affiliation! Try again.');
            this.editUserAffiliation(this.user, affiliation);
          });
      } else {
        this.editUserAffiliation(this.user, affiliation);
      }
    }
  }

  editUserAffiliation(user: User, affiliation: Affiliation) {
    if (affiliation) {
      user.profile.affiliation = affiliation
      this.userService.update(user).subscribe(u => {
        this.user = u;
      });
    }
  }
}
