import { Component, OnInit, Input } from '@angular/core';
import { Submission } from '../../model/paper';
import { style, animate, transition, state, trigger } from '@angular/animations';
import { EventsService } from 'src/app/service/events.service';
import { PreferencesService } from '../../service/preferences.service';
import { Review } from '../../model/paper';
import { SubmissionStatus } from '../../enum/submission.status';
import { ReviewStatus } from '../../enum/review.status';
import { UserEvent} from '../../model/user.event';
import { User } from '../../model/user';
import {InfoService} from '../../service/info.service';

@Component({
  selector: 'app-people-card',
  templateUrl: './people-card.component.html',
  styleUrls: ['./people-card.component.scss'],
  animations: [
    trigger('slideIn', [
      state('*', style({ 'overflow-y': 'hidden', height: '*', opacity: 1 })),
      state('void', style({ 'overflow-y': 'hidden', height: 0, opacity: 0 })),
      transition('* => void', [animate('500ms 0.2ms ease-out')]),
      transition('void => *', [animate('300ms 0.2ms ease-out')])
    ])
  ]
})
export class PeopleCardComponent implements OnInit {
  @Input() element: UserEvent;

  user: User;
  constructor(
    private infoService: InfoService,
    private eventsService: EventsService,
    public preferencesService: PreferencesService
  ) { }

  ngOnInit() {
    if ( this.element ) {
      this.user = this.element.user;
    }
  }

  get styles(): object {
    return {
      compact: this.preferencesService.getPreference('viewMode') === 'Compact',
      default: this.preferencesService.getPreference('viewMode') !== 'Default',
      noIcon: !this.preferencesService.getPreference('statusIcon'),
      bgColor: this.preferencesService.getPreference('statusColorAsBackgroundColor')
    };
  }
}
