import {
  Component,
  ComponentRef,
  ViewChild,
  Input,
  ViewContainerRef,
  ComponentFactoryResolver,
  AfterViewInit,
  OnChanges
} from '@angular/core';

import { FormGroup } from '@angular/forms';
import { Form } from '../../model/form';
import { FormField } from '../../model/form.field';
import { FormField as FormFieldComponent } from '../../class/form-field';
import { FormFieldChoice } from '../../model/form.field.choice';
import { Answer } from '../../model/answer';
import { FormFieldType } from '../../enum/form.field.type';

@Component({
  selector: 'app-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss']
})
export class CustomFormComponent implements AfterViewInit, OnChanges {
  @ViewChild('fields', { read: ViewContainerRef, static: true })
  private fieldsRef: ViewContainerRef;

  @Input()
  public form: Form;

  @Input()
  public controller: FormGroup = new FormGroup({});

  constructor(private cfr: ComponentFactoryResolver) { }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.fieldsRef?.length > 0) {
        this.fieldsRef.clear();
      }
      this.form.fields.sort((a: FormField, b: FormField) => a.order - b.order).forEach(field => this.createComponentFromField(field));
    });
  }

  ngOnChanges(): void {
    if (this.fieldsRef?.length > 0) {
      this.fieldsRef.clear();
      this.ngAfterViewInit();
    }
  }

  public get title(): string {
    let formType;
    if (this.form.isReview()) {
      formType = 'review';
    } else if (this.form.isSubmission()) {
      formType = 'submission';
    } else {
      formType = 'no-form';
    }
    return `admin.event.settings.forms.title-${formType}`;
  }

  private createComponentFromField(field: FormField): void {
    const componentReference: ComponentRef<FormFieldComponent> =
      this.fieldsRef.createComponent(this.cfr.resolveComponentFactory(field.component));

    componentReference.instance.min = field.min;
    componentReference.instance.max = field.max;
    componentReference.instance.name = String(field.id);
    componentReference.instance.form = this.controller;
    componentReference.instance.order = field.order;
    componentReference.instance.required = field.required;
    componentReference.instance.choices = field.choices.sort((a: FormFieldChoice, b: FormFieldChoice) => a.order - b.order);
    componentReference.instance.placeholder = field.placeholder;
    componentReference.instance.formType = this.form.type;

    componentReference.instance.label = {
      short: field.shortLabel,
      full: field.description
    };

    this.controller.addControl(componentReference.instance.name, field.control);
  }

  public get answers(): Array<Answer> {
    return this.form.formAnswers(this.controller);
  }
}
