import { ReviewStatus } from '../enum/review.status';
import { User } from './user';

export class AssignReview {
  public assignedBy: number;
  public dueAt: Date;
  public status: ReviewStatus = ReviewStatus.CONFIRMED;
  public user: User | number;
  public submission: number;
}
