import { Directive, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Submission, SubmissionStatus } from '../model/paper';
import { FileRules } from '../model/file.rules';
import { Track } from '../model/track';
import { SubmissionsService } from '../service/submissions.service';
import { FilesService } from '../service/files.service';
import { EventsService } from '../service/events.service';
import { InfoService } from '../service/info.service';
import { Event } from '../model/event';

@Directive()
export abstract class FileUploadViewDirective {
  public abstract submission: Submission;

  public uploadForm: FormGroup;

  constructor(
    public fb: FormBuilder,
    public submissionsService: SubmissionsService,
    public filesService: FilesService,
    public eventsService: EventsService,
    public infoService: InfoService
  ) { }

  initUploadForm() {
    this.uploadForm = this.fb.group({
      files: [[]]
    });
  }

  public get track(): Track {
    return this.submission.track as Track;
  }

  public get userIsAuthor(): boolean {
    return this.submission.isAuthor(this.infoService.user.id);
  }

  setTrackOfTrackFiles() {
    (<Track>this.submission.track).trackFiles.forEach(trackFile => {
      trackFile.track = <Track>this.submission.track;
    });
  }

  public verifyAndUpdateStatus(
    submission: Submission, rule: FileRules, fileRules: Array<FileRules>, submissionChanged: EventEmitter<Submission>
  ) {
    const trackFiles = fileRules.slice();
    trackFiles.splice(trackFiles.findIndex(tf => tf.name === rule.name), 1); // remove the actual track from list

    if(rule.required) {
      if ((submission.status === SubmissionStatus.PENDING || submission.status === SubmissionStatus.ACTIVE) &&
        this.filesService.requiredFilesSubmitted(trackFiles, submission.files.map(f => f.trackFile))) {

        submission.status = SubmissionStatus.ACTIVE;
        this.submissionsService.editSubmission({ status: SubmissionStatus.ACTIVE }, submission.id).subscribe(s => {
          // TODO: Edit submission returns incomplete submission, so refreshing submission is necessary.
          // Extra getSubmission can be removed when editSubmission serializes the correct value.

          this.submissionsService.getSubmission(s.id, true).subscribe(sub => this.submissionSetEventAndTrack(sub, submissionChanged));
        });
      } else {
        this.submissionsService.getSubmission(submission.id, true).subscribe(sub => this.submissionSetEventAndTrack(sub, submissionChanged));
      }
    } else {
      // for not required files/rules
      this.submissionsService.editSubmission({ status: submission.status }, submission.id).subscribe(s => {
        // TODO: Edit submission returns incomplete submission, so refreshing submission is necessary.
        // Extra getSubmission can be removed when editSubmission serializes the correct value.

        this.submissionsService.getSubmission(s.id, true).subscribe(sub => this.submissionSetEventAndTrack(sub, submissionChanged));
      });
    }



  }

  public submissionSetEventAndTrack(submission: Submission, submissionChanged: EventEmitter<Submission>) {
    if (!(submission.event instanceof Event) || !(submission instanceof Track)) {
      this.eventsService.getEventByTrack(submission.trackID)
        .subscribe(event => {
          submission.event = event;
          submission.track = event.tracks.find(track => submission.trackID === track.id);
          submission.track.trackFiles.forEach(r => {
            r.track = <Track>submission.track;
          });

          submissionChanged.emit(submission);
        });
    }
  }
}
