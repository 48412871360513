import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { SignupComponent } from './landing/signup/signup.component';
import { SigninComponent } from './landing/signin/signin.component';
import { RecoverPasswordComponent } from './landing/recover-password/recover-password.component';
import { ChangePasswordComponent } from './landing/change-password/change-password.component';
import { LandingComponent } from './landing/landing.component';
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { EmailConfirmedComponent } from './landing/email-confirmed/email-confirmed.component';
import { CalendarComponent } from './home/calendar/calendar.component';
import { SubmissionsComponent } from './home/submissions/submissions.component';
import { ReviewsComponent } from './home/reviews/reviews.component';
import { ReviewViewComponent } from './component/review-view/review-view.component';
import { CommitteesComponent } from './home/committees/committees.component';
import { MyEventsComponent } from './home/my-events/my-events.component';
import { EventsComponent } from './home/events/events.component';
import { AboutComponent } from './home/about/about.component';
import { HelpComponent } from './home/help/help.component';
import { NotificationsComponent } from './home/notifications/notifications.component';
import { SettingsComponent } from './home/settings/settings.component';
import { AuthGuard } from './guard/auth.guard';
import { RedirectGuard } from './guard/redirect.guard';
import { SubmissionViewComponent } from './component/submission-view/submission-view.component';
import { SubmissionFormViewComponent } from './component/submission-form-view/submission-form-view.component';
import { CreateSubmissionComponent } from './component/create-submission/create-submission.component';
import { ReviewFormComponent } from './component/review-form/review-form.component';
import { EventViewComponent } from './component/event-view/event-view.component';
import { EventAdminComponent } from './admin/event-admin/event-admin.component';
import { EventSubmissionsComponent } from './admin/event-admin/event-submissions/event-submissions.component';
import { EventSettingsComponent } from './admin/event-admin/event-settings/event-settings.component';
import { EventPeopleComponent } from './admin/event-admin/event-people/event-people.component';
import { SearchResultsInEventComponent } from './admin/event-admin/search-results-in-event/search-results-in-event.component';
import { AdminEventViewComponent } from './admin/event-admin/event-view/event-view.component';
import { SearchResultsComponent } from './home/search-results/search-results.component';
import { AdminEventCreateComponent } from './admin/events/create-event/create-event.component';
import { AdminGuard } from './guard/admin.guard';
import { ErrorViewComponent } from './component/error-view/error-view.component';
import { RequestEventComponent } from './home/request/event/event.component';
import { SignoutComponent } from './landing/signout/signout.component';
import { LoadingComponent } from './home/loading/loading.component';
import { CheckEventComponent } from './component/check-event/check-event.component';
import { AdminLandingComponent } from './admin/landing/landing.component';
import { AdminEventRequestComponent } from './admin/event-requests/request/request.component';
import { SubmissionEditComponent } from './component/submission-edit/submission-edit.component';
import { EventSettingsEmailComponent } from './admin/event-admin/event-settings/event-settings-email/event-settings-email.component';
import { ActivateEmailComponent } from './landing/activate-email/activate-email.component';
import { EventTopicsComponent } from './admin/event-admin/event-settings/event-topics/event-topics.component';
import { EventChairsComponent } from './admin/event-admin/event-people/event-chairs/event-chairs.component';
import { EventTracksComponent } from './admin/event-admin/event-settings/event-tracks/event-tracks.component';
import { EventTracksEditComponent } from './component/event-tracks-edit/event-tracks-edit.component';
import { EventMembersComponent } from './admin/event-admin/event-people/event-members/event-members.component';
import { ConfirmTPCInviteComponent } from './landing/confirm-tpcinvite/confirm-tpcinvite.component';
import { EventSendEmailComponent } from './admin/event-admin/event-people/event-send-email/event-send-email.component';
import { AdminCreateSubmissionComponent } from './component/admin-create-submission/admin-create-submission.component';
import { AdminSubmitSelectTrackComponent } from './admin/event-admin/admin-submit-select-track/admin-submit-select-track.component';
import { ReviewsConfigurationComponent } from './admin/event-admin/event-reviews/reviews-configuration/reviews-configuration.component';
import { PaperGroupsComponent } from './admin/event-admin/event-settings/paper-groups/paper-groups.component';
import { EventFormsComponent } from './admin/event-admin/event-settings/event-forms/event-forms.component';
import { TPCGroupsComponent } from './admin/event-admin/event-settings/tpc-groups/tpc-groups.component';
import { ChairOrAdminGuard } from './guard/chair-admin.guard';
import { ReportPrintingProblemComponent } from './component/report-printing-problem/report-printing-problem.component';
import { UserEventTopicsComponent } from './home/user-event-topics/user-event-topics.component';
import { EventUserTopicsComponent } from './admin/event-admin/event-people/event-user-topics/event-user-topics.component';
import { PreviewFormComponent } from './admin/event-admin/event-settings/event-forms/preview-form/preview-form.component';
import { UserViewComponent } from './admin/event-admin/event-people/user-view/user-view.component';
import { PaperReviewHistoryComponent } from './component/paper-review-history/paper-review-history.component';
import { SubmissionHistoryComponent } from './component/submission-history/submission-history.component';
import { ConflictInterestComponent } from './home/conflict-interest/conflict-interest.component';
import { MissingManuscriptsComponent } from './admin/event-admin/event-settings/missing-manuscripts/missing-manuscripts.component';
import { ShowAllSubmissionsComponent } from './admin/event-admin/event-submissions/show-all-submissions/show-all-submissions.component';
import { ReviewStatusPerSubmissionComponent } from './admin/event-admin/event-reviews/review-status-per-submission/review-status-per-submission.component';
import { ReviewStatusPerUserComponent } from './admin/event-admin/event-reviews/review-status-per-user/review-status-per-user.component';
import { ConfirmReviewComponent } from './landing/confirm-review/confirm-review.component';
import { AdminNotifyReviewersComponent } from './admin/event-admin/event-reviews/notify-reviewers/notify-reviewers.component';
import { ClaimReviewComponent } from './component/claim-review/claim-review.component';
import { SubmissionRankingComponent } from './admin/event-admin/event-submissions/submission-ranking/submission-ranking.component';
import { AssignReviewSuggestionComponent } from './admin/event-admin/event-reviews/assign-review-suggestion/assign-review-suggestion.component';
import { AdminNotifyAuthorsComponent } from './admin/event-admin/event-submissions/notify-authors/notify-authors.component';
import { SubmissionFilesComponent } from './admin/event-admin/event-submissions/submission-files/submission-files.component';
import { AdminBibtexExportComponent } from './admin/event-admin/event-submissions/bibtex-export/bibtex-export.component';
import { FindReviewersByInterestComponent } from './component/find-reviewers-by-interest/find-reviewers-by-interest.component';
import { EventProgramComponent } from './admin/event-admin/event-sessions/program/event-program.component';
import { EmailStatusComponent } from './admin/event-admin/event-settings/email-status/email-status.component';
import { RouteTitleGuard } from './guard/route-title.guard';
import { AdminEventWizardComponent } from './admin/event-admin/event-wizard/event-wizard.component';
import { DashboardSettingsComponent } from './admin/event-admin/event-settings/dashboard-settings/dashboard-settings.component';
import { UserDashboardComponent } from './home/user-dashboard/user-dashboard.component';
import { CalendarViewComponent } from './admin/event-admin/event-sessions/calendar-view/calendar-view.component';
import { IeeeElectronicCopyrightComponent } from './component/ieee-electronic-copyright/ieee-electronic-copyright.component';
import { SubmissionStatisticsComponent } from './admin/event-admin/event-submissions/submission-statistics/submission-statistics.component';
import { EventPublicationComponent } from './admin/event-admin/event-publication/event-publication/event-publication.component';
import { EventPublicationStatusComponent } from './admin/event-admin/event-publication/event-publication-status/event-publication-status.component';
import { EventPublicationProceedingsComponent } from './admin/event-admin/event-publication/event-publication-proceedings/event-publication-proceedings.component';
import { NotifyPendingsComponent } from './component/notify-pendings/notify-pendings.component';
import { ExportSubmissionsComponent } from './admin/event-admin/event-submissions/export-submissions/export-submissions.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    canActivateChild: [RedirectGuard, RouteTitleGuard],
    children: [
      { path: '', component: SigninComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'recoverPassword', component: RecoverPasswordComponent },
      { path: 'changePassword/:uidb64/:token', component: ChangePasswordComponent },
      { path: 'emailConfirmed/:uidb64/:token/:userb64', component: EmailConfirmedComponent },
      { path: 'activateAccount', component: ActivateEmailComponent },
      { path: 'signout', component: SignoutComponent },
      { path: 'loading', component: LoadingComponent }
    ],
    runGuardsAndResolvers: 'always',
  },
  {
    path: '',
    component: HomeComponent,
    canActivateChild: [AuthGuard, RouteTitleGuard],
    children: [
      { path: 'calendar', component: CalendarComponent },
      { path: 'submissions', component: SubmissionsComponent },
      { path: 'submissions/:id', component: SubmissionViewComponent },
      { path: 'submissions/:submissionId/edit', component: SubmissionEditComponent },
      { path: 'submissions/:id/history', component: SubmissionHistoryComponent },
      { path: 'submissions/:id/find-reviewers-by-interest', component: FindReviewersByInterestComponent },
      { path: 'submissions/:id/review-history', component: PaperReviewHistoryComponent },
      { path: 'submissions/:id/ieeeElectronicCopyright', component: IeeeElectronicCopyrightComponent},
      { path: 'submissions/:id/extra-items', component: SubmissionFormViewComponent },
      { path: 'submissions/:id/:id', component: SubmissionViewComponent },
      { path: 'reviews', component: ReviewsComponent },
      { path: 'reviews/:id', component: ReviewViewComponent },
      { path: 'reviews/:id/create', component: ReviewFormComponent },
      { path: 'reviews/:id/edit', component: ReviewFormComponent },
      { path: 'reviews/:id/printing-problem/report', component: ReportPrintingProblemComponent },
      { path: 'reviews/:id/review-history', component: PaperReviewHistoryComponent },
      { path: 'reviews/:id/history', component: SubmissionHistoryComponent },
      { path: 'committees', component: CommitteesComponent },
      { path: 'my-events', component: MyEventsComponent },
      { path: 'events', component: EventsComponent },
      { path: 'events/:id', component: EventViewComponent },
      { path: 'events/:eventId/:trackId/submit', component: CreateSubmissionComponent },
      { path: 'events/:eventId/defineTopics', component: UserEventTopicsComponent },
      { path: 'events/:eventId/claimReview', component: ClaimReviewComponent },
      { path: 'about', component: AboutComponent },
      { path: 'about/:pageZero', component: AboutComponent },
      { path: 'about/:pageZero/:pageOne', component: AboutComponent },
      { path: 'help', component: HelpComponent },
      { path: 'help/:pageZero', component: HelpComponent },
      { path: 'help/:pageZero/:pageOne', component: HelpComponent },
      { path: 'notifications', component: NotificationsComponent },
      { path: 'loading', component: LoadingComponent },
      { path: 'search', component: SearchResultsComponent },
      { path: 'request/event', component: RequestEventComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'confirm/tpcMember/:id/:eventId', component: ConfirmTPCInviteComponent },
      { path: 'confirm/reviewSubmission/:reviewId', component: ConfirmReviewComponent },
      { path: 'conflictInterest', component: ConflictInterestComponent },
      { path: 'dashboardHided', component: UserDashboardComponent },
      {
        path: 'error',
        children: [
          { path: '', pathMatch: 'full', redirectTo: '404' },
          {
            component: ErrorViewComponent, matcher: errorMatcher
          }
        ],
      },
    ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivateChild: [AuthGuard, AdminGuard, RouteTitleGuard],
    children: [
      { path: '', component: AdminLandingComponent },
      { path: 'about', component: AboutComponent },
      { path: 'help', component: HelpComponent },
      { path: 'event/create', component: AdminEventCreateComponent },
      { path: 'event/request/:id', component: AdminEventRequestComponent },
      { path: 'settings', component: SettingsComponent },
      {
        path: 'error',
        children: [
          { path: '', pathMatch: 'full', redirectTo: '404' },
          {
            component: ErrorViewComponent, matcher: errorMatcher
          }
        ],
      },
    ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'admin/:eventId',
    component: EventAdminComponent,
    canActivateChild: [AuthGuard, ChairOrAdminGuard, RouteTitleGuard],
    children: [
      { path: '', component: AdminEventViewComponent },
      { path: 'submissions', component: EventSubmissionsComponent },
      { path: 'submissions/track', component: AdminSubmitSelectTrackComponent },
      { path: 'submissions/track/:trackId/submit', component: AdminCreateSubmissionComponent },
      { path: 'submissions/missing-manuscripts', component: MissingManuscriptsComponent },
      { path: 'submissions/show-all', component: ExportSubmissionsComponent },
      { path: 'submissions/groups', component: PaperGroupsComponent },
      { path: 'submissions/:type/form', component: PreviewFormComponent },
      { path: 'submissions/:type/form/edit', component: EventFormsComponent },
      { path: 'submissions/:type/form/create', component: EventFormsComponent },
      { path: 'submissions/ranking', component: SubmissionRankingComponent },
      { path: 'submissions/notify', component: AdminNotifyAuthorsComponent },
      { path: 'submissions/notify-pending', component: NotifyPendingsComponent },
      { path: 'submissions/submission-files', component: SubmissionFilesComponent },
      { path: 'submissions/bibtex-export', component: AdminBibtexExportComponent },
      { path: 'submissions/statistics', component: SubmissionStatisticsComponent },
      { path: 'submissions/:id/review-history', component: PaperReviewHistoryComponent },
      { path: 'submissions/:id/history', component: SubmissionHistoryComponent },
      { path: 'submissions/:id', component: SubmissionViewComponent },
      { path: 'submissions/:id/find-reviewers-by-interest', component: FindReviewersByInterestComponent },
      { path: 'reviews/configuration', component: ReviewsConfigurationComponent },
      { path: 'reviews/:type/form', component: PreviewFormComponent },
      { path: 'reviews/:type/form/edit', component: EventFormsComponent },
      { path: 'reviews/:type/form/create', component: EventFormsComponent },
      { path: 'reviews/assignment-suggestions', component: AssignReviewSuggestionComponent },
      { path: 'reviews/reviewers/notify', component: AdminNotifyReviewersComponent },
      { path: 'reviews/:id', component: ReviewViewComponent },
      { path: 'reviews/:id/printing-problem/report', component: ReportPrintingProblemComponent },
      { path: 'reviews/:id/review-history', component: PaperReviewHistoryComponent },
      { path: 'reviews/:id/history', component: SubmissionHistoryComponent },
      { path: 'people', component: EventPeopleComponent },
      { path: 'people/chairs', component: EventChairsComponent },
      { path: 'people/send-email', component: EventSendEmailComponent },
      { path: 'people/members', component: EventMembersComponent },
      { path: 'people/members/topics/:userId', component: EventUserTopicsComponent },
      { path: 'people/members/groups', component: TPCGroupsComponent },
      { path: 'people/:userId', component: UserViewComponent },
      { path: 'settings', component: EventSettingsComponent },
      { path: 'settings/emailTemplates', component: EventSettingsEmailComponent },
      { path: 'settings/emailStatus', component: EmailStatusComponent },
      { path: 'settings/topics', component: EventTopicsComponent },
      { path: 'settings/tracks', component: EventTracksComponent },
      { path: 'settings/tracks/:trackId/edit', component: EventTracksEditComponent },
      { path: 'settings/tracks/create', component: EventTracksEditComponent },
      { path: 'search', component: SearchResultsInEventComponent },
      { path: 'reviews/reports/statusBySubmission', component: ReviewStatusPerSubmissionComponent },
      { path: 'reviews/reports/statusByUser', component: ReviewStatusPerUserComponent },
      { path: 'checklist', component: AdminEventWizardComponent },
      { path: 'dashboard/settings', component: DashboardSettingsComponent },
      { path: 'emails/send-email', component: EventSendEmailComponent },
      { path: 'emails/emailStatus', component: EmailStatusComponent },
      { path: 'emails/emailTemplates', component: EventSettingsEmailComponent },
      { path: 'program/list', component: EventProgramComponent },
      { path: 'program/calendar', component: CalendarViewComponent },
      { path: 'program/:viewType', component: CalendarViewComponent },
      { path: 'publication', component: EventPublicationComponent },
      { path: 'publication/status', component: EventPublicationStatusComponent },
      { path: 'publication/proceedings', component: EventPublicationProceedingsComponent },
      {
        path: 'error',
        children: [
          { path: '', pathMatch: 'full', redirectTo: '404' },
          {
            component: ErrorViewComponent, matcher: errorMatcher
          }
        ],
      },
    ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'error',
    component: LandingComponent,
    canActivateChild: [RouteTitleGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/error/404' },
      {
        component: ErrorViewComponent, matcher: errorMatcher
      }
    ],
    runGuardsAndResolvers: 'always'
  },
  { // Component checks if link is of event. If not, redirects to 404.
    path: '**',
    component: CheckEventComponent,
    runGuardsAndResolvers: 'always'
  }
];

export function errorMatcher(url: UrlSegment[]) {
  const code = url[0].path;
  url[0].parameters = ['403', '404', '500'].find(el => el === code) ? { code: String(code) } : { code: '404' };

  return ({ consumed: url });
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
