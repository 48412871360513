import { Environment } from '../environment/environment';

export class Country {
  public name: string;
  public isoCode: string;
  public flag?: string;
  public id?: number;

  get flagUrl() {
    return this.flag ? `${Environment.urls.API}${this.flag}` : null;
  }
}
