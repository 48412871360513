import { Country } from './country';

export class Language {
  constructor(
    public id: string,
    public value: string,
    public countryName: string,
    public country: Country
  ) { }

  get flag(): string {
    return this.country.flagUrl;
  }
}
