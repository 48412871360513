import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from '../../service/events.service';
import { SubmissionsService } from '../../service/submissions.service';
import { InfoService } from '../../service/info.service';
import { UserService } from '../../service/user.service';
import { NavbarService } from '../../service/navbar.service';
import { Submission } from '../../model/paper';
import { EventTopic } from '../../model/eventTopic';
import { FormBuilder, FormArray } from '@angular/forms';
import { NotificationService } from '../../service/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { UserEventTopicsInterest } from '../../model/user.event.topics.interest';


export class ClaimSubmission extends Submission {
  isClaimedByUser = false;
  isAssigned = false;
  interest = 0;
  noInterest = 0;
}

@Component({
  selector: 'app-claim-review',
  templateUrl: './claim-review.component.html',
  styleUrls: ['./claim-review.component.scss']
})
export class ClaimReviewComponent implements OnInit {
  public tableColumns = ['claim', 'id', 'title', 'interest', 'no-interest'];
  public submissions: MatTableDataSource<ClaimSubmission>;
  public eventId: number;
  public eventName: string;
  public eventAcronym: string;
  public eventYear: number;
  public currentUser;

  public userEventTopicInterest: String;
  public userEventTopicsList: Array<UserEventTopicsInterest>
  public userHasTopics = false;
  public loaded = false;

  public form: FormArray;


  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    if (sort) {
      this.submissions.sort = sort;
      this.submissions.sortingDataAccessor = (item: ClaimSubmission, header: string) => {
        switch (header) {
          case 'id': return item.id;
          case 'title': return item.title;
          case 'interest': return item.interest;
          case 'no-interest': return item.noInterest;
        }
      };
    }
  }

  constructor(
    public route: ActivatedRoute,
    private fb: FormBuilder,
    private navbar: NavbarService,
    private notificationService: NotificationService,
    private eventService: EventsService,
    private userService: UserService,
    private submissionService: SubmissionsService,
    private infoService: InfoService
  ) { }

  ngOnInit() {
    this.navbar.loadingStart();
    this.userService.getUserFromToken().subscribe( user => {
      this.currentUser = user;
    }, () => {},
      () => {
        setTimeout(() => {
          this.route.params.subscribe(({ eventId }) => {
            this.eventId = eventId;
            this.submissionService.getSubmissionsToClaim(this.eventId, this.currentUser.id).subscribe(
              v => {
                let submissions = v.map(sub => <ClaimSubmission>sub);
                submissions = submissions?.sort((a,b) => { return b.interest - a.interest });
                this.submissions = new MatTableDataSource<ClaimSubmission>(submissions);
                this.initForm(submissions);
                this.navbar.loadingStop();
              }, () => {
                this.navbar.loadingStop();
              }, () => {
                this.loaded = true;
                this.eventService.getUserEventTopicsInterestByUserInEvent(eventId, this.currentUser.id).subscribe(
                  topics => {
                    this.userEventTopicsList = topics;                    
                    this.userEventTopicInterest = topics.filter(eventInterest => eventInterest.interest > 1)
                      .map(eventInterest => ' ' + eventInterest.eventTopic.name).toString();

                    this.userHasTopics = this.userEventTopicInterest?.length > 0;
                });

                this.eventService.getEvent(eventId).subscribe(event => {
                  this.eventName = event.name;
                  this.eventAcronym = event.acronym;
                  this.eventYear = event.year;
                });
              });
          });
        });
    });
  }

  initForm(submissions) {
    this.form = this.fb.array(
      submissions.map(sub => {
        return sub;
      })
    );
  }

  getTopicClass(topic: EventTopic, name: string = null) {
    let resul = this.userEventTopicsList?.filter(eventInterest => eventInterest.eventTopic.id === topic.id)
      .map(userTopic => {
        if (userTopic.interest == 2) {
          return 'interest';
        }
        if (userTopic.interest == 1) {
          return 'neutral-interest';
        }
        if (userTopic.interest == 0) {
          return 'no-interest';
        }
      });

      return resul ? resul[0] : '';
  }

  saveClaim() {
    const claimPaper = this.submissions.data.filter(sub => sub.isClaimedByUser).map(sub => sub.id);
    this.submissionService.updateClaimSubmissions(this.eventId, this.infoService.user.id, claimPaper).subscribe(
      v => {
        this.notificationService.notify('bid-claim.updated');
      });
  }
}
