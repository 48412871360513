import { Component } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { EventsService } from '../../../service/events.service';
import { NotificationService } from '../../../service/notification.service';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { EventRequest } from '../../../model/eventRequest';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { EVENT_TYPE } from 'src/app/admin/event-requests/request/request.component';

@Component({
  selector: 'app-request-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class RequestEventComponent {
  constructor(
    private eventsService: EventsService,
    private notificationService: NotificationService,
    private router: Router
  ) { }

  create(event: { form: FormGroup, errors: Array<{ control: string, errors: ValidationErrors }> }): void {
    if (event.form.valid) {

      const request = {
        name: event.form.value.name,
        acronym: event.form.value.acronym,
        year:event.form.value.year,
        uri: event.form.value.uri,
        eventType: EVENT_TYPE.CONFERENCE, // always create a new event as a conference
        city: event.form.value.city,
        state: event.form.value.state,
        country: event.form.value.country,
        timeZone: event.form.value.timeZone,
        beginAt: event.form.value.beginAt,
        endAt: event.form.value.endAt,
        makePublicAt: event.form.value.makePublicAt,
        eventParent: event.form.value.eventParent,
        chairs: event.form.value.chairs,
      };

      this.notificationService.notify('events.request.requesting-event');
      this.eventsService.requestEventCreation(<EventRequest>request).subscribe(e => {
        this.notificationService.notify('events.request.event-requested', { params: { acronym: e.acronym } });
        setTimeout(() => {
          this.router.navigate(['/my-events']);
        }, 1000);
      });
    } else if (event.errors.length > 0) {
      this.notificationService.notify('events.request.invalid-form', { params: { errors: event.errors.length } });
    } else {
      this.notificationService.notify('events.request.error');
    }
  }
}
