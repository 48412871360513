import { Component } from '@angular/core';
import { Review } from '../../model/paper';
import { ReviewsService } from '../../service/reviews.service';
import { ReviewStatus } from '../../enum/review.status';
import { NavbarService } from '../../service/navbar.service';
import { NO_PAGINATION } from 'src/app/model/pagination.options';
import { ShowAllPagedViewDirective } from 'src/app/class/paged-view-all';
import { LocalCache } from 'src/app/enum/local.cache';
import { PreferencesService } from 'src/app/service/preferences.service';
import { EventReviewConfiguration } from 'src/app/model/eventReviewConfiguration';
import { ReviewsConfigurationService } from 'src/app/service/reviews.configuration.service';
import { Event } from 'src/app/model/event';
import { plainToClass } from 'class-transformer';

class ReviewsByEvent {
  event: Event;
  count: number;
  reviews: Array<Review>;
  globalBlock: EventReviewConfiguration;
}

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})

export class ReviewsComponent extends ShowAllPagedViewDirective {
  public filterStatus: boolean = this.checkedFilterNotification;
  public reviewsByEvent = [];

  constructor(
    private reviewsService: ReviewsService,
    public navbarService: NavbarService,
    private reviewConfigurationService: ReviewsConfigurationService,
    public preferencesService: PreferencesService
  ) {
    super(preferencesService, LocalCache.OLD_REVIEWS);
  }

  load() {
    setTimeout(() => {
      this.myReviews = true;
      this.getReviews();
    });
  }

  get reviews(): Array<Review> {
    return <Array<Review>> this.elements;
  }

  public getReviews(): void {
    this.navbarService.loadingStart();
    const paginationOptions = NO_PAGINATION;
    paginationOptions.show = this.showAll;
    this.reviewsService.getUserReviews(paginationOptions, this.filter, undefined, true).subscribe(pagination => {
      this.unfilteredElements = pagination.items as Review[];
      this.filterPapers(<Review[]>this.unfilteredElements);
      this.getReviewsByEvent();
      this.navbarService.loadingStop();
      window.scroll(0, 0);  
    });
  }

  public getReviewsByEvent(){
    let reviews: Array<Review> = this.reviews;
    this.reviewsByEvent = [];
    if (reviews?.length > 0) {
      let listEvents: Array<Event> = [];
      let events: Array<Event> = [];
      
      listEvents = reviews.map(review => review.submission.event);
      events = listEvents.filter((value, index) => listEvents.findIndex(e => value.id === e.id) === index);
      
      events.forEach(e => {
        const result = plainToClass(ReviewsByEvent, {
          event: null,
          count: 0,
          reviews: [],
          globalBlock: null,
        });

        result.event = e;
        this.reviewConfigurationService.getReviewsConfigurationGlobalBlock(e.id).subscribe(configuration => {
          result.globalBlock = configuration;
          result.reviews = reviews.filter((value, index) => value.submission.event.id === e.id);
          result.count = result.reviews.length;
          this.reviewsByEvent.push(result);
        }, () => {}, () => {
          this.reviewsByEvent = this.reviewsByEvent.sort((a: ReviewsByEvent, b: ReviewsByEvent) => a.event.id - b.event.id);          
        });
      });
    }
  }

  updateReviewsByFilter(): void {
    this.reviewsByEvent?.map(eventList => {
      eventList.reviews?.map(r => {
        r.visible = this.filter.status.includes(r.status);
      });
    });

  }

  ifVisibleReviews(reviews: Array<Review>): boolean {
    return reviews.some((r) => r.visible === true);
  }

  stopClickPropagation(event): void {
    event.stopPropagation();
  }

  get checkedFilterNotification(): boolean {
    return this.reviewStatusFields.length > this.cachedStatusFields.length ? true : false;
  }

  get cachedStatusFields(): Array<string> {
    const cachedStatus = this.preferencesService.getPreference(LocalCache.STATUS_REVIEWS);
    if (cachedStatus === undefined || cachedStatus === false) {
      // No cached value defined. Set all fields as checked.
      return this.reviewStatusFields;
    } else if (cachedStatus === '') {
      // Cached value is defined with no status marked.
      return [];
    }
    // Return array of string status that are marked.
    return (<string>cachedStatus).split(',');
  }

  get reviewStatusFields(): Array<string> {
    return ReviewStatus.toIterable();
  }

  getFilterStatus(status: boolean) {    
    this.filterStatus = status;
    this.updateReviewsByFilter();
  }

  public isFiltered(): boolean {
    return this.filter.status.length < this.reviewStatusFields.length;
  }
}
