import { FormGroup } from '@angular/forms';
import { FormType } from '../enum/form.type';
import { FormFieldChoice } from '../model/form.field.choice';

export class FormField {
  public form: FormGroup;

  public label: {
    short: string,
    full: string
  };

  public min: number;
  public max: number;
  public name: string;
  public order: number;
  public hidden: boolean;
  public required: boolean;
  public placeholder: string;
  public formType: FormType;

  public choices: Array<FormFieldChoice>;
}
