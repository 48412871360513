import { Injectable } from '@angular/core';
import { Environment } from '../environment/environment';
import { HttpClient } from '@angular/common/http';
import { Review } from '../model/paper';
import { Response } from '../model/response';
import { InfoService } from './info.service';
import { Observable, of as ObservableFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToClass, plainToClassFromExist } from 'class-transformer';
import { PrintingProblem } from '../model/printing.problem';
import { CacheService } from './cache.service';
import { UserReview } from '../model/user.review';
import { SubmissionFilter } from '../model/submission.filter';
import { AssignReview } from '../model/assign.review';
import { Pagination } from '../model/pagination';
import { DEFAULT_PAGINATION_OPTIONS, PaginationOptions } from '../model/pagination.options';
import { PaperReviewHistory } from '../model/paper-review-history';
import { SubmissionHistory } from '../model/submission-history';
import { ReviewInvitation } from '../model/reviewInvitation';
import { ReviewStatusPerSubmissionList, ReviewStatusPerReviewerList } from '../model/review.status.list'
import { User } from '../model/user';
import { ReviewStatus } from '../enum/review.status';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  public reviews: { [id: number]: Review; } = {};
  constructor(private http: HttpClient, private infoService: InfoService, private cache: CacheService) { }

  getUserReviews(
    options: PaginationOptions = DEFAULT_PAGINATION_OPTIONS, filter?: SubmissionFilter, user?: number, ignoreStatus = false
  ): Observable<Pagination<Review>> {
    const key = `${this.infoService.user.id.toString()}-${options.pageNumber}`;
    const params = { params: {} };

    if (options.pageNumber) {
      params.params['pageNumber'] = String(options.pageNumber);
    }

    if (options.pageSize) {
      params.params['pageSize'] = String(options.pageSize);
    }

    if (options.show) {
      params.params['show'] = String(options.show);
    }

    if (!filter.isEmpty()) {
      params.params['orderBy'] = filter.orderBy;
      params.params['sortBy'] = filter.sortBy;

      if (!ignoreStatus && filter.status.length > 0) {
        params.params['status'] = filter.status.map(wtf => wtf);
      }
    }

    if (user) {
      params.params['user'] = user;
    }

    return this.http.get<Response>(`${Environment.urls.API}/core/review/byUser/`, params)
      .pipe(map(v => plainToClassFromExist(new Pagination<Review>(Review), v.data)))
      .pipe(map(v => {
        this.cache.setAll(
          this.cache.reviews,
          v.items.map(review => ({ key: review.id.toString(), value: review }))
        );

        return v;
      }));
  }

  getReview(id: number, forced = false): Observable<Review> {
    let foundReview;

    if (!forced) {
      foundReview = this.cache.getValue(this.cache.reviews, id.toString());
    }

    if (foundReview) {
      return ObservableFrom(foundReview);
    } else {
      return this.http.get<Response>(`${Environment.urls.API}/core/review/${id}/`)
        .pipe(map(v => plainToClass(Review, v.data as Review)))
        .pipe(map(review =>
          this.cache
            .setValue(this.cache.reviews, review.id.toString(), review)
            .get(review.id.toString())
        ));
    }
  }

  editReview(data: any, reviewId: number): Observable<Review> {
    return this.http.put<Response>(`${Environment.urls.API}/core/review/${reviewId}/`, data)
      .pipe(map(v => plainToClass(Review, v.data as Review)))
      .pipe(map(review =>
        this.cache
          .setValue(this.cache.reviews, review.id.toString(), review)
          .get(review.id.toString())
      ));
  }

  public reportPrintingProblem(data: PrintingProblem): Observable<PrintingProblem> {
    return this.http.post<Response>(Environment.urls.API + `/core/review/report/problem/printing/`, data)
      .pipe(map(v => plainToClass(PrintingProblem, v.data as PrintingProblem)));
  }

  public getReportPrintingProblem(reviewId: number): Observable<Array<PrintingProblem>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/review/${reviewId}/report/problem/printing/`)
      .pipe(map(v => plainToClass(PrintingProblem, v.data as Array<PrintingProblem>)));
  }

  getReviewsBySubmissionId(id: number): Observable<Review[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/review/bySubmission/${id}/`)
      .pipe(map(v => plainToClassFromExist(new Pagination<Review>(Review), v.data).items));
  }

  getReviewsByEvent(eventId: number): Observable<Review[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/review/byEvent/${eventId}/`)
      .pipe(map(v => plainToClassFromExist(new Pagination<Review>(Review), v.data).items));
  }

  getReviewsAssignedByEvent(eventId: number): Observable<Review[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/reviewAssigned/byEvent/${eventId}/`)
      .pipe(map(v => plainToClassFromExist(new Pagination<Review>(Review), v.data).items));
  }

  getReviewsAssignedByEventByID(eventId: number, userId: number): Observable<Review[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/reviewAssigned/byEvent/${eventId}/${userId}/`)
      .pipe(map(v => plainToClassFromExist(new Pagination<Review>(Review), v.data).items));
  }

  getReport(id: number): Observable<string> {
    return this.http.get<Response>(`${Environment.urls.API}/core/review/report/${id}/`)
      .pipe(map(res => Environment.urls.API + res.data.report));
  }

  // TEMP: There should not be 2 distinct review models
  updateReview(review: UserReview): Observable<Response> {
    // TEMP: (?)
    this.cache.clear(this.cache.reviews);

    return this.http.put<Response>(Environment.urls.API + `/core/review/${review.id}/`, review);
  }

  createReview(review: AssignReview): Observable<Review> {
    // TEMP: (?)

    return this.http.post<Response>(Environment.urls.API + `/core/review/`, review)
      .pipe(map(v => plainToClass(Review, v.data.review as Review)))
      .pipe(map(updatedReview =>
        this.cache
          .setValue(this.cache.reviews, updatedReview.id.toString(), updatedReview)
          .get(updatedReview.id.toString())
      ));
  }

  deleteReview(id: number): Observable<Response> {
    return this.http.delete<Response>(Environment.urls.API + `/core/review/${id}/`);
  }

  getPagedReviewsByEvent(
    eventId: number,
    options: PaginationOptions = DEFAULT_PAGINATION_OPTIONS,
    filter: SubmissionFilter
  ): Observable<Pagination<Review>> {
    const params = { params: {} };

    if (options.pageNumber) {
      params.params['pageNumber'] = String(options.pageNumber);
    }

    if (options.pageSize) {
      params.params['pageSize'] = String(options.pageSize);
    }

    if (options.show) {
      params.params['show'] = String(options.show);
    }

    if (!filter.isEmpty()) {
      params.params['orderBy'] = filter.orderBy;
      params.params['sortBy'] = filter.sortBy;

      if (filter.status.length > 0) {
        params.params['status'] = filter.status.map(wtf => wtf);
      }
    }

    return this.http.get<Response>(`${Environment.urls.API}/core/review/byEvent/${eventId}/`, params)
      .pipe(map(v => plainToClassFromExist(new Pagination<Review>(Review), v.data)))
      .pipe(map(v => {
        this.cache.setAll(
          this.cache.reviews,
          v.items.map(review => ({ key: review.id.toString(), value: review }))
        );

        return v;
      }));
  }

  getReviewStatusPerSubmissionListByEvent(event: number): Observable<Array<ReviewStatusPerSubmissionList>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/reviewStatusPerSubmission/byEvent/${event}/`)
      .pipe(map(v => plainToClass(ReviewStatusPerSubmissionList, v.data as Array<ReviewStatusPerSubmissionList>)));
  }

  getReviewStatusPerReviewerListByEvent(event: number): Observable<Array<ReviewStatusPerReviewerList>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/reviewStatusPerReviewer/byEvent/${event}/`)
      .pipe(map(v => plainToClass(ReviewStatusPerReviewerList, v.data as Array<ReviewStatusPerReviewerList>)));
  }

  public getReviewHistoryBySubmission(submission: number): Observable<Array<PaperReviewHistory>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/submission/${submission}/history/`)
      .pipe(map(v => plainToClass(PaperReviewHistory, v.data as Array<PaperReviewHistory>)));
  }

  public getReviewHistoryByReview(review: number): Observable<Array<PaperReviewHistory>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/review/${review}/history/`)
      .pipe(map(v => plainToClass(PaperReviewHistory, v.data as Array<PaperReviewHistory>)));
  }

  public getSubmissionHistoryBySubmission(submission: number): Observable<Array<SubmissionHistory>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/submission/${submission}/submission-history/`)
      .pipe(map(v => plainToClass(SubmissionHistory, v.data as Array<SubmissionHistory>)));
  }

  public sendReviewInvitationAnswer(review: number, status: 'CONFIRMED' | 'DECLINED'): Observable<Review> {
    return this.http.put<Response>(`${Environment.urls.API}/core/review/${review}/inviteAnswer/`, { status })
      .pipe(map(v => plainToClass(Review, v.data as Review)))
      .pipe(map(r =>
        this.cache
          .setValue(this.cache.reviews, r.id.toString(), r)
          .get(r.id.toString())
      ));
  }

  public getReviewInvitation(review: number): Observable<Review> {
    return this.http.get<Response>(`${Environment.urls.API}/core/review/${review}/inviteAnswer/`)
      .pipe(map(v => plainToClass(Review, v.data as Review)));
  }

  public sendInvitationEmail(reviews_ids: number[]): Observable<{ [user: number]: boolean }> {
    return this.http.post<Response>(`${Environment.urls.API}/core/review/sendInvitationEmail/`, { reviews_ids })
      .pipe(map(v => v.data));
  }

  public previewReviewerInvitation(
    event: number,
    status: ReviewStatus.ASSIGNED | ReviewStatus.REMINDED | ReviewStatus.LATE
  ): Observable<ReviewInvitation> {
    return this.http.get<Response>(`${Environment.urls.API}/core/review/previewInvitationEmail/${event}/`,
      { params: { status }})
      .pipe(map(v => plainToClass(ReviewInvitation, v.data as ReviewInvitation)));
  }

  public assignReview(review: number, user: number, params: { delegated?: 'true' | 'false' } = {}): Observable<Review> {
    return this.http.put<Response>(`${Environment.urls.API}/core/review/assign/${review}/`, { user }, { params })
      .pipe(map(v => plainToClass(Review, v.data as Review)))
      .pipe(map(r =>
        this.cache
          .setValue(this.cache.reviews, r.id.toString(), r)
          .get(r.id.toString())
      ));
  }

  public userIsReviewer(user: User, reviews: Array<Review>): boolean {
    return reviews.some(review => review.userId === user.id);
  }

  public userIsAssigner(user: User, reviews: Review[]): boolean {
    return reviews.some(review => review.assignedByUserId === user.id);
  }

  public getNumberAnsweredReview(event: number): Observable<number> {
   return this.http.get<Response>(`${Environment.urls.API}/core/review/byEvent/count/${event}/`).pipe(map(num => <number>num.data)
   );
  }

  public reclaimReview(review: number): Observable<Review> {
    return this.http.post<Response>(`${Environment.urls.API}/core/review/${review}/reclaim/`, {})
      .pipe(map(v => plainToClass(Review, v.data as Review)));
  }
}
