import { Type, Expose } from 'class-transformer';
import { Profile } from './profile';
import { BaseModel } from './baseModel';

export class User extends BaseModel {
  @Type(() => Profile)
  profile: Profile;

  @Expose()firstName: string;
  @Expose()lastName: string;
  @Expose()email: string;
  @Expose()isActive: boolean;
  isStaff: boolean;
  isSuperuser: boolean;

  get name(): string {
    if (this.firstName || this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    }
    return null;
  }

  get abbreviatedName(): string {
    if (!this.name) {
      return null;
    }

    const prepositions = ['da', 'de', 'do', 'dos', 'das'];
    const names = this.name.split(" ").filter(name => !prepositions.includes(name));
    let abbreviatedName = names[names.length - 1] + " ";

    for (let i = 0; i < names.length - 1; i++) {
      const name = names[i];
      abbreviatedName += `${name[0].toUpperCase()}. `;
    }

    abbreviatedName = abbreviatedName.slice(0, -1);

    return abbreviatedName;
  }

  get avatar(): string {
    const name = this.name.split(' ');
    const avatar = [name[0]];

    if (name.length > 1) {
      avatar.push(name.pop());
    }

    return avatar.join(' ');
  }

  get uid(): string {
    return btoa(this.id.toString());
  }

  canAssignReviewers(): boolean {
    return this.isStaff || this.isSuperuser;
  }
}
