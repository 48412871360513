import { Component, OnInit, HostBinding, ViewChild, Input } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '../../service/auth.service';
import { InfoService } from '../../service/info.service';
import { NavbarComponent } from '../../component/navbar/navbar.component';
import { NavbarService } from '../../service/navbar.service';
import { SidebarService } from '../../service/sidebar.service';
import { SidebarItem } from '../../model/sidebar-item';
import { NotificationService } from '../../service/notification.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @ViewChild('navbar', { static: true }) public navbar: NavbarComponent;

  public isMobile: boolean;

  @Input() items = [
    new SidebarItem('/events', { name: 'fa-lightbulb', font: 'fas' }, { full: 'menus.sidebar.events.full', short: 'menus.sidebar.events.short' }, { divider: true, class: 'button-events' }),
    new SidebarItem('/submissions', { name: 'fa-file-alt', font: 'fas' }, { full: 'menus.sidebar.my-submissions.full', short: 'menus.sidebar.my-submissions.short'}, {
      class: 'button-my-submissions'
    }),
    new SidebarItem('/reviews', { name: 'fa-balance-scale', font: 'fas' }, { full: 'menus.sidebar.my-reviews.full', short: 'menus.sidebar.my-reviews.short' }, {
      class: 'button-my-reviews'
    }),
    new SidebarItem('/committees', { name: 'fa-users', font: 'fas' }, { full: 'menus.sidebar.my-committees.full', short: 'menus.sidebar.my-committees.short' }, { class: 'button-tpcs' }),
    new SidebarItem('/my-events', { name: 'fa-star', font: 'fas' }, { full: 'menus.sidebar.my-events.full', short: 'menus.sidebar.my-events.short' }, { class: 'button-chairings' })
  ];

  constructor(
    public infoService: InfoService,
    private navbarService: NavbarService,
    private sidebarService: SidebarService,
    public notificationService: NotificationService
  ) {
    this.isMobile = window.innerWidth < 768;
  }

  ngOnInit(): void {
    this.sidebarService.setSidebar(undefined, this);
    this.navbarService.setNavbar(this.navbar);
  }
}
