import * as dayjs from 'dayjs';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

export class Notification {
  constructor(
    public text: string,
    public link: string,
    public date: dayjs.Dayjs,
    public read: boolean = false,
    public params: { [key: string]: any } = {},
    public overridePanelClass: string,
    public snackbarConfig: MatSnackBarConfig,
    public onOpen: () => void,
    public onClose: () => void
  ) {}

  getTimeDiff(): string {
    const now = dayjs();

    const days = now.diff(this.date, 'day');
    const hours = now.diff(this.date, 'hour') - days * 24;
    const minutes = now.diff(this.date, 'minute') - hours * 60;

    let str = '';
    if (days) {
      str += (days > 30) ? this.date.format('DD/MM/YYYY') : `${days}d`;
    } else if (hours) {
      str += `${hours}h`;
    } else if (minutes) {
      str += `${minutes}m`;
    }

    if (!str.length) {
      str += '0m';
    }

    return str;
  }

  callback(func: () => void) {
    if (func) {
      func();
    }
  }
}
