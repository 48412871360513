import { Review } from './paper';
import { Type } from 'class-transformer';

export class SelectableReview extends Review {
  selected = false;
}

export class ReviewInvitation {
  @Type(() => SelectableReview)
  reviews: Array<SelectableReview>;

  example: {
    content: string;
    subject: string;
    toUser: string;
    fromUser: string;
    replyTo: string;
    fromUserName: string;
  };
}
