import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileRules } from 'src/app/model/file.rules';

export interface DialogData {
  rule: FileRules
}

@Component({
  selector: 'app-file-rules-card-info-dialog',
  templateUrl: './file-rules-card-info-dialog.component.html',
  styleUrls: ['./file-rules-card-info-dialog.component.scss']
})
export class FileRulesCardInfoDialogComponent {
  @Input() rule : FileRules;
  mimeTypes : string;

  constructor(
    public dialogRef: MatDialogRef<FileRulesCardInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.rule = data.rule;
    this.mimeTypes = this.rule.mimetypes.reduce<string>((acc, value) => `${acc}${value.type},`, "").slice(0, -1);
  }
}
