import { Component, Inject, OnInit, Optional, Input, Output, EventEmitter } from '@angular/core';
import { NavbarService } from 'src/app/service/navbar.service';
import { Review, Submission } from 'src/app/model/paper';
import { ReviewsService } from 'src/app/service/reviews.service';
import { PaperReviewHistory } from 'src/app/model/paper-review-history';
import { forkJoin, Observable } from 'rxjs';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { AuthService } from 'src/app/service/auth.service';
import { Event } from 'src/app/model/event';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { EventReviewConfiguration } from 'src/app/model/eventReviewConfiguration';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReviewStatus } from 'src/app/enum/review.status';
import { InfoService } from 'src/app/service/info.service';
import { User } from 'src/app/model/user';
import { NotificationService } from 'src/app/service/notification.service';
import { ReviewsConfigurationService } from 'src/app/service/reviews.configuration.service';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { FormGroup, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-user-search-dialog',
  templateUrl: './user-search-dialog.component.html',
  styleUrls: ['./user-search-dialog.component.scss']
})
export class UserSearchDialogComponent implements OnInit {

  public form: FormGroup;

  constructor(
    public notificationService: NotificationService,
    public fb: FormBuilder,
    private dialogRef: MatDialogRef<UserSearchDialogComponent>,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.form = this.fb.group({
        user: [],
      });
    });
  }

  public confirm(): void {
    const user = <User> this.form.value.user[0];
    this.dialogRef.close(user);
  }

  public close(data = null): void {
    this.dialogRef.close(data);
  }

  blockConfirm() {
    if (this.form.get('user').value?.length == 0) {
      return true;
    }
    return false;
  }

}
