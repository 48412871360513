import { Transform } from 'class-transformer';
import { User } from './user';
import { BaseModel, toDayjs } from './baseModel';
import { Type } from 'class-transformer';
import * as dayjs from 'dayjs';

export class MergeUsersRegister extends BaseModel {

  @Transform(toDayjs)
  public createdAt: dayjs.Dayjs;

  @Transform(toDayjs)
  public updatedAt: dayjs.Dayjs;

  public firstCrudeventId: number;
  public lastCrudeventId: number;

  @Type(() => User)
  mainUser: User;

  @Type(() => User)
  mergedUser: User;

  @Type(() => User)
  mergedByUser: User;

  public status: string; // TO DO ENUM
}
