import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProxyHistory } from 'src/app/model/proxyHistory';
import { AdminService } from 'src/app/service/admin.service';

@Component({
  selector: 'app-proxy-history',
  templateUrl: './proxy-history.component.html',
  styleUrls: ['./proxy-history.component.scss']
})
export class ProxyHistoryComponent implements OnInit {

  proxyHistoryData = new MatTableDataSource<ProxyHistory>();
  proxyHistoryColumns = ['id', 'action', 'datetime', 'admin', 'user', 'host'];

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    if (sort) {
      this.proxyHistoryData.sort = sort;
      this.proxyHistoryData.sortingDataAccessor = (history: ProxyHistory, header: string) => {
        switch(header) {
          case 'id': return history.id;
          case 'action': return history.action;
          case 'datetime': return history.createdAt.valueOf();
          case 'admin': return history.admin.name;
          case 'user': return history.user.name;
          case 'host': return history.host;

          default: return history.id;
        }
      }
    }
  }

  constructor(
    private adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.adminService.getProxyHistory().subscribe(data => {
      this.proxyHistoryData.data = data;
    });
  }

}
