import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FileRules } from 'src/app/model/file.rules';
import { FilesService } from 'src/app/service/files.service';
import { FileUploadErrors } from 'src/app/model/file';
import { RulesFileSlot } from '../file-rules-card/file-rules-card.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-input-drop-file',
  templateUrl: './input-drop-file.component.html',
  styleUrls: ['./input-drop-file.component.scss']
})
export class InputDropFileComponent {
  @Input() rules: FileRules;
  @Input() readonly slot: RulesFileSlot;
  @Input() readonly allowPagesExceed: boolean;

  @Output() fileDragged = new EventEmitter<{file: File, rule: FileRules, slot: RulesFileSlot}>();
  @Output() hasError = new EventEmitter<number>();

  private error: string = null;

  constructor(
    private filesService: FilesService,
    private dialog: MatDialog
  ) { }

  public fileHandler($files: FileList) {
    const file = Array.from($files)[0];

    this.filesService.validFileAsync(file, this.rules).subscribe(isValid => {
      this.hasErrors(isValid) ? this.openErrorDialog() : this.fileDragged.emit({file, rule: this.rules, slot: this.slot});
    });

  }

  private hasErrors(isValid: FileUploadErrors): boolean {
    switch (isValid) {
      case FileUploadErrors.MAX_SIZE:
        this.error = 'errors.file.max-size';
        break;
      case FileUploadErrors.INVALID_FORMAT:
        this.error = 'errors.file.invalid-format';
        break;
      case FileUploadErrors.MAX_PAGES:
        if (this.allowPagesExceed === false) {
          this.error = 'errors.file.max-pages';
          break;
        }
        this.error = null;
        break;
      case FileUploadErrors.VALID:
        this.error = null;
        break;
      default:
        this.error = null;
        break;
    }

    if (this.error) {
      this.hasError.emit(this.rules.id);
      return true;
    }

    return false;
  }

  private openErrorDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = { 
      title: this.error,
      content: ''
    };
    dialogConfig.data.params = { onlyOkButton: true };

    this.dialog.open(ConfirmationDialogComponent, dialogConfig);
  }
}
