import { Component, Input } from '@angular/core';
import { NotificationService } from '../../service/notification.service';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'app-notification-menu-trigger',
  templateUrl: './notification-menu-trigger.component.html',
  styleUrls: ['./notification-menu-trigger.component.scss']
})
export class NotificationMenuTriggerComponent {
  @Input() menu: MatMenu;

  constructor(
    private notificationService: NotificationService
  ) { }
}
