import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarService } from '../service/navbar.service';
import { InfoService } from '../service/info.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent {
  constructor(private router: Router, private navbarService: NavbarService, public infoService: InfoService) { }
}
