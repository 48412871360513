import { Component, ChangeDetectorRef, ViewChild, ElementRef, OnInit } from '@angular/core';
import { InfoService } from '../../service/info.service';
import { UserService } from '../../service/user.service';
import { User } from 'src/app/model/user';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-change-profile-picture',
  templateUrl: './change-profile-picture.component.html',
  styleUrls: ['./change-profile-picture.component.scss']
})
export class ChangeProfilePictureComponent implements OnInit {
  @ViewChild('imageInput', { static: true }) imagePicture: ElementRef;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedImageAvatar: any = '';
  cropSection: boolean = false;
  user$: Observable<User>;
  user: User;

  constructor(
    private ref: ChangeDetectorRef,
    public infoService: InfoService,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.user$ = this.userService.getUserFromToken();

    this.user$.subscribe(user => this.user = user);
  }

  processPicture(event: any): void {
    this.imageChangedEvent = event;
    this.cropSection = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.resizeProfileImageAvatar();
  }

  showProfileImage(): string{
    let imageProfile: any;

    if (this.croppedImage) {
      imageProfile = this.croppedImage;
    } else {
      imageProfile = this.user.profile.imageUrl;
    }

    return imageProfile;
  }

  resizeProfileImageAvatar () {
    const img = new Image();
    img.src = this.croppedImage;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = 300;
      canvas.height = 300;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      this.croppedImageAvatar = canvas.toDataURL('image/png');
    };
  }

  resetCrop() {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.croppedImageAvatar = '';
    this.cropSection = false;

    (<HTMLInputElement>document.getElementById('imageInput')).value = '';
  }

  cancelCrop() {
    this.resetCrop();
  }

  saveCrop() {
    let imageBlob = base64ToFile(this.croppedImage);
    let imageAvatarBlob = base64ToFile(this.croppedImageAvatar);
    let imageFile = new File([imageBlob], this.imageChangedEvent.target.files[0].name);
    let imageAvatarFile = new File([imageAvatarBlob], this.imageChangedEvent.target.files[0].name);

    this.userService.changePicture(this.user, imageFile, imageAvatarFile).subscribe(profile => {
      setTimeout(() => {
        const user = this.user;
        user.profile = profile;
        this.user = user;

        this.ref.detectChanges();
      });
    });

    this.resetCrop();
  }

  removePicture(): void {
    this.userService.removePicture(this.user.id).subscribe(res => {
      const user = this.user;
      user.profile = res.data;
      this.user = user;
      this.ref.detectChanges();
    });
  }
}
