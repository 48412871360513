import { SubmissionStatus } from '../enum/submission.status';
import { ReviewStatus } from '../enum/review.status';

export class SubmissionFilter {
  status: Array<SubmissionStatus | ReviewStatus> = [];
  orderBy = '';
  sortBy: 'ascending' | 'descending' = 'ascending';
  missingManuscripts = false;

  isEmpty(): boolean {
    return this.status.length === 0 &&
      this.orderBy === '' &&
      this.sortBy === 'ascending';
  }
}
