import { Component, OnInit, HostBinding, ViewChild } from '@angular/core';
import { NotificationService } from '../../service/notification.service';
import { InfoService } from 'src/app/service/info.service';
import { AuthService } from 'src/app/service/auth.service';
import { SidebarService } from 'src/app/service/sidebar.service';
import { FixedTopProgressBarComponent } from '../fixed-top-progress-bar/fixed-top-progress-bar.component';
import { MatMenu } from '@angular/material/menu';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.scss']
})
export class AdminNavbarComponent implements OnInit {
  @HostBinding('class.hidden') isHidden = false;
  @ViewChild(FixedTopProgressBarComponent, { static: true }) progressBar: FixedTopProgressBarComponent;

  icon = undefined;

  notificationMenu: MatMenu;
  user: User;

  constructor(
    public sidebarService: SidebarService,
    public authService: AuthService,
    public notificationService: NotificationService,
    private userService: UserService,
    public infoService: InfoService
  ) { }

  ngOnInit() {
    this.userService.getUserFromToken().subscribe( user => {
      this.user = user;
    });
    this.notificationService.refreshNotifications();
  }

  toggle(open: boolean = this.isHidden): void {
    this.isHidden = !open;
  }

  toggleSidebar(): void {
    return;
  }

  get isMobile(): boolean {
    return window.innerWidth < 768;
  }
}
