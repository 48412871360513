import { Component, ViewChild, OnInit, Injectable } from '@angular/core';
import { FixedTopProgressBarComponent } from '../component/fixed-top-progress-bar/fixed-top-progress-bar.component';
import { EmailConfirmedComponent } from './email-confirmed/email-confirmed.component';
import { RouterOutlet, ActivatedRoute } from '@angular/router';
import { ErrorViewComponent } from '../component/error-view/error-view.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LanguageService } from '../service/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  @ViewChild(FixedTopProgressBarComponent, { static: true }) progressBar: FixedTopProgressBarComponent;

  @ViewChild('routerOutlet', { static: true }) routerOutlet: RouterOutlet;
  containerIsLarge = false;

  languageForm: FormGroup;
  message: string;

  constructor(
    public languageService: LanguageService,
    public fb: FormBuilder,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.routerOutlet.isActivated) {
      this.containerIsLarge =
        this.routerOutlet.component instanceof EmailConfirmedComponent ||
        this.routerOutlet.component instanceof ErrorViewComponent;
    }

    this.initForm();

    this.message = this.route.snapshot.queryParams['message'];
  }

  private initForm() {
    this.languageForm = this.fb.group({
      language: [this.translate.currentLang]
    });
  }

  clearMessage() {
    this.message = undefined;
  }
}
