import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivateChild {
  constructor(public authService: AuthService, public router: Router) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let isAdmin = false;
    this.authService.directUserIsAdmin().subscribe(issu => {
      isAdmin = issu;
    }, () => {
      this.router.navigate(['/']);
      return false;
    },
    () => {
      if (isAdmin === true) {
        return true;
      } else {
        this.router.navigate(['/']);
        return false;
      }
    });
  }
}
