import {
  Injectable,
  Injector,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef,
  ComponentRef
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DomService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) { }

  closeCalendarSource = new BehaviorSubject<boolean>(false);
  closeCalendar$ = this.closeCalendarSource.asObservable();

  appendComponentToBody(component: any): ComponentRef<any> {
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    this.appRef.attachView(componentRef.hostView);
    document.body.appendChild(domElem);

    return componentRef;
  }

  closeCalendar(message: boolean) {
    this.closeCalendarSource.next(message);
  }
}
