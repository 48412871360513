import { AbstractControl } from '@angular/forms';

export class PasswordValidator {
  static MatchPassword(abstractControl: AbstractControl) {
    if (abstractControl.get('password').value !== abstractControl.get('confirmPassword').value) {
      abstractControl.get('confirmPassword').setErrors({ matchPassword: true });
      return abstractControl;
    } else {
      return null;
    }
  }
}
