import { Component, forwardRef, Input } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextInputComponent),
    multi: true
  }]
})

export class TextInputComponent{
  @Input() label: string;
  @Input() icon: string;
  @Input() name: string;
  @Input() align: string;
  @Input() hint: string;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() required: boolean;
  @Input() maxLength: number;
  @Input() readOnly: boolean;
  @Input() appearance: string;
  @Input() placeholder: string;
  @Input() formGroupController: FormGroup;
  @Input() warning: boolean;

  @Input() help: {
    label: string,
    align: 'start' | 'end'
  };

  checkValidity() {
    if (!!this.formGroupController.controls[this.name].invalid && !!this.warning) {
      return 'warning';
    }
  }
}
