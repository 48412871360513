import { Component, OnInit, Input } from '@angular/core';
import { ReviewsService } from 'src/app/service/reviews.service';
import { AdminService } from 'src/app/service/admin.service';
import { Event } from 'src/app/model/event';
import { ReviewInvitation } from 'src/app/model/reviewInvitation';
import { NotificationService } from 'src/app/service/notification.service';
import { ReviewStatus } from 'src/app/enum/review.status';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import _ from 'lodash';
@Component({
  selector: 'app-notify-reviewers',
  templateUrl: './notify-reviewers.component.html',
  styleUrls: ['./notify-reviewers.component.scss']
})
export class NotifyReviewersComponent implements OnInit {
  @Input() event: Event;
  @Input() status: ReviewStatus.ASSIGNED | ReviewStatus.REMINDED | ReviewStatus.LATE;

  reviewInvitation: ReviewInvitation;
  notifiedReviewInvitation: ReviewInvitation;

  form: FormGroup;

  ReviewStatus = ReviewStatus;

  constructor(
    public adminService: AdminService,
    public reviewsService: ReviewsService,
    public notificationService: NotificationService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.event && this.status) {
      this.getReviews();
    }
  }

  initForm() {
    this.form = this.fb.group({
      includeInputStatus: [{value: this.reviewInvitation.reviews.length != 0, disabled: this.reviewInvitation.reviews.length == 0}],
      includeNotified: [{value: false, disabled: this.notifiedReviewInvitation.reviews.length == 0}]
    });

    this.form.get('includeInputStatus').valueChanges.subscribe(value => {
      this.reviewInvitation.reviews.forEach(r => r.selected = value);
    });

    this.form.get('includeNotified').valueChanges.subscribe(value => {
      this.notifiedReviewInvitation.reviews.forEach(r => r.selected = value);
    });
  }

  getReviews(): void {
    this.adminService.progressBar.start();

    this.reviewsService.previewReviewerInvitation(this.event.id, this.status).subscribe(preview => {
      this.reviewInvitation = preview;
      this.notifiedReviewInvitation = _.cloneDeep(this.reviewInvitation);

      this.reviewInvitation.reviews = this.reviewInvitation.reviews.filter(r => r.status != ReviewStatus.NOTIFIED);
      this.notifiedReviewInvitation.reviews = this.notifiedReviewInvitation.reviews.filter(r => r.status == ReviewStatus.NOTIFIED);

      this.reviewInvitation.reviews.forEach(r => r.selected = true);

      this.adminService.progressBar.stop();
    }, () => {}, () => {
      this.initForm();
    });
  }

  notifyReviewers(): void {
    const reviews = this.reviewInvitation.reviews.filter(r => r.selected).map(r => r.id).concat(this.notifiedReviewInvitation.reviews.filter(r => r.selected).map(r => r.id));
    
    this.reviewsService.sendInvitationEmail(reviews).subscribe(() => {
      this.reviewInvitation.reviews = this.reviewInvitation.reviews.filter(r => !r.selected);
      this.notificationService.notify('reviews.notify-reviewers.notification-sent');
    }, () => {
      this.notificationService.notifyError('reviews.notify-reviewers.notification-sent-failed');
    });
  }

  getField(name: string): FormControl {
    return this.form.get(name) as FormControl;
  }

  isStatus(name: string, status: string): boolean {
    let reviewInvitation = name == 'includeInputStatus' ? this.reviewInvitation : this.notifiedReviewInvitation;
    const reviewsLength = reviewInvitation.reviews.length;
    const selectedReviewsLength = reviewInvitation.reviews.filter(r => r.selected).length;
    
    return status == 'checked' ? reviewsLength == selectedReviewsLength : reviewsLength > 0 && selectedReviewsLength > 0 && reviewsLength != selectedReviewsLength;
  }

  get selectedReviewers(): number {
    return this.reviewInvitation.reviews?.filter(r => r.selected).length + this.notifiedReviewInvitation.reviews?.filter(r => r.selected).length;
  }


  templateParameter(status: ReviewStatus.ASSIGNED | ReviewStatus.REMINDED | ReviewStatus.LATE): { template: string } {
    let template: string;

    switch (status) {
      case ReviewStatus.ASSIGNED:
        template = 'ON_REVIEW_ASSIGNED';
        break;
      case ReviewStatus.REMINDED:
        template = 'ON_REVIEW_REMIND';
        break;
      case ReviewStatus.LATE:
        template = 'ON_REVIEW_LATE';
        break;
    }

    return { template };
  }
}
