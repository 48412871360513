import { Component, OnInit, Input } from '@angular/core';
import { Track } from 'src/app/model/track';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/service/admin.service';

@Component({
  selector: 'app-tracks',
  templateUrl: './tracks.component.html',
  styleUrls: ['./tracks.component.scss']
})
export class TracksComponent implements OnInit {
  @Input() userIsChair = false;
  @Input() tracks: Array<Track>;
  @Input() eventTimeZone: string;

  @Input() showTitle = true;
  @Input() showTrackFiles = false;

  isEventViewRoute = false;
  isUserViewRoute = false;

  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.isEventViewRoute = this.adminService.isEventAdminRoute(this.route.snapshot);
    this.isUserViewRoute = this.adminService.isUserViewRoute(this.route.snapshot);
  }
}
