import { Component, OnInit } from '@angular/core';
import { EmailTrigger } from '../../enum/email.trigger';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { EventsService } from '../../service/events.service';
import { NotificationService } from '../../service/notification.service';
import { EmailTemplatesEditDirective } from 'src/app/class/email-templates-edit';

@Component({
  selector: 'app-admin-default-email-templates',
  templateUrl: './admin-default-email-templates.component.html',
  styleUrls: ['./admin-default-email-templates.component.scss']
})
export class AdminDefaultEmailTemplatesComponent extends EmailTemplatesEditDirective implements OnInit {
  emailExists = false;

  constructor(
    protected fb: FormBuilder,
    protected translateService: TranslateService,
    protected eventsService: EventsService,
    private notificationService: NotificationService
  ) {
    super(false, fb, translateService, eventsService);
  }

  ngOnInit() {
    this.initComponent();
  }

  initComponent(): void {
    this.initForm();
  }

  updateSelectedEmailTemplate(): void {
    this.eventsService.editDefaultEmailTemplate(this.notificationEmail.trigger, this.notificationEmail)
      .subscribe(nf => {
        this.notificationService.notify('admin.email-template.email-updated');
        this.notificationEmail = nf;
      });
  }

  getEmailTemplate(trigger: EmailTrigger): void {
    this.getDefaultEmailTemplate(trigger);
  }
}
