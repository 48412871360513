import { Transform } from 'class-transformer';
import { User } from './user';
import { Submission } from './paper';
import { EmailStatus } from '../enum/emails.status';
import { BaseModel, toDayjs } from './baseModel';
import { Type } from 'class-transformer';
import * as dayjs from 'dayjs';

export class EmailRegister extends BaseModel {

  @Transform(toDayjs)
  public createdAt: dayjs.Dayjs;

  subject: string;
  content: string;
  cc: string;
  displayInfo: boolean;

  event: number;

  @Type(() => User)
  to: Array<User | number> = [];

  status: EmailStatus;
}
