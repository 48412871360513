import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextInputComponent } from '../text-input/text-input.component';
import { DateTimePickerHandler } from 'src/app/class/date-time-picker-handler';
import * as dayjs from 'dayjs';
import * as moment from 'moment';
import { DomService } from 'src/app/service/dom.service';
import { TimezoneService } from 'src/app/service/timezone.service';
import { FormGroup } from '@angular/forms';
import { TimeZoneInputComponent } from '../time-zone-input/time-zone-input.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-datetimepicker-input',
  templateUrl: './datetimepicker-input.component.html',
  styleUrls: ['./datetimepicker-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatetimepickerInputComponent),
    multi: true
  }]
})
export class DatetimepickerInputComponent extends TextInputComponent implements OnInit, AfterViewInit {
  @ViewChild('datepicker', { static: false}) datepicker;
  @ViewChild(TimeZoneInputComponent) timeZoneInput: TimeZoneInputComponent;
  @Input() max: number;
  @Input() min: number;
  @Input() eventTimeZone: string;
  @Input() enabledTimeZone: boolean;
  @Input() timeZoneController: string;
  @Input() dateTimeController: string;
  @Input() disabledTime: boolean = false;
  @Input() datePipe: string = 'dateTime';
  
  @Input() link: {
    label: string,
    route: string,
    align: 'start' | 'end'
  };

  public DateTimePickerHandler: DateTimePickerHandler;
  public container: Element;
  public clonedForm: FormGroup;

  @ViewChild('dateTimePicker', { static: false }) containerDateTimePicker: ElementRef;

  constructor(
    private domService: DomService,
    private tzService: TimezoneService
  ) {
    super();
    this.DateTimePickerHandler = new DateTimePickerHandler(this.tzService, this.domService);
  }

  ngOnInit() {
    this.listenClosePicker();
    this.clonedForm = this.cloneForm();
  }
  
  ngAfterViewInit(): void {
    this.DateTimePickerHandler.removeInputDateTime(this.containerDateTimePicker, '._container-input', '.container-date-time-mask');
  }

  private cloneForm() {
    return _.cloneDeep(this.formGroupController);
  }
  
  public listenClosePicker() {
    this.domService.closeCalendar$.subscribe(isClose => {
      if (isClose) {
        this.datepicker?.close();
      }
    });
  }
  
  public get offsetTimeZoneSelected(): string {
    const timeZone = this.formGroupController.get(this.timeZoneController).value;
    const offset = moment.tz(timeZone).format('Z');
    return offset ? `(UTC${offset})` : '';
  }

  dateToString(date: number) {
    return dayjs(date).format('DD/MM/YYYY, HH:mm:ss');
  }

  public get dateTimeViewed(): Date {
    return this.clonedForm.get(this.name).value;
  }

  public generateInputInDateTime($event: PointerEvent) {
    if (this.enabledTimeZone) {
      this.DateTimePickerHandler.generateInputInDateTime('.time-container', this.formGroupController, this.clonedForm, this.timeZoneController, this.name, this.getContainerTimeZoneInput($event), this.eventTimeZone);
      this.timeZoneInput.radioSelectedTimeZone = this.formGroupController.get(this.timeZoneController).value;
      return;
    }

    this.DateTimePickerHandler.addOnlyButtonClose('.actions', this.formGroupController, this.clonedForm, this.name);
  }

  private getContainerTimeZoneInput($event: PointerEvent) {
    if (this.container) return this.container;
    
    const containerDateTimePicker = ($event.target as HTMLElement).closest('app-datetimepicker-input');
    const appTimeZoneInput = containerDateTimePicker.getElementsByTagName('app-time-zone-input')[0];
    const containerButton = appTimeZoneInput.getElementsByClassName('container-button')[0];
    const div = appTimeZoneInput.getElementsByTagName('div')[0];
    this.container = document.createElement('div');
    this.container.appendChild(containerButton);
    this.container.appendChild(div);

    return this.container;
  }
}
