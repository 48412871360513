import { SelectOption } from '../model/select.option';

export enum EventRequestStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED'
}

export namespace EventRequestStatus {
  export function toIterable(): Array<string> {
    return Object.keys(EventRequestStatus).filter(key => typeof EventRequestStatus[key] !== 'function');
  }

  export function toSelectable(): Array<SelectOption> {
    return EventRequestStatus.toIterable().map(value => ({
      id: value,
      value: EventRequestStatus[value]
    }));
  }
}
