import { Component, Input } from '@angular/core';
import { PreferencesService } from '../../service/preferences.service';

@Component({
  selector: 'app-card-placeholder',
  templateUrl: './card-placeholder.component.html',
  styleUrls: ['./card-placeholder.component.scss']
})
export class CardPlaceholderComponent {
  @Input() animate = true;

  constructor(public preferencesService: PreferencesService) { }

  get hasIconArithmeticModifier(): number {
    return Number(this.preferencesService.getPreference('statusIcon'));
  }
}
