import { Injectable } from '@angular/core';
import { User } from '../model/user';
import { LoginResponse } from '../model/login.response';
import { plainToClass } from 'class-transformer';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/service/user.service';
import { Event } from '../model/event';
import { AdminService } from './admin.service';

@Injectable({
  providedIn: 'root'
})
export class InfoService {
  private event: Event;
  private _user: User;
  private _proxyUser: User;
  private _proxyToken: string;
  public proxyActive = false;

  constructor(
    public adminService: AdminService,
    public translate: TranslateService,
    public userService: UserService,
  ) {
    if (this.token) {
      this._user = this.getUserFromService();
    }
    this._proxyToken = localStorage.getItem('user_proxy_token');
    if(this._proxyToken){
      this._user = this.getUserFromService();
    }
    this._proxyUser = this.getUserFromStorage('user_proxy');

    if (this._proxyUser) {
      this.proxyActive = true;
    }
  }

  set user(u: User) {
    this._user = u;
    this.setPreferencesLocalCache();
  }

  get user(): User {
    if(this._user !== undefined){
      return this._user;
    } else {
      this.userService.getUserFromToken().subscribe(u => {
        this._user = u;
      }, () => {
        return this._user;
      },
      () => {
          return this._user;
      });
    }
  }

  getUserFromService(): User {
    if(this._user !== undefined){
      return this._user;
    }else{
      this.userService.getUserFromToken().subscribe((u) => {
        this._user = u;
      });
      return this._user;
    }
  }

  getUserFromStorage(key = 'user'): User {
    const u = JSON.parse(localStorage.getItem(key));
    if (u) {
      return plainToClass(User, u as User);
    }
    return undefined;
  }
  
  set selectedEvent(event: Event) {
    this.event = event;
  }

  get selectedEvent(): Event {
    return this.event;
  }

  getPreference(key: string): string | number | boolean {
    const preferences = JSON.parse(localStorage.getItem('preferences'));
    if (preferences) {
      return preferences[key];
    } else {
      this.setPreferencesLocalCache();
    }
    return undefined;
  }

  setPreference(key: string, value: any): void  {
    let preferences = JSON.parse(localStorage.getItem('preferences'));
    if (preferences) {
      this.setPreferencesLocalCache();
      preferences = JSON.parse(localStorage.getItem('preferences'));
    }
    preferences[key] = value;
    localStorage.setItem('preferences', JSON.stringify(preferences));
  }

  setPreferencesLocalCache(): void {
    let user = this.getUserFromService();

    if (user) {
      let preferences = `{"viewMode": "${user.profile.viewMode}",` +
        `"statusIcon":${user.profile.statusIcon},` +
        `"statusColorAsBackgroundColor":${user.profile.statusColorAsBackgroundColor}}`
      localStorage.setItem('preferences', preferences);
    }
  }

  set response(res: LoginResponse) {
    this.token = res.token;
    this.user = res.user;

    this.translate.use(res.user.profile.language);
  }

  set token(token: string) {
    localStorage.setItem('token', token);
  }

  get token(): string {
    return localStorage.getItem('token');
  }

  setProxyUser(res: LoginResponse) {
    // Backup user before proxy.
    localStorage.setItem('user_proxy', JSON.stringify(this.user));
    localStorage.setItem('user_proxy_token', this.token);
    this._proxyUser = this.user;
    this._proxyToken = this.token;

    // Set new user as active user.
    this.user = res.user;
    this.token = res.token;
    this.proxyActive = true;
  }

  clearProxy(user?: User) {
    // If proxy was active, restore previous user and token.
    // ELse will do nothing and just clear any garbage, just in case.
    this.user = this._proxyUser ? this._proxyUser : this.user;
    this.token = this._proxyToken ? this._proxyToken : this.token;

    if (user) {
      this.adminService.clearProxyUser(user.id).subscribe();
    }
 
    this.proxyActive = false;
    localStorage.removeItem('user_proxy');
    localStorage.removeItem('user_proxy_token');
  }
}
