import { Component, Inject, OnInit, Optional, Input, Output, EventEmitter } from '@angular/core';
import { NavbarService } from 'src/app/service/navbar.service';
import { Review, Submission } from 'src/app/model/paper';
import { ReviewsService } from 'src/app/service/reviews.service';
import { PaperReviewHistory } from 'src/app/model/paper-review-history';
import { forkJoin, Observable } from 'rxjs';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { AuthService } from 'src/app/service/auth.service';
import { Event } from 'src/app/model/event';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { EventReviewConfiguration } from 'src/app/model/eventReviewConfiguration';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReviewStatus } from 'src/app/enum/review.status';
import { InfoService } from 'src/app/service/info.service';
import { User } from 'src/app/model/user';
import { NotificationService } from 'src/app/service/notification.service';
import { ReviewsConfigurationService } from 'src/app/service/reviews.configuration.service';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { FormGroup, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-review-delegate',
  templateUrl: './review-delegate.component.html',
  styleUrls: ['./review-delegate.component.scss']
})
export class ReviewDelegateComponent implements OnInit {

  public review: Review;
  public reviewConfig: EventReviewConfiguration;
  public userIsChair = false;
  public form: FormGroup;

  @Output() reviewDelegated = new EventEmitter<Review[]>();

  constructor(
    private route: ActivatedRoute,
    private reviewsService: ReviewsService,
    private submissionsService: SubmissionsService,
    public notificationService: NotificationService,
    private dialog: MatDialog,
    private navbarService: NavbarService,
    public fb: FormBuilder,
    private dialogRef: MatDialogRef<ReviewDelegateComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) @Optional() public data: { review: Review, userIsChair: boolean },
  ) { }

  ngOnInit() {
    setTimeout(() => {
      if (this.data) {
        this.review = this.data.review;
        this.userIsChair = this.data.userIsChair;
        this.form = this.fb.group({
          user: [],
        });
      }
    });
  }

  blockDelegate() {
    if (this.form.get('user').value?.length == 0) {
      return true;
    }
    return false;
  }

  delegate() {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'messages.confirm-delegate-review',
        content: ''
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.delegateToSelectedUser();
      }
    });
  }

  delegateToSelectedUser() {
    const user = <User> this.form.value.user[0];
    if (this.userHasAssignmentConflict(user)) {
      this.notificationService.notifyError('reviews.assign-conflict-detected', {
        params: { user: `#${user.id} ${user.name}` }
      });
    } else {
      this.delegateToExternalReview(user);
    }
  }

  delegateToExternalReview(user: User) {
    this.reviewsService.assignReview(this.review.id, user.id).subscribe(review => {
      this.notificationService.notify('messages.assigned-external-user-review', { params: { user: user.firstName + ' ' + user.lastName } });
      // this.router.navigate(['/reviews']);
      this.dialogRef.close();
      window.location.reload();
    });
  }

  userHasAssignmentConflict(user: User): boolean {
    return this.review.submission.isAuthor(user.id) || this.reviewsService.userIsReviewer(user, [this.review]);
  }
}
