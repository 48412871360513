import { Component } from '@angular/core';

@Component({
  selector: 'app-submission-edit',
  templateUrl: './submission-edit.component.html',
  styleUrls: ['./submission-edit.component.scss']
})
export class SubmissionEditComponent {
  constructor() { }
}
