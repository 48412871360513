import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Review } from 'src/app/model/paper';
import { EventsService } from 'src/app/service/events.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserEvent } from 'src/app/model/user.event';
import { UserEventRole } from 'src/app/enum/user.event.role';
import { InfoService } from 'src/app/service/info.service';
import { AssignReview } from 'src/app/model/assign.review';
import { NotificationService } from 'src/app/service/notification.service';
import { ReviewsService } from 'src/app/service/reviews.service';
import { ReviewStatus } from 'src/app/enum/review.status';
import { User } from 'src/app/model/user';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-delegate-review',
  templateUrl: './delegate-review.component.html',
  styleUrls: ['./delegate-review.component.scss']
})
export class DelegateReviewComponent implements OnInit, OnChanges {
  @Input() review: Review;

  reviews: Array<Review>;

  form: FormGroup;
  committeeList: Array<UserEvent>;

  name = 'delegatedUser';
  @Output() reviewChanged = new EventEmitter<Review>();

  constructor(
    private eventsService: EventsService,
    private fb: FormBuilder,
    private infoService: InfoService,
    private reviewsService: ReviewsService,
    private notificationService: NotificationService
  ) { }

  ngOnChanges(changed): void {
    if (changed.review && this.review) {
      this.ngOnInit();
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      delegatedUser: [[], Validators.required]
    });

    forkJoin([
      this.eventsService.getEventCommitteeList(this.review.submission.event.id),
      this.reviewsService.getReviewsBySubmissionId(this.review.submission.id)
    ]).subscribe(([members, reviews]) => {
      this.committeeList = members;
      this.reviews = reviews;
    });
  }

  choiceIsCurrentReviewer(member: UserEvent): boolean {
    return this.review.userId === member.user.id;
  }

  userHasAssignmentConflict(user: User): boolean {
    return this.review.submission.isAuthor(user.id) || this.reviewsService.userIsReviewer(user, this.reviews);
  }

  delegate() {
    const user = <User>this.form.value.delegatedUser;

    if (this.userHasAssignmentConflict(user)) {
      this.notificationService.notifyError('reviews.assign-conflict-detected', {
        params: { user: `#${user.id} ${user.name}` }
      });
    } else {
      this.reviewsService.assignReview(this.review.id, user.id, { delegated: 'true' }).subscribe(updatedReview => {
        this.notificationService.notify('messages.delegated-user-review', { params: { user: updatedReview.userId } });
        this.review = updatedReview;

        this.reviewChanged.emit(this.review);
        this.form.patchValue({ delegatedUser: undefined });
      });
    }
  }
}
