import { SelectOption } from '../model/select.option';

// Templates usable by Event Chair
export enum EmailTrigger {
  ON_SUBMISSION_CHANGE = 'ON_SUBMISSION_CHANGE',
  ON_SUBMISSION_CREATE = 'ON_SUBMISSION_CREATE',
  ON_SUBMISSION_ACCEPTED = 'ON_SUBMISSION_ACCEPTED',
  ON_SUBMISSION_REJECTED = 'ON_SUBMISSION_REJECTED',
  ON_SUBMISSION_WITHDRAW = 'ON_SUBMISSION_WITHDRAW',
  ON_MISSING_MANUSCRIPT = 'ON_MISSING_MANUSCRIPT',
  ON_UPLOAD_PROBLEM = 'ON_UPLOAD_PROBLEM',
  ON_REBUTTAL_SEND = 'ON_REBUTTAL_SEND',
  ON_REVIEW_ASSIGNED = 'ON_REVIEW_ASSIGNED',
  ON_REVIEW_COMPLETED = 'ON_REVIEW_COMPLETED',
  ON_REVIEW_CHANGE = 'ON_REVIEW_CHANGE',
  ON_REVIEW_LATE = 'ON_REVIEW_LATE',
  ON_REVIEW_REMIND = 'ON_REVIEW_REMIND',
  ON_REVIEW_CONFIRMED_BY_REVIEWER = 'ON_REVIEW_CONFIRMED_BY_REVIEWER',
  ON_REVIEW_DECLINED_BY_REVIEWER = 'ON_REVIEW_DECLINED_BY_REVIEWER',
  ON_REVIEW_DELEGATED_TO = 'ON_REVIEW_DELEGATED_TO',
  ON_REVIEW_DELEGATED_FROM = 'ON_REVIEW_DELEGATED_FROM',
  ON_REVIEW_CONFIRMED_IN_DELEGATED = 'ON_REVIEW_CONFIRMED_IN_DELEGATED',
  ON_REVIEW_DECLINED_IN_DELEGATED = 'ON_REVIEW_DECLINED_IN_DELEGATED',
  ON_TPC_MEMBER_ADDED = 'ON_TPC_MEMBER_ADDED',
  ON_TPC_MEMBER_ACCEPTED = 'ON_TPC_MEMBER_ACCEPTED',
  ON_TPC_MEMBER_DECLINED = 'ON_TPC_MEMBER_DECLINED',
  ON_TPC_MEMBER_INVITE = 'ON_TPC_MEMBER_INVITE',
  ON_DISCUSSION_MESSAGE_SEND = 'ON_DISCUSSION_MESSAGE_SEND',
  ON_TPC_CHAIR_ADDED = 'ON_TPC_CHAIR_ADDED',
  ON_REVIEW_RECLAIMED_BY_ASSIGNER = 'ON_REVIEW_RECLAIMED_BY_ASSIGNER'
}

export namespace EmailTrigger {
  export function toIterable(): Array<string> {
    return Object.keys(EmailTrigger).filter(key => typeof EmailTrigger[key] !== 'function');
  }
  export function toString(type: EmailTrigger): string {
    return `type.${type}`;
  }

  export function toSelectable(): Array<SelectOption> {
    return this.toIterable().map(value => ({
      id: value,
      value: `admin.email-template.type.${EmailTrigger[value]}.name`
    }));
  }

  export function getName(trigger: string): string {
    return `admin.email-template.type.${EmailTrigger[trigger]}.name`;
  }
}

// Used by System Administrator
// Must contain all system templates
export enum DefaultEmailTrigger {
  ON_SUBMISSION_CHANGE = 'ON_SUBMISSION_CHANGE',
  ON_SUBMISSION_CREATE = 'ON_SUBMISSION_CREATE',
  ON_SUBMISSION_ACCEPTED = 'ON_SUBMISSION_ACCEPTED',
  ON_SUBMISSION_REJECTED = 'ON_SUBMISSION_REJECTED',
  ON_SUBMISSION_WITHDRAW = 'ON_SUBMISSION_WITHDRAW',
  ON_MISSING_MANUSCRIPT = 'ON_MISSING_MANUSCRIPT',
  ON_UPLOAD_PROBLEM = 'ON_UPLOAD_PROBLEM',
  ON_REBUTTAL_SEND = 'ON_REBUTTAL_SEND',
  ON_REVIEW_ASSIGNED = 'ON_REVIEW_ASSIGNED',
  ON_REVIEW_COMPLETED = 'ON_REVIEW_COMPLETED',
  ON_REVIEW_CHANGE = 'ON_REVIEW_CHANGE',
  ON_REVIEW_LATE = 'ON_REVIEW_LATE',
  ON_REVIEW_REMIND = 'ON_REVIEW_REMIND',
  ON_REVIEW_CONFIRMED_BY_REVIEWER = 'ON_REVIEW_CONFIRMED_BY_REVIEWER',
  ON_REVIEW_DECLINED_BY_REVIEWER = 'ON_REVIEW_DECLINED_BY_REVIEWER',
  ON_REVIEW_DELEGATED_TO = 'ON_REVIEW_DELEGATED_TO',
  ON_REVIEW_DELEGATED_FROM = 'ON_REVIEW_DELEGATED_FROM',
  ON_REVIEW_CONFIRMED_IN_DELEGATED = 'ON_REVIEW_CONFIRMED_IN_DELEGATED',
  ON_REVIEW_DECLINED_IN_DELEGATED = 'ON_REVIEW_DECLINED_IN_DELEGATED',
  ON_TPC_MEMBER_ADDED = 'ON_TPC_MEMBER_ADDED',
  ON_TPC_MEMBER_ACCEPTED = 'ON_TPC_MEMBER_ACCEPTED',
  ON_TPC_MEMBER_DECLINED = 'ON_TPC_MEMBER_DECLINED',
  ON_TPC_MEMBER_INVITE = 'ON_TPC_MEMBER_INVITE',
  ON_DISCUSSION_MESSAGE_SEND = 'ON_DISCUSSION_MESSAGE_SEND',
  ON_TPC_CHAIR_ADDED = 'ON_TPC_CHAIR_ADDED',
  ON_USER_CREATE = 'ON_USER_CREATE',
  ON_USER_ACTIVATE = 'ON_USER_ACTIVATE',
  ON_USER_NON_ACTIVE_RESET_PASSWORD = 'ON_USER_NON_ACTIVE_RESET_PASSWORD',
  ON_USER_ACTIVE_RESET_PASSWORD = 'ON_USER_ACTIVE_RESET_PASSWORD',
  ON_EVENT_REQUEST = 'ON_EVENT_REQUEST',
  ON_EVENT_REQUEST_ACCEPTED = 'ON_EVENT_REQUEST_ACCEPTED',
  ON_EVENT_REQUEST_REJECTED = 'ON_EVENT_REQUEST_REJECTED',
  ON_REVIEW_RECLAIMED_BY_ASSIGNER = 'ON_REVIEW_RECLAIMED_BY_ASSIGNER'
}

export namespace DefaultEmailTrigger {
  export function toIterable(): Array<string> {
    return Object.keys(DefaultEmailTrigger).filter(key => typeof DefaultEmailTrigger[key] !== 'function');
  }

  export function toString(type: DefaultEmailTrigger): string {
    return `type.${type}`;
  }

  export function toSelectable(): Array<SelectOption> {
    return this.toIterable().map(value => ({
      id: value,
      value: `admin.email-template.type.${DefaultEmailTrigger[value]}.name`
    }));
  }

  export function getName(trigger: string): string {
    return `admin.email-template.type.${DefaultEmailTrigger[trigger]}.name`;
  }
}
