import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { LoginHistory } from 'src/app/model/login.history';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { AuthService } from 'src/app/service/auth.service';
import { InfoService } from 'src/app/service/info.service';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-login-history',
  templateUrl: './login-history.component.html',
  styleUrls: ['./login-history.component.scss']
})
export class LoginHistoryComponent implements OnInit, AfterViewInit {

  constructor(
    public authService: AuthService,
    public infoService: InfoService,
    private userService: UserService
  ) { }

  public loginHistoryDataSource = new MatTableDataSource<LoginHistory>();
  public loginHistoryColumns = ['datetime', 'remoteIp'];

  @ViewChild(MatSort) sort: MatSort;
  user: User;

  ngOnInit() {
    this.userService.getUserFromToken().subscribe( user => {
      this.user = user;
    }, () => {},
    () => {
      this.initForm();
    });
  }

  ngAfterViewInit() {
    this.loginHistoryDataSource.sort = this.sort;
  }

  private initForm() {
    this.authService.getLoginHistory(this.user.id).subscribe(history => {
      this.loginHistoryDataSource.data = history;
    });
  }
}
