import { Component, Input, OnInit } from '@angular/core';
import { NotificationEmail } from 'src/app/model/notificationEmail';
import { ExportService } from 'src/app/service/export.service';

@Component({
  selector: 'app-email-preview',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss'],
  providers: []
})

export class EmailPreviewComponent {
  @Input() preview: Array<NotificationEmail>;
  @Input() loading = false;

  constructor( public exportService: ExportService ) { }

  public generateEmailsExport() {    
    if (this.preview && this.preview.length > 0){
      setTimeout(() => {
        let emailList = [];
        emailList = this.preview.map(m => [...m.to, ...m.cc]);
        emailList = emailList.flat().filter((value, index, self) => self.indexOf(value) === index);
        
        const emailsList = emailList.map(email => `${email},`).join('\n');
        setTimeout(() => {
          const blob = new Blob([emailsList], {type: 'text/plain'});
          const downloadURL = URL.createObjectURL(blob);
          this.downloadLink(downloadURL, `emails_list.txt`);
        }, 1000);
      }, 1000);
    }
  }

  private downloadLink(downloadURL: string, name: string) {
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = name;
    link.click();
  }
}
