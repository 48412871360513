import { Component, forwardRef, Input, ViewChild, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { HttpEvent, HttpEventType, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { FilesService } from 'src/app/service/files.service';
import { InfoService } from 'src/app/service/info.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { NotificationService } from 'src/app/service/notification.service';
import { FileRules } from 'src/app/model/file.rules';
import { File } from '../../model/file';
import { zip, Observable, Subscription, of, forkJoin } from 'rxjs';
import { CookieService } from 'ng2-cookies';
import { AuthService } from 'src/app/service/auth.service';
import { NavbarService } from 'src/app/service/navbar.service';



@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss'],
})
export class FileDownloadComponent implements OnDestroy {
  @Input() file: File;
  downloadStatus: number;
  sessionId = false;

  subscriptions: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private filesService: FilesService,
    private submissionsService: SubmissionsService,
    public cookieService: CookieService,
    private navbarService: NavbarService,
    private notificationService: NotificationService
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  downloadFile(file: File) {
    let downloadURL = file.url
    // this.sessionId = this.cookieService.check('csrftoken');
    if (!this.sessionId) {
      // this.setSessionId();  // If to set sessionId cookie
      this.navbarService.loadingStart();
      let contentType = 'application/pdf';
      if (file.type) {
        let contentType = file.type;
      }
      this.subscriptions.push(this.filesService.downloadFile(file.id).subscribe((data) => {
        let blob = new Blob([data], {type: contentType});
        downloadURL = URL.createObjectURL(blob);
      }, () => {},
      () => {
        this.navbarService.loadingStop();
        this.downloadLink(downloadURL, file.name);
      }));
    } else {
      this.downloadLink(downloadURL, file.name);
    }
  }

  downloadLink(downloadURL: string, fileName: string) {
    // Create link to lownload
    var link = document.createElement('a');
    link.href = downloadURL;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.download = fileName;
    link.click();
  }

  setSessionId() {
    this.authService.getSessionId().subscribe(
      response => {
      let sessionId = response.data;
      if (sessionId) {
         this.cookieService.set('sessionid', sessionId, 30, '/');
      }
    });
  }
}
