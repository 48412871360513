import { Component, Input, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/service/user.service';
import { User } from 'src/app/model/user';
import { Affiliation } from 'src/app/model/affiliation';
import { NotificationService } from 'src/app/service/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  affiliations: Affiliation[];
}

@Component({
  selector: 'app-create-affiliation',
  templateUrl: './create-affiliation.component.html',
  styleUrls: ['./create-affiliation.component.scss']
})
export class CreateAffiliationComponent implements OnInit {
  public affiliationForm: FormGroup;

  @Input() user: User;
  @Input() affiliations: Affiliation[];
  @Input() maxLength: number = 15;
  @Input() width: string = 'is-six';
  @Output() closeContainerNewAffiliation = new EventEmitter<{value: boolean, wasCreated: boolean}>();
  @Output() newAffiliationID = new EventEmitter<number>();

  newAffiliation: {existInDB: boolean, affiliation: string} = {existInDB: false, affiliation: ''};
  currentAcronym: string;

  constructor(
    public fb: FormBuilder,
    public userService: UserService,
    public notificationService: NotificationService,
    public dialogRef: MatDialogRef<CreateAffiliationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    const affiliationRegex = '^[a-zA-Z0-9-]*$'
    const blockEmailRegex = /^(?!.*@.*\.).*$/;
    this.affiliationForm = this.fb.group({
      acronym: ['', [Validators.pattern(affiliationRegex), Validators.maxLength(15)]],
      name: ['', [Validators.pattern(blockEmailRegex)]],
    });
  }

  public submitNewAffiliation(): void {
    const acronym = this.affiliationForm.get('acronym').value;
    const name = this.affiliationForm.get('name').value;

    this.close({acronym, name});
  }

  public close(data = null): void {
    this.dialogRef.close(data);
  }

  public getInputAcronym(acronym: string): void {
    if (!!this.data.affiliations) {
      this.data.affiliations.filter((affiliation: Affiliation) => {
        if (!!affiliation.acronym) {
          if (affiliation.acronym.toLowerCase() === acronym.toLowerCase()) {
            this.currentAcronym = acronym;
            this.newAffiliation.affiliation = affiliation.filter;
            this.newAffiliation.existInDB = true;
          } else if (this.currentAcronym != acronym) {
            this.newAffiliation.existInDB = false;
          }
        }
      });
    }
  }

  public countCharAffiliation(): number {
    return this.affiliationForm.get('acronym').value.length;
  }

  public isValidFormNewAffiliation(): boolean {
    const name = this.affiliationForm.get('name').value;
    const acronym = this.affiliationForm.get('acronym').value;

    return ((!!name || !!acronym) && this.affiliationForm.valid && !this.newAffiliation.existInDB);
  }
}
