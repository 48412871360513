import { Component, Input } from '@angular/core';
import { SelectableReview } from 'src/app/model/reviewInvitation';
import { User } from  'src/app/model/user';

@Component({
  selector: 'app-reviewer-invitation',
  templateUrl: './reviewer-invitation.component.html',
  styleUrls: ['./reviewer-invitation.component.scss']
})
export class ReviewerInvitationComponent {

  @Input() review: SelectableReview;
  @Input() disabled: boolean;

  constructor() { }

  public getReviewerName(review: SelectableReview): string {
    if (review.user instanceof User) {
      return review.user?.name;
    }
    return null;
  }

  public getReviewerEmail(review: SelectableReview): string {
    if (review.user instanceof User) {
      return review.user?.email;
    }
    return null;
  }

}
