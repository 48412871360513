import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatHorizontalStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-event-publication-proceedings',
  templateUrl: './event-publication-proceedings.component.html',
  styleUrls: ['./event-publication-proceedings.component.scss']
})

export class EventPublicationProceedingsComponent implements AfterViewInit {

  @ViewChild(MatHorizontalStepper) stepper: MatHorizontalStepper;

  steps: { name: string, label: string }[] = [
    {name: 'ISBN/ISSN', label: 'admin.event.publication.proceedings.steps.identification'},
    {name: 'Content', label: 'admin.event.publication.proceedings.steps.content'},
    {name: 'TrackFile', label: 'admin.event.publication.proceedings.steps.track-file'},
    {name: 'Indexes', label: 'admin.event.publication.proceedings.steps.indexes'},
    {name: 'Preview', label: 'admin.event.publication.proceedings.steps.preview'},
    {name: 'Generate', label: 'admin.event.publication.proceedings.steps.generate'},
  ];

  constructor() { }

  ngAfterViewInit(): void {
    this.stepper._getIndicatorType = () => 'number';
  }

  extractStepsNames() : string[] {
    return this.steps.map(s => s.name);
  }
  
}
