import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MarkdownService } from 'ngx-markdown';
import { plainToClass, Type } from 'class-transformer';

class Page {
  name: string;
  @Type(() => Page)
  pages?: Array<Page>;

  constructor() {}

  get filename() {
    return `${this.name.replace(/-/g, ' ')}.md`;
  }
}

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  public pages: Array<Page> = plainToClass(Page, [
    { name: 'Index',
      pages: [
        { name: 'JEMS-Overview' },
        { name: 'Registration-Form' },
        { name: 'General-Recommendations-for-Users' },
        { name: 'How-to-use-JEMS-for-my-Event' },
        { name: 'Frequent-Asked-Questions' },
        { name: 'JEMS-Administrators' }
      ]
    },
    { name: 'Authors', pages: [] },
    { name: 'Reviewer', pages: [] },
    {
      name: 'TPC-Chairs',
      pages: [
        { name: 'Event-Creation' },
        { name: 'Paper-Submission' },
        { name: 'Paper-Review' },
        { name: 'Rebuttal-and-Discussion-Phase' },
        { name: 'Selection-Phase' },
        { name: 'TPC-Chair-Additional-Information' },
      ]
    },
    { name: 'Publication-Chair', pages: [] },
    { name: 'Session-Chair', pages: [] }
  ] as Array<Page>);

  public activePage: Page;
  public activeSub: Page;
  public activeLinkData: string;

  constructor(
    private http: HttpClient,
    public route: ActivatedRoute,
    private markdownService: MarkdownService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      const { main, sub } = this.getPage(paramMap, this.pages);

      this.activePage = main;
      this.activeSub = sub;

      this.getData(this.currentPage);
    });

    this.markdownService.renderer.link = (href: string, title: string, text: string) => {
      let pagename;

      const absoluteLink = `${href.split('jems3-wiki/wiki/')[1]}`;
      const externalLink = href.startsWith('http') && absoluteLink === 'undefined';

      if (absoluteLink?.length > 0 && absoluteLink !== 'undefined') {
        pagename = absoluteLink;
      } else {
        pagename = href;
      }

      if (externalLink) {
        return `<a href="${href}">${text}</a>`
      }

      const isSubpage = this.activePage.pages.find(p => p.name === pagename);
      if (isSubpage) {
        return `<a href="/help/${this.activePage.name}/${pagename}">${text}</a>`;
      }
      return `<a href="/help/${pagename}">${text}</a>`;
    }
  }

  getPage(paramMap, pageArray: Page[]) {
    let main: Page;
    let sub: Page;

    if (paramMap.has('pageZero')) {
      const foundIndex = pageArray.findIndex(p => p.name === paramMap.get('pageZero'));
      if (foundIndex !== -1) {
        main = pageArray[foundIndex];
      } else {
        main = pageArray[0];
      }
    } else {
      main = pageArray[0];
    }

    if (paramMap.has('pageOne')) {
      const foundIndex = main.pages.findIndex(p => p.name === paramMap.get('pageOne'));
      if (foundIndex !== -1) {
        sub = main.pages[foundIndex];
      }
    }

    return { main, sub };
  }

  changePage(page: Page, level: 0 | 1) {
    if (level === 0) {
      this.activePage = page;
      this.activeSub = undefined;
      this.getData(this.currentPage);
      window.history.replaceState({}, '', `help/${this.activePage.name}`);
    } else {
      this.activeSub = page;
      this.getData(this.currentPage);
      window.history.replaceState({}, '', `help/${this.activePage.name}/${this.activeSub.name}`);
    }
  }

  getData(page?: Page) {
    if (page) {
      this.http.get(`/assets/wiki/${page.name}.md`, { responseType: 'text' }).subscribe((data: string) => {
        // Substitute wiki-only link to images to application link to images.
        this.activeLinkData = data.replace(/images/g, 'assets/wiki/images');
      })
    }
  }

  get currentPage(): Page {
    return this.activeSub ? this.activeSub : this.activePage;
  }
}
