import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { NotificationService } from 'src/app/service/notification.service';
import { AdminService } from 'src/app/service/admin.service';
import { DEFAULT_FILETYPE } from 'src/app/enum/file.type';
import { FileType } from 'src/app/model/fileType';
import { SelectOption } from 'src/app/model/select.option';
import { Track } from 'src/app/model/track';
import { Event } from 'src/app/model/event';
import { CheckListField } from 'src/app/model/checklist-field';
import { ActivatedRoute, Router } from '@angular/router';
import { EventsService } from 'src/app/service/events.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { TimezoneService } from 'src/app/service/timezone.service';
import { animate, style, transition, trigger } from '@angular/animations';

export const FILETYPE_HAS_PAGES = ['PDF', 'DOC', 'DOCX'];

@Component({
  selector: 'app-event-tracks-edit',
  templateUrl: './event-tracks-edit.component.html',
  styleUrls: ['./event-tracks-edit.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.15s', style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.15s', style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class EventTracksEditComponent implements OnInit {
  event: Event;
  track: Track;
  trackForm: FormGroup;
  checklistForm: FormArray;
  mimetypes: Array<FileType> = [];

  checklistFields: Array<CheckListField> = [];
  deletedChecklistFields: Array<number> = [];
  mimetypeValues: Array<SelectOption>;

  params: {
    trackId: number
  };

  constructor(
    private adminService: AdminService,
    private eventService: EventsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public notificationService: NotificationService,
    private router: Router,
    private dialog: MatDialog,
    private tzService: TimezoneService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.setMimetypes();

      this.route.params.subscribe(params => {
        this.event = this.adminService.selectedEvent;

        if (!params.trackId) {
          this.initChecklistForm();
        } else {
          this.eventService.getTrackCheckListFields(params.trackId).subscribe(checklistfields => {
            this.checklistFields = checklistfields;

            this.initChecklistForm();
          });
        }

        if (!this.event) {
          this.adminService.getEvent().subscribe(event => {
            this.event = event;
            this.getTrack(params.trackId);
          });
        } else {
          this.getTrack(params.trackId);
        }
      });
    });
  }

  private getTrack(trackId): void {
    if (trackId) {
      this.eventService.getTrack(trackId).subscribe(track => {
        this.track = track;
        this.initTrackForm();
      });
    } else {
      this.track = new Track(this.event.id, '');
      this.track.trackFiles = [];
      this.initTrackForm();
      this.addNewTrackFile();
    }
  }

  private setMimetypes(): void {
    this.eventService.getMimetypes().subscribe(mimetypes => {
      this.mimetypes = mimetypes;
      this.mimetypeValues = mimetypes.map(m => ({ id: m.id, value: m.type }));
    });
  }

  private initTrackForm(): void {
    this.trackForm = this.fb.group({
      event: [this.event.id],
      name: [this.track.name, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      openAt: [this.track.openAt ?.toDate()],
      editPendingSubmissions: [this.track.editPendingSubmissions],
      editActiveSubmissions: [this.track.editActiveSubmissions],
      editRejectedSubmissions: [this.track.editRejectedSubmissions],
      editAcceptedSubmissions: [this.track.editAcceptedSubmissions],
      timeZoneOpenAt: [this.tzService.browserTimeZone],
      closeAt: [this.track.closeAt ?.toDate()],
      timeZoneCloseAt: [this.tzService.browserTimeZone],
      trackFiles: this.fb.array(this.track.trackFiles.map(file => this.fb.group({
        id: [file.id],
        maxPages: [file.maxPages, [Validators.required, Validators.min(0)]],
        allowPagesExceeded: [file.allowPagesExceeded != null ? file.allowPagesExceeded : true],
        maxSize: [file.maxSize / Math.pow(1024, 2), [Validators.required, Validators.min(0)]], // Convert bytes to megabytes (2^20)
        mimetypes: [file.mimetypes.map(e => e.id)],
        name: [file.name, [Validators.required, Validators.minLength(1), Validators.maxLength(300)]],
        openAt: [file.openAt ?.toDate()],
        timeZoneOpenAt: [this.tzService.browserTimeZone],
        closeAt: [file.closeAt ?.toDate()],
        timeZoneCloseAt: [this.tzService.browserTimeZone],
        required: [file.required],
        visibleSessionChair: [file.visibleSessionChair ? file.visibleSessionChair :false],
        useOfSbcTemplate: [file.useOfSbcTemplate ? file.useOfSbcTemplate :false]
      })))
    });
  }

  deleteTrackFile(trackFileId: number): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'admin.event.tracks.confirm-trackfile-deletion',
        content: ''
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.notificationService.notify('admin.event.tracks.deleted-trackfile', {
          params: {
            trackFile: trackFileId
          }
        });

        const control = (<FormArray>this.trackForm.get('trackFiles'));
        control.removeAt(trackFileId);
      }
    });
  }

  addNewTrackFile(): void {
    const control = <FormArray>this.trackForm.get('trackFiles');
    const newControl = this.fb.group({
      id: [null],
      maxPages: [0],
      allowPagesExceeded: [true],
      maxSize: [0],
      mimetypes: [[]],
      name: ['', [Validators.required, Validators.minLength(1)]],
      openAt: [new Date()],
      timeZoneOpenAt: [this.tzService.browserTimeZone],
      closeAt: [new Date()],
      timeZoneCloseAt: [this.tzService.browserTimeZone],
      required: [true],
      visibleSessionChair: [false],
      useOfSbcTemplate: [false]
    });
    control.push(newControl);
  }

  private removeTimeZoneControls(): void {
    this.trackForm.value.trackFiles.forEach(trackFile => {
      delete trackFile.timeZoneOpenAt;
      delete trackFile.timeZoneCloseAt;
    });
  }

  public updateRuleExceedNumberOfPages($trackFileName: string, $value: boolean) {
    this.trackForm.value.trackFiles.forEach(track => {
      if (track.name === $trackFileName) track.allowPagesExceeded = $value;
    });
  }

  save(): void {
    this.removeTimeZoneControls(); // The backend does not support timezone fields for track files
    if (this.track.id) {
      this.verifyFields();
      this.eventService.editTrack(this.trackForm.value, this.track.id).subscribe(track => {
        this.eventService.getEvent(this.adminService.selectedEvent.id).subscribe(e => {
          this.event = e;
          this.adminService.selectedEvent = e;
        });

        this.notificationService.notify('admin.event.tracks.saved-track', {
          params: {
            track: `#${track.id} "${track.name}"`
          }
        });

        this.saveChecklist().subscribe(() => {
          this.router.navigate(['admin', this.event.id, 'settings', 'tracks']);
        });
      }, error => {
        console.error(error);
      });
    } else {
      this.eventService.createTrack(this.trackForm.value).subscribe(track => {
        this.adminService.selectedEvent.tracks.push(track);
        this.event = this.adminService.selectedEvent;

        this.notificationService.notify('admin.event.tracks.create-new', {
          params: {
            track: `#${track.id} "${track.name}"`
          }
        });
        this.saveChecklist(track.id).subscribe(() => {
          this.router.navigate(['..'], { relativeTo: this.route });
        });
      });
    }
  }

  verifyFields(): void {
    this.trackForm.value.trackFiles.forEach((trackFile) => {
      if (trackFile.mimetypes.length === 0) {
        const id = this.mimetypes.find(m => m.name === DEFAULT_FILETYPE) ?.id;
        trackFile.mimetypes = id ? [id] : [];
      }

      trackFile.maxSize = trackFile.maxSize * Math.pow(1024, 2); // Convert from megabytes to stored bytes value
    });
  }

  public isFormatFileHasPages($trackFileName: string): boolean {
    let mimetypesSelected: number[] = [];
    this.trackForm.value.trackFiles.forEach(trackFile => {
      if (trackFile.name === $trackFileName) mimetypesSelected = trackFile.mimetypes;
    });

    const mimetypes = this.mimetypes.filter(mimetype => mimetypesSelected.includes(mimetype.id));
    return mimetypes.some(mimetype => FILETYPE_HAS_PAGES.includes(mimetype.type));
  }

  public isUnlimitedPages($trackFileName: string): boolean {
    let maxPages: number = 0;
    this.trackForm.value.trackFiles.forEach(trackFile => {
      if (trackFile.name === $trackFileName) maxPages = Number(trackFile.maxPages);
    });

    return maxPages === 0;
  }

  private initChecklistForm(): void {
    this.checklistForm = this.fb.array(this.checklistFields.map(field => this.fb.group({
      id: [field.id],
      description: [field.description, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      visibleTo: this.fb.group({
        author: [field.visibleTo.author],
        reviewer: [field.visibleTo.reviewer],
        sessionChair: [field.visibleTo.sessionChair],
        publicationChair: [field.visibleTo.publicationChair],
      }),
      modifiableBy: this.fb.group({
        author: [field.modifiableBy.author],
        reviewer: [field.modifiableBy.reviewer],
        sessionChair: [field.modifiableBy.sessionChair],
        publicationChair: [field.modifiableBy.publicationChair]
      })
    })
    ));
  }

  deleteChecklistField(order: number): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'admin.event.tracks.checklist.confirm-checklistfield-deletion',
        content: ''
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        if (this.checklistForm.controls[order].value.id) {
          this.deletedChecklistFields.push(this.checklistForm.controls[order].value.id);
          this.removeChecklistFieldForm(order);
        } else {
          this.removeChecklistFieldForm(order);
        }
      }
    });
  }

  private removeChecklistFieldForm(order: number) {
    this.checklistForm.removeAt(order);
    this.notificationService.notify('admin.event.tracks.checklist.deleted-checklistfield');
  }

  addNewChecklistField(): void {
    const newControl = this.fb.group({
      id: [null],
      description: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      visibleTo: this.fb.group({
        author: [false],
        reviewer: [false],
        sessionChair: [false],
        publicationChair: [false]
      }),
      modifiableBy: this.fb.group({
        author: [false],
        reviewer: [false],
        sessionChair: [false],
        publicationChair: [false]
      })
    });
    this.checklistForm.push(newControl);
  }

  saveChecklist(trackFormValue?: number): Observable<CheckListField[][]> {
    const fieldsToCreate = [];
    const fieldsToEdit = [];

    this.checklistForm.value.forEach((field, index) => {
      field.order = index;
      field.type = 'CHECKBOX';

      if (field.id !== null) {
        fieldsToEdit.push(field);
      } else {
        fieldsToCreate.push(field);
      }
    });

    if (trackFormValue) {
      return forkJoin([
        this.eventService.createTrackCheckList(fieldsToCreate, trackFormValue),                              // Create new checklist items.
        this.eventService.editTrackCheckList(fieldsToEdit, trackFormValue),                                  // Edit existing items.
        ...this.deletedChecklistFields.map(id => this.eventService.deleteTrackCheckList(id, trackFormValue)) // Remove marked checklist items.
      ]);
    } else {
      return forkJoin([
        this.eventService.createTrackCheckList(fieldsToCreate, this.track.id),                              // Create new checklist items.
        this.eventService.editTrackCheckList(fieldsToEdit, this.track.id),                                  // Edit existing items.
        ...this.deletedChecklistFields.map(id => this.eventService.deleteTrackCheckList(id, this.track.id)) // Remove marked checklist items.
      ]);
    }
  }
}
