import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { TextInputComponent } from '../text-input/text-input.component';
import { Affiliation } from 'src/app/model/affiliation';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-input-filter-autocomplete',
  templateUrl: './input-filter-autocomplete.component.html',
  styleUrls: ['./input-filter-autocomplete.component.scss']
})
export class InputFilterAutocompleteComponent extends TextInputComponent implements OnInit {
  public filteredOptions: any | null;
  private callLoad: boolean = false;
  
  @Input() minCharactersForFilter: number = 0;
  @Input() options;
  @Input() indexes;
  @Input() initEmpty: boolean = true;
  @Input() allowNewAffiliation: boolean = true;
  @Output() stateAndCountry = new EventEmitter<{city: string, state: string, country: string}>();
  @Output() affiliationConflictInterest = new EventEmitter<Affiliation>();
  @Output() createNewAffiliation = new EventEmitter<{value: boolean, wasCreated: boolean}>();
  @Output() loadAffiliationsEvent = new EventEmitter<boolean>();

  private sizeIndexes: number;

  private modelNewAffiliation = {id: -1, newAffiliation: '', filter: ''};

  @ViewChild('inputText') input: ElementRef;
  @ViewChild('inputAutoComplete', { read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;

  constructor(public translate: TranslateService) {
    super();
  }
  
  ngOnInit() {
    if (this.name === 'affiliation') {
      this.includeAffiliationInModel();
      this.setLabelInNewAffiliationModel();
    }
    this.sizeIndexes = Object.keys(this.indexes).length;
  }

  public _filter($word: string): void {
    if (this.name === 'affiliation') {
      this.loadAffiliations();
    }
    
    if (!!this.options) {
      if ($word.length >= this.minCharactersForFilter) {
        this.filteredOptions = this.changeOptions($word);
      } else {
        this.filteredOptions = null;
      }
      if (this.name === 'affiliation' && this.allowNewAffiliation) {
        this.includeModelNewAffiliation($word);
      }
      this.saveDataInForm({[this.indexes.dataBase]: $word});
    }
  }

  public saveDataInForm(data): void {
    this.formGroupController.controls[this.name].setValue(data[this.indexes.dataBase]);

    if (this.name === 'city')
      this.sendStateAndCountry(data);

    if (this.name === 'affiliation' && data.id === -1) {
      this.createNewAffiliation.emit({value: true, wasCreated: false});
    } else if (this.name === 'affiliation') {
      this.createNewAffiliation.emit({value: false, wasCreated: false});
      this.sendAffiliation(data);
    }
  }

  private changeOptions(valueInput: string) {
    valueInput = valueInput.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    if (this.name === 'city') {
      const filterCities = this.options.filter((city: any) => city.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").startsWith(valueInput));
      return filterCities;
    } else if (this.name === 'affiliation') {
      return this.filterWithMaxResults(valueInput, this.options, 200);
    }
  }

  private filterWithMaxResults(affiliationInput: string, listAffiliations: Affiliation[], maxResults: number = 200) {
    const listFilter: Affiliation[] = [];
    for (let i = 0; i < listAffiliations.length; i++) {
      if (listAffiliations[i].filter.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").match(affiliationInput)) {
        listFilter.push(listAffiliations[i]);
      }
      if (listFilter.length === maxResults) {
        break;
      }
    }
    return listFilter;
  }

  private sendStateAndCountry(city): void {
    const dataCity = this.options?.filter((option) => {
      if (option.name.toLowerCase() === city.name.toLowerCase() && option.countryCode === city.countryCode && option.stateCode === city.stateCode) {
        return option;
      }
    });

    if (dataCity?.length > 0) {
      this.stateAndCountry.emit({city: dataCity[0].name, state: dataCity[0].stateCode, country: dataCity[0].countryCode});
    }
  }

  private sendAffiliation(affiliation: Affiliation): void {
    const validAffiliation = ["id", "name", "acronym", "filter"];
    const propertyAffiliation = Object.getOwnPropertyNames(affiliation);
    if (JSON.stringify(validAffiliation) === JSON.stringify(propertyAffiliation)) {
      this.affiliationConflictInterest.emit(affiliation);
    }
  }

  public loadAffiliations(): void {
    if (this.options === undefined && this.callLoad === false) {
      this.callLoad = true;
      this.loadAffiliationsEvent.emit(true);
    }
  }

  private includeAffiliationInModel(): void {
    const affiliation = this.formGroupController.get('affiliation').value;
    if (affiliation) {
      this.modelNewAffiliation.filter = affiliation;
    }
  }

  private includeModelNewAffiliation(word: string): void {
    if (word.length >= this.minCharactersForFilter) {
        this.filteredOptions === null ? this.filteredOptions = [this.modelNewAffiliation] : this.filteredOptions.push(this.modelNewAffiliation);
      }
    }

  public formControlIsValid(): boolean {
    if (this.formGroupController.controls[this.name].invalid && !this.initEmpty) {
      return true;
    }
    return false;
  }

  private setLabelInNewAffiliationModel(): void {
    this.translate.get('forms.texts.create-new-affiliation').subscribe((translated: string) => {
      this.modelNewAffiliation.newAffiliation = translated;
    });
  }
}
