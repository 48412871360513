import { toDayjs } from './baseModel';
import * as dayjs from 'dayjs';
import { Transform } from 'class-transformer';

export class LoginHistory {
  public id: number;

  @Transform(toDayjs)
  public datetime: dayjs.Dayjs;

  loginType: number;
  remoteIp: string;
  user: number;
  username: string;
}
