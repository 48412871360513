import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class EventSessionsComponent {
  constructor() { }
}
