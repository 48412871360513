import { Transform } from 'class-transformer';
import { BaseModel, toDayjs } from './baseModel';
import { FileRules } from './file.rules';
import { Type } from 'class-transformer';
import * as dayjs from 'dayjs';

export class Track extends BaseModel {
  @Type(() => FileRules)
  public trackFiles: Array<FileRules> = [];

  @Transform(toDayjs)
  public openAt: dayjs.Dayjs;

  @Transform(toDayjs)
  public closeAt: dayjs.Dayjs;

  //Flags to enable editing submissions after registration deadline
  editPendingSubmissions = false;
  editActiveSubmissions = false;
  editRejectedSubmissions = false;
  editAcceptedSubmissions = false;

  constructor(
    public event: number,
    public name: string
  ) { super(); }

  public get isOpenForSubmissions(): boolean {
    return !this.closed && this.open;
  }

  public get date(): any {
    return dayjs();
  }

  public get closed(): boolean {
    return dayjs() > this.closeAt;
  }

  public get open(): boolean {
    return dayjs() > this.openAt;
  }

  public get hasOpenTrackFiles(): boolean {
    return this.trackFiles.some(f => f.open);
  }
}
