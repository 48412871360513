import { SelectOption } from '../model/select.option';

export enum FileType {
  DOC = 'application/doc',
  VIDEO = 'application/video',
  PDF = 'application/pdf',
  MSWORD = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PLAIN = 'text/plain',
  POSTSCRIPT = 'application/postscript',
  HTML = 'text/html',
  OTHER = 'application/octet-stream'
}

export const DEFAULT_FILETYPE = FileType.OTHER;

export namespace FileType {
  export function toIterable(): Array<string> {
    return Object.keys(FileType).filter(key => typeof FileType[key] !== 'function');
  }

  export function toSelectable(): Array<SelectOption> {
    return FileType.toIterable().map(value => ({
      id: value,
      value: FileType[value]
    }));
  }

  export function toSelectableInvert(): Array<SelectOption> {
    return toSelectable().map(e => ({ id: String(e.value), value: String(e.id)}));
  }

  export function icon(status: FileType) {
    switch (status) {
      case FileType.PDF:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-file-pdf'
        };
      case FileType.DOCX:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-file-word'
        };
      default:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-file-alt'
        };
    }
  }
}
