import { Component, forwardRef, Input, Inject, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextInputComponent } from '../text-input/text-input.component';
import * as dayjs from 'dayjs';
import { MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { DateTimePickerHandler } from 'src/app/class/date-time-picker-handler';
import { TimezoneService } from 'src/app/service/timezone.service';
import { DomService } from 'src/app/service/dom.service';

@Component({
  selector: 'app-datepicker-input',
  templateUrl: './datepicker-input.component.html',
  styleUrls: ['./datepicker-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatepickerInputComponent),
    multi: true
  }]
})
export class DatepickerInputComponent extends TextInputComponent implements OnInit, AfterViewInit {
  @Input() max: number;
  @Input() min: number;

  @Input() link: {
    label: string,
    route: string,
    align: 'start' | 'end'
  };

  private dateTimePickHandler: DateTimePickerHandler;
  @ViewChild('datePicker', { static: false }) containerDatePicker: ElementRef;

  constructor(
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private tzService: TimezoneService,
    private domService: DomService,
  ) {
    super();
    this.dateTimePickHandler = new DateTimePickerHandler(tzService, domService);
  }

  ngOnInit(): void {
    this._locale = localStorage.getItem('language');
    this._adapter.setLocale(this._locale);
  }

  ngAfterViewInit(): void {
    this.dateTimePickHandler.removeInputDateTime(this.containerDatePicker, '._container-input', '.container-date-time-mask');
  }
  
  dateToString(date: number) {
    return dayjs(date).format('DD/MM/YYYY, HH:mm:ss');
  }

}
