import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Review } from 'src/app/model/paper';
import { User } from 'src/app/model/user';
import { AuthService } from 'src/app/service/auth.service';
import { ReviewsService } from 'src/app/service/reviews.service';

@Component({
  selector: 'app-review-reclaim',
  templateUrl: './review-reclaim.component.html',
  styleUrls: ['./review-reclaim.component.scss']
})
export class ReviewReclaimComponent implements OnInit {

  review: Review;
  originalAssigner: User;
  userIsAdminOrChair: boolean;

  alreadyIsReviewer: boolean;

  constructor(
    private authService: AuthService,
    public reviewsService: ReviewsService,
    @Inject(MAT_DIALOG_DATA) public data: { review: Review, reviews: Review[], userIsChair: boolean },
  ) { }

  ngOnInit(): void {
    this.review = this.data.review;
    this.originalAssigner = this.review.assignedBy as undefined as User;
    this.userIsAdminOrChair = this.data.userIsChair || this.authService.userIsAdmin();

    this.checkErrors();
  }

  checkErrors() {
    this.alreadyIsReviewer = this.reviewsService.userIsReviewer(this.originalAssigner, this.data.reviews);
  }

  public hideEmail(email: string): string {
    return this.authService.hideEmail(email);
  }

}
