import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SystemService } from 'src/app/service/system.service';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { NotificationService } from 'src/app/service/notification.service';
import { Event } from 'src/app/model/event';
import * as moment from 'moment';
import { EventReviewConfiguration, ViewersValues, ViewersWithoutOwnValues, AnonymityValues } from 'src/app/model/eventReviewConfiguration';
import { ReviewsConfigurationService } from '../../../../service/reviews.configuration.service';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { toDayjs } from 'src/app/model/baseModel';
import { forkJoin } from 'rxjs';
import { TimezoneService } from 'src/app/service/timezone.service';
import { getLocale } from 'src/app/app.module';
import { DatetimepickerInputComponent } from 'src/app/component/datetimepicker-input/datetimepicker-input.component';

@Component({
  selector: 'app-reviews-configuration',
  templateUrl: './reviews-configuration.component.html',
  styleUrls: ['./reviews-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class ReviewsConfigurationComponent implements OnInit {
  event: Event;

  reviewConfig: EventReviewConfiguration;
  reviewConfigForm: FormGroup;

  anonymityChoices = this.toSelectable(AnonymityValues);

  viewersReviewChoice = this.toSelectable(ViewersValues);
  viewersIdentityChoice = this.toSelectable(ViewersWithoutOwnValues);
  desableToggleAuthorsCanViewScores = false;

  @ViewChild(DatetimepickerInputComponent) dateTimePicker: DatetimepickerInputComponent;

  constructor(
    private adminService: AdminService,
    private fb: FormBuilder,
    public systemService: SystemService,
    public eventsService: EventsService,
    public notificationService: NotificationService,
    private reviewsService: ReviewsConfigurationService,
    private dialog: MatDialog,
    private timezoneService: TimezoneService
  ) { }

  ngOnInit() {
    this.reviewConfig = new EventReviewConfiguration();

    setTimeout(() => {
      this.event = this.adminService.selectedEvent;

      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.reviewsService.getReviewsConfiguration(this.event.id).subscribe(data => {
            this.reviewConfig = data;
            this.initReviewConfigForm();
          });
        });

      } else {
        this.reviewsService.getReviewsConfiguration(this.event.id).subscribe(data => {
          this.reviewConfig = data;
          this.initReviewConfigForm();
        });
      }
    });
  }

  toIterable(object): Array<string> {
    return Object.keys(object).filter(key => typeof object[key] !== 'function');
  }

  toSelectable(object): Array<{ id: number, value: string }> {
    return this.toIterable(object).map((key, index) => ({
      id: index,
      value: object[key].toString()
    }));
  }

  private getId(key, values) {
    const index = this.toIterable(values).findIndex(value => value === key);
    return index !== -1 ? index : null;
  }

  private validateDate(date) {
    return date ? moment(date.toString()) : null;
  }

  private initReviewConfigForm(): void {
    this.reviewConfigForm = this.fb.group({
      doubleBlind: [this.reviewConfig.doubleBlind],

      anonymousReview: [this.reviewConfig.anonymousReview],
      authorsCanView: [this.reviewConfig.authorsCanView],
      authorsCanViewScores: [this.reviewConfig.authorsCanViewScores],
      tpcCanView: [this.getId(this.reviewConfig.tpcCanView, ViewersValues)],
      tpcViewReviewIdentity: [this.getId(this.reviewConfig.tpcViewReviewIdentity, ViewersWithoutOwnValues)],

      copyReviewAssigner: [this.reviewConfig.copyReviewAssigner],
      numberOfReviewsRequired: [this.reviewConfig.numberOfReviewsRequired, Validators.min(0)],

      numberOfReviewsForTpcMember: [this.reviewConfig.numberOfReviewsForTpcMember, Validators.min(0)],
      journalDaysForReview: [this.reviewConfig.journalDaysForReview],
      conferenceDateDue: [this.validateDate(this.reviewConfig.conferenceDateDue)?.toDate(), Validators.required],
      timeZone: [this.timezoneService.browserTimeZone],
      reminderStartDate: [this.validateDate(this.reviewConfig.reminderStartDate)?.toDate()],
      daysBetweenReminders: [this.reviewConfig.daysBetweenReminders],

      blockReview: [this.reviewConfig.blockReview],
      tpcListAllPapers: [this.reviewConfig.tpcListAllPapers],
      showOnlyCompletedReviewToTpc: [this.reviewConfig.showOnlyCompletedReviewToTpc],
      paperRankingToTpc: [this.reviewConfig.paperRankingToTpc],

      spanLimitA: [this.reviewConfig.spanLimitA],
      spanLimitB: [this.reviewConfig.spanLimitB],

      tpcDelegate: [this.reviewConfig.tpcDelegate],
      tpcAssignToExternal: [this.reviewConfig.tpcAssignToExternal],
      tpcBidClaimPaper: [this.reviewConfig.tpcBidClaimPaper],
      claimPendingSubmissions: [this.reviewConfig.claimPendingSubmissions],

      discussionPeriodEnable: [this.reviewConfig.discussionPeriodEnable],
      discussionPeriodStart: [this.validateDate(this.reviewConfig.discussionPeriodStart)?.toDate()],
      timeZonePeriodStart: [this.timezoneService.browserTimeZone],
      discussionPeriodEnd: [this.validateDate(this.reviewConfig.discussionPeriodEnd)?.toDate()],
      timeZonePeriodEnd: [this.timezoneService.browserTimeZone],

      rebuttalPeriodEnable: [this.reviewConfig.rebuttalPeriodEnable],
      rebuttalPeriodStart: [this.validateDate(this.reviewConfig.rebuttalPeriodStart)?.toDate()],
      timeZoneRebuttalStart: [this.timezoneService.browserTimeZone],
      rebuttalPeriodEnd: [this.validateDate(this.reviewConfig.rebuttalPeriodEnd)?.toDate()],
      timeZoneRebuttalEnd: [this.timezoneService.browserTimeZone]
    });
    this.desableToggleAuthorsCanViewScores = !this.reviewConfig.authorsCanView;
    this.toggleValidatorsDiscussionMessage(this.reviewConfig.discussionPeriodEnable);
    this.toggleValidatorsRebuttalMessage(this.reviewConfig.rebuttalPeriodEnable);
  }

  submitReviewConfiguration() {
    if (!this.reviewConfigForm.valid) {
      this.reviewConfigForm.markAllAsTouched();
      this.notificationService.notifyError('admin.event.reviews.configuration.edit-reviews-config-invalid');
    } else {
      const reviewConfig = this.reviewConfigForm.getRawValue();

      reviewConfig.tpcCanView = this.toIterable(ViewersValues)[reviewConfig.tpcCanView];
      reviewConfig.tpcViewReviewIdentity = this.toIterable(ViewersWithoutOwnValues)[reviewConfig.tpcViewReviewIdentity];

      this.reviewsService.editReviewsConfiguration(this.event.id, reviewConfig).subscribe(reviewsConfig => {
        this.notificationService.notify('admin.event.reviews.configuration.edit-reviews-config-success');
      });
    }
  }

  disableAuthorsCanViewScores(ev){
    if (ev.checked){
      this.desableToggleAuthorsCanViewScores = false;
    }else{
      this.desableToggleAuthorsCanViewScores = true;
    }
  }

  toggleValidatorsDiscussionMessage(value) {
    const controlDiscussionPeriodStart = this.reviewConfigForm.get('discussionPeriodStart');
    const controlDiscussionPeriodEnd = this.reviewConfigForm.get('discussionPeriodEnd');

    if (value) {
      controlDiscussionPeriodStart.setValidators([Validators.required]);
      controlDiscussionPeriodEnd.setValidators([Validators.required]);
    } else {
      controlDiscussionPeriodStart.clearValidators();
      controlDiscussionPeriodEnd.clearValidators();
    }

    controlDiscussionPeriodStart.updateValueAndValidity();
    controlDiscussionPeriodEnd.updateValueAndValidity();
  }

  toggleValidatorsRebuttalMessage(value) {
    const controlRebuttalPeriodStart = this.reviewConfigForm.get('rebuttalPeriodStart');
    const controlRebuttalPeriodEnd = this.reviewConfigForm.get('rebuttalPeriodEnd');

    if (value) {
      controlRebuttalPeriodStart.setValidators([Validators.required]);
      controlRebuttalPeriodEnd.setValidators([Validators.required]);
    } else {
      controlRebuttalPeriodStart.clearValidators();
      controlRebuttalPeriodEnd.clearValidators();
    }

    controlRebuttalPeriodStart.updateValueAndValidity();
    controlRebuttalPeriodEnd.updateValueAndValidity();
  }

  setGlobalDueDate() {
    const dueAt = this.reviewConfigForm.value.conferenceDateDue;
    const formattedDate = this.formatDateTime(this.dateTimePicker.dateTimeViewed);

    const timeZone = this.reviewConfigForm.value.timeZone;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'admin.event.reviews.configuration.review-global-due-at.title',
        content: 'admin.event.reviews.configuration.review-global-due-at.content',
        cancel: false,
        params: {
          content: {
            date: formattedDate,
            offset: this.timezoneService.getUTCTimeZone(timeZone)
          }
        }
      }
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        forkJoin([
          this.reviewsService.editGlobalReviewDueDate(this.event.id, dueAt),
          this.reviewsService.editReviewsConfiguration(this.event.id, { conferenceDateDue: dueAt })
        ]).subscribe(
          () => {
            this.notificationService.notify('admin.event.reviews.configuration.review-global-due-at.notification-success', {
              params: {
                date: formattedDate // TODO: Pegar isso do retorno da API, para confirmar
              }
            });
          },
          () => {
            this.notificationService.notifyError('admin.event.reviews.configuration.review-global-due-at.notification-error');
          }
        );
      }
    });
  }

  private formatDateTime(date: Date): string {
    if (getLocale() === 'pt-BR') {
      return toDayjs(date).format('HH:mm:ss DD/MM/YYYY')
    }

    return toDayjs(date).format('HH:mm:ss MM/DD/YYYY')
  }
}
