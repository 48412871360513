import { BaseModel } from './baseModel';

export class FormFieldChoice extends BaseModel {
  public formField: number;
  public value: number;

  constructor(
    public description: string,
    public order: number
  ) { super(); }
}
