import { Type, Expose, Transform } from 'class-transformer';
import { UserEventTopicsInterest } from './user.event.topics.interest';
import { Event } from './event';
import { UserEventBase } from './user.event.base';

export class UserEvent extends UserEventBase {
  @Type(() => Event)
  @Expose()event: Event;

  public tpcGroup: any;

  topicsInterest: Array<UserEventTopicsInterest> = [];
  conflicts: Array<number> = [];
  reviewing: Array<number> = [];
  claims: number;

  public isReviewing(submissionId: number): boolean {
    return this.reviewing.some(subId => subId === submissionId);
  }

  public hasConflict(userId: number): boolean {
    return this.conflicts.some(user => user === userId);
  }
}
