import { Component, OnInit, Inject, Optional, ChangeDetectorRef, AfterViewChecked, Input } from '@angular/core';
import {Location} from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Submission, SubmissionStatus } from 'src/app/model/paper';
import { NavbarService } from 'src/app/service/navbar.service';
import { NotificationService } from 'src/app/service/notification.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-submission-delete',
  templateUrl: './submission-delete.component.html',
  styleUrls: ['./submission-delete.component.scss']
})
export class SubmissionDeleteComponent implements OnInit, AfterViewChecked {
  public submission: Submission;
  public submissions: Array<Submission> = [];

  deleteForm: FormGroup;
  maxLength = 250;
  disableButton = false;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private location: Location,
    private navbarService: NavbarService,
    private notificationService: NotificationService,
    public submissionService: SubmissionsService,
    public fb: FormBuilder,
    private currentDialogRef: MatDialogRef<SubmissionDeleteComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) @Optional() public data: { submission: Submission, submissions: Array<Submission> }
  ) { }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    if (this.data) {
        this.submission = this.data.submission;
        this.submissions = this.data.submissions;
    }

    this.deleteForm = this.fb.group({
      motive: ['', [Validators.maxLength(this.maxLength)]]
    });
  }

  deleteSubmission() {
    const motive = this.deleteForm.value.motive;

    if (this.submissions.length > 0) {
      this.multipleDeleteSubmission(motive);
      return;
    }

    this.navbarService.loadingStart();
    this.submissionService.deleteSubmission(this.submission.id, motive).subscribe(s => {
      this.navbarService.loadingStop();
      this.notificationService.notify('submissions.deleted.success-delete', { params: { id: s.id } });
      this.currentDialogRef.close(s);
    }, error => {
      this.navbarService.loadingStop();
      this.notificationService.notify('submissions.deleted.fail-delete');
      this.currentDialogRef.close();
    }, () => {
      this.location.back();
    });
  }

  multipleDeleteSubmission(motive: string) {
    let submissionsList = [];
    this.navbarService.loadingStart();
    this.disableButton = true;

    this.submissions.forEach((s, idx) => {
      this.submissionService.deleteSubmission(s.id, motive).subscribe(sub => {
        submissionsList.push(sub);
      },
      () => {},
      () => {
        if (idx === this.submissions.length-1) {
          this.notificationService.notify('submissions.deleted.success-delete', {params: {id:this.transformIDsToString(submissionsList)}});
          this.navbarService.loadingStop();
          this.currentDialogRef.close(submissionsList);
        }
      });
    });   

    // forkJoin(
    //   this.submissions.map(s => this.submissionService.deleteSubmission(s.id, motive))
    // ).subscribe(submissions => {
    //   if (submissions) {
    //     this.notificationService.notify('submissions.deleted.success-delete', {params: {id:this.transformIDsToString(submissions)}});
    //     submissionsList = submissions;
    //   }
    // }, (err) => { 
    //   if (err) this.notificationService.notify('submissions.deleted.fail-delete');
    // }, () => {
    //   this.currentDialogRef.close(submissionsList);
    // });
  }

  private transformIDsToString(submissions: Array<Submission>) {
    return submissions.map(s => s.id).join(', #');
  }

  closeDialog() {
    this.currentDialogRef.close();
  }

  disableButtons(): boolean {
    return !this.deleteForm.valid || this.disableButton;
  }
}
