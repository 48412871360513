import { Component, OnInit, ViewChild } from '@angular/core';
import { NavbarService } from '../service/navbar.service';
import { AdminNavbarComponent } from '../component/admin-navbar/admin-navbar.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  @ViewChild('navbar', { static: true }) public navbar: AdminNavbarComponent;

  constructor(
    private navbarService: NavbarService
  ) { }

  ngOnInit() {
    this.navbarService.setNavbar(this.navbar);
  }
}
