import { Transform, Type } from 'class-transformer';
import { BaseModel } from './baseModel';

export class WizardTask extends BaseModel {
  title: string;
  link?: string;

  // Convert backend array of params into object.
  @Transform((params: Array<{ title: string, value: string }>) => {
    const obj = {};
    params.forEach(param => {
      if (param.value.startsWith('EmailTrigger.')) {
        obj[param.title] = param.value.split('EmailTrigger.')[1];
      } else {
        obj[param.title] = param.value;
      }
    });

    return obj;
  })
  linkParams?: {
    [param: string]: string
  };
  checked: boolean;
}

export class WizardStep extends BaseModel {
  title: string;

  @Type(() => WizardTask)
  tasks: Array<WizardTask>;
}
