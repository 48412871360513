import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SubmissionStatus } from 'src/app/enum/submission.status';
import { SelectOption } from 'src/app/model/select.option';
import { groupFields, dictControlFields, dictControlFilters, publicationFields, specialColumnsFields, ColorSchemeFilter } from 'src/app/model/submissionFieldFilter';
import { SubmissionsFilter } from 'src/app/enum/submissions.filter';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { Event } from 'src/app/model/event';
import { EventTopic } from 'src/app/model/eventTopic';

@Component({
  selector: 'app-submissions-field-filter-v2',
  templateUrl: './submissions-field-filter-v2.component.html',
  styleUrls: ['./submissions-field-filter-v2.component.scss']
})
export class SubmissionsFieldFilterV2Component implements OnInit {
  @Input() readonly page: boolean;

  public readonly SubmissionsFilter = SubmissionsFilter;
  @Input() public readonly event: Event;
  @Input() formFields: FormControl;
  @Input() formFilters: FormGroup;

  @Output() showSubmissionsEvent = new EventEmitter<boolean>();

  public groupFields = groupFields;

  public status: Array<SelectOption> = SubmissionStatus.toSelectable();
  public topics: SelectOption[] = [];
  public tracks: SelectOption[] = [];
  public tpcGroups: SelectOption[] = [];
  public colorSchemes: SelectOption[] = [];
  @Input() readonly groups: SelectOption[] = []
  panelOpenState = false;

  constructor(
    private adminService: AdminService,
    private eventService: EventsService
  ) { }

  ngOnInit(): void {
    this.buildTopicOptions();
    this.buildTrackOptions();
    this.getTpcGroupOptions();
    this.buildColorSchemeOptions();
  }

  public changeFields(control: string) {
    const formFieldsValue = this.formFields.value;
    if (formFieldsValue.includes(control)) {
      if (this.isSpecialFieldsColumn(control)) {
        this.removeSpecialColumnsField(control, formFieldsValue);
      }

      formFieldsValue.splice(formFieldsValue.indexOf(control), 1);
    } else {
      if (this.isSpecialFieldsColumn(control)) {
        this.addSpecialColumnsField(control, formFieldsValue);
      }
      
      formFieldsValue.push(control);
    }
    this.formFields.setValue(formFieldsValue);
  }

  public isChecked(control: string) {
    return this.formFields.value.includes(control);
  }

  private buildTopicOptions() {
    this.topics = this.event.topics
      .filter((t: EventTopic) => t.isParent)
      .map((t: EventTopic) => new SelectOption(t.id, t.name, null, t.children.map(t2 => new SelectOption(t2.id, t2.name))));
  }

  private buildTrackOptions() {
    this.tracks = this.event.tracks.map(track => new SelectOption(track.id, track.name));
  }

  private buildColorSchemeOptions() {
    this.colorSchemes = Object.keys(ColorSchemeFilter).map(key => new SelectOption(ColorSchemeFilter[key], key));
  }

  private getTpcGroupOptions(): void {
    this.eventService.getEventTPCGroups(this.event.id)
      .subscribe(tpcGroups => this.tpcGroups = tpcGroups.map(group => new SelectOption(group.id, group.name)));
  }

  public showSubmissions() {
    this.adminService.progressBar.start();
    this.showSubmissionsEvent.emit(true);
    this.submitForms();
  }

  private submitForms(): void {
    const formsSubmit = {...this.listFieldsObj, ...this.customFieldsObj, ...this.listFiltersObj, page: this.page};

    this.eventService.putSubmissionFieldsAndFilters(this.event.id, formsSubmit).subscribe(() => {});
  }

  private get listFiltersObj() {
    const filtersSubmit = {};
    for (const filter of Object.keys(this.formFilters.value)) {
      if (filter === SubmissionsFilter.COLOR_SCHEME) continue;
      
      const key = Object.keys(dictControlFilters).find(k => dictControlFilters[k] === filter);

      const value = this.formFilters.value[filter]?.join(',');
      if (key) filtersSubmit[key] = value;
    }

    return filtersSubmit;
  }

  private get listFieldsObj() {
    const fieldsSubmit = {};
    for (const field of this.formFields.value) {
      const key = Object.keys(dictControlFields).find(k => dictControlFields[k] === field);

      if (key) fieldsSubmit[key] = true;
    }

    Object.keys(dictControlFields).forEach(key => {
      if (!fieldsSubmit[key]) fieldsSubmit[key] = false;
    });

    return fieldsSubmit;
  }

  private get customFieldsObj() {
    const customFieldsSubmit = { customFields: '' };

    for (const field of this.formFields.value) {
      if (field.startsWith(publicationFields.customFields)) {
        const id = field.split('-')[1] + ','; // Just the id of the custom field is necessary to submit to the back-end
        customFieldsSubmit.customFields = `${customFieldsSubmit.customFields}${id}`;
      }
    }

    return customFieldsSubmit;
  }

  private isSpecialFieldsColumn(control: string): boolean {
    return Object.keys(specialColumnsFields).includes(control);
  }

  private addSpecialColumnsField(control: string, formsFieldsValue: string[]): void {
    Object.keys(specialColumnsFields).forEach(key => {
      if (key === control) {
        formsFieldsValue.push(...specialColumnsFields[key]);
      }
    });
  }

  private removeSpecialColumnsField(control: string, formsFieldsValue: string[]): void {
    Object.keys(specialColumnsFields).forEach(key => {
      if (key === control) {
        specialColumnsFields[key].forEach(field => {
          formsFieldsValue.splice(formsFieldsValue.indexOf(field), 1);
        });
      }
    });
  }
}
