import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as isoCountry from 'i18n-iso-countries';

@Pipe({
  name: 'countryPipe',
  pure: false
})

export class CountryPipe implements PipeTransform {
  constructor(private translateService: TranslateService)  {
    isoCountry.registerLocale(require("i18n-iso-countries/langs/en.json"));
    isoCountry.registerLocale(require("i18n-iso-countries/langs/pt.json"));
   }

  transform(country: string | number): string {
    let language;
    if(this.translateService.currentLang){
        language = this.translateService.currentLang;
    }else{
         language = "en";
    }
    if (language === ('pt-BR' || 'pt-br')) return isoCountry.getName(country, 'pt');
    if (country === ( 'TWN' ||'TW' )) return 'Taiwan';  // Remove after libUpdate i18n-iso-countries pull #350
    return isoCountry.getName(country, language);
  }
}
