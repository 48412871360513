import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/service/admin.service';
import { Event } from 'src/app/model/event';

@Component({
  selector: 'app-admin-notify-authors',
  templateUrl: './notify-authors.component.html',
  styleUrls: ['./notify-authors.component.scss']
})
export class AdminNotifyAuthorsComponent implements OnInit {
  event: Event;
  status: 'ASSIGNED' | 'REMINDED' = 'ASSIGNED';

  constructor(
    public adminService: AdminService,
  ) { }

  ngOnInit(): void {
    this.adminService.progressBar.start();

    this.event = this.adminService.selectedEvent;
    if (!this.event) {
      this.adminService.getEvent().subscribe(event => {
        this.event = event;
      });
    }
  }
}
