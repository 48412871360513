import { Injectable } from '@angular/core';
import { InfoService } from './info.service';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';
import { User } from '../model/user';
import { Observable } from 'rxjs';
import { ViewMode } from '../enum/view.mode';
import { LocalCache } from '../enum/local.cache';
import { Event } from '../model/event';
import { plainToClass } from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {
  constructor(private infoService: InfoService, private userService: UserService) { }

  getPreference(key: string, event?: Event): string | number | boolean {
    // OVERRIDE PREFERENCES TEMPORARY FIX
    // TODO: Keep only default and cache when viewMode and statusIcon implementations are remade.
    let preference;
    switch (key) {
      case 'viewMode':
        preference = ViewMode.COMPACT;
        break;
      case 'statusIcon':
        preference = false;
        break;
      case LocalCache.OLD_SUBMISSIONS:
      case LocalCache.OLD_REVIEWS:
      case LocalCache.OLD_EVENTS:
      case LocalCache.OLD_TPC:
      case LocalCache.OLD_CHAIRINGS:
      case LocalCache.STATUS_REVIEWS:
      case LocalCache.STATUS_SUBMISSIONS:
      case LocalCache.SUBMISSION_FILTER:
        if (event) {
          preference = LocalCache.getValue(key, event.id);
        } else {
          preference = LocalCache.getValue(key);
        }
        break;
      default:
        preference = this.infoService.getPreference(key);
      }
    return preference;
  }

  changePreference(key: string, value: any, id?: number): void {
    switch (key) {
      case LocalCache.OLD_SUBMISSIONS:
      case LocalCache.OLD_REVIEWS:
      case LocalCache.OLD_EVENTS:
      case LocalCache.OLD_TPC:
      case LocalCache.OLD_CHAIRINGS:
      case LocalCache.STATUS_REVIEWS:
      case LocalCache.STATUS_SUBMISSIONS:
      case LocalCache.SUBMISSION_FILTER:
        LocalCache.setValue(key, value, id);
        break;
      default:
        this.infoService.setPreference(key, value);
    }
  }

  clearLocalPreference(key: LocalCache, id?: number): void {
    LocalCache.clearValue(key, id);
  }

  saveCachedPreferences(): Observable<User> {
    let userSet = this.infoService.getUserFromService();

    userSet.profile.viewMode = ViewMode.DEFAULT;
    userSet.profile.statusIcon = this.infoService.getPreference('statusIcon') as boolean;
    userSet.profile.statusColorAsBackgroundColor = this.infoService.getPreference('statusColorAsBackgroundColor') as boolean;

    return this.userService.update(userSet).pipe(map(user => {
      return user;
    }));
  }
}
