import { AbstractControl } from '@angular/forms';
import { FormField, SortedFormFields } from './form.field';
import { BaseModel } from './baseModel';
import { FormType } from '../enum/form.type';
import { Type } from 'class-transformer';
import { FormFieldType } from '../enum/form.field.type';
import { FormFieldChoice } from './form.field.choice';
import { Answer } from '../model/answer';

export class Form extends BaseModel {
  @Type(() => FormField)
  public fields: Array<FormField>;

  constructor(
    public name: string,
    public type: FormType
  ) { super(); }

  isReview(): boolean {
    return this.type === FormType.REVIEW;
  }

  isSubmission(): boolean {
    return this.type === FormType.SUBMISSION;
  }

  isStatement(): boolean {
    return this.type === FormType.STATEMENT;
  }

  public get descriptiveFields(): Array<FormField> {
    return this.fields.filter(field => field.type === FormFieldType.TEXT);
  }

  public get assignableFields(): Array<FormField> {
    return this.fields.filter(field => [FormFieldType.CHECKBOX, FormFieldType.RADIO].includes(field.type));
  }

  public hasAnswers(): boolean {
    return this.fields.some(field => field.hasAnswers());
  }

  public get sortedFields(): Array<SortedFormFields> {
    // Array de array de questões.
    // Se for de escrever, array.push
    // Se for de marcar, olha o último item do array.
    //    Se o item for um array de questões de marcar, adiciona pra esse array.
    //    Se não, array.push pra um novo item da lista

    const sorted: SortedFormFields[] = [];
    this.fields = this.fields.sort((a: FormField, b: FormField) => a.order - b.order);

    this.fields.forEach(field => {
      const f = new SortedFormFields();

      if ([FormFieldType.CHECKBOX, FormFieldType.RADIO].includes(field.type)) {
        field.choices = field.choices.sort((a: FormFieldChoice, b: FormFieldChoice) => a.order - b.order);        
        if (sorted.length >= 1 && sorted[sorted.length - 1].assignableFields) {
          sorted[sorted.length - 1].fields.push(field);
        } else {
          f.fields = [field];
          f.assignableFields = true;
          sorted.push(f);
        }
      } else {
        f.fields = [field];
        f.assignableFields = false;
        sorted.push(f);
      }
    });

    return sorted;
  }

  public formAnswers(controller: AbstractControl): Array<Answer> {
    const answers: Array<Answer> = [];
    this.fields.forEach(field => {
      if (field.type === FormFieldType.CHECKBOX) {
        field.choices.forEach(choice => {
          const answer = new Answer(field.id, String(controller.get([field.id, choice.id]).value));
          answer.fieldChoice =  Number(choice.id);
          answers.push(answer);
        });
      } else if (field.type === FormFieldType.RADIO) {
        const answer = new Answer(field.id, String(controller.get([field.id]).value));
        answer.fieldChoice = Number(controller.get([field.id]).value);
        answers.push(answer);
      } else {
        answers.push(new Answer(field.id, String(controller.get([field.id]).value)));
      }
    });

    return answers;
  }
}
