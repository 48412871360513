import { ViewChild, Directive, OnInit } from '@angular/core';
import { Submission } from '../model/paper';
import { Review } from '../model/paper';
import { DEFAULT_PAPER_FIELDS_SHOW } from '../component/paper-card/paper-card.component';
import { SubmissionFilter } from '../model/submission.filter';
import { ShowPaperCardsInterface } from '../interface/show-paper-cards-interface';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { Event } from '../model/event';
import { PreferencesService } from '../service/preferences.service';
import { LocalCache } from '../enum/local.cache';

@Directive()
export abstract class ShowAllPagedViewDirective implements OnInit, ShowPaperCardsInterface {
  public toggleNgModel: boolean;
  @ViewChild('toggle') public toggle: MatCheckbox;

  public filter = new SubmissionFilter();
  public filterVisible = false;
  public fieldsShow = this.checkedFieldsShow;
  public showName: boolean;
  public showAffiliation: boolean;
  public showEmail: boolean;
  public showCountry: boolean;
  public showAbstract: boolean;
  public showTopics: boolean;
  public showReviewerName: boolean;
  public myReviews: boolean = false;
  event?: Event;

  public unfilteredElements: Array<Submission> | Array<Review> | Array<Event>;
  public elements: Array<Submission> | Array<Review> | Array<Event>;

  constructor(
    public preferencesService: PreferencesService,
    public showAllPreferenceKey: LocalCache
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.toggleNgModel = <boolean>this.preferencesService.getPreference(this.showAllPreferenceKey);

      this.toggle.change.subscribe((change: MatCheckboxChange) => {
        this.preferencesService.changePreference(this.showAllPreferenceKey, change.checked);
      });

      this.load();
    });
  }

  updateToggle() {
    this.toggleNgModel = !this.toggleNgModel;
    this.preferencesService.changePreference(this.showAllPreferenceKey, this.toggleNgModel);
    this.load();
  }

  get checkedFieldsShow(): Array<string> {
    const cachedValues = this.preferencesService.getPreference(LocalCache.SUBMISSION_FILTER, this.event);

    if (cachedValues === undefined || cachedValues === false) {
      // No cached value defined. Use default.
      return [...DEFAULT_PAPER_FIELDS_SHOW];
    } else if (cachedValues === '') {
      // Cached value is defined with no fields marked.
      return [];
    }
    // Return array of strings that are marked.
    return (<string>cachedValues).split(',');
  }

  public abstract load(): void;

  public filterPapers(unfiltered): void {
    if (this.myReviews) {
      this.elements = unfiltered.map((r: Review) => {
        r.visible = this.filter.status.includes(r.status);
        return r;
      });      
    } else {
      this.elements = unfiltered.filter((s: Submission | Review) => this.filter.status.includes(s.status));
    }
  }

  public get showAll(): 'all' | 'active' {
    return this.toggle && this.toggle.checked ? 'all' : 'active';
  }
}
