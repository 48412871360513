import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { EventsService } from 'src/app/service/events.service';
import { NotificationService } from 'src/app/service/notification.service';
import { EventRequest } from 'src/app/model/eventRequest';
import { EventRequestStatus } from 'src/app/enum/event.request.status';
import { forkJoin } from 'rxjs';
import { User } from 'src/app/model/user';

export enum EVENT_TYPE {
  CONFERENCE = 1,
  JOURNAL = 2
}

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class AdminEventRequestComponent implements OnInit {
  selectedRequest: EventRequest;

  constructor(
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private eventsService: EventsService,
    private router: Router
   ) { }

  ngOnInit() {
    this.route.params.subscribe(({ id }) => {
      this.eventsService.getEventRequest(id).subscribe(request => {
        if (request.status === EventRequestStatus.PENDING) {
          this.selectedRequest = request;
        } else {
          this.router.navigate(['/error/404']);
        }
      });
    });
  }

  create(event: { form: FormGroup, errors: Array<{ control: string, errors: ValidationErrors }> }): void {
    if (event.form.valid) {
      const { value } = event.form;

      // Default values for create eventInfoParams
      const eventInfoParams = {
        description: '',
        city: event.form.value.city,
        state: event.form.value.state,
        country: event.form.value.country,
        timeZone: event.form.value.timeZone,
        makePublicAt: event.form.value.makePublicAt,
        beginAt: event.form.value.beginAt,
        endAt: event.form.value.endAt,
      };

      this.eventsService.createEventInfo(eventInfoParams).subscribe(eventInfo => {
        const params = {
          name: value.name,
          acronym: value.acronym,
          tracks: value.tracks,
          anonimity: value.anonimity,
          sessionDuration: value.sessionDuration ? value.sessionDuration : null,
          year: value.year,
          eventInfo: eventInfo.id,
          eventType: EVENT_TYPE.CONFERENCE,
          eventParent: event.form.value.eventParent,
          uri: value.uri
        };

        this.notificationService.notify('events.create.creating-event');
        this.eventsService.createEvent(params).subscribe(e => {
          this.selectedRequest.event = e.id;
          this.selectedRequest.status = EventRequestStatus.ACCEPTED;

          const editEventRequest = this.eventsService.editEventRequest(this.selectedRequest);
          const setUserAsChairRequests = (<User[]>value.chairs).map(user => this.eventsService.setUserAsAcceptedChair(e.id, user.id));

          forkJoin([editEventRequest, ...setUserAsChairRequests]).subscribe(responses => {
            this.notificationService.notify('events.create.created-event', { params: { id: e.id, acronym: e.acronym } });
            this.selectedRequest = undefined;

            setTimeout(() => {
              this.router.navigate(['/events/', e.id]);
            }, 1000);
          });
        });
      });
    } else if (event.errors.length > 0) {
      this.notificationService.notify('events.create.creating-invalid-form', { params: { errors: event.errors.length } });
    } else {
      this.notificationService.notify('events.create.creating-error');
    }
  }
}
