import { Injectable, EventEmitter } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidebarComponent } from '../component/sidebar/sidebar.component';
import { AdminEventSidebarComponent } from '../component/admin-event-sidebar/admin-event-sidebar.component';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  public sidenav: MatSidenav;
  public sidebar: SidebarComponent | AdminEventSidebarComponent;
  public miniSidenavToggle = new EventEmitter();
  public hideContent = new EventEmitter();
  public hideContentOnClose = false;

  public setSidebar(sidenav?: MatSidenav, sidebar?: SidebarComponent | AdminEventSidebarComponent) {
    this.sidenav = sidenav;
    this.sidebar = sidebar;

    this.sidenav?.closedStart.subscribe(() => {
      if (window.innerWidth >= 768) {
        this.miniSidenavToggle.emit({ reopen: false });
      }
    });
  }

  public setSidebarItems(items) {
    if (this.sidebar && (<SidebarComponent>this.sidebar).items) {
      (<SidebarComponent>this.sidebar).items = items;
    }
  }

  public open({ hideContent = false }): void {
    setTimeout(() => {
      if (hideContent) {
        this.toggleContent();
      }

      if (this.sidenav) {
        this.sidenav.open();
      }
    });
  }

  public close({ hideContent = false }): void {
    setTimeout(() => {
      if (hideContent) {
        this.toggleContent();
      }

      if (this.sidenav) {
        this.sidenav.close();
      }
    });
  }

  public toggle(hideContent = false): void {
    if (hideContent) {
      this.toggleContent();
    }

    if (this.sidenav) {
      if (window.innerWidth >= 768) {
        if (!this.sidenav.opened) {
          this.sidenav.open();
        }
        this.miniSidenavToggle.emit({ reopen: true });
      } else {
        this.sidenav.toggle();
      }
    }
  }

  public toggleContent(): void {
    this.hideContentOnClose = !this.hideContentOnClose;
    this.hideContent.emit(this.hideContentOnClose);
  }
}
