import { ThemePalette } from '@angular/material/core';
import { NotificationSheetData } from './notificationSheetData';

export class SidebarItem {
  link: string;
  icon: string | { name: string, font: string };
  label: string | { full: string, short: string };
  divider?: boolean;
  children: Array<SidebarItem> | Array<AdminSidebarItem> = [];
  overrideClick?: Function;
  class: string;

  selected = false;
  noSelect = false;
  noLink = false;

  constructor(
    link: string,
    icon: string | { name: string, font: string },
    label: string | { full: string, short: string },
    extra?: {
      divider?: boolean,
      children?: Array<SidebarItem> | Array<AdminSidebarItem>,
      overrideClick?: Function,
      noSelect?: boolean,
      noLink?: boolean,
      class?: string
    }
  ) {
    this.link = link;
    this.icon = icon;
    this.label = label;

    if (extra) {
      this.divider = extra.divider;
      this.children = extra.children;
      this.overrideClick = extra.overrideClick;
      this.class = extra.class;

      if (extra.noSelect) {
        this.noSelect = true;
      }

      if (extra.noLink) {
        this.noLink = true;
      }
    }
  }

  get shouldRouterLink() {
    return !this.children;
  }
}

export class AdminSidebarItem {
  label: string;
  link: string;
  selected: boolean = false;

  constructor(
    label: string,
    link?: string,
    selected?: boolean
  ) {
    this.label = label;
    this.link = link;
    this.selected = selected;
  }
}
