import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(
    private http: HttpClient
  ) { }

  private getElementTag(tag: keyof HTMLElementTagNameMap): string {
    const html: string[] = [];
    const elements = document.getElementsByTagName(tag);
    for (let index = 0; index < elements.length; index++) {
      html.push(elements[index].outerHTML);
    }
    return html.join('\r\n');
  }

  public extractExportHTML(targetElementId: string): Observable<{ content: string, styles: string, links: string }> {
    const content = document.getElementById(targetElementId).innerHTML;

    let styles = this.getElementTag('style');
    let links = this.getElementTag('link');

    // External style bundle is now included via link.
    // Get styles.css file and insert as inline style.
    return this.http.get('/styles.css', { responseType: 'text' }).pipe(map(v => {
      styles += `<style>${v}</style>`;
      links.replace('<link rel=\"stylesheet\" href=\"styles.css\">', '');

      return {
        content,
        styles,
        links
      };
    }));
  }

  public encodeHTML(s: string): string {
    // Converts string to UTF-8, then converts to base64.
    const utf8 = unescape(encodeURIComponent(s));

    return window.btoa(utf8);
  }

  public setDownloadTarget(targetId: string, filename: string, filecontent: string, datatype: string) {
    const anchor = document.getElementById(targetId);

    if (anchor) {
      anchor.setAttribute('download', filename);
      anchor.setAttribute('href', `data:${datatype};charset=UTF-8;base64,${this.encodeHTML(filecontent)}`);
    }
  }

  public setDownloadMultiTarget(selector: string, filename: string, filecontent: string, datatype: string) {
    const selected = document.querySelectorAll(selector);

    selected.forEach(anchor => {
      anchor.setAttribute('download', filename);
      anchor.setAttribute('href', `data:${datatype};charset=UTF-8;base64,${this.encodeHTML(filecontent)}`);
    });
  }
}
