import { Component, OnInit, Input, ViewChildren, ViewEncapsulation, HostListener, HostBinding } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { FAB } from '../../model/fab';

@Component({
  selector: 'app-fab',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FabComponent implements OnInit {
  @Input() fab: FAB;

  @ViewChildren('tooltip') tooltips;

  fabOpen = false;
  fabSpin = false;
  fabFixed = true;
  fabDirection = 'up';
  fabAnimationMode = 'fling';

  color: ThemePalette;
  icon: String;
  items: Array<Object>;

  isScrolling: NodeJS.Timer;
  scrollTicking = false;
  @HostBinding('class.hidden') hidden = false;

  constructor() { }

  ngOnInit() {
    this.setupFAB(this.fab);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    if (!this.scrollTicking) {
      window.requestAnimationFrame(() => {
        if (window.innerWidth < 768) {
          this.hidden = true;
          clearTimeout(this.isScrolling);

          this.isScrolling = setTimeout(() => {
            this.hidden = false;
          }, 300);
          this.scrollTicking = false;
        }
      });
    }
    this.scrollTicking = true;
  }

  setupFAB(config) {
    this.color = config.color;
    this.icon = config.icon;
    this.items = config.items;
  }

  open() {
    if (!this.fabOpen) {
      const timeout = 500;
      this.fabOpen = true;

      this.tooltips.forEach(tooltip => {
        setTimeout(() => {
          tooltip.show();
        }, timeout);
      });
    } else {
      this.onClickOutside();
    }
  }

  onClickOutside() {
    this.fabOpen = false;

    this.tooltips.forEach(tooltip => {
      tooltip.hide();
    });
  }
}
