import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from 'src/app/service/admin.service';
import { Event } from 'src/app/model/event';
import { NotifyAuthorsTemplate } from 'src/app/model/notifyAuthorsTemplate';
import { NotificationService } from 'src/app/service/notification.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { SubmissionStatus } from 'src/app/model/paper';
import { ReviewsConfigurationService } from 'src/app/service/reviews.configuration.service';

@Component({
  selector: 'app-notify-authors',
  templateUrl: './notify-authors.component.html',
  styleUrls: ['./notify-authors.component.scss']
})
export class NotifyAuthorsComponent implements OnInit {
  @Input() event: Event;

  preview: NotifyAuthorsTemplate;

  includeAccepted: boolean = true;
  includeRejected: boolean = true;

  constructor(
    public adminService: AdminService,
    public submissionsService: SubmissionsService,
    public notificationService: NotificationService,
    private reviewsService: ReviewsConfigurationService
  ) { }

  ngOnInit(): void {
    if (this.event) {
      this.getSubmissions();
    }
  }

  getSubmissions(): void {
    this.adminService.progressBar.start();

    this.adminService.previewNotifyAuthors(this.event.id).subscribe(preview => {
      this.preview = preview;
      this.preview.submissions.forEach(r => r.selected = true);

      this.preview.accepted = this.preview.submissions.filter(s => s.status.toUpperCase() === SubmissionStatus.ACCEPTED);
      this.preview.rejected = this.preview.submissions.filter(s => s.status.toUpperCase() === SubmissionStatus.REJECTED);

      this.initValues();

      this.adminService.progressBar.stop();
    });
  }

  notifyAuthors(): void {
    const submissions = [...this.preview.accepted, ...this.preview.rejected].filter(s => s.selected).map(s => s.id);

    if (submissions.length > 0) {
      this.adminService.notifyAuthors(submissions).subscribe(
        r => {
          this.notificationService.notify('submissions.notify-authors.notification-sent');
          this.preview.accepted = this.preview.accepted.filter(s => !s.selected);
          this.preview.rejected = this.preview.rejected.filter(s => !s.selected);
          this.setAuthorsCanViewScores(this.event.id);
        },
        error => {
          this.notificationService.notifyError('submissions.notify-authors.notification-sent-failed');
        });
      }
    }

    private setAuthorsCanViewScores(eventId: number): void {
      const formValues = { authorsCanViewScores: true };
      this.reviewsService.editReviewsConfiguration(eventId, formValues).subscribe();
    }

    initValues(): void {
      this.includeAccepted = this.selectedAcceptedLength != 0;
      this.includeRejected = this.selectedRejectedLength != 0;
    }

    markAll(status: string): void {
      const submissions = status == 'accepted' ? this.preview.accepted : this.preview.rejected;
      const value = status == 'accepted' ? this.includeAccepted : this.includeRejected;

      submissions.map(s => s.selected = !value);
    }

    isIndeterminate(status: string): boolean {
      let submissionsInStatus = status == 'accepted' ? this.preview.accepted : this.preview.rejected;
      const selectedSubmissionsLength = submissionsInStatus.filter(r => r.selected).length;
      
      return selectedSubmissionsLength > 0 && submissionsInStatus.length != selectedSubmissionsLength;
    }

    get selectedAcceptedLength(): number {
      return this.preview.accepted.filter(s => s.selected).length;
    }

    get selectedRejectedLength(): number {
      return this.preview.rejected.filter(s => s.selected).length;
    }

    get selectedLength(): number {
      return this.preview.submissions.filter(s => s.selected).length;
    }
  }
