import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Route } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class RouteTitleGuard implements CanActivateChild {
  constructor(public router: Router, public title: Title, public translateService: TranslateService) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const titleKey = `routes.${this.getRoutePath(route)}`;

    this.translateService.get(titleKey, route.params).subscribe(value => {
      let title;

      if (typeof value === 'string') {
        title = value;
      } else {
        title = value[0];
      }

      this.title.setTitle(title);
    });

    return true;
  }

  getRoutePath(route: ActivatedRouteSnapshot): string {
    if (route.parent && this.getRouteConfigPath(route.parent.routeConfig) !== 'index') {
      return `${this.getRoutePath(route.parent)}.${this.getRouteConfigPath(route.routeConfig)}`;
    }
    return `${this.getRouteConfigPath(route.routeConfig)}`;
  }

  getRouteConfigPath(routeConfig: Route): string {
    if (routeConfig) {
      return routeConfig.path?.length > 0 ? routeConfig.path : 'index';
    }
    return 'index';
  }
}
