import { Pipe, PipeTransform } from '@angular/core';
import { Submission } from '../model/paper';

@Pipe({
  name: 'subSessTextFilter'
})
export class SubSessionTextFilterPipe implements PipeTransform {

  public transform(items: Submission[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(it => {
      if(/^\d+$/.test(searchText)){
        return it.id.toString().includes(searchText);
      }else{
        return it.title.toLocaleLowerCase().includes(searchText);
      }
    });
  }
}
