import { Submission } from './paper';
import { Review } from './paper';
import { Event } from './event';
import { Type } from 'class-transformer';
import {UserEvent} from './user.event';

export class SearchResult {
  @Type(() => Submission)
  public submissions: Array<Submission> = [];

  @Type(() => Review)
  public reviews: Array<Review> = [];

  @Type(() => Event)
  public events: Array<Event> = [];

  @Type(() => Event)
  public committees: Array<Event> = [];

  @Type(() => Event)
  public chairs: Array<Event> = [];

  @Type( () => UserEvent)
  public people: Array<UserEvent> = [];
}
