import { BaseModel } from './baseModel';
import { Type } from 'class-transformer';
import { EventTopic } from './eventTopic';

export class Taxonomy extends BaseModel {
  @Type(() => EventTopic)
  topics: Array<EventTopic>;

  @Type(() => Taxonomy)
  taxonomies: Array<Taxonomy>;

  constructor(
    public name: string
  ) { super(); }
}
