import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormFieldCategory } from 'src/app/enum/form-field-category';
import { CheckListField } from 'src/app/model/checklist-field';
import { Event } from 'src/app/model/event';
import { Submission } from 'src/app/model/paper';
import { AuthService } from 'src/app/service/auth.service';
import { PublicationService, SubmissionCoverage } from 'src/app/service/publication.service';

@Component({
  selector: 'app-paper-publication-info',
  templateUrl: './paper-publication-info.component.html',
  styleUrls: ['./paper-publication-info.component.scss']
})
export class PaperPublicationInfoComponent implements OnInit {
  
  @Output() IEEECopyright = new EventEmitter();
  
  @Input() covers: SubmissionCoverage[];
  @Input() submission: Submission;
  @Input() showIeeeCopyright = false;
  @Input() isAdminOrChair = false;
  @Input() isSessionChair = false;
  @Input() isAuthor = false;

  event: Event;

  form: FormGroup;

  FormFieldCategory = FormFieldCategory;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private publicationService: PublicationService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.event = this.submission.event;
    if (this.isAdminOrChair == undefined) {
      this.authService.userIsAdminOrChair(this.event.id).subscribe(isAdminOrChair => {
        this.isAdminOrChair = isAdminOrChair;
        this.initForm();  
      });
    } else {
      this.initForm();  
    }
  }

  initForm() {
    this.form = this.fb.group({});
    this.covers.forEach(cover => {
      this.form.addControl(cover.field.id.toString(), new FormControl({value: cover.answer, disabled: !this.canEdit(cover.field)}));
    });

    Object.keys(this.form.controls).forEach(controlName => {
      const control = this.form.controls[controlName];
      const coverage = this.covers.filter(c => c.field.id == parseInt(controlName))[0];

      control.valueChanges.subscribe(() => {
        this.toggleCover(coverage);
      });
    });
  }

  canEdit(field: CheckListField) {
    const authorCanEdit = field.modifiableBy.author && this.isAuthor;
    const sessionChairCanEdit = field.modifiableBy.sessionChair && this.isSessionChair;

    return ((this.isAdminOrChair || sessionChairCanEdit) && !this.isAuthor) || authorCanEdit;
  }

  toggleCover(cover: SubmissionCoverage) {
    
    if (!this.canEdit(cover.field)) {
      return;
    }

    const data = {
      'checklist': cover.field.id,
      'submission': this.submission.id
    };

    this.publicationService.toggleSubmissionAnswer(this.event.id, data).subscribe(newAnswer => {
      cover.answer = newAnswer;
    });

  }

  getCoverageTitle(coverage: SubmissionCoverage): string {
    switch(coverage.field.category) {
      case FormFieldCategory.AUTHOR:
        return this.translate.instant('admin.event.publication.controls.registration');
      case FormFieldCategory.COPYRIGHT:
        return this.translate.instant('admin.event.publication.controls.copyright');
      case FormFieldCategory.CUSTOM:
        return coverage.field.description;
    }
  }

  submitToIEEE() {
    this.IEEECopyright.emit();
  }

}
