import { Component, OnInit } from '@angular/core';
import { Event } from '../../../../model/event';
import { AdminService } from '../../../../service/admin.service';
import { EventsService } from '../../../../service/events.service';
import { SubmissionsService } from '../../../../service/submissions.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectOption } from 'src/app/model/select.option';
import { NotificationService } from 'src/app/service/notification.service';
import { UserEventAnswer } from 'src/app/enum/user.event.answer';
import { UserEventRole } from 'src/app/enum/user.event.role';
import { PaperGroup } from '../../../../model/paper.group';
import { Submission } from '../../../../model/paper';
import { PaperTitlePipe } from '../../../../pipe/paper.title.pipe';
import { TPCGroup } from '../../../../model/tpc.group';
import { UserEvent } from '../../../../model/user.event';
import { User } from '../../../../model/user';

@Component({
  selector: 'app-tpc-groups',
  templateUrl: './tpc-groups.component.html',
  styleUrls: ['./tpc-groups.component.scss']
})
export class TPCGroupsComponent implements OnInit {
  public groups: Array<SelectOption>;
  public submissions: Array<SelectOption>;
  public members: Array<SelectOption>;

  public event: Event;
  public form: FormGroup;

  public selectedGroup: TPCGroup;
  public selectedSubmissions = [];
  public selectedMembers = [];

  public actions: Array<SelectOption> = [
    new SelectOption('REMOVE', 'admin.event.groups.table.actions.remove'),
    new SelectOption('MOVE', 'admin.event.groups.table.actions.move')
  ];

  constructor(
    private fb: FormBuilder,
    private adminService: AdminService,
    private eventsService: EventsService,
    private submissionsService: SubmissionsService
  ) { }

  public ngOnInit(): void {
    setTimeout(() => {
      this.adminService.progressBar.start();
      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.loadData();
        });
      } else {
        this.loadData();
      }
    });
    this.initForm();
  }

  public editGroup(): void {
    this.selectedGroup = undefined;
    this.selectedSubmissions = [];
    this.selectedMembers = [];
  }

  private loadData(): void {
    this.getPaperGroups();
    this.getSubmissions();
    this.getMembers();
  }

  public getGroups(): Array<TPCGroup> {
    return this.groups.map(grp => grp.id);
  }

  public getMembers(): void {
    this.eventsService.getEventPeopleWithoutTPCGroup(this.event.id).subscribe(members => {
      this.members = members.map(group => new SelectOption(group, group.user.name));
    });
  }

  private getPaperGroups(): void {
    this.eventsService.getEventTPCGroups(this.event.id).subscribe(groups => {
      this.groups = groups.map(group => new SelectOption(group, group.name));
    });
  }

  private getSubmissions(): void {
    this.submissionsService.getAllSubmissionsWithoutTPCGroupByEvent(this.event.id).subscribe(submissions => {
      this.submissions = submissions.map(submission =>
        new SelectOption(submission, PaperTitlePipe.prototype.transform(submission))
      );
    });
  }

  public removeGroup(group: TPCGroup): void {
    this.eventsService.deleteEventTPCGroup(this.event.id, group.id).subscribe(() => {
      this.groups = this.groups.filter(grp => group !== grp.id);

      this.submissions.push(...group.submissions.map(submission =>
        new SelectOption(submission, PaperTitlePipe.prototype.transform(submission))
      ));

      this.members.push(...group.members.map(m => new SelectOption(m, m.user.name)));

      this.selectedGroup = undefined;
      this.selectedSubmissions = [];
      this.selectedMembers = [];
      this.reorder();
    });
  }

  public addMembersToGroup(members: Array<UserEvent>, group: TPCGroup): void {
    members.forEach(member => {
      member.tpcGroup = group.id;
      this.eventsService.editEventPeople(member, this.event.id).subscribe((m) => {
        group.members.push(m);
      });
    });

    this.members = this.members.filter(member => !members.includes(member.id));

    this.form.reset();
  }

  public addSubmissionsToGroup(submissions: Array<Submission>, group: TPCGroup): void {
    submissions.forEach(submission => {
      submission.tpcGroup = group.id;

      this.submissionsService.editMinimumSubmission(submission, submission.id).subscribe(() => {
        group.submissions.push(submission);
      });
    });

    this.submissions = this.submissions.filter(submission => !submissions.includes(submission.id));

    this.form.reset();
  }

  private initForm(): void {
    this.form = this.fb.group({
      submission: this.fb.group({
        submissions: ['', [Validators.required]],
        group: ['', [Validators.required]],
      }),
      member: this.fb.group({
        members: ['', [Validators.required]],
        group: ['', [Validators.required]],
      }),
      group: this.fb.group({
        name: ['', [Validators.required, Validators.minLength(5)]]
      }),
      edit: this.fb.group({
        members: this.fb.group({
          action: ['REMOVE'],
          destination: []
        }),
        submissions: this.fb.group({
          action: ['REMOVE'],
          destination: []
        })
      })
    });
    setTimeout(() => { this.adminService.progressBar.stop() });
  }

  public removeSubmissionFromGroup(submission: Submission, group: TPCGroup): void {
    submission.tpcGroup = null;

    this.submissionsService.editMinimumSubmission(submission, submission.id).subscribe(() => {
      group.submissions = group.submissions.filter(sub => sub !== submission);

      this.submissions.push(new SelectOption(submission, PaperTitlePipe.prototype.transform(submission)));
      this.reorder();
    });
  }

  public removeSubmissionsFromGroup(submissions: Array<Submission>, group: TPCGroup): void {
    submissions.forEach(submission => this.removeSubmissionFromGroup(submission, group));
  }

  public removeMemberFromGroup(member: UserEvent, group: TPCGroup): void {
    member.tpcGroup = null;
    this.eventsService.editEventPeople(member, this.event.id).subscribe(() => {
      group.members = group.members.filter(sub => sub !== member);

      this.members.push(new SelectOption(member, member.user.name));
      this.reorder();
    });
  }

  public removeMembersFromGroup(members: Array<UserEvent>, group: TPCGroup): void {
    members.forEach(member => this.removeMemberFromGroup(member, group));
  }

  public doMemberAction(action: string): void {
    switch (action) {
      case 'REMOVE':
        this.removeMembersFromGroup(this.selectedMembers, this.selectedGroup);
        break;

      case 'MOVE':
        this.addMembersToGroup(this.selectedMembers, this.form.value.edit.members.destination);
        break;
    }

    this.selectedGroup.members = this.selectedGroup.members.filter(member => !this.selectedMembers.includes(member));
    this.selectedSubmissions = [];
    this.selectedMembers = [];
  }

  public doSubmissionAction(action: string): void {
    switch (action) {
      case 'REMOVE':
        this.removeSubmissionsFromGroup(this.selectedSubmissions, this.selectedGroup);
        break;

      case 'MOVE':
        this.addSubmissionsToGroup(this.selectedSubmissions, this.form.value.edit.submissions.destination);
        break;
    }

    this.selectedGroup.submissions = this.selectedGroup.submissions.filter(submission => !this.selectedSubmissions.includes(submission));
    this.selectedSubmissions = [];
    this.selectedMembers = [];
  }

  public filterGroupFromOptions(group: PaperGroup): Array<SelectOption> {
    return this.groups.filter(option => option.id !== group);
  }

  private reorder(): void {
    this.groups = this.groups.sort((a, b) => a.value.localeCompare(b.value));
    this.submissions = this.submissions.sort((a, b) => a.value.localeCompare(b.value));
    this.members = this.members.sort((a, b) => a.value.localeCompare(b.value));
  }

  public addGroup(): void {
    this.eventsService.addEventTPCGroup(this.event.id, this.form.value.group.name).subscribe(group => {
      this.groups.push(new SelectOption(group, group.name));

      this.reorder();
      this.form.reset();
    });
  }

  public toggleSubmission(checked: boolean, submission: Submission): void {
    if (checked) {
      this.selectedSubmissions.push(submission);
    } else {
      this.selectedSubmissions = this.selectedSubmissions.filter(sub => sub !== submission);
    }
  }

  public toggleMember(checked: boolean, member: UserEvent): void {
    if (checked) {
      this.selectedMembers.push(member);
    } else {
      this.selectedMembers = this.selectedMembers.filter(mem => mem !== member);
    }
  }
}
