import { Component, OnInit, Input, HostBinding, ViewChild, EventEmitter } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { InfoService } from '../../service/info.service';
import { SidebarService } from '../../service/sidebar.service';
import { FixedTopProgressBarComponent } from '../fixed-top-progress-bar/fixed-top-progress-bar.component';
import { ThemePalette } from '@angular/material/core';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { NotificationService } from '../../service/notification.service';
import { SearchButtonComponent } from '../search-button/search-button.component';
import { NavbarService } from 'src/app/service/navbar.service';
import { SidebarItem } from 'src/app/model/sidebar-item';
import { User } from 'src/app/model/user';
import { Location } from '@angular/common';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() color: ThemePalette;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild(SearchButtonComponent, { static: true }) searchButton: SearchButtonComponent;
  @ViewChild(FixedTopProgressBarComponent, { static: true }) progressBar: FixedTopProgressBarComponent;

  @HostBinding('class.hidden') isHidden = false;

  icon = 'fa-bars';

  notificationMenu: MatMenu;

  @Input() items: SidebarItem[];
  searchIsOpen = false;
  userIsAdmin = false;
  user: User;

  constructor(
    public authService: AuthService,
    public sidebarService: SidebarService,
    public navbarService: NavbarService,
    public infoService: InfoService,
    private notificationService: NotificationService,
    private userService: UserService,
    public location: Location
  ) {}

  ngOnInit(): void {
    this.notificationService.menuTrigger = this.menuTrigger;
    this.notificationService.refreshNotifications();
    this.authService.directUserIsAdmin().subscribe(userIsAdmin => {
      this.userIsAdmin = userIsAdmin;
    });
    this.userService.getUserFromToken().subscribe( user => {
      this.user = user;
    });
  }

  toggle(open: boolean = this.isHidden): void {
    this.isHidden = !open;
  }

  toggleSidebar(): void {
    this.sidebarService.toggle();
  }

  get isMobile(): boolean {
    return this.sidebarService.sidebar.isMobile;
  }

  searchToggled(event: { status: boolean }) {
    this.searchIsOpen = event.status;
  }
}
