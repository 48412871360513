import { Type } from '@angular/core';
import { FormControl, AbstractControl, FormGroup } from '@angular/forms';
import { FormFieldChoice } from './form.field.choice';
import { Answer } from './answer';
import { BaseModel } from './baseModel';
import { FormFieldType } from '../enum/form.field.type';
import { FormField as FormFieldComponent } from '../class/form-field';
import { Type as ClassType } from 'class-transformer';
import { CustomFormCheckboxQuestionComponent } from '../component/custom-form-checkbox-question/custom-form-checkbox-question.component';
import { CustomFormRadioQuestionComponent } from '../component/custom-form-radio-question/custom-form-radio-question.component';
import { CustomFormTextQuestionComponent } from '../component/custom-form-text-question/custom-form-text-question.component';
import { FormFieldValidator } from './form.field.validator';

export class FormField extends BaseModel {
  @ClassType(() => FormFieldChoice)
  choices: Array<FormFieldChoice>;

  @ClassType(() => Answer)
  answers: Array<Answer> = [];

  public placeholder: string;
  public min: number;
  public max: number;
  public hidden: boolean;
  public weight: number;
  public shortLabel: string;
  public description: string;
  public type: FormFieldType;
  public order: number;
  public required: boolean;
  public globalWeight: number;

  constructor() { super(); }

  get component(): Type<FormFieldComponent> {
    switch (this.type) {
      case FormFieldType.CHECKBOX:
        return CustomFormCheckboxQuestionComponent;

      case FormFieldType.RADIO:
        return CustomFormRadioQuestionComponent;

      case FormFieldType.TEXT:
        return CustomFormTextQuestionComponent;

      default:
        throw new TypeError(`Invalid type for this form field: ${this.type}`);
    }
  }

  get control(): AbstractControl {
    switch (this.type) {
      case FormFieldType.CHECKBOX:
        let control: {
          [key: string]: AbstractControl;
        } = {};
        this.choices.forEach((choice, i) => {
          control = Object.assign(control, {
            [choice.id]: new FormControl(this.answers.length && this.answers[i].value === 'true')
          });
        });

        return new FormGroup(control);

      case FormFieldType.RADIO:
      case FormFieldType.TEXT:
        return new FormControl(this.answers.length ? this.answers[0].value : '', FormFieldValidator.create(this));
    }
  }

  public hasAnswers(): boolean {
    return this.answers.length > 0;
  }

  public get answerForReview(): string | Array<FormFieldChoice> {
    switch (this.type) {
      case FormFieldType.RADIO:
        return this.answers.map(a => this.getChoiceForAnswerById(a.fieldChoice));

      case FormFieldType.CHECKBOX:
        return this.answers.filter(a => a.value === 'true').map(a => this.getChoiceForAnswerById(a.fieldChoice));

      case FormFieldType.TEXT:
        return String(this.answers[this.answers.length - 1].value);
    }
  }

  public getChoiceForAnswerById(id: number): FormFieldChoice {
    return this.choices.find(choice => choice.id === id);
  }
}

export class SortedFormFields {
  assignableFields: boolean;
  fields: FormField[];

  constructor() {}

  public hasAnswers(): boolean {
    return this.descriptive.hasAnswers();
  }

  public get descriptive(): FormField {
    return this.fields[0];
  }
}
