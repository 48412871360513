import { ViewChild, HostListener, Directive } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatPaginator } from '@angular/material/paginator';
import { AdminService } from '../service/admin.service';
import { NavbarService } from '../service/navbar.service';
import { Submission } from '../model/paper';
import { Review } from '../model/paper';
import { Event } from '../model/event';
import { EventRequest } from '../model/eventRequest';
import { Pagination } from '../model/pagination';
import { PaginationOptions } from '../model/pagination.options';
import { PreferencesService } from '../service/preferences.service';
import { DEFAULT_PAPER_FIELDS_SHOW } from '../component/paper-card/paper-card.component';
import { LocalCache } from '../enum/local.cache';
import { ShowPaperCardsInterface } from '../interface/show-paper-cards-interface';
import { SubmissionFilter } from '../model/submission.filter';

@Directive()
export abstract class PagedViewDirective implements ShowPaperCardsInterface{
  @ViewChild('toggle')
  public toggle: MatSlideToggle;

  public pagination: Pagination<Submission | Review | Event | EventRequest>;

  public filter = new SubmissionFilter();
  public filterVisible = false;
  public paginatorComponent: MatPaginator = undefined;

  public pageSizeOptions: Array<number> = [5, 10, 25, 100];

  public paginatorAtBottom = false;

  fieldsShow = this.checkedFieldsShow;
  event?: Event;

  public unfilteredElements: Array<Submission> | Array<Review> | Array<Event>;
  public elements: Array<Submission> | Array<Review> | Array<Event>;

  constructor(
    protected navbarService: AdminService | NavbarService,
    protected preferencesService: PreferencesService
  ) { }

  public abstract load(): void;

  @ViewChild('paginator') set content(content: MatPaginator) {
    this.paginatorComponent = content;
  }

  public get paginationOptions(): PaginationOptions {
    return {
      pageNumber: this.page,
      pageSize: this.pageSize,
      show: this.showAll
    };
  }

  public get showAll(): 'all' | 'active' {
    return this.toggle && this.toggle.checked ? 'all' : 'active';
  }

  public get page(): number {
    return this.paginatorComponent ? this.paginatorComponent.pageIndex + 1 : 1;
  }

  public get pageSize(): number {
    return this.paginatorComponent ? this.paginatorComponent.pageSize : 10;
  }

  @HostListener('window:scroll')
  public shouldMovePaginatorToBottom(): void {
    // Paginator binds to paginatorAtBottom variable.

    this.paginatorAtBottom =
      this.pagination?.items.length > 5 &&
      document.body.clientHeight + window.scrollY + 200 >= document.body.scrollHeight;
  }

  get checkedFieldsShow(): Array<string> {
    const cachedValues = this.preferencesService.getPreference(LocalCache.SUBMISSION_FILTER, this.event);

    if (cachedValues === undefined || cachedValues === false) {
      // No cached value defined. Use default.
      return [...DEFAULT_PAPER_FIELDS_SHOW];
    } else if (cachedValues === '') {
      // Cached value is defined with no fields marked.
      return [];
    }
    // Return array of strings that are marked.
    return (<string>cachedValues).split(',');
  }

  public filterPapers(unfiltered): void {
    this.elements = unfiltered.filter((s: Submission | Review) => this.filter.status.includes(s.status));
  }
}
