import { Component, OnInit, Input } from '@angular/core';
import { ReviewsService } from 'src/app/service/reviews.service';
import { Review } from 'src/app/model/paper';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/service/notification.service';
import { ReviewStatus } from 'src/app/enum/review.status';
import { InfoService } from 'src/app/service/info.service';
import { UserService } from 'src/app/service/user.service';
import { User } from 'src/app/model/user';
import { AuthService } from 'src/app/service/auth.service';
import { EventsService } from 'src/app/service/events.service';
import { NavbarService } from 'src/app/service/navbar.service';
import { Event } from 'src/app/model/event';
import { AdminService } from 'src/app/service/admin.service';

@Component({
  selector: 'app-confirm-review',
  templateUrl: './confirm-review.component.html',
  styleUrls: ['./confirm-review.component.scss']
})
export class ConfirmReviewComponent implements OnInit {
  reviewId: number;
  review: Review;
  event: Event;
  blockAccess: boolean = false;
  reviewerEmail: string;
  eventId: number;

  @Input() isReviewView = false;
  @Input() currentUser: User;

  constructor(
    public authService: AuthService,
    public infoService: InfoService,
    private userService: UserService,
    public reviewsService: ReviewsService,
    public eventsService: EventsService,
    public route: ActivatedRoute,
    public notificationService: NotificationService,
    private navbarService: NavbarService,
    public router: Router,
    private adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.navbarService.loadingStart();
    this.route.params.subscribe(({ reviewId, id }) => {
      if (reviewId) {
        this.reviewId = reviewId;
      } else {
        this.reviewId = id;
      }

      if (!this.currentUser) {
        this.userService.getUserFromToken().subscribe( user => {
          this.currentUser = user;
        });
      }
      
      this.userService.getUserFromToken().subscribe( user => {
          this.currentUser = user;
        }, () => {},
        () => {
            this.reviewsService.getReviewInvitation(this.reviewId).subscribe(review => {
              this.eventId = review.eventId;
              let userIsAssignedReviewer = review.userId === this.currentUser.id || review.assignedByUserId === this.currentUser.id;
              if (!userIsAssignedReviewer) {
                this.authService.userIsAdminOrChair(this.eventId).subscribe(isAdminOrChair => {
                  userIsAssignedReviewer = isAdminOrChair;
                  this.handleReview(review, userIsAssignedReviewer);
                });
              } else {
                this.handleReview(review, userIsAssignedReviewer);
              }
            });
          });
        }
      );
  }

  handleReview(review: Review, userIsAssignedReviewer: boolean) {
    const isEventViewRoute = this.adminService.isEventAdminRoute(this.route.snapshot);
    if (review.status !== ReviewStatus.NOTIFIED) {  //Redirect to review page and handler permission
      if (!this.isReviewView) {
        this.router.navigate(['/loading'], { skipLocationChange: true }).then(() => {
          if (isEventViewRoute) {
            this.router.navigate(['/admin', this.eventId, 'reviews', review.id]);
          } else {
            this.router.navigate(['/reviews', review.id]);
          }
        });
      }
    } else { 
      if (!userIsAssignedReviewer) {        
        this.reviewerEmail = review.user instanceof User ? review.user.email : null;
        this.blockAccess = true;
      } else {
        this.reviewsService.getReview(this.reviewId).subscribe(review => {
          this.review = review;
        });
      }
    }
    this.navbarService.loadingStop();
  }

  submitAnswer(answer: 'CONFIRMED' | 'DECLINED'): void {
    const isEventViewRoute = this.adminService.isEventAdminRoute(this.route.snapshot);

    this.reviewsService.sendReviewInvitationAnswer(this.reviewId, answer).subscribe(review => {
      if (review.status === ReviewStatus.CONFIRMED) {
        this.notificationService.notify('reviews.invite-answer-confirmed', { params: { id: this.reviewId } });

        this.router.navigate(['/loading'], { skipLocationChange: true }).then(() => {
          if (isEventViewRoute) {
            this.router.navigate(['/admin', this.eventId, 'reviews', review.id]);
          } else {
            this.router.navigate(['/reviews', review.id]);
          }
        });
      } else if (review.status === ReviewStatus.DECLINED) {
        this.notificationService.notify('reviews.invite-answer-rejected', { params: { id: this.reviewId } });

        this.router.navigate(['/loading'], { skipLocationChange: true }).then(() => {
          if (isEventViewRoute) {
            this.router.navigate(['/admin', this.eventId]);
          } else {
            this.router.navigate(['/reviews']);
          }
        });
      } else {
        this.notificationService.notify('reviews.invite-answer-error');
      }
    });
  }
}
