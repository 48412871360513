import { Component, forwardRef, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EventReviewConfiguration } from 'src/app/model/eventReviewConfiguration';
import { ReviewDelegateComponent } from 'src/app/component/review-delegate/review-delegate.component';
import { Submission, Review } from 'src/app/model/paper';
import { SubmissionStatus } from 'src/app/model/paper';
import { ReviewStatus } from 'src/app/enum/review.status';
import { ReviewsService } from 'src/app/service/reviews.service';
import { InfoService } from 'src/app/service/info.service';
import { User } from 'src/app/model/user';
import { NotificationService } from 'src/app/service/notification.service';
import { ReviewsConfigurationService } from 'src/app/service/reviews.configuration.service';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { ReviewReclaimComponent } from '../review-reclaim/review-reclaim.component';
import { AuthService } from 'src/app/service/auth.service';


@Component({
  selector: 'app-paper-review-actions',
  templateUrl: './paper-review-actions.component.html',
  styleUrls: ['./paper-review-actions.component.scss'],
})
export class PaperReviewActionsComponent implements OnInit {
  @Input() review: Review;
  @Input() reviews: Review[];
  @Input() reviewConfig: EventReviewConfiguration;
  @Input() userIsChair = false;
  @Output() reviewUpdated: EventEmitter<Review> = new EventEmitter<Review>(); 

  userIsReviewer = false;
  userIsAssigner = false;
  isAdmin = false;
  canReclaim = false;

  constructor(
    private authService: AuthService,
    private reviewsService: ReviewsService,
    private dialog: MatDialog,
    private infoService: InfoService,
    private reviewConfigurationService: ReviewsConfigurationService,
    private notificationService: NotificationService,
    private router: Router,
  ) { }

  ngOnInit(){
    this.userIsReviewer = this.setUserIsReviewer();
    this.userIsAssigner = this.setUserIsAssigner();
    this.isAdmin = this.authService.userIsAdmin();
  }

  public setUserIsReviewer(): boolean {
    const currentUserId = this.infoService.user.id;
    let isReviewer = false;

    if (this.review.user instanceof User) {
      isReviewer = (this.review.user.id === currentUserId);
    } else {
      isReviewer = (this.review.user === currentUserId);
    }

    return isReviewer;
  }

  public setUserIsAssigner(): boolean {
    let isAssigner = false;
    const currentUserId = this.infoService.user.id;

    if (this.review.user instanceof User) {
      isAssigner = (this.review.assignedBy as User).id == currentUserId;
    } else {
      isAssigner = this.review.assignedBy == currentUserId;
    }

    return isAssigner;
  }

  editReview(event: any): void {
    const editOrCreate =  this.review.wasAnswered() ? 'edit' : 'create';
    const link = `/reviews/${this.review.id}/${editOrCreate}`;    
    if (event.metaKey || event.ctrlKey) {
      window.open(link, '_blank');
    } else {
      this.router.navigate([link]);
    }
  }

  delegateReview(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45rem';
    dialogConfig.height = '23rem';
    dialogConfig.panelClass = 'my-custom-dialog-class';
    dialogConfig.data = { review: this.review, userIsChair: this.userIsChair };
    this.dialog.open(ReviewDelegateComponent, dialogConfig);
  }

  isReviewBlocked(): boolean {
    return this.review.isReviewBlocked(this.reviewConfig.blockReview, this.userIsChair, this.review.submission?.isAuthor(this.infoService.user.id));
  }

  reclaim() {
    const dialogConfig = {
      'autoFocus': false,
      'width': '45rem',
      'height': '23rem',
      'panelClass': 'my-custom-dialog-class',
      'data': {
        review: this.review,
        reviews: this.reviews,
        userIsChair: this.userIsChair
      }
    };
    this.dialog.open(ReviewReclaimComponent, dialogConfig).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.reviewsService.reclaimReview(this.review.id).subscribe(r => {
          this.reviewUpdated.emit(r);
        }, () => {}, () => {
          this.notificationService.notify('submissions.view.reclaim-review.notify', { params: { id: this.review.id } });
        });
      }
    });
  }

  delegateBlocked(): boolean {
    return this.isReviewBlocked() || this.review.status == ReviewStatus.COMPLETED || this.review.status == ReviewStatus.DRAFT;
  }

  submitAnswer(answer: 'CONFIRMED' | 'DECLINED'): void {
    if (answer === "CONFIRMED") {
      this.saveAnswer(this.review.id, answer);
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'reviews.confirm-review-decline-invite',
          content: ''
        }
      }).afterClosed().subscribe(confirmed => {
        if (confirmed) {
          this.saveAnswer(this.review.id, answer);
        }
      });
    }
  }

  saveAnswer(reviewId: number, answer: 'CONFIRMED' | 'DECLINED' ): void {
    this.reviewsService.sendReviewInvitationAnswer(reviewId, answer).subscribe(r => {
      if (r.status === ReviewStatus.CONFIRMED) {
        this.notificationService.notify('reviews.invite-answer-confirmed', { params: { id: this.review.id } });
      } else if (r.status === ReviewStatus.DECLINED) {
        this.notificationService.notify('reviews.invite-answer-rejected', { params: { id: this.review.id } });
      } else {
        this.notificationService.notify('reviews.invite-answer-error');
      }
      this.reviewUpdated.emit(r);
      //window.location.reload();
    });
  }
}
