import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { Event } from '../../../../model/event';
import { Submission, SubmissionStatus } from 'src/app/model/paper';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { forkJoin } from 'rxjs';
import { NotificationService } from 'src/app/service/notification.service';

@Component({
  selector: 'app-missing-manuscripts',
  templateUrl: './missing-manuscripts.component.html',
  styleUrls: ['./missing-manuscripts.component.scss']
})
export class MissingManuscriptsComponent implements OnInit {
  public event: Event;
  public submissions: Array<Submission>;
  public form: FormGroup;

  constructor(
    private adminService: AdminService,
    private eventsService: EventsService,
    private submissionsService: SubmissionsService,
    private fb: FormBuilder,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.event = this.adminService.selectedEvent;

      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.loadData();
        });
      } else {
        this.loadData();
      }
    });
  }

  private loadData(): void {
    this.eventsService.getMissingManuscripts(this.event.id).subscribe(submissions => {
      this.submissions = submissions;
      this.initForm();
    });
  }

  private initForm(): void {
    this.form = this.fb.group({
      ids: this.fb.array(Array(this.submissions.length).fill(false))
    });
  }

  public get marked(): Array<Submission> {
    return this.submissions.filter((_sub, i) => this.form.value.ids[i]);
  }

  public notify(): void {
    this.eventsService.notifyAuthorsWithMissingManuscripts(this.event.id).subscribe(() => {
      this.notificationService.notify('admin.event.submissions.missing-manuscripts.authors-notified');
    });
  }

  public withdraw(): void {
    forkJoin(this.marked.map(submission => {
      submission.status = SubmissionStatus.WITHDRAWN;

      return this.submissionsService.editMinimumSubmission(submission, submission.id);
    })).subscribe(submissions => {
      this.form.reset();

      this.submissions = this.submissions.filter(sub => !submissions.find(subm => subm.id === sub.id));

      this.notificationService.notify('admin.event.submissions.missing-manuscripts.papers-withdrawn');
    });
  }
}
