import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { LandingComponent } from '../landing.component';
import { InfoService } from '../../service/info.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  signInForm: FormGroup;

  constructor(
    private container: LandingComponent,
    private authService: AuthService,
    private fb: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    private infoService: InfoService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  submit() {
    if (this.signInForm.valid) {
      this.container.progressBar.start();

      const navigateUrl = this.route.snapshot.queryParams['returnUrl'] || '/events';
      this.authService.signIn(this.signInForm.value).subscribe(
        response => {
          this.infoService.response = response;
        },
        error => {
          this.container.progressBar.stop();
        },
        () => {
          this.router.navigateByUrl(navigateUrl);
        }
      );
    }
  }

  private initForm() {
    this.signInForm = this.fb.group({
      login: ['', [Validators.required, this.loginValidator]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  loginValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const loginIsNumber = !isNaN(Number(control.value));
    if (loginIsNumber) {
      return null; // Allow login if is id.
    }

    // Check if login is a valid email.
    return Validators.email(control);
  }
}
