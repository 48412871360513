import { Component, Input, ViewChild } from '@angular/core';
import { Review } from '../../model/paper';
import { CustomFormComponent } from '../custom-form/custom-form.component';
import { style, animate, transition, state, trigger } from '@angular/animations';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-review',
  templateUrl: './user-review.component.html',
  styleUrls: ['./user-review.component.scss'],
  animations: [
    trigger('slide', [
      state('0', style({ 'overflow-y': 'hidden' })),
      state('1', style({ 'overflow-y': 'auto', 'height': 'auto' })),
      transition('0 => 1', [animate('1000ms 0.2ms ease-out')]),
      transition('1 => 0', [animate('1000ms 0.2ms ease-out')])
    ])
  ]
})
export class UserReviewComponent {
  @ViewChild('formContainer', { static: false })
  public formContainer: CustomFormComponent;

  @Input() order: number;
  @Input() review: Review;

  form: FormGroup = new FormGroup({});
}
