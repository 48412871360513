import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/service/admin.service';
import { Event } from 'src/app/model/event';
import { ReviewStatus } from 'src/app/enum/review.status';

@Component({
  selector: 'app-admin-notify-reviewers',
  templateUrl: './notify-reviewers.component.html',
  styleUrls: ['./notify-reviewers.component.scss']
})
export class AdminNotifyReviewersComponent implements OnInit {
  event: Event;

  status: ReviewStatus.ASSIGNED | ReviewStatus.REMINDED | ReviewStatus.LATE = ReviewStatus.ASSIGNED;
  tabToStatus: Array<ReviewStatus.ASSIGNED | ReviewStatus.REMINDED | ReviewStatus.LATE> = [
    ReviewStatus.ASSIGNED, ReviewStatus.REMINDED, ReviewStatus.LATE
  ];

  reviewStatus = ReviewStatus;

  constructor(
    public adminService: AdminService,
  ) { }

  ngOnInit(): void {
    this.adminService.progressBar.start();

    this.event = this.adminService.selectedEvent;
    if (!this.event) {
      this.adminService.getEvent().subscribe(event => {
        this.event = event;
      });
    }
  }

  selectedTab({ index }) {
    this.status = this.tabToStatus[index];
  }
}
