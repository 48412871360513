import { Component, OnInit, ViewChild } from '@angular/core';
import { ReviewsService } from '../../../../service/reviews.service';
import { Event } from '../../../../model/event';
import { AdminService } from '../../../../service/admin.service';
import { Review } from '../../../../model/paper';
import { Submission, SubmissionStatus } from '../../../../model/paper';
import { ReviewStatusPerSubmissionList } from '../../../../model/review.status.list';
import { ReviewsConfigurationService } from '../../../../service/reviews.configuration.service';
import { ReviewStatus } from 'src/app/enum/review.status';
import { map } from 'rxjs/operators';
import { zip } from 'rxjs';
import { SubmissionsService } from '../../../../service/submissions.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-review-status-per-submission',
  templateUrl: './review-status-per-submission.component.html',
  styleUrls: ['./review-status-per-submission.component.scss']
})
export class ReviewStatusPerSubmissionComponent implements OnInit {
  event: Event;
  reviewsRequired: number;
  dataSource: MatTableDataSource<ReviewStatusPerSubmissionList>;
  reviewStatusPerSubmission: Array<ReviewStatusPerSubmissionList>;
  columns = ['id', 'submission', 'totalReviews', 'completedConfirmed', 'assigned', 'notified', 'confirmed', 'completed', 'draft', 'reminded', 'late', 'delegated', 'declined'];

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.sort = sort;
    if (this.sort) {
      this.dataSource.sort = this.sort;

      this.dataSource.sortingDataAccessor = (item: ReviewStatusPerSubmissionList, header: string) => {
        switch (header) {
          case 'id': return item.id;
          case 'submission': return item.title;
          case 'totalReviews': return item.validReviews();
          case 'completedConfirmed': return item.completedAndConfirmed();
          default: return item[header]; // Default is status columns
        }
      };
    }
  }
  sort: MatSort;

  constructor(
    public adminService: AdminService,
    public reviewsService: ReviewsService,
    public reviewConfigurationService: ReviewsConfigurationService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.adminService.progressBar.start();

      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.getReviewsPerSubmissionList();
        });
      } else {
        this.getReviewsPerSubmissionList();
      }
    });
  }

  getReviewsPerSubmissionList() {
    this.adminService.progressBar.start();
    this.dataSource = new MatTableDataSource<ReviewStatusPerSubmissionList>();

    zip(
      this.reviewsService.getReviewStatusPerSubmissionListByEvent(this.event.id),
      this.reviewConfigurationService.getReviewsConfiguration(this.event.id)
    )
    .pipe(map(([reviewsPerSubmission, reviewConfiguration]) => ({ reviewsPerSubmission, reviewConfiguration })))
    .subscribe(({ reviewsPerSubmission, reviewConfiguration }) => {
      this.reviewStatusPerSubmission = reviewsPerSubmission;
      this.reviewsRequired = reviewConfiguration.numberOfReviewsRequired;
      this.dataSource.data = [...this.reviewStatusPerSubmission];

      this.adminService.progressBar.stop();
    });
  }

  getClass(reviewStatusPerSubmission: ReviewStatusPerSubmissionList): string {
    const total = reviewStatusPerSubmission.validReviews();

    if (reviewStatusPerSubmission.completed >= this.reviewsRequired) {
      return 'exactly-completed';
    } else if (total < this.reviewsRequired) {
      return 'less';
    } else if (total > this.reviewsRequired) {
      return 'more';
    } else {
      return 'exactly';
    }
  }

  getTotalSubmissions(): number {
    return this.dataSource.data.length;
  }

  getTotalNonDeclined(): number {
    return this.dataSource.data.map(e => e.validReviews()).reduce((acc, value) => acc + value, 0);
  }

  getTotalCompletedConfirmed(): number {
    return this.dataSource.data.map(e => e.completedAndConfirmed()).reduce((acc, value) => acc + value, 0);
  }

  getTotalStatus(status: string): number {
    return this.dataSource.data.map(e => e[status]).reduce((acc, value) => acc + value, 0);
  }
}
