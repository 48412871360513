import { Component, OnInit } from '@angular/core';
import { CardViewComponent } from '../card-view/card-view.component';
import { Review } from 'src/app/model/paper';
import { PrintingProblem } from 'src/app/model/printing.problem';
import { Router, ActivatedRoute } from '@angular/router';
import { InfoService } from 'src/app/service/info.service';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { NavbarService } from 'src/app/service/navbar.service';
import { EventsService } from 'src/app/service/events.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { ReviewsService } from 'src/app/service/reviews.service';
import { NotificationService } from '../../service/notification.service';

@Component({
  selector: 'app-report-printing-problem',
  templateUrl: './report-printing-problem.component.html',
  styleUrls: ['./report-printing-problem.component.scss']
})
export class ReportPrintingProblemComponent extends CardViewComponent implements OnInit {
  public review: Review;
  public form: FormGroup;

  public sent: boolean;

  public problems: Array<{ label: string }> = [
    { label: 'paper_format' },
    { label: 'paper_format_unrecognized' },
    { label: 'paper_postscript_generation' },
    { label: 'paper_pdf_display' },
    { label: 'paper_pdf_colormaps' },
    { label: 'paper_pdf_pages' },
    { label: 'paper_pdf_truncated' },
    { label: 'paper_pdf_missing_figures' },
    { label: 'paper_pdf_missing_citation' },
    { label: 'paper_word' }
  ];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public infoService: InfoService,
    private fb: FormBuilder,
    private navbarService: NavbarService,
    private eventsService: EventsService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    protected reviewsService: ReviewsService,
    protected submissionService: SubmissionsService, location: Location,
    private notificationService: NotificationService
  ) {
    super(router, route, submissionService, location, infoService, reviewsService);
  }

  ngOnInit(): void {
    this.loadReview();
    this.initForm();
  }

  private loadReview(): void {
    this.navbarService.loadingStart();
    this.reviewsService.getReview(Number(this.route.snapshot.paramMap.get('id'))).subscribe(review => {
      this.review = review;
      this.navbarService.loadingStop();
    });
  }

  private initForm(): void {
    this.form = this.fb.group({
      description: ['']
    });

    this.problems.forEach(problem => {
      this.form.addControl(problem.label, new FormControl(false));
    });
  }

  public get valid(): boolean {
    return Object.values(this.form.value).some((value: boolean) => value);
  }

  public submit(): void {
    if (this.form.valid) {
      const report = new PrintingProblem();

      report.user = this.infoService.user.id;
      report.review = this.review.id;
      report.description = this.form.value.description;
      report.causes = this.problems.filter(prob => this.form.value[prob.label]).map(a => a.label.toUpperCase());

      this.navbarService.loadingStart();
      this.reviewsService.reportPrintingProblem(report).subscribe(() => {
        this.navbarService.loadingStop();

        this.notificationService.notify('reviews.report-printing-problem', {
          onClose: () => {
            this.router.navigate(['reviews', this.review.id]);
          }
        });
      });
    }
  }
}
