import { Component, ViewChild, Input } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'app-fixed-top-progress-bar',
  templateUrl: './fixed-top-progress-bar.component.html',
  styleUrls: ['./fixed-top-progress-bar.component.scss']
})
export class FixedTopProgressBarComponent {
  @Input() dark: boolean;

  bufferValue: number;
  mode: string;
  value: number;


  start(mode = 'indeterminate'): void {
    this.mode = mode;
  }

  stop(): void {
    this.mode = undefined;
  }
}
