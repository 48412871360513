import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavbarService } from '../../service/navbar.service';
import { InfoService } from '../../service/info.service';
import { User } from '../../model/user';
import { Environment } from '../../environment/environment';
import { DEFAULT_PAGINATION_OPTIONS, PaginationOptions } from 'src/app/model/pagination.options';
import { PageEvent } from '@angular/material/paginator';
import { Pagination } from 'src/app/model/pagination';
import { Affiliation } from '../../model/affiliation';
import { UserService } from 'src/app/service/user.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../component/confirmation-dialog/confirmation-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { slideOutAnimation } from 'src/app/animations/slideInOut';
import { SystemService } from 'src/app/service/system.service';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-conflict-interest',
  templateUrl: './conflict-interest.component.html',
  styleUrls: ['./conflict-interest.component.scss'],
  animations: [
    slideOutAnimation
  ]
})
export class ConflictInterestComponent implements OnInit {
  public form: FormGroup;
  public affiliationForm: FormGroup;

  public allAffiliations: Affiliation[];
  public modelAffiliation = { dataBase: 'filter', value_1: 'filter' };
  public individual: User;
  public affiliation: Affiliation;
  public conflictList: { user: User, selected: boolean }[];
  public conflictListI: User[];
  public paginationOptions: PaginationOptions;
  public pagination: Pagination<any>;
  showCreateUserForm = false;

  public markButton = false;
  public markIndeterminate = false;

  loading = {
    conflictList: true
  };

  eventId: number;
  user: User;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private userService: UserService,
    private navbarService: NavbarService,
    public infoService: InfoService,
    public route: ActivatedRoute,
    public systemService: SystemService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.userService.getUserProfile().subscribe( user => {
        this.user = user;
      }, () => {},
      () => {
        this.initForms();
        this.load();
      });

      this.route.queryParams.subscribe(({ eventId }) => {
        this.eventId = eventId;
      });
    });
  }

  private initForms(): void {
    this.form = this.fb.group({
      individual: ['', [Validators.required]],
      conflicts: [[], [Validators.required]]
    });
    this.affiliationForm = this.fb.group({
      affiliation: ['']
    });
  }

  public loadAffiliations(load: boolean): void {
    if (load) {
      this.systemService.getAllAffiliations().pipe(map(res => res.data))
        .subscribe((Affiliations: Affiliation[]) => this.allAffiliations = [...Affiliations]);
    }
  }

  public addAffiliation(affiliation: Affiliation) {
    if (!!affiliation) {
      this.loading.conflictList = true;
      this.userService.addAffiliationConflictInterest(this.user.id, affiliation).subscribe(
        conflictsReceived => {
          this.loading.conflictList = false;

          this.conflictList = conflictsReceived
            .sort((a, b) => a.firstName.localeCompare(b.firstName))
            .map(item => ({ user: item, selected: false }));
        });
    }
  }

  public found(user) {
    const conflict = { user: user, selected: false };
    this.conflictList.push(conflict);
    this.conflictListI.push(conflict.user);
    this.conflictList.sort((a, b) => a.user.firstName.localeCompare(b.user.firstName));

    const form = this.form.value;
    form.individual.splice(0, form.individual.length);
    this.form.reset();
    this.form.setValue(form);

    this.loading.conflictList = true;
    this.userService.addConflictInterest(this.user.id, user).subscribe();
    this.loading.conflictList = false;
  }

  public deleteUser(item) {
    this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true, autoFocus: false,
      data: {
        title: 'conflicts_interest.confirm_delete_dialog',
        content: ''
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        if (item.selected === false) {
          item.selected = true;
        }
        for (var temp of this.conflictList) {
          if (item.user.id === temp.user.id) {
            temp.selected = true;
          }else{
            temp.selected = false;
          }
        }

        const deleteList = this.conflictList.filter(item => item.selected).map(item => item.user.id);

        this.userService.deleteConflictInterestList(this.user.id, deleteList).subscribe(
          conflictListUpdated => {
            this.conflictListI = conflictListUpdated
            this.conflictList = conflictListUpdated
              .sort((a, b) => a.firstName.localeCompare(b.firstName))
              .map(item => ({ user: item, selected: false }));

            this.markButton = false;
            this.setIndeterminateState(false);
          });
      }
    });
  }


  public deleteSelected() {
    const deleteList = this.conflictList.filter(item => item.selected).map(item => item.user.id);
    if (deleteList.length > 0) {
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'conflicts_interest.confirm_delete_dialog',
          content: ''
        }
      }).afterClosed().subscribe(confirmed => {
        if (confirmed) {

          this.loading.conflictList = true;
          this.userService.deleteConflictInterestList(this.user.id, deleteList).subscribe(
            conflictListUpdated => {
              this.conflictListI = conflictListUpdated
              this.conflictList = conflictListUpdated
                .sort((a, b) => a.firstName.localeCompare(b.firstName))
                .map(item => ({ user: item, selected: false }));

              this.markButton = false;
              this.setIndeterminateState(false);
              this.loading.conflictList = false;
            });
        }
      });
    }
  }

  markAll(state: boolean) {
    this.conflictList.forEach(item => item.selected = state);
    this.markIndeterminate = false;
  }

  setIndeterminateState(state: boolean) {
    this.markIndeterminate = this.markButton && state;
  }

  updatedSearchUser(event: { found: boolean, value: boolean }): void {
    const { found, value } = event;

    this.showCreateUserForm = !found && value;
  }

  load(event?: PageEvent) {
    if (this.paginationOptions == undefined) {
      this.paginationOptions = DEFAULT_PAGINATION_OPTIONS;
      this.paginationOptions.pageSize = 100;
    }

    if (event) {
      this.paginationOptions.pageNumber = <number>event.pageIndex + 1;
    }

    this.userService.getConflictsInterest(this.user.id, this.paginationOptions).subscribe(pagination => {
      this.pagination = pagination;
      this.conflictListI = pagination.items as User[];
      this.conflictList = this.conflictListI
        .sort((a, b) => a.firstName.localeCompare(b.firstName))
        .map(item => ({ user: item, selected: false }));
      this.loading.conflictList = false;
    });
  }
}
