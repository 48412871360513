import { Type, Expose, Transform } from 'class-transformer';
import { User } from './user';
import { BaseModel, toDayjs } from './baseModel';
import { UserEventRole } from '../enum/user.event.role';
import { UserEventAnswer } from '../enum/user.event.answer';
import * as dayjs from 'dayjs';

export class UserEventBase extends BaseModel {
  @Expose()invitationAnswer: UserEventAnswer;

  @Transform(toDayjs)
  @Expose()invitationAnsweredAt: dayjs.Dayjs;

  @Transform(toDayjs)
  @Expose()notificationSentAt: dayjs.Dayjs;

  @Expose()sendInvite = false;

  @Transform(toDayjs)
  @Expose()invitationSentAt: dayjs.Dayjs;

  @Expose()role: UserEventRole;

  @Type(() => User)
  @Expose()user: User;

  public roleToString(): string {
    return this.role === UserEventRole.CHAIR.toUpperCase() ? 'CHAIR' : 'MEMBER';
  }
}
