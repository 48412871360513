import { Component } from '@angular/core';
import { InfoService } from '../../service/info.service';
import { EventsService } from '../../service/events.service';
import { Event } from '../../model/event';
import { NavbarService } from 'src/app/service/navbar.service';
import { UserEventRole } from '../../enum/user.event.role';
import { ShowAllPagedViewDirective } from 'src/app/class/paged-view-all';
import { NO_PAGINATION } from 'src/app/model/pagination.options';
import { PreferencesService } from 'src/app/service/preferences.service';
import { LocalCache } from 'src/app/enum/local.cache';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-committees',
  templateUrl: './committees.component.html',
  styleUrls: ['./committees.component.scss']
})
export class CommitteesComponent extends ShowAllPagedViewDirective {
  private userID: number;

  constructor(
    private infoService: InfoService,
    private eventsService: EventsService,
    public navbarService: NavbarService,
    public preferencesService: PreferencesService,
    public userService: UserService
  ) {
    super(preferencesService, LocalCache.OLD_TPC);
  }

  load() {
    this.userID = this.infoService.user?.id;
    setTimeout(() => {
      if (this.userID) {
        this.getEventsByUserRole();
        return;
      }
      this.userService.getUserFromToken().subscribe(u => {
        next: this.userID = u.id;
        complete: this.getEventsByUserRole();
      });
    });
  }

  get committees(): Array<Event> {
    return <Array<Event>>this.elements;
  }

  getEventsByUserRole(): void {
    this.navbarService.loadingStart();
    const paginationOptions = NO_PAGINATION;
    paginationOptions.show = this.showAll;
    this.eventsService
      .getEventByUserRole(this.userID, UserEventRole.COMMITTEE, paginationOptions).subscribe(pagination => {
        this.unfilteredElements = pagination.items as Array<Event>;
        this.elements = this.unfilteredElements;

        this.navbarService.loadingStop();
        window.scroll(0, 0);
      });
  }
}
