import { Component, Input, OnInit } from '@angular/core';
import { Author } from 'src/app/model/author';
import { FileRules } from 'src/app/model/file.rules';
import { Submission } from 'src/app/model/paper';
import { ProceedingsContent, ProceedingsContentType } from 'src/app/model/proceedingsContent';
import { Session } from 'src/app/model/session';
import { ProceedingsIndex } from '../proceeding-step/proceeding-step.component';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

class ProceedingsAuthorIndex {
  author: Author;
  pages: number[];

  constructor(author: Author, pages?: number[]) {
    this.author = author;
    this.pages = pages ? pages : [];
  }

}

@Component({
  selector: 'app-proceeding-author-index-item',
  templateUrl: './proceeding-author-index-item.component.html',
  styleUrls: ['./proceeding-author-index-item.component.scss']
})
export class ProceedingAuthorIndexItemComponent implements OnInit {

  @Input() item: ProceedingsContent;
  @Input() content: ProceedingsContent[];
  @Input() sessions: Session[];
  @Input() submissions: Submission[];
  @Input() proceedingsTrackFiles: FileRules[];
  @Input() normalIndexList: ProceedingsIndex[];
  @Input() indexForm: FormGroup;

  indexList: ProceedingsAuthorIndex[] = [];
  authors: Author[] = [];

  constructor() { }

  ngOnInit(): void {
    this.indexList = this.mountAuthorIndex();
  }

  getField(name: string): FormControl {
    return this.indexForm.get(name) as FormControl;
  }

  isTheFirst(author: Author): boolean {
    const initial = author.user.name[0];
    const indexInList = this.authors.indexOf(author);

    if (indexInList == 0) {
      return true;
    } else {
      const previousInitial = this.authors[indexInList - 1].user.name[0];

      return initial != previousInitial;
    }
  }

  mountAuthorIndex() : ProceedingsAuthorIndex[] {

    if (this.item.type == ProceedingsContentType.INDEX) {
      return;
    }

    this.authors = this.extractAllAuthors().sort(function(a, b) {
      return a.user.name.localeCompare(b.user.name);
    });

    const indexList: ProceedingsAuthorIndex[] = [];
    this.authors.forEach(a => {
      indexList.push(new ProceedingsAuthorIndex(a, this.getAuthorPaperPages(a)));
    });

    return indexList;
  }

  getAuthorPaperPages(author: Author): number[] {
    const pages = [];

    this.normalIndexList.filter(item => item.content.type == ProceedingsContentType.SUBMISSION).forEach(s => {
      const submission = this.submissions.filter(su => su.id == s.content.submission.id)[0];

      const isAuthor = submission.authors.filter(a => a.user.id == author.user.id).length != 0;
      
      if (isAuthor) {
        pages.push(s.pageStart);
      }

    });

    return pages;
  }

  extractAllAuthors(): Author[] {
    let authors = [];
    this.content.forEach(c => {
      const contentAuthors = this.extractAuthors(c);

      if (contentAuthors) {
        authors = authors.concat(contentAuthors);
      }

    });

    const authorsSet = [];
    const usersIds = [];

    authors.forEach(a => {
      if (!usersIds.includes(a.user.id)) {
        authorsSet.push(a);
        usersIds.push(a.user.id);
      }
    });

    return authorsSet;
  }

  extractAuthors(content: ProceedingsContent): Author[] {
    if (content.type != ProceedingsContentType.SUBMISSION && content.type != ProceedingsContentType.SESSION) {
      return;
    }

    if (content.type == ProceedingsContentType.SUBMISSION) {
      const submission = this.submissions.filter(s => s.id == content.submission.id)[0];
      return submission.authors;
    } else if (content.type == ProceedingsContentType.SESSION) {
      const session = this.sessions.filter(s => s.id == content.session.id)[0];
      let authors = [];
      session.submissions.forEach(s => {
        authors = authors.concat(s.authors);
      });

      return authors;
    }
  }

}
