import { BaseModel } from './baseModel';
import { SubmissionStep } from './submissionStep';
import { Type } from 'class-transformer';

export class EventSubmissionStep extends BaseModel {
  @Type(() => SubmissionStep)
  public submissionStep: SubmissionStep;

  constructor(
    public optional: boolean = true,
    public order: number
  ) { super(); }

  get name() {
    return this.submissionStep.name;
  }
}
