import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { SubmissionsService } from '../../service/submissions.service';
import { Location } from '@angular/common';
import { ReviewsService } from '../../service/reviews.service';
import { CardViewComponent } from '../card-view/card-view.component';
import { InfoService } from '../../service/info.service';
import { TranslateService } from '@ngx-translate/core';
import { Track } from '../../model/track';
import { Review } from '../../model/paper';
import { Form } from 'src/app/model/form';
import { EventsService } from 'src/app/service/events.service';
import { Submission } from '../../model/paper';
import { NavbarService } from 'src/app/service/navbar.service';
import { ReviewStatus } from 'src/app/enum/review.status';
import { NotificationService } from 'src/app/service/notification.service';


@Component({
  selector: 'app-submission-form-view',
  templateUrl: './submission-form-view.component.html',
  styleUrls: ['./submission-form-view.component.scss', '../card-view/card-view.component.scss']
})
export class SubmissionFormViewComponent extends CardViewComponent implements OnInit {
  constructor(
    router: Router, route: ActivatedRoute,
    submissionService: SubmissionsService, location: Location,
    infoService: InfoService, reviewsService: ReviewsService,
    private translateService: TranslateService, private eventsService: EventsService,
    private navbarService: NavbarService,
    private notificationService: NotificationService
  ) {
    super(router, route, submissionService, location, infoService, reviewsService);
  }

  submission: Submission;
  permissionChecked = false;
  submitButtonDisabled = true;
  public form: Form;
  public controller: FormGroup = new FormGroup({});

  ngOnInit() {
    setTimeout(() => {
      this.navbarService.loadingStart();

      this.route.queryParams.subscribe(({ refreshCache }) => {
        this.submissionService
          .getSubmission(parseInt(this.route.snapshot.paramMap.get('id'), 10), refreshCache)
          .subscribe(submission => {
            this.eventsService
            .getEventByTrack(submission.trackID)
            .subscribe(event => {
                this.submission = submission;
                this.submission.event = event;
                this.submission.track = event.tracks.find(track => submission.trackID === track.id);

                const formSubmission = submission.formSubmission;
                if (formSubmission) {
                  this.form = formSubmission;
                  if (formSubmission.fields.length > 0) {
                    this.submitButtonDisabled = false;
                  }
                }
                this.navbarService.loadingStop();
                // Se tem um evento associado e o evento da submissão for diferente do associado.
                const parentEvent = Number(this.route.parent.snapshot.params.eventId);
                if (parentEvent && parentEvent !== submission.event.id) {
                  this.router.navigate(['/error/403']);
                }
                this.checkReviewerPermission(submission);

                this.loaded = true;
              });
          });
      });
    });
  }

  submit(): void {
    let notifyStartMessage = 'submissions.edit.editing';
    let notifyStartMessageParams = { id: this.submission.id };
    let notifySuccessMessage = 'submissions.edit.edited';
    this.notificationService.notify(notifyStartMessage, { params: notifyStartMessageParams });
    this.submitButtonDisabled = true;

    let formAnswers = [];
    if (this.submission?.formSubmission) {
      formAnswers = this.submission.formSubmission.formAnswers(this.controller);
    }
    this.submission.formAnswer = formAnswers;
    this.submissionService.editSubmission(this.submission.data(), this.submission.id).subscribe(submission => {
      this.notificationService.notify(notifySuccessMessage, { params: { id: submission.id } });
      this.submitButtonDisabled = false;
    });
  }

  goToSubmission(): void {
    this.router.navigate(['/submissions/', this.submission.id]);
  }

  checkReviewerPermission(submission: Submission){
    this.reviewsService.getReviewsBySubmissionId(this.submission.id).subscribe(reviews => {
      reviews.some((review: Review) => {
        if (review.userId === this.infoService.user.id && review.status ===  ReviewStatus.NOTIFIED) {
          this.router.navigate(['/confirm/reviewSubmission', review.id]);
        }
      });
    }, () => {},
    () => {
      this.permissionChecked = true;
    });
  }
}
