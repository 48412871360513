import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FilesService } from 'src/app/service/files.service';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { NotificationService } from 'src/app/service/notification.service';
import { NavbarService } from 'src/app/service/navbar.service';
import { SelectOption } from 'src/app/model/select.option';
import { zip, Observable, Subscription, of, forkJoin } from 'rxjs';
import { Event } from 'src/app/model/event';

@Component({
  selector: 'app-submission-files',
  templateUrl: './submission-files.component.html',
  styleUrls: ['./submission-files.component.scss']
})
export class SubmissionFilesComponent implements OnInit,  OnDestroy{

  event: Event;
  public form: FormGroup;

  public trackValues: Array<SelectOption> = [];
  public trackFilesValues: Array<SelectOption> = [];

  public loadingLink = false;

  public link;
  subscriptions: Subscription[] = [];

  constructor(
    public adminService: AdminService,
    private eventsService: EventsService,
    private fb: FormBuilder,
    private filesService: FilesService,
    private navbarService: NavbarService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.navbarService.loadingStart();

    if (!this.event) {
      this.adminService.getEvent().subscribe(event => {
        this.event = event;
        this.initForm();
      });
    } else {
      this.initForm();
    }
  }

  ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.navbarService.loadingStop();
  }

  initForm(): void {
    this.trackValues = this.event.tracks.map(t => ({
      id: t.id,
      value: t.name
    }));

    this.form = this.fb.group({
      track: [undefined, Validators.required],
      trackFiles: [[], Validators.required]
    });

    this.form.get('track').valueChanges.subscribe(change => {
      this.updateTrackFilesValues(change);
    });
    this.navbarService.loadingStop();
  }

  updateTrackFilesValues(track): void {
    const track_selected = this.event.tracks.find(t => t.id === track);
    if (track_selected?.trackFiles) {
      this.trackFilesValues = track_selected.trackFiles.map(t => new SelectOption(t.id, t.name));
    }
  }

  getLink() {
    const trackSelected = this.form.value.track;
    const trackFilesSelected = this.form.value.trackFiles;
    this.navbarService.loadingStart();
    this.loadingLink = true;
    this.subscriptions.push(this.eventsService.getSubmissionFilesByTrack(trackSelected, trackFilesSelected).subscribe(
      response => {
        this.link = response.link;
        this.loadingLink = false;
      }, error => {
        this.notificationService.notifyError('errors.error-create-link');
        this.loadingLink = false;
      }, () => {
        let downloadURL = this.link
        let contentType = 'application/zip';
        let name = this.event.acronym + '-Track-' + this.event.tracks.find(t => t.id === trackSelected).name + '_Files'
        this.subscriptions.push(this.filesService.downloadTrackFiles(this.link).subscribe((data) => {
          let blob = new Blob([data], {type: contentType});
          downloadURL = URL.createObjectURL(blob);
        }, () => {},
        () => {
          this.navbarService.loadingStop();
          this.downloadLink(downloadURL, name);
        }));
      }));
  }

  downloadLink(downloadURL: string, name: string) {
    // Create link to lownload
    var link = document.createElement('a');
    link.href = downloadURL;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.download = name;
    link.click();
  }
}
