export interface PaginationOptions {
  pageNumber?: number;
  pageSize?: number;
  show?: 'active' | 'all';
}

export const DEFAULT_PAGINATION_OPTIONS: PaginationOptions = {
  pageNumber: 1,
  pageSize: 10,
  show: 'all'
};

export const SHOW_ALL_PAGINATION_OPTIONS: PaginationOptions = {
  pageNumber: 0,
  pageSize: 0,
  show: 'all'
};

export const NO_PAGINATION: PaginationOptions = {
  pageNumber: 0,
  pageSize: 0
};
