import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemService } from '../../service/system.service';
import { UserSearch } from '../../model/user.search';
import { SearchResult } from '../../model/search.result';
import { plainToClass } from 'class-transformer';
import { NavbarService } from '../../service/navbar.service';
import { SidebarService } from '../../service/sidebar.service';
import { FixedTopProgressBarComponent } from '../../component/fixed-top-progress-bar/fixed-top-progress-bar.component';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {
  results: SearchResult;
  selectedIndex: number;
  progressBar: FixedTopProgressBarComponent;

  TABS_INDEXES = {
    SUBMISSIONS: 0,
    REVIEWS: 1,
    COMMITTEES: 2,
    CHAIRS: 3,
    EVENTS: 4
  };

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private systemService: SystemService,
    private navbarService: NavbarService,
    private sidebarService: SidebarService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.selectedIndex = this.route.snapshot.queryParamMap['params'].where === 'all' ? 0 : -1;

    this.progressBar = this.navbarService.progressBar;

    this.sidebarService.close({});
    if (this.navbarService instanceof NavbarService) {
      const navbar = this.navbarService;
      navbar.changeBehavior({
        icon: 'fa-arrow-left',
        onClick: () => {
          this.router.navigateByUrl('/submissions');
          navbar.reset();
        }
      });
    }

    this.search();
  }

  search(): void {
    this.navbarService.loadingStart();

    this.route.queryParams.subscribe(params => {
      this.systemService.search( plainToClass(UserSearch, params as UserSearch) )
        .subscribe(res => {
          this.results = res;

          this.navbarService.loadingStop();
        });
    });
  }
}
