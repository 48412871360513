import { Transform } from 'class-transformer';
import { User } from './user';
import { Submission } from './paper';
import { BaseModel, toDayjs } from './baseModel';
import { Type } from 'class-transformer';
import * as dayjs from 'dayjs';

export class Session extends BaseModel {

  @Transform(toDayjs)
  public start: dayjs.Dayjs;

  @Transform(toDayjs)
  public end: dayjs.Dayjs;

  @Type(() => User)
  chair: User; 

  @Type(() => Submission)
  submissions: Array<Submission>;

  constructor(
    public event: number,
    public title: string,
    public subtitle: string,
    public url: string,
    public room: string,
    public backgroundColor: string
  ) { super(); }

}
