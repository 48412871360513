import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Route } from '@angular/router';
import { SidebarService } from '../../../service/sidebar.service';
import { EventsService } from '../../../service/events.service';
import { Event } from '../../../model/event';
import { AdminService } from '../../../service/admin.service';
import { SidebarItem, AdminSidebarItem } from '../../../model/sidebar-item';
import { NavbarService } from '../../../service/navbar.service';
import { Location } from '@angular/common';
import { FixedTopProgressBarComponent } from 'src/app/component/fixed-top-progress-bar/fixed-top-progress-bar.component';

@Component({
  selector: 'app-admin-submit-select-track',
  templateUrl: './admin-submit-select-track.component.html',
  styleUrls: ['./admin-submit-select-track.component.scss']
})
export class AdminSubmitSelectTrackComponent implements OnInit {
  event: Event;
  eventId: number;
  sidebarItems: Array<SidebarItem>;
  bottomItems: Array<SidebarItem>;
  @ViewChild(FixedTopProgressBarComponent) progressBar: FixedTopProgressBarComponent;

  constructor(
    private sidebarService: SidebarService,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    setTimeout( () => {
      this.adminService.progressBar.start();

      this.route.params.subscribe(({eventId}) => {
        const id: number = parseInt(eventId, 10);

        this.event = this.adminService.selectedEvent;
        if (!this.event) {
          this.adminService.getEvent().subscribe(event => {
            this.event = event;
            this.adminService.progressBar.stop();
          });
        } else {
          this.adminService.progressBar.stop();
        }
      });
    });
  }
}
