import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() message: string;
  @Input() includeOld: string
  @Input() toggle = false;
  @Output() updateToggle = new EventEmitter();
}
