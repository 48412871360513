import { Injectable } from '@angular/core';

import { Dictionary } from '../interface/idictionary';
import { Event } from '../model/event';
import { EventInfo } from '../model/eventInfo';
import { EventTopic } from '../model/eventTopic';
import { Form } from '../model/form';
import { Track } from '../model/track';
import { Observable, of as ObservableFrom } from 'rxjs';
import { Submission } from '../model/paper';
import { Review } from '../model/paper';
import { EventType } from '../model/eventType';
import { Workflow } from '../model/workflow';
import { Taxonomy } from '../model/taxonomy';
import { EventReviewConfiguration } from '../model/eventReviewConfiguration';
import { PaperGroup } from '../model/paper.group';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  events: Dictionary<Event> = new Dictionary<Event>();
  pagedEvents: Dictionary<Event[]> = new Dictionary<Event[]>();
  eventsByUserRole: Dictionary<Event[]> = new Dictionary<Event[]>();

  eventsByTrack: Dictionary<Event> = new Dictionary<Event>();

  eventsInfo: Dictionary<EventInfo> = new Dictionary<EventInfo>();

  eventTypes: Dictionary<EventType> = new Dictionary<EventType>();
  workflows: Dictionary<Workflow> = new Dictionary<Workflow>();

  topics: Dictionary<EventTopic> = new Dictionary<EventTopic>();
  tracks: Dictionary<Track> = new Dictionary<Track>();

  forms: Dictionary<Form> = new Dictionary<Form>();

  submissions: Dictionary<Submission> = new Dictionary<Submission>();

  reviews: Dictionary<Review> = new Dictionary<Review>();

  taxonomies: Dictionary<Taxonomy> = new Dictionary<Taxonomy>();

  reviewsConfiguration: Dictionary<EventReviewConfiguration> = new Dictionary<EventReviewConfiguration>();
  paperGroupByEvent: Dictionary<PaperGroup[]> = new Dictionary<PaperGroup[]>();

  constructor() { }

  getValue<T>(dict: Dictionary<T>, key: string): T {
    return dict.get(key);
  }

  setValue<T>(dict: Dictionary<T>, key: string, value: T): Map<string, T> {
    return dict.set(key, value);
  }

  deleteValue<T>(dict: Dictionary<T>, key: string): boolean {
    return dict.remove(key);
  }

  getAll<T>(dict: Dictionary<T>): Array<T> {
    return Array.from(dict.values());
  }

  setAll<T>(dict: Dictionary<T>, items: { key: string, value: T }[]): void {
    items.forEach(item => this.setValue(dict, item.key, item.value));
  }

  getValueAsObservable<T>(dict: Dictionary<T>, key: string): Observable<T> {
    return ObservableFrom(this.getValue(dict, key));
  }

  getAllAsObservable<T>(dict: Dictionary<T>): Observable<Array<T>> {
    return ObservableFrom(this.getAll(dict));
  }

  clear<T>(dict: Dictionary<T>): void {
    dict = new Dictionary<T>();
  }
}
