import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { SmallInfo } from 'src/app/service/dataset.service';

@Component({
  selector: 'app-reviews-small-table',
  templateUrl: './reviews-small-table.component.html',
  styleUrls: ['./reviews-small-table.component.scss']
})
export class ReviewsSmallTableComponent {
  @Input() reviews: Array<SmallInfo> = [];

  @Input() columns? = [];

  @Input() labelNoReview: string;

  constructor() { };
}
