import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  content: string;
  cancel: string;
  confirm: string;

  params?: {
    title?: any,
    content?: any,
    onlyOkButton?: boolean
  };
}

const justInformation = [
  'submissions.view.file-rules-card.details-dialog.title'
]

const useInnerContent = [
  'submissions.view.file-rules-card.details-dialog.title'
]

const messagesFormInvalidWithContent = [
  'messages.affiliation-not-found',
  'messages.country-not-found',
  'messages.country-affiliation-not-found',
]

const messagesFormInvalidWithNotContent = [
  'messages.profile-incomplete',
  'messages.affiliation-form-invalid',
  'errors.file.max-size'
]

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  notContentMessage: boolean = false;
  isMessageNotFoundInfo: boolean = false;
  useInnerContent = false;
  justInformation = false;

  dialogMessages: DialogData = {
    title: 'confirmation-dialog.title',
    content: 'confirmation-dialog.content',
    cancel: 'forms.buttons.cancel',
    confirm: 'forms.buttons.confirm',
    params: {}
  };

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    if (data) {
      Object.assign(this.dialogMessages, data);
    }
  }

  ngOnInit(): void {
    const titleMessage = this.dialogMessages.title;
    const onlyOkButton = this.dialogMessages.params?.onlyOkButton;

    if (onlyOkButton) {
      this.isMessageNotFoundInfo = true;
    } else {
      messagesFormInvalidWithContent.filter((message: string) => {
        if (titleMessage === message) {
          this.isMessageNotFoundInfo = true;
        }
      });
    }


    messagesFormInvalidWithNotContent.filter((message: string) => {
      if (titleMessage === message) {
        this.notContentMessage = true;
      }
    });

    justInformation.filter((message: string) => {
      if (titleMessage === message) {
        this.isMessageNotFoundInfo = false;
        this.justInformation = true;
      }
    });

    useInnerContent.filter((message: string) => {
      if (titleMessage === message) {
        this.useInnerContent = true;
      }
    });
  }
}
