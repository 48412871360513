import {
  Component, OnInit, ViewChild, HostListener, OnDestroy
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SubmissionsService } from '../../service/submissions.service';
import { CardViewComponent } from '../card-view/card-view.component';
import { ReviewsService } from '../../service/reviews.service';
import { NavbarService } from '../../service/navbar.service';
import { AuthService } from '../../service/auth.service';
import { InfoService } from '../../service/info.service';
import { Review} from '../../model/paper';
import { EventsService } from 'src/app/service/events.service';
import { CustomFormComponent } from '../custom-form/custom-form.component';
import { UserReview } from '../../model/user.review';
import { TranslateService } from '@ngx-translate/core';
import { ReviewStatus } from '../../enum/review.status';
import { ReviewsConfigurationService } from 'src/app/service/reviews.configuration.service';
import { EventReviewConfiguration } from 'src/app/model/eventReviewConfiguration';
import { NotificationService } from '../../service/notification.service';
import { Event } from 'src/app/model/event';
import { Track } from 'src/app/model/track';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-review-form',
  templateUrl: './review-form.component.html',
  styleUrls: ['./review-form.component.scss', '../card-view/card-view.component.scss']
})
export class ReviewFormComponent extends CardViewComponent implements OnInit, OnDestroy {
  @ViewChild('formContainer', { read: CustomFormComponent })
  public formContainer: CustomFormComponent;

  public review: Review;
  public form: FormGroup;

  private autosave: NodeJS.Timeout;

  public reviewConfig: EventReviewConfiguration;

  public userIsChair = false;
  public reviewBlocked = false;
  public userIsReviewer = false;
  public userCanAssignReviewers = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public infoService: InfoService,
    private fb: FormBuilder,
    private navbarService: NavbarService,
    private eventsService: EventsService,
    private translateService: TranslateService,
    public authService: AuthService,
    private notification: NotificationService,
    protected reviewsService: ReviewsService,
    protected reviewConfigurationService: ReviewsConfigurationService,
    protected submissionService: SubmissionsService, location: Location,
    private notificationService: NotificationService
  ) {
    super(router, route, submissionService, location, infoService, reviewsService);
  }

  ngOnInit(): void {
    this.navbarService.loadingStart();
    setTimeout(() => {
      this.initForm();
      this.loadReview();
    });
  }

  private loadReview() {
    setTimeout(() => {
      this.navbarService.loadingStart();

      this.reviewsService.getReview(Number(this.route.snapshot.paramMap.get('id'))).subscribe(review => {
        if (review.form) {
          this.setupReview(review);
        } else {
          this.reviewsService.getReview(Number(this.route.snapshot.paramMap.get('id')), true).subscribe(forcedreview => {
            this.setupReview(forcedreview);
          });
        }
      });
    });
  }

  private reloadReview(){
    if (this.userIsChair) {
        this.router.navigate(['/submissions/', this.review.submission.id]);
    } else {
      if (this.review.submission.event){
        this.router.navigate(['/events/', this.review.submission.event.id]);
      } else {
        this.router.navigate(['/reviews']);
      }
    }
  }

  setupReview(review: Review) {
    this.eventsService.getEventAndTrackByTrackID(review.submission.trackID).subscribe((result: { event: Event, track: Track }) => {
      review.submission.event = result.event;
      review.submission.track = result.track;

      this.authService.userIsAdminOrChair(review.submission.event.id).subscribe(isAdminOrChair => {
        this.userIsChair = isAdminOrChair;
      }, () => {},
      () => {
        this.checkBlocked(review).subscribe(blocked => {
          if (blocked) {
            this.reviewBlocked = true;
            this.navbarService.loadingStop();
          } else {
            this.review = review;
            this.setupDraftAutoSave();
            this.navbarService.loadingStop();
          }
          this.setUserCanAssignReviewers();
        });
      });
    });
  }

  public setUserCanAssignReviewers(): void {
    this.userCanAssignReviewers = this.userIsChair;
    this.userIsReviewer = this.review.userId === this.infoService.user.id;
  }

  checkBlocked(review: Review): Observable<boolean> {
    return this.reviewConfigurationService.getReviewsConfiguration(review.submission.event.id).pipe(map(reviewConfig => {
      this.reviewConfig = reviewConfig;
      return review.isReviewBlocked(
        this.reviewConfig.blockReview,
        this.userIsChair,
        review.submission?.isAuthor(this.infoService.user.id)
      );
    }));
  }

  private initForm(): void {
    this.form = this.fb.group({});
  }

  private setupDraftAutoSave(): void {
    if (this.review.status !== ReviewStatus.COMPLETED) {
      this.autosave = setInterval(() => {
        this.draft();
      }, 1000 * 60);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  private draft(): void {
    if (this.form.dirty && this.form.valid) {
      this.navbarService.loadingStart();
      this.reviewsService.updateReview(new UserReview(this.review.id, ReviewStatus.DRAFT, this.formContainer.answers)).subscribe(() => {
        this.notification.notify('reviews.draft-saved', {
          onClose: () => {
            this.navbarService.loadingStop();
          }
        });
      });
    }
  }

  public submit(): void {
    if (this.form.valid) {
      this.navbarService.loadingStart();
      this.reviewsService.updateReview(new UserReview(this.review.id, ReviewStatus.COMPLETED, this.formContainer.answers)).subscribe(() => {
        this.navbarService.loadingStop();

        this.notificationService.notify('reviews.submitted');
        this.router.navigate(['/submissions/', this.review.submission?.id]);
      });
    }
  }

  public ngOnDestroy(): void {
    clearInterval(this.autosave);
  }
}
