import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ShowAllPagedViewDirective } from 'src/app/class/paged-view-all';
import { LocalCache } from 'src/app/enum/local.cache';
import { PreferencesService } from 'src/app/service/preferences.service';
import { PublicationService } from 'src/app/service/publication.service';
import { CheckListField } from 'src/app/model/checklist-field';
import { Event } from 'src/app/model/event';
import { EventsService } from 'src/app/service/events.service';
import { LazyTranslateLoader } from 'src/app/app.module';

@Component({
  selector: 'app-submission-fields-filter',
  templateUrl: './submission-fields-filter.component.html',
  styleUrls: ['./submission-fields-filter.component.scss']
})
export class SubmissionFieldsFilterComponent implements OnInit {
  @Input() public form: FormGroup;

  @Input() showPapersComponent: ShowAllPagedViewDirective;
  @Input() event: number;
  @Input() event_obj: Event;
  @Input() fieldsSaved: {};
  @Input() customCheckListFieldsSaved:string;
  @Input() customCheckListFields: CheckListField[] = [];
  
  @Input() page: string;
  @Output() fieldsShowChanged = new EventEmitter();

  customCheckListFieldsIds: string[] = [];
  lastFieldsShow: number = 0;
  hidedGroups: string[] = [];
  previousLastVisibleColumn = '';
  columnsVisibility = {}; 
  loadingCustomFields = true;
  specialFields = ['name', 'affiliation', 'country', 'email', 'abstract', 'topics', 'reviewerName' ];

  public groups = [
    {
      label: 'forms.fields.filters.submission',
      fields: [
        { label: 'forms.fields.filters.submission-number', control: 'id', name: 'submissionId' },
        { label: 'forms.fields.filters.submission-status', control: 'status', name: 'status'},
        { label: 'forms.fields.filters.submission-title', control: 'title', name: 'title' },
        { label: 'forms.fields.filters.submission-topics', control: 'submission-topics', name: 'topics' },
        { label: 'forms.fields.filters.submission-abstract', control: 'submission-abstract', name: 'abstract'},
        { label: 'forms.fields.filters.name-of-submission-group', control: 'submission-group', name: 'group'}
      ],
      control: 'submission'
    },
    {
      label: 'forms.fields.filters.author',
      fields: [
        { label: 'forms.fields.filters.author-names', control: 'authors-names', name: 'name' },
        { label: 'forms.fields.filters.author-affiliation', control: 'authors-affiliations', name: 'affiliation' },
        { label: 'forms.fields.filters.author-country', control: 'authors-countries', name: 'country' },
        { label: 'forms.fields.filters.author-emails', control: 'authors-emails', name: 'email' },
      ],
      control: 'author'
    },
    {
      label: 'reviews.event',
      fields: [
        { label: 'forms.fields.filters.name-of-event', control: 'submission-event', name: 'eventBool' },
        { label: 'forms.fields.filters.name-of-track', control: 'submission-track', name: 'track' }
      ],
      control: 'event'
    },
    {
      label: 'forms.fields.filters.score',
      fields: [
        { label: 'forms.fields.filters.reviewer-name', control: 'reviewer-name', name: 'reviewerName'},
        { label: 'forms.fields.filters.rebuttal', control: 'rebuttal', name: 'rebuttal'},
        { label: 'forms.fields.filters.number-of-discussion-messages', control: 'discussion-messages', name: 'discussionMessages' },
        { label: 'forms.fields.filters.number-of-reviews', control: 'submission-reviewnumber', name: 'reviews' },
        { label: 'forms.fields.filters.numeric-review-scores', control: 'score-numeric', name: 'numericReviewScores' },
        { label: 'forms.fields.filters.average-review-score', control: 'score-average', name: 'averageReviewScore' },
        { label: 'forms.fields.filters.span-max-min-scores', control: 'score-span', name: 'spanOfScores' },
      ],
      control: 'score'
    },
    {
      label: 'forms.fields.filters.others',
      fields: [
        { label: 'forms.fields.filters.files', control: 'others-files', name: 'files' },
        { label: 'forms.fields.filters.submission-form-items', control: 'others-form', name: 'submissionFormItems' },
      ],
      control: 'others'
    },
    {
      label: 'forms.fields.filters.publication',
      fields: [],
      control: 'publication'
    }
  ];

  constructor(
    private preferencesService: PreferencesService,
    private publicationService: PublicationService,
    private eventsService: EventsService,
    ) {}

  ngOnInit(): void {
    this.initPublicationFields();
  }

  initForm() {
    this.initSpecialFields();
    this.groups.forEach((g) => {
      g.fields.forEach((f) => {
        this.form.addControl(f.control, new FormControl(this.fieldsSaved[f.name]));
        this.preferencesService.changePreference(LocalCache.SUBMISSION_FILTER, this.showPapersComponent.fieldsShow.join(','), this.event);
      });
    });
    this.fieldsShowChanged.emit();
    this.initColumns();
    this.fixBorders();
  }

  initPublicationFields() {
    // let position = this.showPapersComponent.fieldsShow.length;
    const publicationGroup = this.groups.filter(group => group.label.endsWith('publication'))[0];
    if (this.event_obj.authorRegistrationEnable) {
      publicationGroup.fields.push(
        { label: 'forms.fields.filters.author-registration', control: 'author-registration', name: 'registration'}
      );
      this.showPapersComponent.fieldsShow.push('author-registration');
      // position = position + 1;
    }

    if (this.event_obj.copyrightEnable) {
      publicationGroup.fields.push(
        { label: 'forms.fields.filters.copyright', control: 'copyright', name: 'crVersion'}
      );
      this.showPapersComponent.fieldsShow.push('copyright');
    }
    this.initCustomFields();
  }

  initCustomFields() {
    // let position = this.showPapersComponent.fieldsShow.length;
    let customSaveds = this.customCheckListFieldsSaved.split(",");
    const publicationGroup = this.groups.filter(group => group.label.endsWith('publication'))[0];  
    this.lastFieldsShow = this.showPapersComponent.fieldsShow.length;    
    this.customCheckListFields.forEach(field => {
      const label = field.description;
      const control = "customField"+field.id.toString();
      publicationGroup.fields.push({ label: label, control: control, name: control });
      this.customCheckListFieldsIds.push(control);
      if (customSaveds.includes(field.id.toString())) {
        this.showPapersComponent.fieldsShow.push(control);
        this.fieldsSaved[control] = true;  // Fix?? 
      }
      this.form.addControl(control, new FormControl(this.showPapersComponent.fieldsShow.includes(control)));
    });
    if (publicationGroup.fields.length === 0) {
      this.hidedGroups.push(publicationGroup.control);
    }
    this.loadingCustomFields = false;
    this.initForm();
  }

  private initColumns(): void {    
    const fields = Object.keys(this.fieldsSaved);
    fields.forEach((field) => {
    if (field !== 'event') {
        this.filterColumn(field, this.fieldsSaved[field])
      }
    });
  }

  private filterColumn(column: string, controlMarked:boolean): void {
    if (controlMarked) {
      this.setCSSVariable(column, 'table-cell');
    }
    else {
      this.setCSSVariable(column, 'none');
    }
  }

  public fixBorders() {
    const lastVisibleColumn = this.getLastVisibleColumn();      
    if (this.previousLastVisibleColumn !== lastVisibleColumn || this.customCheckListFieldsIds.includes(lastVisibleColumn)) {
      if (!this.previousLastVisibleColumn?.includes('customField')) this.addBorders(this.previousLastVisibleColumn);
      this.removeBorders(lastVisibleColumn);
    }
    this.previousLastVisibleColumn = lastVisibleColumn;
  }

  private addBorders(column: string) {
    let elements = document.getElementsByClassName(column);    
    for (let count = 0; count < elements.length; count++) {
      const element = elements[count] as HTMLElement;
      element.style.borderRight = '1px solid white';
    }
  }

  private removeBorders(column: string) {
    let elements = document.getElementsByClassName(column);
    if (elements.length == 0) {  // Fix TODO
      setTimeout( () =>
        this.removeBorders(column)
      , 100); 
    } else {
      for (let count = 0; count < elements.length; count++) {
        const element = elements[count] as HTMLElement;
        element.style.borderRight = '0px';
      }
    }
  }

  private getLastVisibleColumn(): string {
    const columns = Object.keys(this.columnsVisibility);
    const values = Object.values(this.columnsVisibility);  
    return columns[values.lastIndexOf(true)];
  }

  private listenToFieldChanges(fieldName: string) {   
    let fieldMarked = this.getCSSVariable(fieldName) === 'table-cell';
    fieldMarked ? this.setCSSVariable(fieldName, 'none') : this.setCSSVariable(fieldName, 'table-cell');
    fieldMarked = !fieldMarked;
 
    if (this.specialFields.includes(fieldName)) {
      this.setSpecialFields(fieldName);
    } 

    // Fix with css class approach
    if (this.customCheckListFieldsIds.includes(fieldName)) {
      // CustomCheck list, hardRemove to fieldsShow
      let index = this.showPapersComponent.fieldsShow.findIndex(c => c === fieldName);
      if (index >= 0) {
        this.showPapersComponent.fieldsShow.splice(index, 1);
      } else {
        const indexCustomCheckList = this.customCheckListFieldsIds.indexOf(fieldName);
        index = this.lastFieldsShow + indexCustomCheckList;
        this.showPapersComponent.fieldsShow.splice(index, 0, fieldName);
      }
      this.fieldsShowChanged.emit();
    }
    this.fixBorders();
    this.saveFieldChanges(fieldName, fieldMarked);
  }
   
  private saveFieldChanges(fieldName: string, controlMarked: boolean): void {
    const changes = {};
    
    if (this.customCheckListFieldsIds.includes(fieldName)) {
      let fieldId = fieldName.split("customField")[1];
      let customSaveds = this.customCheckListFieldsSaved.split(",");
      if (customSaveds.includes(fieldId.toString()) && !controlMarked) {
        let index = customSaveds.indexOf(fieldId.toString());
        customSaveds.splice(index,1);
      }
      if (!customSaveds.includes(fieldId.toString()) && controlMarked) {        
        customSaveds.push(fieldId.toString());
      }
      this.customCheckListFieldsSaved = customSaveds.sort().join(',');
      changes["customFields"] = this.customCheckListFieldsSaved;
    } else {
      changes[fieldName] = controlMarked;
    }
    this.eventsService.putSubmissionFieldsAndFilters(this.event, changes).subscribe(() => {});
   }

  private initSpecialFields(): void {
    this.showPapersComponent.showName = this.fieldsSaved['name'];
    this.showPapersComponent.showAffiliation = this.fieldsSaved['affiliation'];
    this.showPapersComponent.showCountry = this.fieldsSaved['country'];
    this.showPapersComponent.showEmail = this.fieldsSaved['email'];
    this.showPapersComponent.showAbstract = this.fieldsSaved['abstract'];
    this.showPapersComponent.showTopics = this.fieldsSaved['topics'];
    this.showPapersComponent.showReviewerName = this.fieldsSaved['reviewerName'];
  }

  private setSpecialFields(name: string): void {
    const value = this.getCSSVariable(name) === 'table-cell';

    name === 'name' ? this.showPapersComponent.showName = value: null;
    name === 'affiliation' ? this.showPapersComponent.showAffiliation = value : null;
    name === 'country' ? this.showPapersComponent.showCountry = value : null;
    name === 'email' ? this.showPapersComponent.showEmail = value : null;
    name === 'abstract' ? this.showPapersComponent.showAbstract = value : null;
    name === 'topics' ? this.showPapersComponent.showTopics = value : null;
    name === 'reviewerName' ? this.showPapersComponent.showReviewerName = value : null;
  }

  private getCSSVariable(column: string): string {
    column = '--column-display-' + column;
    return getComputedStyle(document.documentElement).getPropertyValue(column);
  }

  private setCSSVariable(column: string, value: string): void {
    if (!this.specialFields.includes(column)) {
      value === 'table-cell' ? this.columnsVisibility[column] = true : this.columnsVisibility[column] = false;
    }
    const aux = column;
    column = '--column-display-' + column;
    document.documentElement.style.setProperty(column, value);
  }

}
