import { Component, OnInit } from '@angular/core';
import { EventRequest } from 'src/app/model/eventRequest';
import { NotificationService } from 'src/app/service/notification.service';
import { FormGroup, ValidationErrors, FormBuilder } from '@angular/forms';
import { EventsService } from 'src/app/service/events.service';
import { NavbarService } from '../../service/navbar.service';
import { Router } from '@angular/router';
import { EventRequestStatus } from '../../enum/event.request.status';
import { SelectOption } from '../../model/select.option';
import { Event } from 'src/app/model/event';

@Component({
  selector: 'app-admin-event-requests',
  templateUrl: './event-requests.component.html',
  styleUrls: ['./event-requests.component.scss']
})
export class AdminEventRequestsComponent implements OnInit {
  requests: Array<EventRequest> = [];
  filter: FormGroup;
  eventRequestStatus: Array<SelectOption>;

  constructor(
    private notificationService: NotificationService,
    private navbarService: NavbarService,
    private eventsService: EventsService,
    private fb: FormBuilder
  ) {
    this.eventRequestStatus = EventRequestStatus.toSelectable();
  }

  ngOnInit() {
    this.filter = this.fb.group({
      status: [EventRequestStatus.PENDING]
    });

    this.eventsService.getAllEventRequests().subscribe(requests => {
      this.requests = requests;
    });
  }

  get baseUrl(): string {
    return window.location.origin;
  }

  requestLink(request: EventRequest) {
    return `/admin/event/request/${request.id}`;
  }

  filterByStatus(status: EventRequestStatus): void {
    this.navbarService.loadingStart();
    this.eventsService.getAllEventRequests(status).subscribe(requests => {
      this.requests = requests;
      this.navbarService.loadingStop();
    });
  }
}
