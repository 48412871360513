import { Answer } from './answer';
import { Dayjs } from 'dayjs';
import { ReviewStatus } from '../enum/review.status';
import { Type } from 'class-transformer';

export class UserReview {
  public assignedBy: number;
  public dueAt: Dayjs;

  constructor(
    public id: number,
    public status: ReviewStatus,
    public answers: Array<Answer> = []
  ) { }
}
