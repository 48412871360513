import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { File } from 'src/app/model/file';
import { ProceedingsContent } from 'src/app/model/proceedingsContent';
import { Session } from 'src/app/model/session';
import { NavbarService } from 'src/app/service/navbar.service';
import { PublicationService } from 'src/app/service/publication.service';

@Component({
  selector: 'app-proceeding-content-item',
  templateUrl: './proceeding-content-item.component.html',
  styleUrls: ['./proceeding-content-item.component.scss']
})
export class ProceedingContentItemComponent {

  @Input() item: ProceedingsContent;
  @Input() included: boolean;
  @Input() fileUploadPercent: number;
  @Input() last: boolean;

  @Output() deleteContentItem: EventEmitter<any> = new EventEmitter();

  subscriptions: Subscription[] = [];

  constructor(
    private publicationService: PublicationService,
    private navbarService: NavbarService
  ) { }


  getSessionBackgroundColor(session: Session) {
    if (!session) return;
    return session.backgroundColor;
  }

  downloadFile(item: ProceedingsContent) {
    const file = item.file;
    let downloadURL = file.url;

    this.navbarService.loadingStart();
    const contentType = 'application/pdf';

    this.subscriptions.push(this.publicationService.downloadProceedingsFile(file.id, item.eventId).subscribe(data => {
      let blob = new Blob([data], {type: contentType});
      downloadURL = URL.createObjectURL(blob);
    }, () =>{
      this.navbarService.loadingStop();
    }, () => {
      this.navbarService.loadingStop();
      this.downloadLink(downloadURL, file.name);
    }));

    // this.publicationService.downloadProceedingsFile(file.id, item.eventId).subscribe();
  }

  downloadLink(downloadURL: string, fileName: string) {
    // Create link to lownload
    var link = document.createElement('a');
    link.href = downloadURL;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.download = fileName;
    link.click();
  }

  formatFileName(file: File) {
    return file.name.replace('.pdf', '').replace('api', '');
  }

  deleteItem() {
    this.deleteContentItem.emit();
  }

}
