import { BaseModel } from './baseModel';

export class Affiliation extends BaseModel {
  public _acronym: string;
  public filter: string;

  constructor(
    public name: string,
  ) { super(); }

  get acronym() {
    if (this._acronym === 'undefined' || !this._acronym) {
      return this.name;
    }
    return this._acronym;
  }

  set acronym(value: string) {
    this._acronym = value;
  }
}
