import { SelectOption } from '../model/select.option';

export enum ProfileTitle {
  '' = 'profile.honorific.none',
  MR = 'profile.honorific.mr',
  MS = 'profile.honorific.ms',
  MRS = 'profile.honorific.mrs',
  DR = 'profile.honorific.dr',
  PROF = 'profile.honorific.prof'
}

export namespace ProfileTitle {
  export function toIterable(): Array<string> {
    return Object.keys(ProfileTitle).filter(key => typeof ProfileTitle[key] !== 'function');
  }

  export function toSelectable(): Array<SelectOption> {
    return ProfileTitle.toIterable().map(value => ({
      id: value,
      value: ProfileTitle[value]
    }));
  }
}
