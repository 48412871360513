import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  content: string;
  cancel: string;
  confirm: string;

  params?: {
    title?: any,
    content?: any
  };
}

@Component({
  selector: 'app-review-confirmation-dialog',
  templateUrl: './review-confirmation-dialog.component.html',
  styleUrls: ['./review-confirmation-dialog.component.scss']
})
export class ReviewConfirmationDialogComponent {
  dialogMessages: DialogData = {
    title: 'confirmation-dialog.title',
    content: 'confirmation-dialog.content',
    cancel: 'forms.buttons.cancel',
    confirm: 'forms.buttons.confirm',
    params: {}
  };

  constructor(
    public dialogRef: MatDialogRef<ReviewConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    if (data) {
      Object.assign(this.dialogMessages, data);
    }
  }
}
