import { User } from './user';

export class UserNotification extends User {
  public chair: boolean;
  public triggers: Array<number>;

  includeTrigger(trigger_id: number): boolean {
    return this.triggers.includes(trigger_id);
  }
}
