import { Component, Input } from '@angular/core';
import * as dayjs from 'dayjs';
import { Event } from '../../model/event';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})

export class EventComponent {
  @Input() event: Event;
  @Input() showEventAdminButton = false;

  today() {
    return dayjs(new Date());
  }

  get baseUrl(): string {
    return window.location.origin;
  }
}
