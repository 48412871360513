import { Component, Input, OnInit } from '@angular/core';
import { EventTopic } from '../../model/eventTopic';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class TopicsComponent implements OnInit {
  @Input() topics: Array<EventTopic | number>;

  constructor(private eventsService: EventsService) {}

  ngOnInit() {
    this.topics.forEach((topic, index) => {
      if (typeof topic === 'number') {
        this.eventsService.getTopic(topic).subscribe(foundTopic => this.topics[index] = foundTopic);
      }
    });
  }
}
