import { Component, Input } from '@angular/core';
import { ProceedingsIndex } from '../proceeding-step/proceeding-step.component';
import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-proceeding-index-item',
  templateUrl: './proceeding-index-item.component.html',
  styleUrls: ['./proceeding-index-item.component.scss']
})
export class ProceedingIndexItemComponent {

  @Input() indexList: ProceedingsIndex[];
  @Input() indexForm: FormGroup;

  constructor() {}

  getField(name: string): FormControl {
    return this.indexForm.get(name) as FormControl;
  }

}
