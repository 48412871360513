import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Notification } from '../../model/notification';

@Component({
  selector: 'app-notification-snackbar',
  templateUrl: './notification-snackbar.component.html',
  styleUrls: ['./notification-snackbar.component.scss']
})
export class NotificationSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public notification: Notification,
    @Inject(MatSnackBarRef) public snackbar: MatSnackBarRef<NotificationSnackbarComponent>
  ) { }

  handleClick() {
    this.notification.read = true;
  }
}
