import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../service/admin.service';
import { SubmissionsService } from '../../../service/submissions.service';
import { Submission } from '../../../model/paper';
import { SubmissionStatus } from '../../../enum/submission.status';
import { PagedViewDirective } from '../../../class/paged-view';
import { PreferencesService } from 'src/app/service/preferences.service';
import { LocalCache } from 'src/app/enum/local.cache';

@Component({
  selector: 'app-event-submissions',
  templateUrl: './event-submissions.component.html',
  styleUrls: ['./event-submissions.component.scss']
})
export class EventSubmissionsComponent extends PagedViewDirective implements OnInit {
  public filterNotification: boolean = this.checkedFilterNotification;

  constructor(
    protected adminService: AdminService,
    protected preferencesService: PreferencesService,
    private submissionsService: SubmissionsService
  ) {
    super(adminService, preferencesService);
  }

  ngOnInit() {
    setTimeout(() => {
      this.adminService.progressBar.start();

      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.load();
        });
      } else {
        this.load();
      }
    });
  }

  load() {
    this.getSubmissions();
  }

  get submissions(): Array<Submission> {
    return <Array<Submission>> this.elements;
  }

  getSubmissions(): void {
    this.adminService.progressBar.start();

    this.fieldsShow = this.checkedFieldsShow;

    this.submissionsService.getPagedSubmissionsByEvent(this.event.id, this.paginationOptions, this.filter).subscribe(pagination => {
      this.pagination = pagination;
      this.unfilteredElements = this.pagination.items as Submission[];
      this.filterPapers(<Submission[]>this.unfilteredElements);
      this.adminService.progressBar.stop();
      window.scroll(0, 0);
    });
  }

  get checkedFilterNotification(): boolean {
    return this.submissionStatusFields.length > this.cachedStatusFields.length ? true : false;
  }

  get cachedStatusFields(): Array<string> {
    const cachedStatus = this.preferencesService.getPreference(LocalCache.STATUS_SUBMISSIONS);
    if (cachedStatus === undefined || cachedStatus === false) {
      // No cached value defined. Set all fields as checked.
      return this.submissionStatusFields;
    } else if (cachedStatus === '') {
      // Cached value is defined with no status marked.
      return [];
    }
    // Return array of string status that are marked.
    return (<string>cachedStatus).split(',');
  }

  get submissionStatusFields(): Array<string> {
    return SubmissionStatus.toIterable();
  }

  getFilterNotification(status: boolean) {
    this.filterNotification = status;
  }
}
