import { Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { FormType } from 'src/app/enum/form.type';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Event } from '../../../../../model/event';
import { CustomFormComponent } from '../../../../../component/custom-form/custom-form.component';
import { Form } from 'src/app/model/form';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-preview-form',
  templateUrl: './preview-form.component.html',
  styleUrls: ['./preview-form.component.scss']
})
export class PreviewFormComponent implements OnInit {
  @ViewChild('formContainer', { static: false })
  public formContainer: CustomFormComponent;
  public controller: FormGroup;
  public form: Form;
  public canIEdit:Boolean = false;

  public event: Event;
  public type: FormType;

  constructor(
    private adminService: AdminService,
    private eventService: EventsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.event = this.adminService.selectedEvent;
      this.type = <FormType>params.get('type').toUpperCase();

      if (!FormType[this.type]) {
        this.router.navigate(['/404']);
      } else if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.getForm(this.event.id);
        });
      } else {
        this.getForm(this.event.id);
      }
    });
  }

  private getForm(id: number): void {
    this.eventService.getForm(this.type, id).subscribe(form => {
      if (form) {
        if (form.fields.length > 0) {
          this.form = form;
        } else {
          this.router.navigate(['./create'], { relativeTo: this.route });
        }
      } else {
        this.router.navigate(['./create'], { relativeTo: this.route });
      }
    }, error => {
      this.router.navigate(['./create'], { relativeTo: this.route });
    });
  }

  public get existAnswer(): boolean {
    return this.form.fields.some(field => field.answers.length > 0);
  }

  public get title(): string {
    let formType;
    if (this.form.isReview()) {
      formType = 'review';
    } else if (this.form.isSubmission()) {
      formType = 'submission';
    } else {
      formType = 'no-form';
    }
    return `admin.event.settings.forms.title-${formType}`;
  }
}
