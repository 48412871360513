import { Injectable } from '@angular/core';
import { Environment } from '../environment/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '../model/response';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/model/user';

import { Transform, Type } from 'class-transformer';
import * as dayjs from 'dayjs';
import { toDayjs } from '../model/baseModel';
import { SubmissionStatus } from '../enum/submission.status';
import { ReviewStatus } from '../enum/review.status';

export class SmallSubmission {
  public id: number;
  public title?: string;
  public status: SubmissionStatus;
}

export class SmallEvent {
  public id: number;
  public acronym: string;
  public year: number;

  @Transform(toDayjs)
  public endAt?: dayjs.Dayjs;
}

export class SmallReview {
  public id: number;
  public status: ReviewStatus;

  public submission: SmallSubmission;
  public event: SmallEvent;

  @Transform(toDayjs)
  public dueAt: dayjs.Dayjs;

  @Type(() => User)
  public user: User;

  get late(): boolean {
    const d = new Date();
    return this.dueAt.isBefore(d);
  }

  get upcoming(): boolean {
    const d = new Date();
    return this.dueAt.subtract(1, 'week').isBefore(d);
  }

  get textTooltip(): string {
    if (this.late) {
      return 'user-dashboard.legend.late';
    } else if (this.upcoming) {
      return 'user-dashboard.legend.upcoming';
    } else {
      return '';
    }
  }
}

export class SmallInfo {
  public id: number;
  public name: string;
  public hasFile?: boolean;

  public submission: SmallSubmission;
  public event: SmallEvent;

  @Transform(toDayjs)
  public closeAt: dayjs.Dayjs;

  get late(): boolean {
    const d = new Date();
    return this.closeAt.isBefore(d);
  }

  get upcoming(): boolean {
    const d = new Date();
    return this.closeAt.subtract(1, 'week').isBefore(d);
  }

  get textTooltip(): string {
    if (this.late) {
      return 'user-dashboard.legend.late';
    } else if (this.upcoming) {
      return 'user-dashboard.legend.upcoming';
    } else {
      return '';
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class DatasetService {

  constructor(
    private http: HttpClient
  ) { }

  reviewsByDueAt(userId: number): Observable<SmallReview[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/dataset/${userId}/reviewsByDueAt`)
      .pipe(map(v => plainToClass(SmallReview, v.data as Array<SmallReview>)));
  }

  submissionByTrackEnd(userId: number): Observable<SmallInfo[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/dataset/${userId}/submissionByTrackEnd`)
      .pipe(map(v => plainToClass(SmallInfo, v.data as Array<SmallInfo>)));
  }

  submissionByTrackFileEnd(userId: number): Observable<SmallInfo[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/dataset/${userId}/submissionByTrackFileEnd`)
      .pipe(map(v => plainToClass(SmallInfo, v.data as Array<SmallInfo>)));
  }
}
