import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment-timezone';
import { TimezoneService } from 'src/app/service/timezone.service';
import { DateTimePickerHandler } from 'src/app/class/date-time-picker-handler';

class timeZoneInput {
  name: string;
  mask?: string;
}

@Component({
  selector: 'app-time-zone-input',
  templateUrl: './time-zone-input.component.html',
  styleUrls: ['./time-zone-input.component.scss']
})
export class TimeZoneInputComponent implements OnInit {

  @Input() fontSet: string = 'far';
  @Input() public eventTimeZone: string;
  @Input() public form: FormGroup;
  @Input() public controller: string;
  @Input() DateTimePickerHandler: DateTimePickerHandler;
  public displayedColumns: string[] = ['name', 'time', 'select'];
  public timeZones: timeZoneInput[] = [];
  public selectedTimeZone: string;
  public radioSelectedTimeZone: string;

  constructor(
    public tzService: TimezoneService
  ) { }

  ngOnInit(): void {
    this.initTimeZonesOptions();
  }

  private initTimeZonesOptions(): void {
    const browserTimeZone = moment.tz.guess(true);
    const eventTimeZone = this.getEventTimeZone(browserTimeZone);
    const UTCTimeZone = 'Greenwich';
    const IDLWTimeZone = 'Etc/GMT+12'; // Etc/GMT+12 means GMT-12, Moment use the POSIX Convention

    this.timeZones.push({name: browserTimeZone});
    if (eventTimeZone) this.timeZones.push({name: eventTimeZone});
    this.timeZones.push({name: UTCTimeZone});
    this.timeZones.push({name: IDLWTimeZone, mask: 'International Date Line West'});
  }

  private getEventTimeZone(browserTimeZone: string): string {
    if (this.eventTimeZone != browserTimeZone && this.eventTimeZone != 'Greenwich') {
      return this.eventTimeZone;
    }

    return null;
  }

  public get offsetTimeZoneSelected(): string {
    const timeZone = this.radioSelectedTimeZone || this.form.get(this.controller).value;
    const offset = moment.tz(timeZone).format('Z');
    return offset ? `(UTC${offset})` : '';
  }

  public setTimeZoneSelected(): void {
    this.selectedTimeZone = this.form.get(this.controller).value || moment.tz.guess(true);
  }

  public setTimeZone(timeZone: string): void {
    this.DateTimePickerHandler.timeZoneSelected = timeZone;
    this.radioSelectedTimeZone = timeZone;
  }
}
