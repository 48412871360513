import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '../component/error-snackbar/error-snackbar.component';
import { Response } from '../model/response';
import { plainToClass } from 'class-transformer';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private snackbar: MatSnackBar,
    private router: Router,
    private authService: AuthService
  ) { }

  handleError(e: HttpErrorResponse): Observable<never> {
    const res = plainToClass(Response, e.error as Response);

    if (e.error && e.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', e.error.message);
      this.openSnackbar(e.error.message);
    } else if (res && res.status) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${e.status}, ` +
        `body was: ${e.error}`
      );
      console.error(res.status.params);
      this.openSnackbar(res.status.message);
    } else {
      // Not backend defined error message.
      if (e.error && e.error.message) {
        this.openSnackbar(e.error.message);
      } else if (e.status >= 400) {
        if (e.status === 401) {
          const returnUrl = this.router.url;

          this.router.navigate(['/loading'], { skipLocationChange: true }).then(() => {
            this.authService?.signOut(false);
            this.router.navigate(['/'], { queryParams: { returnUrl } });
          });
        } else {
          if (e.status === 403) {
            this.router.navigate(['/error', e.status]);
            return;
          }
          this.openSnackbar(e.message);
          this.router.navigate(['/error', e.status]);
        }
      }
    }

    return throwError(e);
  }

  openSnackbar(string: string, params?: Object, config: MatSnackBarConfig = {
    panelClass: 'error',
    horizontalPosition: 'left',
    verticalPosition: 'bottom',
    duration: 5000
  }): void {
    this.snackbar.openFromComponent(ErrorSnackbarComponent, { data: { string, params }, ...config });
  }
}
