import { Component, forwardRef, Input } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxInputComponent),
    multi: true
  }]
})
export class CheckboxInputComponent {
  @Input() formGroupController: FormGroup;
  @Input() name: string;
  @Input() label: string;
  @Input() disabled: boolean;
}
