import { Component, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { Event } from 'src/app/model/event';
import { NotificationService } from 'src/app/service/notification.service';
import { Submission, SubmissionStatus } from 'src/app/model/paper';
import { ExportService } from 'src/app/service/export.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { calendarTimezone } from 'src/app/enum/calendar.timezone';
import * as moment from 'moment-timezone';
import { TimezoneService } from 'src/app/service/timezone.service';
import * as Dayjs from 'dayjs';
import _ from 'lodash';

interface calendarTimeZone {
  id: string;
  value: string;
  offset: number;
}

@Component({
  selector: 'app-event-program',
  templateUrl: './event-program.component.html',
  styleUrls: ['./event-program.component.scss']
})
export class EventProgramComponent implements OnInit {
  event: Event;

  isAbstract = false;
  isAffiliation = false;
  isCountry = false;
  isEmail = false;
  loadedSessions= false;
  exportTableGenerated = false;
  styleString: string = '';

  public days: Array<any>;

  public timeZoneForm: FormGroup;
  public listTimeZones: Array<any>;
  public cloneEvent: Event;

  constructor(
    private adminService: AdminService,
    private http: HttpClient,
    private eventService: EventsService,
    private submissionsService: SubmissionsService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private exportService: ExportService,
    public fb: FormBuilder,
    private timezoneService: TimezoneService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.adminService.progressBar.start();
      this.route.params.subscribe(params => {
        this.event = this.adminService.selectedEvent;
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.initFormTimeZone(this.event);
          this.eventService.getSessionsByEvent(event.id).subscribe(sessions => {
            this.event.sessions = sessions;
            this.loadedSessions = true;
          }, () => {},
          () => {
            this.event.sessions = this.event.sessions.sort((a, b) => {
              if(<any>a.start > <any>b.start){
                return 1;
              }else if(<any>a.start === <any>b.start){
                if(<any>a.backgroundColor > <any>b.backgroundColor){
                  return 1;
                }else{
                  return -1;
                }
              }else{
                return -1;
              }
            });
            const offsetEventTimezone: number = this.offsetEventTimezone;
            this.convertScheduleToTimeZone(this.event, offsetEventTimezone);
            this.adminService.progressBar.stop();
            this.exportTableGenerated = true;
          });
        });
      });
    });
  }

  private initFormTimeZone(event: Event): void {
    const eventTimeZone: string = event.eventInfo.timeZone;
    this.listTimeZones = calendarTimezone.toSelectable(eventTimeZone, moment.tz.guess());

    let timezone: string;
    if (!!eventTimeZone) {
      timezone = 'event';
    } else {
      timezone = 'browser';
    }

    this.timeZoneForm = this.fb.group({
      programTimeZone: [timezone]
    });
  }

  public changeTimeZone($id: string): void {
    const offsetTimeZoneSelected = this.listTimeZones.find(timeZone => timeZone.id === $id).offset;
    this.convertScheduleToTimeZone(this.event, offsetTimeZoneSelected);
  }

  private convertScheduleToTimeZone(event: Event, offsetTimeZone: number): void {
    this.cloneEvent = _.cloneDeep(event);
    this.cloneEvent.sessions.forEach(session => {
      session.start = Dayjs(this.timezoneService.timeZoneConverter(session.start.toDate(), offsetTimeZone));
      session.end = Dayjs(this.timezoneService.timeZoneConverter(session.end.toDate(), offsetTimeZone));
    });
  }

  public get offsetEventTimezone(): number {
    const nameTimeZone: string = this.event.eventInfo.timeZone;
    const offsetTimeZone: number = moment.tz(nameTimeZone).utcOffset();

    return offsetTimeZone;
  }

  // private reorder(): void {
  //   this.event.sessions = this.event.sessions.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
  // }

  submitStatusChange(element) {
    this.submissionsService.editSubmission({ noShow: true }, element.id).subscribe(() => {
        this.notificationService.notify('submissions.edit.status', { params: { id: element.id } });
    });
  }

  generateExportHtml() {
    this.adminService.progressBar.start();
    this.exportTableGenerated = false;

    setTimeout(() => {
      this.exportService.extractExportHTML('program').subscribe(({ content, styles, links }) => {
        const html = `
          <!doctype html>
          <html>
            <head>
              <meta charset="utf-8">
              <meta name="viewport" content="width=device-width, initial-scale=1">
              <meta http-equiv="Content-Type" content="text/html;charset=UTF-8">

              <title>${this.event.name}</title>
              <style type="text/css">
                p {
                  margin: 0;
                  padding: 0 0 0 0;
                }
              </style>
            </head>
            <body>
              <div class="export-container">
                <div class="event-program">
                  ${content}
                </div>
              </div>
            </body>
          </html>
        `;

        setTimeout(() => {
          let blob = new Blob([html], {type: 'text/html'});
          let downloadURL = URL.createObjectURL(blob);
          this.downloadLink(downloadURL, `${this.event.name}.html`);
          this.exportTableGenerated = true;
          this.adminService.progressBar.stop();
        }, 1000);
      });

    }, 1000);
  }

  downloadLink(downloadURL: string, name: string) {
    // Create link to lownload
    var link = document.createElement('a');
    link.href = downloadURL;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.download = name;
    link.click();
  }

  printToPDF() {

  }
}
