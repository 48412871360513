import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormField } from '../../class/form-field';

@Component({
  selector: 'app-custom-form-checkbox-question',
  templateUrl: './custom-form-checkbox-question.component.html',
  styleUrls: ['./custom-form-checkbox-question.component.scss']
})
export class CustomFormCheckboxQuestionComponent extends FormField {
  constructor() {
    super();
  }
}
