import { SelectOption } from '../model/select.option';

export enum UserEventAnswer {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  NOT_ANSWERED = 'not_answered'
}

export namespace UserEventAnswer {
  export function toIterable(): Array<string> {
    return Object.keys(UserEventAnswer).filter(key => typeof UserEventAnswer[key] !== 'function');
  }

  export function toSelectable(): Array<SelectOption> {
    return UserEventAnswer.toIterable().map((value, index) => ({
      id: value,
      value: UserEventAnswer[value],
      i18n: 'forms.buttons.invite.' + value.toLowerCase()
    }));
  }
}
