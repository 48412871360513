import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/service/user.service';
import { User } from 'src/app/model/user';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from 'src/app/service/notification.service';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { MatSort } from '@angular/material/sort';
import { InfoService } from 'src/app/service/info.service';

@Component({
  selector: 'app-administrators',
  templateUrl: './administrators.component.html',
  styleUrls: ['./administrators.component.scss']
})
export class AdministratorsComponent implements OnInit {
  administrators: Array<User>;
  form: FormGroup;
  userTableColumns = ['id', 'name', 'affiliation', 'email', 'notify', 'remove' ];

  administratorDataSource = new MatTableDataSource<User>();

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.sort = sort;
    if (this.sort) {
      this.administratorDataSource.sort = this.sort;

      this.administratorDataSource.sortingDataAccessor = (user: User, header: string) => {
        switch (header) {
          case 'id': return user.id;
          case 'name': return user.name;
          case 'affiliation': return user.profile.affiliation.name;
          case 'email': return user.email;
          case 'notify': return user.profile.notify;
          default: return user[header];
        }
      };
    }
  }
  sort: MatSort;

  loggedUser: User;

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    public infoService: InfoService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getSuperUsers();
      this.userService.getUserFromToken().subscribe( user => {
        this.loggedUser = user;
      });
    });
  }

  public getSuperUsers(): void {
    this.userService.getSuperUsers().subscribe((superUsers: Array<User>) => {
      this.administrators = superUsers;
      this.administratorDataSource.data = this.administrators;

      this.initForm();
    });
  }

  public initForm(): void {
    this.form = this.fb.group({
      users: this.administrators
    });

    this.form.get('users').valueChanges.subscribe((users: Array<User>) => {
      const selectedUser = users[0];
      this.setSuperUserStatus(selectedUser, true);
      return users.splice(0, 1);
    });
  }

  public updateNotifyUser(user: User): void {
    if (user){
      this.userService.update(user).subscribe(() => {});
    }
  }

  public setSuperUserStatus(user: User, status: boolean): void {
    if (user) {
      this.userService.updateSuperUser(user.id, status).subscribe(
        () => {
          this.notificationService.notify(
            `admin.administrators.${status ? 'added' : 'removed' }-super-user`,
            { params: { user: user.name } }
          );
          this.getSuperUsers();
        },
        () => {
          this.notificationService.notifyError(
            'admin.administrators.error-assigning-user',
            { params: { user: user.name } }
          );
        }
      );
    }
  }

  removeSuperUser(id: number): void {
    this.userService.getUser(id).subscribe(user => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'admin.administrators.confirm-dialog-title',
          content: 'admin.administrators.confirm-dialog-content',
          params: {
            content: { user }
          }
        }
      });

      dialogRef.afterClosed().subscribe(confirmed => {
        if (confirmed) {
          this.setSuperUserStatus(user, false);
        }
      });
    });
  }
}
