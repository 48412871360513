import { Component, forwardRef, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { TextInputComponent } from '../text-input/text-input.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from '../../model/select.option';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectInputComponent),
    multi: true
  }]
})

export class SelectInputComponent extends TextInputComponent implements OnInit, OnChanges {
  @Input() options;
  @Input() indices = {
    internal: 'id',
    public: 'value'
  };

  @Input() prependPublic = '';
  @Output() selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();
  @Output() inputValueChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() multiple = false;

  @Input() compact = false;
  @Input() includeNoneOption = false;
  @Input() disabled = false;

  @Input() filterable = false;
  @Input() indicesFilter: string = 'public';
  filteredOptions: Array<SelectOption>;

  valueInputSelected: string;

  @Input() LastOptionBold = false;
  @Input() tooltips: { disabled?: string } = { disabled: null };

  ngOnChanges(): void {
    this.ngOnInit();
  }

  ngOnInit() {
    this.valueInputSelected = this.formGroupController.controls[this.name].value;
    if (this.filterable && this.options) {
      // Create a new, mutable, copy of the options variable.
      this.filteredOptions = this.options.slice();
    } else {
      // Both variables are the same. A new array is not created.
      this.filteredOptions = this.options;
    }
  }

  onSelection($event: MatSelectChange): void {
    this.valueInputSelected = $event.value;
    this.selectionChange.emit($event);
  }

  setOptionTooltip(option): string {
    if (this.tooltips.disabled && option?.disabled) {
      return this.tooltips.disabled;
    }

    return null;
  }

  tooltipTimezoneContent(): string {
    if (this.name === 'timeZone' && !!this.valueInputSelected) {
      const tooltip = this.options.filter((option) => option[this.indices.public] === this.valueInputSelected)[0];
      return tooltip.filter;
    }
  }

  getValueInputCity($value: any): void {
    if ($value.length >= 3) {
      this.inputValueChange.emit($value);
    }
  }

  setClassCountryWarning() {
    const componentGeneratingNow: string = this.indices.internal;
    const countryIsDefined = this.formGroupController.controls?.country?.value;

    if (componentGeneratingNow === 'isoCode' && countryIsDefined === null) {
      return 'country-warning';
    }
  }
}
