import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SubmissionsService } from '../../service/submissions.service';
import { ReviewsService } from '../../service/reviews.service';
import { Location } from '@angular/common';
import { InfoService } from '../../service/info.service';

@Component({
  selector: 'app-card-view',
  templateUrl: './card-view.component.html',
  styleUrls: ['./card-view.component.scss']
})
export class CardViewComponent {
  loaded: boolean;

  constructor(
    protected router: Router, protected route: ActivatedRoute,
    protected submissionService: SubmissionsService, protected location: Location,
    protected infoService: InfoService, protected reviewsService: ReviewsService
  ) { }

  //  conflict with file-viewer
  // @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
  //   this.location.back();
  // }
}
