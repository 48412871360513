import { SelectOption } from '../model/select.option';

export enum Motives {
  CLAIM = 'CLAIM',
  ASSIGNED_CLAIMED = 'ASSIGNED_CLAIMED',
  INTEREST = 'INTEREST',
  ASSIGNED_INTEREST = 'ASSIGNED_INTEREST',
  DECLINED = 'DECLINED',
  AUTHOR = 'AUTHOR',
  CONFLICT = 'CONFLICT',
  REQUESTED = 'REQUESTED'
}

export enum Translations {
  CLAIM = 'admin.event.reviews.assign-suggestion.motives.claim',
  ASSIGNED_CLAIMED = 'admin.event.reviews.assign-suggestion.motives.assigned_claim',
  INTEREST = 'admin.event.reviews.assign-suggestion.motives.interest',
  ASSIGNED_INTEREST = 'admin.event.reviews.assign-suggestion.motives.assigned_interest',
  DECLINED = 'admin.event.reviews.assign-suggestion.motives.decline',
  AUTHOR = 'admin.event.reviews.assign-suggestion.motives.author',
  CONFLICT = 'admin.event.reviews.assign-suggestion.motives.conflict',
  REQUESTED = 'admin.event.reviews.assign-suggestion.motives.requested'
}

export namespace AssignMotive {
  export function toIterable(): Array<string> {
    return Object.keys(Motives).filter(key => typeof Motives[key] !== 'function');
  }

  export function toSelectable(): Array<SelectOption> {
    return Object.keys(Motives).map(value => ({
      id: value,
      value: Motives[value]
    }));
  }
}
