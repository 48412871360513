import { Injectable, Injector } from '@angular/core';
import { Language } from '../model/language';
import languages from '../../assets/languages.js';
import { SystemService } from './system.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  availableLanguages: Array<Language>;

  constructor(
    private systemService: SystemService,
    private translate: TranslateService
  ) {
    this.systemService.countries.subscribe(countries => {
      this.availableLanguages = Object.keys(languages)
        .map(id => new Language(
          id, languages[id].name, languages[id].country,
          countries.find(country => country.name === languages[id].country)
        ));
    });
  }

  get languages(): Array<Language> {
    return this.availableLanguages;
  }

  public getLanguage(id: string): Language {
    return this.availableLanguages?.find(lang => lang.id === id);
  }

  public translationExists(id: string): boolean {
    return !!this.getLanguage(id);
  }

  changeLanguage(language: string): void {
    this.translate.use(language);
  }
}
