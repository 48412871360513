import { Component, OnInit, Input } from '@angular/core';
import { Dayjs } from 'dayjs';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-dialog-timezone',
  templateUrl: './dialog-timezone.component.html',
  styleUrls: ['./dialog-timezone.component.scss']
})
export class DialogTimezoneComponent implements OnInit {

  public eventTimeZoneIsDefined: boolean = false;
  @Input() fontSet: string = 'far';
  @Input() public date: Dayjs;
  @Input() public eventTimeZone: string;
  public displayedColumns: string[] = ['name', 'time'];
  public timeZones: string[];
  constructor() { }

  ngOnInit(): void {
    const browserTimeZone = moment.tz.guess(true); // Get user browser's timeZone
    const gmt0_timezone   = 'Greenwich';           // Get GMT-O
    const edt_timezone    = 'America/New_York';    // EDT timeZone

    this.timeZones = [browserTimeZone, gmt0_timezone];
    if (this.eventTimeZone && !this.timeZones.includes(this.eventTimeZone)) {
      this.timeZones.splice(1, 0, this.eventTimeZone); // Add Event's TimeZone if defined.
      this.eventTimeZoneIsDefined = true;
    }
    if (this.eventTimeZone !== edt_timezone) {
      this.timeZones.push(edt_timezone);
    }
  }
}
