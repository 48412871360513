import { Type } from 'class-transformer';
import { User } from './user';
import { BaseModel } from './baseModel';
import { EventTopic } from './eventTopic';

export enum TopicInterest {
  yes = 2,
  neutral = 1,
  no = 0
}

export namespace TopicInterest {
  export function keys() {
    return Object.keys(TopicInterest)
      .filter(key => typeof TopicInterest[key] !== 'function')
      .filter(key => !isNaN(Number(TopicInterest[key])));
  }
}

export class TopicChoice {
  eventTopic: EventTopic;
  interest = TopicInterest.neutral;

  constructor(topic, interest = TopicInterest.neutral) {
    this.eventTopic = topic;
    this.interest = interest;
  }
}

export class UserEventTopicsInterest extends BaseModel {
  @Type(() => User)
  public user: User;

  @Type(() => EventTopic)
  public eventTopic: EventTopic;

  public interest: number;
  public id: number;
}
