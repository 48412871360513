import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ChangePasswordProfileComponent } from '../change-password-profile/change-password-profile.component';
import { UserService } from '../../service/user.service';
import { AuthService } from '../../service/auth.service';
import { User } from '../../model/user';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-landing-profile',
  templateUrl: './landing-profile.component.html',
  styleUrls: ['./landing-profile.component.scss']
})
export class LandingProfileComponent implements OnInit {
  constructor(
    private bottomSheet: MatBottomSheet,
    public authService: AuthService,
    public userService: UserService
  ) { }

  user: User;

  ngOnInit() {
    this.userService.getUserProfile().subscribe( (user) => {
      this.user = user;
    }, (err) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.authService.signOut();
        }
      }
    });
  }

  changePassword(): void {
    this.bottomSheet.open(ChangePasswordProfileComponent);
  }
}
