import { Component } from '@angular/core';

@Component({
  selector: 'app-event-publication-status',
  templateUrl: './event-publication-status.component.html',
  styleUrls: ['./event-publication-status.component.scss']
})
export class EventPublicationStatusComponent {

  constructor() { }

}
