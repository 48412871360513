import { BaseModel, toDayjs } from './baseModel';
import { Event } from './event';
import { Transform, Type } from 'class-transformer';
import * as dayjs from 'dayjs';

export enum ViewersValues {
  'OWN' = 'admin.event.reviews.configuration.own',
  'ASSIGNER' = 'admin.event.reviews.configuration.assigner',
  'ALL' = 'admin.event.reviews.configuration.all'
}

export enum ViewersWithoutOwnValues {
  'ASSIGNER' = 'admin.event.reviews.configuration.assigner',
  'ALL' = 'admin.event.reviews.configuration.all'
}

export enum AnonymityValues {
  'NO' = 'admin.event.reviews.configuration.no',
  'REVIEW' = 'admin.event.reviews.configuration.review'
}

export class EventReviewConfiguration extends BaseModel {
  event: Event;

  doubleBlind: boolean;
  anonymousReview: boolean;

  authorsCanView: boolean;
  authorsCanViewScores: boolean;
  @Transform(value => isNaN(value) ? value : Object.keys(ViewersValues)[value])
  tpcCanView: string;
  @Transform(value => isNaN(value) ? value : Object.keys(ViewersWithoutOwnValues)[value])
  tpcViewReviewIdentity: string;

  copyReviewAssigner: boolean;
  numberOfReviewsRequired: number;
  numberOfReviewsForTpcMember: number;
  journalDaysForReview: number;

  @Transform(toDayjs)
  conferenceDateDue: dayjs.Dayjs;

  @Transform(toDayjs)
  reminderStartDate: dayjs.Dayjs;

  daysBetweenReminders: number;

  blockReview: boolean;
  tpcListAllPapers: boolean;
  showOnlyCompletedReviewToTpc: boolean;
  paperRankingToTpc: boolean;

  spanLimitA: number;
  spanLimitB: number;

  tpcDelegate: boolean;
  tpcAssignToExternal: boolean;
  tpcBidClaimPaper: boolean;
  claimPendingSubmissions: boolean;

  discussionPeriodEnable: boolean;
  @Transform(toDayjs)
  discussionPeriodStart: dayjs.Dayjs;
  @Transform(toDayjs)
  discussionPeriodEnd: dayjs.Dayjs;

  public get discussionPeriodIsOpen(): boolean {
    return dayjs() > this.discussionPeriodStart;
  }

  public get discussionPeriodIsClosed(): boolean {
    return dayjs() > this.discussionPeriodEnd;
  }

  rebuttalPeriodEnable: boolean;
  @Transform(toDayjs)
  rebuttalPeriodStart: dayjs.Dayjs;
  @Transform(toDayjs)
  rebuttalPeriodEnd: dayjs.Dayjs;


  public get rebuttalPeriodIsOpen(): boolean {
    return dayjs() > this.rebuttalPeriodStart;
  }

  public get rebuttalPeriodIsClosed(): boolean {
    return dayjs() > this.rebuttalPeriodEnd;
  }

  constructor(
  ) { super(); }
}
