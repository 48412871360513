import { Pipe, PipeTransform } from '@angular/core';
import { Event } from '../model/event';
import { ZerofillPipe } from './zerofill.pipe';

@Pipe({
  name: 'eventTitlePipe'
})
export class EventTitlePipe implements PipeTransform {
  transform(event: Event): string {
    return `${ZerofillPipe.prototype.transform(event.id)} - ${event.name}`;
  }
}
