import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Affiliation } from '../../model/affiliation';
import { Observable } from 'rxjs';
import { SystemService } from '../../service/system.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-affiliation-search',
  templateUrl: './affiliation-search.component.html',
  styleUrls: ['./affiliation-search.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(200)]),
      transition(':leave', animate(200, style({ opacity: 0 })))
    ])
  ]
})
export class AffiliationSearchComponent implements OnInit {
  @Input() formGroupController: FormGroup;
  @Input() name: string;
  @Input() allowAffiliationCreation = true;
  @Input() user: User;

  stateCtrl = new FormControl('');
  filteredAffiliations: Observable<Affiliation[]>;

  userIncludeAffiliation: boolean = false;
  unknownAffiliation = false;
  @Output() affiliation = new EventEmitter();

  affiliationControls = {
    name: new FormControl('', Validators.required),
    acronym: new FormControl('', Validators.required),
  };

  constructor(
    private systemService: SystemService,
  ) {
    this.filteredAffiliations = this.systemService.getAffiliations();
    this.filteredAffiliations.subscribe(
      () => { },
      (e) => alert('The request to get affiliations has been blocked! Disable your AdBlock.')
    );
  }

  ngOnInit(): void {
    this.setUserAffiliationInInput(this.user);
    this.formGroupController.addControl(this.name, this.stateCtrl);

    // Get affiliations according to input
    this.stateCtrl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(value => {
        this.allowAffiliationCreation = false;
        this.filteredAffiliations = this.systemService.getAffiliations(value);
        this.filteredAffiliations.subscribe(
          (data) => { },
          (e) => {
            alert('The request to get affiliations has been blocked! Disable your AdBlock.');
          },
          () => {
            this.allowAffiliationCreation = true;
          }
        );
      });

    this.stateCtrl.statusChanges
      .pipe(
        debounceTime(500),
    ).subscribe(status => {
      this.userIncludeAffiliation = true;
      this.allowAffiliationCreation = false;
      const controls = Object.keys(this.affiliationControls);
      if (status === 'VALID' && typeof this.stateCtrl.value === 'string') {
        // If unknown affiliation, request affiliation details
        for (const key of controls) {
          this.formGroupController.addControl(key, this.affiliationControls[key]);
        }
        this.unknownAffiliation = true;
      } else {
        // affiliation known selected, so reset control forms.
        for (const key of controls) {
          this.affiliationControls[key].reset();
          this.formGroupController.removeControl(key);
        }
        this.unknownAffiliation = false;
      }
      this.affiliation.emit(this.unknownAffiliation);
    },
    () => {},
    () => {
      this.allowAffiliationCreation;
      this.affiliation.emit(this.unknownAffiliation);
    });
  }

  displayFn(affiliation?: Affiliation): string | undefined {
    return affiliation.name ? `${affiliation.acronym} - ${affiliation.name}` : undefined;
  }

  private setUserAffiliationInInput(user: User) {
    if (this.user?.profile) {
      this.stateCtrl.setValue(this.user?.profile.affiliation);
    }
  }

  setClassAffiliationWarning() {
    if (this.user) {
      if (!!this.user.profile.affiliation.name === false && !this.userIncludeAffiliation) {
        return 'affiliation-search-warning';
      } else {
        return 'null';
      }
    }
  }
}
