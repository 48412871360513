import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordValidator } from '../../validator/password.validator';
import { AuthService } from '../../service/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-change-password-profile',
  templateUrl: './change-password-profile.component.html',
  styleUrls: ['./change-password-profile.component.scss']
})
export class ChangePasswordProfileComponent implements OnInit {
  passwordForm: FormGroup;

  constructor(
    public fb: FormBuilder,
    public authService: AuthService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.passwordForm = this.fb.group({
      oldPassword: ['', [Validators.required, Validators.minLength(8)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]]
    }, {
      validators: PasswordValidator.MatchPassword
    });
  }

  changePassword(): void {
    this.authService.passwordChange({
      oldPassword: this.passwordForm.value.oldPassword,
      newPassword1: this.passwordForm.value.password,
      newPassword2: this.passwordForm.value.confirmPassword
    }).subscribe(res => {
      this.snackBar.open('password changed message');
    });
  }

  get passwordFormIsValid(): boolean {
    return this.passwordForm.valid;
  }
}
