import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-character-counter',
  templateUrl: './character-counter.component.html',
  styleUrls: ['./character-counter.component.scss']
})
export class CharacterCounterComponent {
  @Input() text: string;
  @Input() maxLength: number;
  @Input() threshold = 5;

  isOnThreshold(): boolean {
    return (this.textLength / this.maxLength * 100) >= 100 - this.threshold;
  }

  get textLength(): number {
    return (this.text || '').trim().length;
  }
}
