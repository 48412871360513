import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavbarService } from 'src/app/service/navbar.service';

@Component({
  selector: 'app-error-view',
  templateUrl: './error-view.component.html',
  styleUrls: ['./error-view.component.scss']
})
export class ErrorViewComponent implements OnInit {
  title: string;
  message: string;
  showErrorMessage = false;

  constructor(
    private route: ActivatedRoute,
    public navbarService: NavbarService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(({ code }) => {
      const t = Number(code);

      if (typeof t === 'number' && !isNaN(t)) {
        this.title = `errors.${t}.title`;
        this.message = `errors.${t}.message`;

        this.showErrorMessage = true;
      }
    });

    this.navbarService.loadingStop();
  }
}
