import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, AfterContentInit } from '@angular/core';
import { FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { EventSubmissionStep } from 'src/app/model/eventSubmissionStep';
import { Event } from 'src/app/model/event';
import { User } from 'src/app/model/user';
import { Track } from 'src/app/model/track';
import { Submission } from 'src/app/model/paper';
import { EventsService } from 'src/app/service/events.service';
import { EventTopic } from 'src/app/model/eventTopic';
import { Form } from 'src/app/model/form';
import { CustomFormComponent } from '../custom-form/custom-form.component';
import { Answer } from 'src/app/model/answer';
import { Location } from '@angular/common';
import { MatStep, MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-submission-step',
  templateUrl: './submission-step.component.html',
  styleUrls: ['./submission-step.component.scss']
})
export class SubmissionStepComponent implements OnInit, AfterViewInit, AfterContentInit {
  @ViewChild('formContainer', { static: false })
  public formContainer: CustomFormComponent;

  public controller: FormGroup;
  public form: Form;

  @Input() submission: Submission;
  @Input() formGroup: FormGroup;
  @Input() event: Event;
  @Input() track: Track;
  @Input() step: EventSubmissionStep;
  @Input() includeLoggedUser = true;
  @Input() isEditing: boolean;
  @Input() matStep: MatStep;

  stepStatus = { completed: false, error: false };
  @Output() stepInitialized: EventEmitter<{stepName: string, stepForm: FormGroup}> = new EventEmitter();


  @Output() errorRaised = new EventEmitter();
  @Output() noErrorRaised = new EventEmitter();
  @Output() nextStep = new EventEmitter();

  @Output() submitForm: EventEmitter<{ valid: boolean, formAnswers: Answer[] }> = new EventEmitter();

  totalSteps: number;

  // step === topics
  topics: EventTopic[];

  submitButtonDisabled = false;
  showCreateUserForm = false;

  stepFormGroup: FormGroup;


  constructor(public eventService: EventsService, public location: Location) { }


  ngOnInit() {
    this.totalSteps = this.event.eventSubmissionStep.length;


    switch (this.step.name) {
      case 'info':
        // max length based from model of DJANGO
        let titleInput = new FormControl('', [Validators.required, Validators.maxLength(150)]);
        let abstractInput = new FormControl('', [Validators.required, Validators.maxLength(3000)]);
        titleInput.statusChanges.subscribe(this.inputStatusChanged.bind(this));
        abstractInput.statusChanges.subscribe(this.inputStatusChanged.bind(this));
        this.stepFormGroup = new FormGroup({
          'title': titleInput,
          'abstract': abstractInput
        });
        this.formGroup.addControl(this.step.name, this.stepFormGroup);
        break;
      case 'topics':
        if (this.event.topics.length > 0) {
          this.eventService.getEventTopics(this.event.id).subscribe(topics => {
            const reducer = (acc, topic) => {
              if (topic.children?.length === 0) {
                acc.top.push(topic);
              } else {
                acc.bottom.push(topic);
              }
              return acc;
            };

            const t = topics.reduce(reducer, { top: [], bottom: [] });
            this.topics = [...t.top, ...t.bottom];
          });

          const topicsValidators = [this.minLengthValidator(Number(this.event.minTopics))];
          if (Number(this.event.maxTopics) > 0) {
            topicsValidators.push(this.maxLengthValidator(Number(this.event.maxTopics)));
          }
          let topicsInput = new FormControl([], topicsValidators);
          this.stepFormGroup = new FormGroup({
            list: topicsInput
          });
          this.formGroup.addControl(this.step.name, this.stepFormGroup);

        } else {
          let topicsInput = new FormControl([]);
          this.stepFormGroup = new FormGroup({
            list: topicsInput
          });
          this.formGroup.addControl(this.step.name, this.stepFormGroup);
        }


        break;
      case 'authors':
        var authorsInput = new FormControl([], Validators.required);
        authorsInput.statusChanges.subscribe(this.inputStatusChanged.bind(this));
        this.stepFormGroup = new FormGroup({
          'list': authorsInput
        });
        this.formGroup.addControl(this.step.name, this.stepFormGroup);
        break;
      case 'form':
        break;
      default:
        break;
    }
    if(this.stepFormGroup) {
      this.stepFormGroup.statusChanges.subscribe(this.formStatusChanged.bind(this));
    }
  }

  ngAfterContentInit(): void {
    this.matStep.stepControl = this.stepFormGroup;
  }

  inputStatusChanged(status : any) {
    if(status === "VALID") {
      this.stepStatus.error = false;
    } else if(status === "INVALID") {
      this.stepStatus.error = true;
    }
  }

  formStatusChanged(status: any){
    if(status === "VALID") {
      this.stepStatus.completed = true;
    } else if(status === "INVALID") {
      this.stepStatus.completed = false;
    }
  }

  setNewUserAsAuthor(user: User): void {
    const authorsController = this.formGroup.get('authors').value;
    authorsController.push(user);
  }

  minLengthValidator(minLength: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valuesLength = control.value.length;
      const invalidLength = valuesLength < minLength;
      return invalidLength ? { 'minlength': { requiredLength: this.event.minTopics, actualLength: valuesLength } } : null;
    };
  }

  maxLengthValidator(maxLength: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valuesLength = control.value.length;
      const invalidLength = valuesLength > maxLength;
      return invalidLength ? { 'maxlength': { requiredLength: this.event.maxTopics, actualLength: valuesLength } } : null;
    };
  }

  next() {
    this.nextStep.emit();
  }

  ngAfterViewInit() {
    if (this.step.name === 'form') {
      if (this.submission?.id && this.submission?.formSubmission) { // Edit submission, get form from submission.
        const formSubmission = this.submission.formSubmission;

        if (formSubmission) {
          this.form = formSubmission;
          this.controller = new FormGroup({});
          this.formGroup.addControl(this.step.name, this.controller);
        }
      } else { // Create submission, get form from event.
        this.eventService.getFormSubmission(this.event.id, <number>this.event.formSubmission)
          .subscribe(form => {
            if (form) {
              form.fields.forEach(f => f.answers = []); // Keep only form field choices, not answers.

              this.form = form;
              this.controller = new FormGroup({});
              this.formGroup.addControl(this.step.name, this.controller);
            }
          });
      }
    }

    this.initializeFormValue();
  }

  private initializeFormValue(): void {
    setTimeout(() => {

      if (this.submission) { // Only initialize values if submission already exists (submission edit mode).
        for(let stepName in this.formGroup.controls){
          let group: FormGroup = (this.formGroup.controls[stepName] as FormGroup);
          if(stepName == 'authors') {
            group.controls['list'].setValue(this.submission.authors.map(author => author.user));
          } else if(stepName == 'topics') {
            group.controls['list'].setValue(this.submission['topics']);
          } else {
            for(let inputName in group.controls) {
              group.controls[inputName].setValue(this.submission[inputName]);
            }
          }
        }
      }
    });
  }

  getTopicMessage(minTopics, maxTopics) {
    const min: number = +minTopics;
    const max: number = +maxTopics;

    const items: Array<{ message: any, value: any }> = [];

    if (min == 0) {
      items.push({ message: "forms.fields.select-topics.min-zero", value: null });
    } else {
      if (min > 0) {
        items.push({ message: "forms.fields.select-topics.min", value: min });
      }
    }

    if (max == 0) {
      items.push({ message: "forms.fields.select-topics.max-zero", value: null });
    } else {
      if (max > 0) {
        items.push({ message: "forms.fields.select-topics.max", value: max });
      }
    }

    return items;
  }

  submit(): void {
    this.submitButtonDisabled = true;
    
    this.eventService.getFormSubmission(this.event.id).subscribe(form => {
      const formAnswers = form?.formAnswers(this.formGroup.controls['form']) || [];
      this.submitForm.emit({ valid: this.formGroup.valid, formAnswers: formAnswers });
    });
  }
}
