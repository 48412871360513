import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../service/admin.service';
import { User } from '../../../model/user';
import { NavbarService } from '../../../service/navbar.service';
import { Event } from '../../../model/event';

@Component({
  selector: 'app-event-people',
  templateUrl: './event-people.component.html',
  styleUrls: ['./event-people.component.scss']
})
export class EventPeopleComponent implements OnInit {
  event: Event;
  people: Array<User>;

  constructor(
    private adminService: AdminService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => this.event = event);
      }
    });
  }
}
