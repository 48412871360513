import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

const TRANSLATIONS = {
  ITEMS_PER_PAGE: 'paginator.items-per-page',
  NEXT_PAGE: 'paginator.next-page',
  PREVIOUS_PAGE: 'paginator.previous-page',
  FIRST_PAGE: 'paginator.first-page',
  LAST_PAGE: 'paginator.last-page'
};

@Injectable()
export class MatPaginatorI18n extends MatPaginatorIntl {
  public constructor(private translate: TranslateService) {
    super();

    this.translate.onLangChange.subscribe((e: Event) => {
      this.setTranslations();
    });

    this.setTranslations();
  }

  public getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex: number = page * pageSize;
    const endIndex: number = startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} / ${length}`;
  }

  public setTranslations(): void {
    this.translate.get([
      TRANSLATIONS.ITEMS_PER_PAGE,
      TRANSLATIONS.NEXT_PAGE,
      TRANSLATIONS.PREVIOUS_PAGE,
      TRANSLATIONS.FIRST_PAGE,
      TRANSLATIONS.LAST_PAGE,
    ])
      .subscribe((translation: any) => {
        this.itemsPerPageLabel = translation[TRANSLATIONS.ITEMS_PER_PAGE];
        this.nextPageLabel = translation[TRANSLATIONS.NEXT_PAGE];
        this.previousPageLabel = translation[TRANSLATIONS.PREVIOUS_PAGE];
        this.firstPageLabel = translation[TRANSLATIONS.FIRST_PAGE];
        this.lastPageLabel = translation[TRANSLATIONS.LAST_PAGE];

        this.changes.next();
      });
  }
}
