import { Directive } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DefaultEmailTrigger, EmailTrigger } from '../enum/email.trigger';
import { NotificationEmail } from '../model/notificationEmail';
import { SelectOption } from '../model/select.option';
import { User } from '../model/user';
import { Event } from '../model/event';
import { EventsService } from '../service/events.service';

@Directive()
export abstract class EmailTemplatesEditDirective {
  emailTriggers: Array<SelectOption>;
  emailTriggerVariables: Array<SelectOption> = [];
  filterForm: FormGroup;
  subject: string;
  notificationEmail: NotificationEmail = new NotificationEmail();
  subjectEmail: string;
  contentEmail: string;
  userForm: FormGroup;
  changeType = true;

  preview: {
    subject: string,
    content: string
  };

  constructor(
    private limitToChairTemplates = true,
    protected fb: FormBuilder,
    protected translateService: TranslateService,
    protected eventsService: EventsService
  ) {
    if (this.limitToChairTemplates) {
      this.emailTriggers = EmailTrigger.toSelectable();
    } else {
      this.emailTriggers = DefaultEmailTrigger.toSelectable();
    }
  }

  // Required custom methods
  abstract emailExists: boolean;
  abstract initComponent();
  abstract getEmailTemplate(trigger: EmailTrigger);
  abstract updateSelectedEmailTemplate();

  initForm(): void {
    this.filterForm = this.fb.group({
      emailTrigger: [],
      emailSubject: [],
      emailTextForm: [],
    });

    this.filterForm.valueChanges.subscribe(({ emailTrigger }) => {
      this.translateService.get(`admin.email-template.type.${emailTrigger}.variables`).subscribe(types => {
        this.emailTriggerVariables = Object.keys(types).map(key => new SelectOption(key, types[key]));
      });
    });
  }

  eventNotificationEmail(includeTo = true): void {
    this.notificationEmail.subject = this.filterForm.value.emailSubject;
    this.notificationEmail.content = this.filterForm.value.emailTextForm;
    this.notificationEmail.event = null;
    this.notificationEmail.trigger = this.filterForm.value.emailTrigger;

    if (includeTo && this.notificationEmail.to) {
      this.notificationEmail.to = this.notificationEmail.to.map((user: User) => user.id);
    }

    this.updateSelectedEmailTemplate();
  }

  previewEmail(event: Event): void {
    this.changeType = false;
    this.preview = {
      subject: this.filterForm.value.emailSubject,
      content: this.filterForm.value.emailTextForm
    };

    this.replaceTemplateVariables(this.filterForm.value.emailSubject, this.filterForm.value.emailTextForm, event);
  }

  replaceTemplateVariables(subject: string, content: string, event: Event) {
    if (content !== null) {
      const domain = window.location.origin;
      const variables = this.emailTriggerVariables.map(trigger => <string>trigger.id);
      const expression = new RegExp(variables.join('|'), 'gi');

      this.translateService.get(variables.map(v => `admin.email-template.variable-defaults.${v}`)).subscribe(e => {
        const variableDefaults = Object.keys(e).map(k => {
          const key = k.split('variable-defaults.')[1];

          let value;
          this.translateService.get(`admin.email-template.variable-defaults.${key}`,
          { domain: domain, event }).subscribe(item => {
            value = item;
          });
          return { key, value };
        });

        const y = {};
        variableDefaults.forEach(item => {
          y[item.key] = item.value;
        });
        if (subject !== null) {
          this.preview.subject = subject.replace(expression, (matched) => y[matched]);
        }
        if (content !== null) {
          this.preview.content = content.replace(expression, (matched) => y[matched]);
        }
      });
    }
  }

  getDefaultEmailTemplate(trigger): void {
    this.changeType = true;
    this.eventsService.getEventNotificationEmail(trigger)
      .subscribe(nf => {
        this.emailExists = false;
        this.notificationEmail = nf;

        this.filterForm.patchValue({
          emailSubject: nf.subject,
          emailTextForm: nf.content
        });
      });
  }
}
