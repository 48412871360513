import { Injectable } from '@angular/core';
import { Environment } from '../environment/environment';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { User } from '../model/user';
import { Profile } from '../model/profile';
import { Registration } from '../model/registration';
import { Observable } from 'rxjs';
import { DEFAULT_PAGINATION_OPTIONS, PaginationOptions } from 'src/app/model/pagination.options';
import { Pagination } from 'src/app/model/pagination';
import { map } from 'rxjs/operators';
import { plainToClass, plainToClassFromExist, classToPlain } from 'class-transformer';
import { Affiliation } from '../model/affiliation';
import { Response } from '../model/response';
import { UserEventLimit } from '../component/user-search/user-search.component';
import { DICT_THEME } from '../model/profile';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private httpClient: HttpClient;

  constructor(private http: HttpClient, private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler); // bypass through the interceptor
  }

  create(signUpData: { firstName: string, lastName: string, email: string }): Observable<User> {
    return this.http.post<Response>(Environment.urls.API + '/core/user/', signUpData)
      .pipe(map(v => plainToClass(User, v.data as User)));
  }

  createUserIgnoreErrorHandling(signUpData: { firstName: string, lastName: string, email: string }): Observable<User> {
    return this.httpClient.post<Response>(Environment.urls.API + '/core/user/', signUpData)
      .pipe(map(v => plainToClass(User, v.data as User)));
  }

  getUserFromToken(): Observable<User>{
    return this.http.get<Response>(Environment.urls.API +  '/core/token/validate/')
      .pipe(map(v => plainToClass(User, v.data as User)));
  }

  getUserProfile(): Observable<User>{
    return this.http.get<Response>(Environment.urls.API +  '/core/userProfile/')
      .pipe(map(v => plainToClass(User, v.data as User)));
  }

  update(user: User): Observable<User> {
    return this.http.put<Response>(Environment.urls.API + `/core/user/${user.id}/`, user)
      .pipe(map(updatedUser => plainToClass(User, updatedUser.data as User)));
  }

  changeLanguage(user:User, language: string): Observable<boolean> {
    const changes = {profile: {language: language}};
    return this.http.put<Response>(Environment.urls.API + `/core/user/${user.id}/`, changes)
      .pipe(map(bool => <boolean>bool.data));
  }

  getTheme(user: User): Observable<string> {
    return this.http.get<Response>(Environment.urls.API + `/core/user/${user.id}/theme/`)
      .pipe(map(res => res.data.theme));
  }

  changeTheme(user: User, selectedTheme: string): Observable<User> {
    selectedTheme = Object.keys(DICT_THEME).find(key => DICT_THEME[key] === selectedTheme);

    return this.http.put<Response>(Environment.urls.API + `/core/user/${user.id}/theme/`, { theme: selectedTheme })
      .pipe(map(updatedUser => plainToClass(User, updatedUser.data as User)));
  }

  refresh(user: User): Observable<User> {
    return this.http.get<Response>(Environment.urls.API + `/core/user/${user.id}/`)
      .pipe(map(updatedUser => plainToClass(User, updatedUser.data as User)));
  }

  getUserProfileImage(user: User): Observable<Profile> {
    return this.http
      .get<Profile>(`${Environment.urls.API}/core/user/${user.id}/`)
      .pipe(map(v => plainToClass(Profile, v as Profile)));
  }

  changePicture(user: User, image: File, imageAvatar: File): Observable<Profile> {
    const formData = new FormData();
    formData.append('pic', image, image.name);
    formData.append('picAvatar', imageAvatar, imageAvatar.name);

    return this.http.put<Response>(Environment.urls.API + `/core/user/image/${user.id}/`, formData)
      .pipe(map(profile => plainToClass(Profile, profile.data as Profile)));
  }

  removePicture(id: number): Observable<Response> {
    return this.http.delete<Response>(Environment.urls.API + `/core/user/image/${id}/`);
  }

  completeRegistration(userData: Registration): Observable<string> {
    return this.http.post<string>(Environment.urls.API + '/core/auth/activate/', userData);
  }

  updatePassword(userId, userData): Observable<User> {
    return this.http.post<Response>(Environment.urls.API + `/core/auth/password/change/${userId}/`, userData).pipe(
      map(v => v.data as User)
    );
  }

  resendActivationEmail(email: string): Observable<Response> {
    return this.http.post<Response>(Environment.urls.API + '/core/auth/activate/sendEmail/', { email });
  }

  getAll(): Observable<User[]> {
    return this.http.get<User[]>(Environment.urls.API + '/core/user/list/').pipe(map(users => plainToClass(User, users)));
  }

  createAffiliation({ name, alternativeName, acronym, domainEmail }): Observable<Affiliation> {
    return this.http.post<Response>(Environment.urls.API + '/core/institution/', {
      name, alternativeName, acronym, domainEmail
    }).pipe(map(v => plainToClass(Affiliation, v.data as Affiliation)));
  }

  search(search: string, userEventLimit?: UserEventLimit): Observable<User[]> {
    const params: { search: string, event?: string, role?: string, exclude_role?: string } = {
      search
    };

    if (userEventLimit) {
      params.event = userEventLimit.event.toString();
      params.role = userEventLimit.role.toString().toUpperCase();
      params.exclude_role = userEventLimit.exclude_role.toString();
    }

    return this.http
      .get<Response>(Environment.urls.API + '/core/user/search/', { params })
      .pipe(map(value => plainToClass(User, value.data as User[])));
  }

  searchConflicts(search: string, userEventLimit?: UserEventLimit): Observable<User[]> {
    const params: { search: string, event?: string, role?: string, exclude_role?: string } = {
      search
    };

    if (userEventLimit) {
      params.event = userEventLimit.event.toString();
      params.role = userEventLimit.role.toString().toUpperCase();
      params.exclude_role = userEventLimit.exclude_role.toString();
    }

    return this.http
      .get<Response>(Environment.urls.API + '/core/user/searchConflicts/', { params })
      .pipe(map(value => plainToClass(User, value.data as User[])));
  }

  getUser(id: number): Observable<User> {
    return this.http
      .get<Response>(`${Environment.urls.API}/core/user/${id}/`)
      .pipe(map(v => plainToClass(User, v.data as User)));
  }

  getConflictsInterest(userId: number, options: PaginationOptions = DEFAULT_PAGINATION_OPTIONS): Observable<Pagination<User>> {
    const params = { params: {} };

    if (options.pageNumber) {
      params.params['pageNumber'] = String(options.pageNumber);
    }

    if (options.pageSize) {
      params.params['pageSize'] = String(options.pageSize);
    }

    return this.http
      .get<Response>(`${Environment.urls.API}/core/user/conflictInterest/${userId}/`, params)
      .pipe(map(v => plainToClassFromExist(new Pagination<User>(User), v.data)));
  }

  addConflictInterest(userId: number, conflictUser: User): Observable<Response> {
    return this.http.post<Response>(`${Environment.urls.API}/core/user/conflictInterest/${userId}/`, conflictUser);
  }

  addAffiliationConflictInterest(userId: number, affiliation: Affiliation): Observable<User[]> {
    return this.http
      .post<Response>(`${Environment.urls.API}/core/user/conflictInterest/${userId}/institution/`, affiliation)
      .pipe(map(value => plainToClass(User, value.data as User[])));
  }

  deleteConflictInterestList(userId: number, conflictUserId: number[]): Observable<User[]> {
    const params = { users: conflictUserId.map(u => ({ id: u, deleted: true })) };

    return this.http
      .put<Response>(`${Environment.urls.API}/core/user/conflictInterest/${userId}/`, params)
      .pipe(map(value => plainToClass(User, value.data as User[])));
  }

  getSuperUsers(): Observable<User[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/user/superUsers/all/`)
      .pipe(map(response => plainToClass(User, response.data as Array<User>)));
  }

  updateSuperUser(user: number, status: boolean): Observable<Response> {
    return this.http.put<Response>(`${Environment.urls.API}/core/user/superUsers/${user}/`, { flagSuperUser: status });
  }
}
