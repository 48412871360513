import { style, animate, transition, trigger } from '@angular/animations';

// Style values for IN and OUT states of component
const styleStateIn = { height: '*', opacity: 1, 'overflow-y': 'hidden' };
const styleStateOut = { height: 0, opacity: 0, 'overflow-y': 'hidden' };

export const slideInAnimation = trigger('slideIn', [
 transition(':enter', [
   style(styleStateOut),
   animate('0.1s ease-in', style(styleStateIn)) // modify here the time on slide In
 ])
]);

export const slideOutAnimation = trigger('slideOut', [
 transition(':leave', [
   style(styleStateIn),
   animate('0.2s ease-out', style(styleStateOut)) // modify here the time of slide Out
 ])
]);
