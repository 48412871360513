import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, Route } from '@angular/router';
import { SidebarService } from '../../service/sidebar.service';
import { EventsService } from '../../service/events.service';
import { Event } from '../../model/event';
import { AdminService } from '../../service/admin.service';
import { SidebarItem, AdminSidebarItem } from '../../model/sidebar-item';
import { NavbarService } from '../../service/navbar.service';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { FixedTopProgressBarComponent } from 'src/app/component/fixed-top-progress-bar/fixed-top-progress-bar.component';
import { log } from 'console';

@Component({
  selector: 'app-event-admin',
  templateUrl: './event-admin.component.html',
  styleUrls: ['./event-admin.component.scss']
})
export class EventAdminComponent implements OnInit, OnDestroy {
  eventId: number;
  event: Event;
  sidebarItems: Array<SidebarItem>;
  subscriptions: Subscription[] = [];

  @ViewChild(FixedTopProgressBarComponent, { static: true }) progressBar: FixedTopProgressBarComponent;

  constructor(
    private sidebarService: SidebarService,
    private navbarService: NavbarService,
    private adminService: AdminService,
    private eventsService: EventsService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.navbarService.loadingStop();
  }

  updateHeadTitle(){
    let eventAcronymYear = this.event.acronym + " " + this.event.year + " - ";
    let oldTitle = this.titleService.getTitle();
    if (!oldTitle.includes(eventAcronymYear)){
      this.titleService.setTitle(eventAcronymYear + this.titleService.getTitle());
    }
  }

  ngOnInit() {
    this.adminService.progressBar = this.progressBar;
    this.navbarService.progressBar = this.adminService.progressBar;

    this.route.params.subscribe(({ eventId }) => {
      this.eventId = Number.parseInt(eventId, 10);

      this.adminService.fetchSelectedEvent(eventId);

      this.eventsService.getEvent(this.eventId).subscribe(event => {
        this.event = event;
        this.updateHeadTitle();
      });

      this.subscriptions.push(this.router.events.subscribe(value => {
        if(value instanceof NavigationEnd){
          this.updateHeadTitle();
        }
      }));

      setTimeout(() => {
        this.sidebarItems = [
          new SidebarItem(`/admin/${this.eventId}`, { name: 'fa-lightbulb', font: 'far' }, 'menus.sidebar.event', {
            noLink: true,
            children: [
              new AdminSidebarItem('menus.admin.event.dashboard', `/admin/${this.eventId}`),
              new AdminSidebarItem('menus.admin.event.checklist', `/admin/${this.eventId}/checklist`),
              new AdminSidebarItem('menus.admin.settings.event', `/admin/${this.eventId}/settings`),
              new AdminSidebarItem('menus.admin.settings.topics', `/admin/${this.eventId}/settings/topics`),
              new AdminSidebarItem('menus.admin.people.members', `/admin/${this.eventId}/people/members`)
            ]
          }),
          new SidebarItem(`/admin/${this.eventId}/submissions`, { name: 'fa-file-alt', font: 'far' }, 'menus.sidebar.submissions', {
            divider: false,
            noLink: true,
            children: [
              new AdminSidebarItem('menus.admin.settings.tracks', `/admin/${this.eventId}/settings/tracks`),
              // new AdminSidebarItem('menus.sidebar.submissions', `/admin/${this.eventId}/submissions`),
              new AdminSidebarItem('menus.admin.submissions.ranking', `/admin/${this.eventId}/submissions/ranking`),
              // new AdminSidebarItem('menus.admin.submissions.missing-manuscripts', `/admin/${this.eventId}/submissions/missing-manuscripts`),
              // new AdminSidebarItem('menus.admin.submissions.groups', `/admin/${this.eventId}/submissions/groups`),
              new AdminSidebarItem('menus.admin.submissions.submission-form', `/admin/${this.eventId}/submissions/submission/form`),
              new AdminSidebarItem('menus.admin.submissions.notify-authors', `/admin/${this.eventId}/submissions/notify`),
              new AdminSidebarItem('menus.admin.submissions.show-all', `/admin/${this.eventId}/submissions/show-all`),
              new AdminSidebarItem('menus.admin.submissions.statistics', `/admin/${this.eventId}/submissions/statistics`)
            ]
          }),
          new SidebarItem(`/admin/${this.eventId}/reviews/configuration`, { name: 'fa-balance-scale', font: 'fas' }, 'menus.sidebar.reviews', {
            divider: false,
            noLink: true,
            children: [
              new AdminSidebarItem('menus.admin.reviews.configuration', `/admin/${this.eventId}/reviews/configuration`),
              new AdminSidebarItem(
                'menus.admin.reviews.assignment-suggestions',
                `/admin/${this.eventId}/reviews/assignment-suggestions`
              ),
              new AdminSidebarItem('menus.admin.reviews.review-form', `/admin/${this.eventId}/reviews/review/form`),
              new AdminSidebarItem('menus.admin.reviews.notify', `/admin/${this.eventId}/reviews/reviewers/notify`),
              new AdminSidebarItem(
                'menus.admin.reports.reviews.statusBySubmission',
                `/admin/${this.eventId}/reviews/reports/statusBySubmission`
              ),
              new AdminSidebarItem(
                'menus.admin.reports.reviews.statusByUser',
                `/admin/${this.eventId}/reviews/reports/statusByUser`
              )
            ]
          }),
          new SidebarItem(`/admin/${this.eventId}/emails`, { name: 'fa-paper-plane', font: 'far' }, 'menus.sidebar.e-mails', {
            divider: false,
            noLink: true,
            children: [
              new AdminSidebarItem('menus.admin.emails.send-email', `/admin/${this.eventId}/emails/send-email`),
              new AdminSidebarItem('menus.admin.emails.emailStatus', `/admin/${this.eventId}/emails/emailStatus`),
              new AdminSidebarItem('menus.admin.emails.emailTemplate', `/admin/${this.eventId}/emails/emailTemplates`)
            ]
          }),
          new SidebarItem(`/admin/${this.eventId}/program`, { name: 'fa-calendar-alt', font: 'far' }, 'menus.sidebar.sessions', {
            divider: false,
            noLink: true,
            children: [
              new AdminSidebarItem('menus.admin.sessions.calendar', `/admin/${this.eventId}/program/calendar`),
              new AdminSidebarItem('menus.admin.sessions.program', `/admin/${this.eventId}/program/list`)
            ]
          }),
          new SidebarItem(`/admin/${this.eventId}/publication`, { name: 'fa-book', font: 'fas' }, 'menus.sidebar.publication', {
            divider: false,
            noLink: true,
            children: [
              new AdminSidebarItem('menus.admin.publication.controls', `/admin/${this.eventId}/publication`),
              new AdminSidebarItem('menus.admin.publication.status', `/admin/${this.eventId}/publication/status`),
              new AdminSidebarItem('menus.admin.publication.proceedings', `/admin/${this.eventId}/publication/proceedings`),
              new AdminSidebarItem('menus.admin.submissions.download-submission-files', `/admin/${this.eventId}/submissions/submission-files`),
              new AdminSidebarItem('menus.admin.submissions.bibtex-export', `/admin/${this.eventId}/submissions/bibtex-export`)
            ]
          }),
          // new SidebarItem(`/admin/${this.eventId}/settings`, { name: 'fa-cogs', font: 'fas' }, 'menus.sidebar.settings', {
          //   divider: false,
          //   noLink: true,
          //   children: [
          //     new AdminSidebarItem('menus.admin.settings.topics', `/admin/${this.eventId}/settings/topics`),
          //     new AdminSidebarItem('menus.admin.settings.tracks', `/admin/${this.eventId}/settings/tracks`),
          //     new AdminSidebarItem('menus.admin.settings.emailTemplate', `/admin/${this.eventId}/settings/emailTemplates`),
          //     new AdminSidebarItem('menus.admin.settings.emailStatus', `/admin/${this.eventId}/settings/emailStatus`),
          //   ]
          // })
        ];
      });
    });
  }
}
