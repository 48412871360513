import { Component, OnInit, Input } from '@angular/core';
import { FormField } from '../../class/form-field';
import { FormType } from 'src/app/enum/form.type';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-custom-form-radio-question',
  templateUrl: './custom-form-radio-question.component.html',
  styleUrls: ['./custom-form-radio-question.component.scss']
})
export class CustomFormRadioQuestionComponent extends FormField implements OnInit {
  constructor(public route: ActivatedRoute) {
    super();
  }
  ngOnInit() {
    this.onChanges();
  }
  onChanges(): void {
  }
}
