import { Component, OnInit, Input, HostListener, ElementRef, AfterViewInit } from '@angular/core';
import { single1, single2, single3, multi } from './data';
import { AdminService } from 'src/app/service/admin.service';
import { StatisticsService } from 'src/app/service/statistics.service';
import { TranslateService } from '@ngx-translate/core';
import { Event } from 'src/app/model/event';
import { formattedError } from '@angular/compiler';
import html2canvas from 'html2canvas';
import * as domToImage from 'dom-to-image';



const defaultColors= ['#647c8a', '#3f51b5', '#2196f3', '#00b862', '#afdf0a', '#a7b61a'].reverse();
const statuses: string[] = ["Active", "Pending", "Accepted", "Rejected", "Withdrawn"];

@Component({
  selector: 'app-chart-component-v2',
  templateUrl: './chart-component-v2.component.html',
  styleUrls: ['./chart-component-v2.component.scss']
})
export class ChartComponentV2Component implements OnInit {
  @Input() chartType: string;
  @Input() chartName: string;
  
  // Options
  // Default chart width and height;
  view: [number, number]= [500, 400]; 
  xAxisLabel: string;
  yAxisLabel: string;
  legendTitle: string;
  data: any[];
  colorScheme = {
    domain: defaultColors
  };
  // maxValue to the gauge chart
  maxValue: number = 1;

  // Flag that determines whether the chart is showed or not
  hasData: boolean = false;

  // Flag that determines whether the chart display labels to the data
  showChartLabels: boolean =false;

  constructor(
    public adminService: AdminService,
    private elRef: ElementRef,
    private statisticsService: StatisticsService,
    public translateService: TranslateService,
  ) {}
  
  ngOnInit(): void {
    this.setChartConfig();
    this.updateChartWidth();
  }

  setChartConfig(){
    // Switch between the given chart (input) to set the configuration
    switch(this.chartName){
      case 'tracks':
        // GROUPED BAR CHART
        this.xAxisLabel = this.translateService.instant('admin.event.statistics.chart.axis.status');
        this.yAxisLabel = this.translateService.instant('admin.event.statistics.chart.axis.submissions');
        this.legendTitle = this.translateService.instant('admin.event.statistics.tables.tracks-submission.track');
        setTimeout(() => {
          this.statisticsService.trackStatus$.subscribe((data: Array<any>) => {
            // Handle chart rendering when data is available
            if(data){
              this.data = statuses.map(status =>({
                name: status,
                series: data.map(track => ({ name: track.track, value: track[status.toLowerCase()]  }))
              }))

              this.translateChartLabels(this.data)

              // Check if theres data in any of the elements
              this.hasData=false
              for (const dict of this.data) {
                if(dict.series.length > 0){
                  this.hasData=true
                  break;
                  }
              }
            }
          });
        });
        break;
      case 'countries':
        // GROUPED BAR CHART
        this.xAxisLabel = this.translateService.instant('admin.event.statistics.chart.axis.status');
        this.yAxisLabel = this.translateService.instant('admin.event.statistics.chart.axis.submissions');
        this.legendTitle = this.translateService.instant('admin.event.statistics.tables.authors-country.country');
        setTimeout(() => {
          this.statisticsService.countryStatus$.subscribe((data: Array<any>) => {
            // Handle chart rendering here when data is available
            if(data){
              const dataCopy = data.map(item => ({ ...item }));
              const sortedData = dataCopy.sort((a, b) => b.total - a.total)
              // Group the series of each status using function
              
              this.data = statuses.map(status =>({
                name: status,
                series: groupData(sortedData.map(country => (
                  {
                  // Format into dictionary, for each status (fetching by lowercase key)
                  name: country.country, value: country[status.toLowerCase()]
                })))
              }))
              this.translateChartLabels(this.data)

              // Check if theres data in any of the elements
              this.hasData=false
              for (const dict of this.data) {
                if(dict.series.length > 0){
                  this.hasData=true
                  break;
                  }
              }
            }
          });
        });
        break;
        case 'topics':
          // GAUGE CHART
          // Get legend title based on translation
          this.legendTitle = this.translateService.instant('admin.event.statistics.tables.topics-submission.topic');
          this.view = [,450]
          this.statisticsService.topicStatus$.subscribe((data: Array<any>) => {
            // Handle chart rendering here when data is available
            if(data){
              this.statisticsService.activeOrderStatusTopic$.subscribe((activeOrderStatus: String) => {
                if(!activeOrderStatus){
                  activeOrderStatus = 'total'
                }else if(activeOrderStatus=='total-valid'){
                  activeOrderStatus = 'valid'
                }

                // Update the name of the axis according to the column used to order the table
                // Get axis table based on translation
                if(activeOrderStatus=='total'){
                  this.xAxisLabel = this.translateService.instant('admin.event.statistics.tables.topics-submission.total')
                }else if(activeOrderStatus=='valid'){
                  this.xAxisLabel = this.translateService.instant('admin.event.statistics.tables.topics-submission.total-valid')
                }else{
                  this.xAxisLabel = this.translateService.instant('admin.event.statistics.tables.status.'+activeOrderStatus)
                }

                // Group the series of each status using function
                // [(the most five numerous data), 'Others']
                const sortedData = [...data].sort((a, b) => a[activeOrderStatus.toString()] < b[activeOrderStatus.toString()] ? 1 : -1)
                this.data = groupData(sortedData.map(topic =>({
                  name: topic.topic,
                  value: topic[activeOrderStatus.toString()]
                })))
                // If there is new data, update max value (if its not zero)
                if(this.data.length>0){
                  const max = this.data.reduce((max, current) => current.value > max.value ? current : max).value
                  if(max>0){
                    this.updateMaxValue(max)
                  }
                }
              })
              // Check if theres data
              this.hasData=this.data.length > 0
            }
          });
        break;
    }
  }

  // Listen for window resize events and update the chart width
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateChartWidth();
  }


  translateChartLabels(data: any[]){
    // Change the data of the graph so that the labels (statuses) translates
    data.map(status => {
      if(status.name=='total'){
        status.name = this.translateService.instant('admin.event.statistics.tables.topics-submission.total')
      }else{
        status.name = this.translateService.instant('admin.event.statistics.tables.status.'+status.name.toLowerCase())
      }
      return status
    })
  }

  updateChartWidth() {
    // Access the current component's element
    const currentElement = this.elRef.nativeElement as HTMLElement;

    // Traverse up the DOM to find the parent element with the class 'mat-tab-body-content'
    const parentElement = currentElement.closest('.mat-tab-body') as HTMLElement;

    if (parentElement) {
      // Get the width of the parent element
      const parentWidth = parentElement.clientWidth;
      const currentHeight = this.view[1];

      // Set the chart width to fit within the parent, with a minimum width of 800px (the height isn't changed)
      this.view = [Math.max(parentWidth*0.95-1, 800), currentHeight];
    }
  }
  saveAsImage(type: String){
    // Capture the element of the chart
    const chartContainer = document.querySelector('.chart-v2') as HTMLElement;
    if (chartContainer) {
      switch(type){
        case 'png':
          html2canvas(chartContainer, {logging: false}).then((canvas) => {
            // Create fake element to click
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = 'chart.png';
            link.click();
          }).catch(error => {
            console.error('Error capturing the chart:', error);
          });
        break;
        case 'svg':
          domToImage.toSvg(chartContainer, {logging: false}).then((dataUrl: string) => {
            // Create fake element to click
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = 'chart.svg';
            link.click();    
          }).catch((error) => {
            console.error('Error capturing the chart:', error);
          });
        break;
      }
    }
  }
  updateMaxValue(max: number){
    // Get the first multiple of 10 after the given maximum
    // The gauge chart has 11 big separator, this way if the max value is multiple of 10, the separator labels will be integers
    let i=0
    while(i<max) i+=10;
    this.maxValue=i;
  }
}


function groupData(arr: any[]){
  if(arr.length>6){
    // Take the top 5 items
    const topFive = arr.slice(0, 5);

    // Calculate the sum of the rest of the items
    const othersValue = arr.slice(5).reduce((sum, current) => sum + current.value, 0);

    // Create the "Others" entry
    const others = {
      name: 'Others',
      value: othersValue
    };

    // Return a new item with the top five items plus the "Others" entry
    return [...topFive, others]
  }else{
    return arr;
  }
}
