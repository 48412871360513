import { ReviewStatus } from '../enum/review.status';
import { User } from './user';
import { Type } from 'class-transformer';
import { Transform } from 'class-transformer';
import * as dayjs from 'dayjs';
import { toDayjs } from './baseModel';

export class PaperReviewHistory {
  @Transform(toDayjs)
  public createdAt: dayjs.Dayjs;

  public review: number;

  public status: ReviewStatus;

  @Type(() => User)
  public by: User;

  @Type(() => User)
  public to: User;

  public host: string;
}
