import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { SystemService } from '../../service/system.service';
import { PasswordValidator } from '../../validator/password.validator';
import { ActivatedRoute } from '@angular/router';
import { Registration } from 'src/app/model/registration';
import { Subscription } from 'rxjs';
import { Affiliation } from 'src/app/model/affiliation';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { CreateAffiliationComponent } from 'src/app/component/create-affiliation/create-affiliation.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { InputFilterAutocompleteComponent } from 'src/app/component/input-filter-autocomplete/input-filter-autocomplete.component';

@Component({
  selector: 'app-email-confirmed',
  templateUrl: './email-confirmed.component.html',
  styleUrls: ['./email-confirmed.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(200)]),
      transition(':leave', animate(200, style({ opacity: 0 })))
    ])
  ]
})
export class EmailConfirmedComponent implements OnInit {
  user = JSON.parse(atob(this.route.snapshot.paramMap.get('userb64')));
  registrationCompleted: boolean;
  errorInRegisterMessage: string;
  completeRegistrationForm: FormGroup;
  unknownAffiliation = false;

  private newAffiliationName: string;
  private newAffiliationAcronym: string;
  public allAffiliations: Affiliation[];
  public modelAffiliation = { dataBase: 'filter', value_1: 'filter' };
  private newAffiliationWasCreated: boolean = false;

  @ViewChild(CreateAffiliationComponent) createAffiliation: CreateAffiliationComponent;
  @ViewChild(InputFilterAutocompleteComponent) inputFilterAutocomplete: InputFilterAutocompleteComponent;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    public systemService: SystemService,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.systemService.getAllAffiliations().pipe(map(res => res.data))
      .subscribe((Affiliations: Affiliation[]) => this.allAffiliations = [...Affiliations]);
    this.initForm();
  }

  private showDialogMessage(message: string): void {
    this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: message
        }
      });
  }

  public OpenDialogCreateAffiliation(params): void {
    if (params.value) {
      const dialogConfig = new MatDialogConfig();
  
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '30rem';
      dialogConfig.data = {
        affiliations: this.allAffiliations,
      }
  
      const dialogRef = this.dialog.open(CreateAffiliationComponent, dialogConfig);
  
      dialogRef.afterClosed().subscribe(
        (data) => {
          if (!!data) {
            this.newAffiliationWasCreated = true;
            this.newAffiliationName = data.name;
            this.newAffiliationAcronym = data.acronym;
            this.setNewAffiliationAfterCreate(this.newAffiliationAcronym, this.newAffiliationName);
          }
          this.blurInputAffiliation();
        }
      );
    }
  }

  private setNewAffiliationAfterCreate(acronym: string, name: string): void {
    const newAffiliation: string = this.buildStringAffiliation(acronym, name);
    this.completeRegistrationForm.get('affiliation').setValue(newAffiliation);
  }

  private buildStringAffiliation(acronym: string, name: string): string {
    if (!!name && !!acronym) {
      return `${acronym}, ${name}`;
    } else if (!!name) {
      return name;
    } else if (!!acronym) {
      return acronym;
    } else {
      return '';
    }
  }

  private findAffiliationID(affiliationForm: string): number {
    for (let affiliation of this.allAffiliations) {
      if (affiliation.filter === affiliationForm) {
        return affiliation.id;
      }
    }
    return -1;
  }

  public checkAffiliation(): void {
    this.newAffiliationWasCreated ? this.submitNewAffiliation() : this.submit();
  }

  private submitNewAffiliation(): void {
    const name = this.newAffiliationName;
    const acronym = this.newAffiliationAcronym;
    const alternativeName = undefined;
    const domainEmail = undefined;
    
    this.userService.createAffiliation({ name, alternativeName, acronym, domainEmail })
    .subscribe((data: Affiliation) => this.submit(data.id));
  }

  submit(newAffiliationID: number = undefined): Subscription {
    if (this.completeRegistrationForm.valid) {
      const { token, uid, firstName, lastName, email, password, confirmPassword, affiliation, country } = this.completeRegistrationForm.value;
      const affiliationId = newAffiliationID ?? this.findAffiliationID(affiliation);

      if (affiliationId != -1) {
        return this.registration({
          token, uid, firstName, lastName, email, password, confirmPassword, country, affiliationId
        });
      } else {
        this.showDialogMessage('messages.affiliation-form-invalid');
      }
    }
  }

  registration({ token, uid, firstName, lastName, email, password, confirmPassword, country, affiliationId }): Subscription {
    const registration = new Registration(
      token, uid, firstName, lastName,
      email, password, confirmPassword, affiliationId, country
    );

    return this.userService.completeRegistration(registration).subscribe(
      response => this.registrationCompleted = true,
      error => this.errorInRegisterMessage = error.error
    );
  }

  private initForm(): void {
    this.completeRegistrationForm = this.fb.group({
      token: [this.route.snapshot.paramMap.get('token'), [Validators.required]],
      uid: [this.route.snapshot.paramMap.get('uidb64'), [Validators.required]],
      firstName: [this.user.firstName, [Validators.required]],
      lastName: [this.user.lastName, [Validators.required]],
      email: [{ value: this.user.email, disabled: true }, [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
      affiliation: ['', [Validators.required]],
      country: ['', [Validators.required]]
    }, {
      validators: PasswordValidator.MatchPassword
    });
  }

  private blurInputAffiliation(): void {
    this.inputFilterAutocomplete.input.nativeElement.blur();
    this.inputFilterAutocomplete.inputAutoComplete.closePanel();
  }
}
