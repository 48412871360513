export class Rank {
  average: number;
  weightedAverage: number;
  span: number;
  globalWeight: number;

  byReview?: ByReviewRank;

  range?: {
    average: {
      high: number,
      low: number
    },
    weight: {
      high: number,
      low: number
    }
  };

  fields: Array<{
    [field: number]: {
      description: string,
      answers: number
    }
  }>;

  getReviewFieldAverage(fieldIndex: number): number {
    return this.byReview.answers.reduce((acc, reviewAnswers) => acc + reviewAnswers[fieldIndex], 0) / this.byReview.answers.length;
  }

  getReviewFieldWeight(): number {
    return this.byReview.weight.reduce((acc, reviewWeight) => acc + reviewWeight, 0) / this.byReview.weight.length;
  }

  getReviewFieldRange(fieldIndex: number): number {
    return -Math.max(...this.byReview.answers.map(reviewAnswers => reviewAnswers[fieldIndex]));
  }
}

class ByReviewRank {
  reviews: Array<{
    id: string,
    reviewer: { id: number, name: string },
    answers: Array<number>,
    average?: number,
    weight?: number,
  }>;
  labels: Array<string>;
  answers: Array<Array<number>>;
  average: Array<number>;
  weight: Array<number>;
}
