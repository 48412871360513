import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { SubmissionStatus } from '../../enum/submission.status';
import { PreferencesService } from '../../service/preferences.service';
import { Review } from '../../model/paper';
import { Submission } from '../../model/paper';
import { ReviewStatus } from 'src/app/enum/review.status';

@Component({
  selector: 'app-paper-status',
  templateUrl: './paper-status.component.html',
  styleUrls: ['./paper-status.component.scss']
})
export class PaperStatusComponent implements OnInit, OnChanges {
  @Input() element: Submission | Review;

  @Input() showStatusString = false;
  @Input() forceStatusIcon: boolean;
  @Input() forceSubmissionStatus = false;
  @Input() hideBackgroundColor = false;
  @Input() showStatusMobile = false;

  @Input() fieldsShow: Array<string> = ['id', 'status'];

  public statusIcon: { fontSet: string, fontIcon: string };
  public statusClass: Array<string>;
  public statusString: string;

  public headerString: string;

  constructor(
    public preferencesService: PreferencesService
  ) { }

  ngOnInit() {
    if (this.element instanceof Submission) {
      this.statusIcon = SubmissionStatus.icon(this.element.status);
      this.statusClass = [SubmissionStatus.toClass(this.element.status), 'submission'];
      this.statusString = SubmissionStatus.toString(this.element.status);
    } else if (this.forceSubmissionStatus) {
      this.statusIcon = SubmissionStatus.icon(this.element.submission.status);
      this.statusClass = [SubmissionStatus.toClass(this.element.submission.status), 'submission'];
      this.statusString = SubmissionStatus.toString(this.element.submission.status);
    } else {
      this.statusIcon = ReviewStatus.icon(this.element.status);
      this.statusClass = [ReviewStatus.toClass(this.element.status), 'review'];
      this.statusString = ReviewStatus.toString(this.element.status);
    }

    if (this.hideBackgroundColor) {
      this.statusClass.push('no-background');
    }
  }
  
  ngOnChanges(): void {
    this.ngOnInit();
  }

  get elementId(): number {
    // Always show submission ID
    return this.element instanceof Review ? this.element.submission.id : this.element.id;
  }
}
