import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Event } from 'src/app/model/event';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SelectOption } from 'src/app/model/select.option';
import { AdminService } from 'src/app/service/admin.service';
import { StatisticsService, AuthorCountries } from 'src/app/service/statistics.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Dictionary } from 'src/app/interface/idictionary';

let unknownCountriesLabel : String;
@Component({
  selector: 'app-submission-author-statistics',
  templateUrl: './submission-author-statistics.component.html',
  styleUrls: ['./submission-author-statistics.component.scss']
})

export class SubmissionAuthorStatisticsComponent implements OnInit, OnDestroy {
  @Input() event: Event;

  form: FormGroup;
  subscriptions: Subscription[] = [];
  haveSubmissions: boolean = false;

  filterControl = new FormControl('');
  tracks: Array<SelectOption>;
  submissionGroups: Array<SelectOption>;
  options: any;
  
  authorsCountry : Array<any>;

  tableColumns = ['country', 'pending', 'active', 'withdrawn', 'accepted', 'rejected', 'total']

  // Flag that determines whether the unknown countries are considered in the data
  hideUnknown: boolean =false;

  // The number of items before filtering
  originalAuthorsCountrySize: Number =0;

  filterParams : Record<any,any>= {}
  constructor(
    private statisticsService: StatisticsService,
    protected adminService: AdminService,
    public fb: FormBuilder,
    public translateService: TranslateService,
  ) { }

  ngOnInit() {
    // Get the label of the unknown countries (just once at start)
    unknownCountriesLabel = this.translateService.instant('dataset-options.unknownCountryLabel')
    setTimeout(() => {
      this.adminService.progressBar.start();
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
        }, () => {
        }, () => {
          this.initComponent();
          this.getDataAuthorsCountryBySubmission({}, true);
        });
      } else {
        this.initComponent();
        this.getDataAuthorsCountryBySubmission({}, true);
      }

      this.setFilters();
    });
  }

  initComponent(){
    this.form = this.fb.group({
      tracks: [],
      submissionGroups: [],
    });

    this.tracks = this.event.tracks.map(t => {
      return new SelectOption(t.id, t.name);
    });

    this.adminService.eventsService.getEventPaperGroups(this.event.id).subscribe(paperGroups => {
      this.submissionGroups = paperGroups.map(g => 
        new SelectOption(g.id, g.name)
      );

      if (this.submissionGroups.length === 0) {
        this.form.controls['submissionGroups'].disable();
      }
    });
  }

  getDataAuthorsCountryBySubmission(params, init: boolean){
    this.statisticsService.getAuthorsCountry(params, this.event.id).subscribe( datasource => {
      this.authorsCountry = datasource.items;
      this.authorsCountry.map(country =>  {
        // Handle the null country names
        if(!country.country){
          country.country = unknownCountriesLabel
        }
      })
      this.statisticsService.setCountriesStatus(this.authorsCountry)
    }, () => {
      this.adminService.progressBar.stop();
    },() => {
      if (init) {
        if (this.authorsCountry.length > 0 ){
          this.originalAuthorsCountrySize = this.authorsCountry.length
          this.haveSubmissions = true;
        } else {
          this.haveSubmissions = false;
        }
      }
      this.adminService.progressBar.stop();
    });
  }

  hideOrShowUnknown(){
    if(this.hideUnknown){
      this.authorsCountry = this.authorsCountry.filter(item => item.country !== unknownCountriesLabel);
      this.statisticsService.setCountriesStatus(this.authorsCountry)
    }else{
      // Use the last filter parameters to get data back  (before filtering to remove unknown)
      this.getDataAuthorsCountryBySubmission(this.filterParams, false);
    }
  }

  setFilters() {
    this.subscriptions.push(
      this.form.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe( value => {
          this.adminService.progressBar.start();
          this.filterParams = {}
          if (value.tracks && value.tracks.length > 0) {
            this.filterParams['tracks'] = value.tracks
          }
          if (value.submissionGroups && value.submissionGroups.length > 0) {
            this.filterParams['submission_groups'] = value.submissionGroups
          }
          this.getDataAuthorsCountryBySubmission(this.filterParams, false);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
