import { Injectable } from '@angular/core';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ErrorService } from '../service/error.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(public errorService: ErrorService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap(() => {}, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        this.errorService.handleError(err);
      } else {
        return err;
      }
    }));
  }
}
