import { Submission } from 'src/app/model/paper';
import { UserEvent } from 'src/app/model/user.event';
import { TopicInterest } from 'src/app/model/user.event.topics.interest';
import { SelectOption } from 'src/app/model/select.option';


export type AssignmentTestFunction = (s: Submission, u: UserEvent, maxReviews: number) => boolean;
export type WeightFunction = (user: UserEvent, submission: Submission, positiveMatchWeight?: number, negativeMatchWeight?: number) => number;


export class UserAssignInfo {
  submissionToAssign: SubmissionAssignSuggestions;
  weight: number;
  motive = '';
  elegible = true;

  constructor(
    public submission: Submission,
    public userPreferences: UserEvent
  ) { }
}


export class SubmissionAssignSuggestions {
  possiblesAssign: Array<UserAssignInfo> = [];
  suggestedAssign: Array<UserAssignInfo> = [];
  options?: Array<SelectOption>;
  selectControls; // some name suggestion and type to this method ? has the style of { key : UserAssignInfo }

  constructor(
    public submission: Submission
  ) {
    this.suggestedAssign = [];
    this.selectControls = {};
  }
}
