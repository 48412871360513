import { Component, OnInit } from '@angular/core';
import { Event } from 'src/app/model/event';
import { AdminService } from 'src/app/service/admin.service';
import { WizardStep, WizardTask } from 'src/app/model/wizard';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { slideOutAnimation } from 'src/app/animations/slideInOut';

@Component({
  selector: 'app-event-wizard',
  templateUrl: './event-wizard.component.html',
  styleUrls: ['./event-wizard.component.scss'],
  animations: [
    slideOutAnimation
  ]
})
export class AdminEventWizardComponent implements OnInit {
  steps: WizardStep[];
  event: Event;

  loading = false;

  constructor(
    private adminService: AdminService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.getSteps(this.event.id);
        });
      } else {
        this.getSteps(this.event.id);
      }
    });
  }

  enableLoading() {
    this.loading = true;
  }

  disableLoading() {
    this.loading = false;
  }

  getSteps(event: number) {
    this.enableLoading();
    this.adminService.getWizardSteps(event).subscribe(steps => {
      this.steps = steps;
      this.disableLoading();
    });
  }

  updateTask(task: WizardTask) {
    this.enableLoading();
    this.adminService.checkWizardTask(this.event.id, [{ id: task.id, checked: task.checked }]).subscribe(steps => {
      this.steps = steps;
      this.disableLoading();
    });
  }

  checkAllTasks(checked: boolean) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: `admin.checklist.confirm-batch-check-${checked}`,
        content: '',
      }
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.enableLoading();
        const taskCheckList = this.steps.map(step => step.tasks.map(task => ({ id: task.id, checked }))).flat(1);

        this.adminService.checkWizardTask(this.event.id, taskCheckList).subscribe(steps => {
          this.steps = steps;
          this.disableLoading();
        });
      }
    });
  }
}
