import { Component, OnInit, OnChanges, ViewChildren } from '@angular/core';
import { AdminService } from '../../../../service/admin.service';
import { User } from '../../../../model/user';
import { Event } from '../../../../model/event';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../../service/user.service';
import { AuthService } from '../../../../service/auth.service';
import { EventsService } from '../../../../service/events.service';
import { SubmissionsService } from '../../../../service/submissions.service';
import { SHOW_ALL_PAGINATION_OPTIONS } from '../../../../model/pagination.options';
import { SubmissionFilter } from 'src/app/model/submission.filter';
import { Submission, Review } from 'src/app/model/paper';
import { ReviewsService } from '../../../../service/reviews.service';
import { UserEventRole } from '../../../../enum/user.event.role';
import { map } from 'rxjs/operators';
import { zip, Observable, of, forkJoin } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReviewsConfigurationService } from 'src/app/service/reviews.configuration.service';
import { AssignReview } from 'src/app/model/assign.review';
import { InfoService } from 'src/app/service/info.service';
import { NotificationService } from 'src/app/service/notification.service';
import { EventUserViewComponent } from 'src/app/component/event-user-view/event-user-view.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit, OnChanges {
  event: Event;
  user: User;
  isComittee: Boolean;
  isAdmin = false;

  user_loaded = false;
  showUserTopics = false;

  @ViewChildren('userViewInEvent') userViewInEvent;

  constructor(
    private adminService: AdminService,
    private notificationService: NotificationService,
    private infoService: InfoService,
    private eventsService: EventsService,
    private userService: UserService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnChanges() {
    this.clearComponent();

    this.ngOnInit();
  }

  ngOnInit() {
    setTimeout(() => {
      this.authService.directUserIsAdmin().subscribe(isAdmin => {
        this.isAdmin = isAdmin;
      });
      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.getUser();
        });
      } else {
        this.getUser();
      }
    });
  }

  getUser() {
    this.route.params.subscribe(({ userId }) => {
      this.userService.getUser(userId).subscribe(
        user => {
          this.user = user;

          this.eventsService.getUserIsUserEvent(this.event.id, UserEventRole.COMMITTEE, user.id).subscribe(bool => {
            this.isComittee = bool;
          });
          this.clearComponent();
        },
        error => {
          this.user_loaded = true;
        },
        () => {
          this.user_loaded = true;
        }
      );
    });
  }

  setProxy() {
    this.infoService.clearProxy();
    let user = this.user;
    this.adminService.getProxyUser(user.id).subscribe(response => {
      if (!response.user.isActive) {
        this.openDialogInactiveUser();
        return;
      }

      this.infoService.setProxyUser(response);
      this.notificationService.notify('admin.user-proxy.proxy-active', { params: {user} });
      this.router.navigate(['/settings']);
    });
  }

  private openDialogInactiveUser(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = { 
        title: 'admin.user-proxy.error.inactive-user-title',
        content: 'admin.user-proxy.error.inactive-user-message',
        params: {
          onlyOkButton: true
        }
    }

    this.dialog.open(ConfirmationDialogComponent, dialogConfig);
  }

  clearComponent() {
    setTimeout(() => {
      if (this.userViewInEvent.first) {
        (<EventUserViewComponent>this.userViewInEvent.first).clearComponent();
      }
    });
  }

  toggleUserTopics() {
    this.showUserTopics = !this.showUserTopics;
  }
}
