import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Event } from 'src/app/model/event';
import { ProceedingsContent, ProceedingsContentType } from 'src/app/model/proceedingsContent';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { PublicationService } from 'src/app/service/publication.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { File, FileUploadErrors } from 'src/app/model/file';
import { NotificationService } from 'src/app/service/notification.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Session } from 'src/app/model/session';
import { forkJoin } from 'rxjs';
import { Submission } from 'src/app/model/paper';
import { Track } from 'src/app/model/track';
import * as _ from 'lodash';
import { FileRules } from 'src/app/model/file.rules';
import { TranslateService } from '@ngx-translate/core';
import { ProceedingsViewingOptions } from 'src/app/model/proceedingsViewingOptions';

export class ProceedingsIndex {

  content: ProceedingsContent;
  title: string;
  pageStart: number;
  length: number;

  constructor(content: ProceedingsContent, title: string, pageStart?: number, length?: number) {
    this.content = content;
    this.title = title;
    this.pageStart = pageStart ? pageStart : -1;
    this.length = length ? length : 1;
  }

}

@Component({
  selector: 'app-proceeding-step',
  templateUrl: './proceeding-step.component.html',
  styleUrls: ['./proceeding-step.component.scss']
})
export class ProceedingStepComponent implements OnInit {

  @Input() step: string;
  @Input() steps: string[];

  loadedContent = false;
  loadedIndexes = false;
  fileUploadPercent: number;

  notIncludedInContent: ProceedingsContent[] = [];
  includedInContent: ProceedingsContent[];

  index: ProceedingsContent;
  authorIndex: ProceedingsContent;
  indexList: ProceedingsIndex[] = [];

  event: Event;
  sessions: Session[];
  submissions: Submission[];
  tracks: Track[];
  proceedingsTrackFiles: FileRules[];
  indexesOptions: ProceedingsViewingOptions;

  identificationForm: FormGroup;
  trackFileForms: FormArray;
  tracksLists = {};
  indexesForm: FormGroup;

  constructor(
    private adminService: AdminService,
    private eventsService: EventsService,
    private publicationService: PublicationService,
    private submissionsService: SubmissionsService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private fb: FormBuilder,
    public location: Location,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.event = this.adminService.selectedEvent;
    if (!this.event) {
      this.adminService.getEvent().subscribe((event) => { // TO-DO: create request to get only relevant data
        this.event = event;
        this.initComponent();
      });
    } else {
      this.initComponent();
    }
  }

  dropContent(event: CdkDragDrop<any[]>) {
    if (event.container.id  == 'cdk-drop-list-0') {
      const elementType = event.previousContainer.data[event.previousIndex].type;
      if (event.previousContainer.id == event.container.id) return;
      if (elementType == ProceedingsContentType.INDEX ||
          elementType == ProceedingsContentType.AUTHOR_INDEX ||
          elementType == ProceedingsContentType.WHITE_PAGE ||
          elementType == ProceedingsContentType.FILE) {
            this.notificationService.openConfirmationDialog({
              title: 'admin.event.publication.proceedings.content.blocked-move',
              content: '',
              cancel: undefined
            });
            return;
          }
    }
    
    transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
  }
  
  dropTrackFile(event: CdkDragDrop<any[]>) {
    if (event.previousContainer.id != event.container.id) return;

    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }

  removeAllContent() {
    this.notIncludedInContent = this.notIncludedInContent.concat(this.includedInContent.filter(c => c.type == ProceedingsContentType.SESSION || c.type == ProceedingsContentType.SUBMISSION));
    this.includedInContent = this.includedInContent.filter(c => !(c.type == ProceedingsContentType.SESSION || c.type == ProceedingsContentType.SUBMISSION));
  }

  includeAllContent() {
    this.includedInContent = this.includedInContent.concat(this.notIncludedInContent); 
    this.notIncludedInContent = [];
  }

  initComponent() {
    this.initIdentificationForm();
    this.initContentStep();
    this.initTrackFileStep();
    this.initIndexesStep();
  }

  sortProceedingsContent(c1: ProceedingsContent, c2: ProceedingsContent) {
    return c1.order < c2.order ? -1 : 1;
  }

  sortTrackFile(f1: FileRules, f2: FileRules) {
    return f1.proceedingsOrder < f2.proceedingsOrder ? -1 : 1;
  }

  uploadProceedingsFile(event: any) {
    const file = event.target.files[0];
    let error = undefined;
    let uploadValid = false;

    const validFile = this.publicationService.validProceedingsFile(file);
    switch (validFile) {
      case FileUploadErrors.MAX_SIZE:
        error = 'errors.file.max-size';
        break;
      case FileUploadErrors.INVALID_FORMAT:
        error = 'errors.file.invalid-format';
        break;
      default:
        error = undefined;
        uploadValid = true;
    }

    if (uploadValid) {
      const formData = new FormData();
      formData.set('file', file);

      this.includedInContent.push(ProceedingsContent.convertFileToProceedingsContent(new File(0, file.name, 0, undefined), this.event.id, 0));

      this.publicationService.submitProceedingsFile(this.event.id, formData).subscribe((event: HttpEvent<Object>) => {
        if (event.type == HttpEventType.Response) {
          const proceedingsContentFile: ProceedingsContent = event.body['data'];
          this.includedInContent.splice(this.includedInContent.length - 1, 1, proceedingsContentFile);
        } else if (event.type == HttpEventType.UploadProgress) {
          const percentDone = Math.round((event.loaded * 100) / event.total);
          this.fileUploadPercent = percentDone;
        }
      });
    } else {
      this.notificationService.openConfirmationDialog({ title: error, content: '' }).subscribe();
    }

    event.target.value = null;
  }


  deleteContentItem(item: ProceedingsContent, index: number) {

    if (item.type == ProceedingsContentType.SESSION || item.type == ProceedingsContentType.SUBMISSION) {
      this.includedInContent.splice(index, 1);
      this.notIncludedInContent.push(item);
    } else {
      this.notificationService.openConfirmationDialog({
        title: 'admin.event.tracks.checklist.confirm-checklistfield-deletion',
        content: '',
      }).subscribe(confirmed => {
        if (!confirmed) return;
        this.includedInContent.splice(index, 1);   
        if (item.type == ProceedingsContentType.FILE) {
          this.publicationService.deleteProceedingsFile(item.file.id, this.event.id).subscribe();
        }
      });
    }
  }

  createContent(type: ProceedingsContentType | string) {
    const contentType = type as ProceedingsContentType;
    const contentItem = new ProceedingsContent(contentType, this.includedInContent.length, this.event.id, undefined);

    if (contentType == ProceedingsContentType.INDEX || contentType == ProceedingsContentType.AUTHOR_INDEX) {
      const indexExists = this.includedInContent.filter(c => c.type == contentType).length != 0;

      if (indexExists) {
        this.notificationService.openConfirmationDialog({
          title: 'admin.event.publication.proceedings.content.blocked-create-index',
          content: '',
          cancel: undefined
        });
        return;
      }

    }

    if (contentType == ProceedingsContentType.INDEX) {
      this.includedInContent.unshift(contentItem);
    } else {
      this.includedInContent.push(contentItem);
    }
  }

  initContentStep() {
    if (this.step !== 'Content') return;
    this.publicationService.getProceedingsContent(this.event.id).subscribe(content => {
      this.includedInContent = content.sort(this.sortProceedingsContent);

      const includedSessionsId = this.includedInContent.filter(i => i.type == ProceedingsContentType.SESSION).map(i => i.session.id);
      this.eventsService.getSessionsByEvent(this.event.id).subscribe(sessions => {
        const convertedSessions = sessions.filter(s => !includedSessionsId.includes(s.id)).map(s => ProceedingsContent.convertSessionToProceedingsContent(s, this.event.id, 0));
        this.notIncludedInContent = convertedSessions;
      }, () => {}, () => {
        const includedSubmissionsId = this.includedInContent.filter(i => i.type == ProceedingsContentType.SUBMISSION).map(i => i.submission.id);
        this.submissionsService.getAllSubmissionsByEvent(this.event.id).subscribe(submissions => {
          const convertedSubmissions = submissions.filter(s => s.session == null && !includedSubmissionsId.includes(s.id)).map(s => ProceedingsContent.convertSubmissionToProceedingsContent(s, this.event.id, 0));
          this.notIncludedInContent = this.notIncludedInContent.concat(convertedSubmissions);
        }, () => {}, () => {
          this.loadedContent = true;
        });
      });

    }, () => {}, () => {});
  }

  initTrackFileStep() {
    if (this.step !== 'TrackFile') return;

    const tasks = [this.publicationService.getProceedingsContent(this.event.id), this.eventsService.getSessionsByEvent(this.event.id), this.submissionsService.getSubmissionsByEvent(this.event.id, {}), this.publicationService.getProceedingsTrackFile(this.event.id)];

    let usedTrackFilesIds: number[];

    forkJoin(tasks).subscribe(([content, sessions, submissions, trackFiles] : [ProceedingsContent[], Session[], Submission[], FileRules[]]) => {
      this.sessions = sessions;
      this.submissions = submissions;
      this.proceedingsTrackFiles = trackFiles;

      usedTrackFilesIds = this.extractIncludedTrackFilesId(content);
      this.tracks = this.event.tracks.filter(t => t.trackFiles.length != 0);

    }, () => {}, () => {
      this.mountTracksLists(usedTrackFilesIds);
      this.initTrackFileForm();
    });
  }

  initTrackFileForm() {

    const savedTrackFiles = this.proceedingsTrackFiles.map(t => t.id);

    this.trackFileForms = this.fb.array(Object.keys(this.tracksLists).map(trackId => this.fb.group({
      id: [trackId]
    })));

    this.trackFileForms.controls.forEach((trackForm) => {
      const form = trackForm as FormGroup;
      const trackId = form.value.id;

      this.tracksLists[trackId].forEach((file, index, array) => {
        const checkedByDefault = savedTrackFiles.includes(file.id) || array.length === 1;
        form.addControl(file.id, new FormControl(checkedByDefault));
      });
    });
  }

  initIndexesStep() {
    if (this.step !== 'Indexes') return;

    const tasks = [this.publicationService.getProceedingsContent(this.event.id), this.eventsService.getSessionsByEvent(this.event.id), this.submissionsService.getSubmissionsByEvent(this.event.id, {}), this.publicationService.getProceedingsTrackFile(this.event.id), this.publicationService.getProceedingsViewingOptions(this.event.id)];

    forkJoin(tasks).subscribe(([content, sessions, submissions, trackFiles, indexesOptions] : [ProceedingsContent[], Session[], Submission[], FileRules[], ProceedingsViewingOptions]) => {

      this.includedInContent = content.sort(this.sortProceedingsContent);
      const filterIndex = content.filter(c => c.type == ProceedingsContentType.INDEX);
      const filterAuthorIndex = content.filter(c => c.type == ProceedingsContentType.AUTHOR_INDEX);

      this.index = filterIndex.length != 0 ? filterIndex[0] : undefined;
      this.authorIndex = filterAuthorIndex.length != 0 ? filterAuthorIndex[0] : undefined;

      this.sessions = sessions;
      this.submissions = submissions;
      this.proceedingsTrackFiles = trackFiles;
      this.indexesOptions = indexesOptions;
    }, () => {}, () => {
      this.loadedIndexes = true;
      this.initIndexesForm();
      this.indexList = this.mountIndex();

      this.indexesForm.get('sessionTitle').valueChanges.subscribe(() => {
        this.indexList = this.mountIndex();
        this.changeDetectorRef.detectChanges();
      });

    });
  }

  initIndexesForm() {
    this.indexesForm = this.fb.group({
      sessionTitle: [this.indexesOptions.sessionTitle],
      letterGrouping: [this.indexesOptions.letterGrouping],
      abbreviatedName: [this.indexesOptions.abbreviatedName]
    });
  }

  getItemTitle(proceedingItem: ProceedingsContent): string {
    switch(proceedingItem.type) {
      case ProceedingsContentType.AUTHOR_INDEX:
        return this.translate.instant('admin.event.publication.proceedings.content.types.author-index');
      case ProceedingsContentType.FILE:
        return proceedingItem.file.name.replace('.pdf', '').replace('api', '');
      case ProceedingsContentType.INDEX:
        return this.translate.instant('admin.event.publication.proceedings.content.types.index');
      case ProceedingsContentType.SESSION:
        return proceedingItem.session.title;
      case ProceedingsContentType.SUBMISSION:
        return proceedingItem.submission.title;
      case ProceedingsContentType.WHITE_PAGE:
        return this.translate.instant('admin.event.publication.proceedings.content.types.white-page');
    }
  }

  calcItemLength(proceedingItem: ProceedingsContent): number {
    switch(proceedingItem.type) {
      case ProceedingsContentType.INDEX:
        const charactersPerLine = 87;
        const linesPerPage = 30;

        let totalLines = 0;

        this.includedInContent.forEach(indexItem => {
          const titleLength = this.getItemTitle(indexItem).length;
          const linesNeeded = titleLength / charactersPerLine;
          totalLines += Math.ceil(linesNeeded);
        });

        const totalPages = Math.ceil(totalLines / linesPerPage);
        return totalPages;
      case ProceedingsContentType.AUTHOR_INDEX:
        return undefined;
      case ProceedingsContentType.FILE:
        return proceedingItem.file.pageCount;
      case ProceedingsContentType.SESSION:
        return 1;
      case ProceedingsContentType.SUBMISSION:
        const submission = this.submissions.filter(s => s.id == proceedingItem.submission.id)[0];
        const includedTrackFilesId = this.proceedingsTrackFiles.map(tf => tf.id);
        const files = submission.files.filter(f => includedTrackFilesId.includes(f.trackFile.id));

        let submissionPageCount = 0;

        files.forEach(f => {
          submissionPageCount += f.pageCount;
        });

        return submissionPageCount;
      case ProceedingsContentType.WHITE_PAGE:
        return 1;
    }
  }

  mountIndex(): ProceedingsIndex[] {
    const indexList: ProceedingsIndex[] = [];

    let convertedContent: ProceedingsContent[] = [];
    this.includedInContent.forEach(cont => {
      convertedContent.push(cont);
      if (cont.type == ProceedingsContentType.SESSION) {
        const session = this.sessions.filter(s => s.id == cont.session.id)[0];
        session.submissions.forEach(submission => {
          convertedContent.push(ProceedingsContent.convertSubmissionToProceedingsContent(submission, undefined, undefined));
        });
      }
    });

    if (!this.indexesForm.get('sessionTitle').value) {
      convertedContent = convertedContent.filter(c => c.type != ProceedingsContentType.SESSION);
    }

    convertedContent.forEach((pi, index) => {

      const lastIndexItem = index != 0 ? indexList[index - 1] : undefined; 

      const pageStart = lastIndexItem ? lastIndexItem.pageStart + lastIndexItem.length : 1;
      const length = this.calcItemLength(pi);

      indexList.push(new ProceedingsIndex(pi, this.getItemTitle(pi), pageStart, length));
    });

    return indexList;
  }

  initIdentificationForm() {
    if (this.step !== 'ISBN/ISSN') return;

    this.identificationForm = this.fb.group({
      isbn: [this.event ? this.event.isbn : ''],
      issn: [this.event ? this.event.issn : '']
    });
  }

  extractIncludedTrackFilesId(content: ProceedingsContent[]): number[] {

    const subs = [];

      content.forEach(c => {
        if (c.type == ProceedingsContentType.SUBMISSION) {
          const includedSubmission = this.submissions.filter(s => s.id == c.submission.id)[0];
          subs.push(includedSubmission);
        } else if (c.type == ProceedingsContentType.SESSION) {
          const includedSession = this.sessions.filter(s => s.id == c.session.id)[0];
          includedSession.submissions.forEach(iss => {
            const includedSessionSubmission = this.submissions.filter(s => s.id == iss.id)[0];
            subs.push(includedSessionSubmission);
          });
        }
      });

    const ids = new Set();

    subs.forEach(sId => {
      sId.files.forEach(file => {
        ids.add(file.trackFile.id);
      });
    });

    return Array.from(ids) as number[];
  }

  mountTracksLists(usedTrackFilesIds: number[]) {
    this.tracks.forEach(track => {

      const trackFiles = track.trackFiles.filter(trackFile => usedTrackFilesIds.includes(trackFile.id)).sort(this.sortTrackFile);

      if (trackFiles.length != 0) {
        this.tracksLists[track.id] = trackFiles;
      }
      
    });
  }

  extractSelectedTrackFiles() {
    const data = _.cloneDeep(this.tracksLists);
    let includedTrackFilesIds = [];

    this.trackFileForms.value.forEach(trackForm => {
      const checkedTrackFiles = Object.keys(trackForm).map(key => parseInt(key)).filter(id => !isNaN(id) && trackForm[id]);
      includedTrackFilesIds = includedTrackFilesIds.concat(checkedTrackFiles);
    });
    
    Object.keys(data).forEach(key => {
      data[key] = data[key].map(file => file.id).filter(id => includedTrackFilesIds.includes(id));
    });

    return data;
  }

  getFormGroup(track: Track): FormGroup {
    return this.trackFileForms.controls.filter(formGroup => formGroup.value.id == track.id)[0] as FormGroup;
  }

  hasAtLeastOneTrackFileSelected(track: Track): boolean {
    return this.extractSelectedTrackFiles()[track.id]?.length !== 0;
  }

  canProceed(): boolean {
    switch(this.step) {
      case 'ISBN/ISSN':
        return this.identificationForm?.valid;
      case 'Content':
      case 'Indexes':
        return true;
      case 'TrackFile':
        let trackFormValid = true;
        this.tracks?.forEach(t => {
          const trackIsValid = this.hasAtLeastOneTrackFileSelected(t);
          if (!trackIsValid) {
            trackFormValid = trackIsValid;
          }
        });

        return trackFormValid;
      default:
        return false;
    }
  }

  partialSubmit(currentStep: string) {
    switch(currentStep) {
      case 'ISBN/ISSN':
        const identificationData = this.identificationForm.value;
        this.publicationService.editProceedingIdentificationInfo(this.event.id, identificationData).subscribe(res => {
          this.event.isbn = res.data['isbn'];
          this.event.issn = res.data['issn'];
        });
        break;
      case 'Content':
        this.includedInContent.forEach((item, index) => {
          item.order = index + 1;
        });
        this.publicationService.editProceedingContent(this.event.id, { 'content': this.includedInContent}).subscribe(pc => {
          this.includedInContent = pc.sort(this.sortProceedingsContent);
        });
        break;
      case 'Indexes':
        this.publicationService.editProceedingsViewingOptions(this.event.id, this.indexesForm.value).subscribe(indexesOptions => {
          this.indexesOptions = indexesOptions;
        });
        break;
      case 'TrackFile':
        const selectedTrackFiles = this.extractSelectedTrackFiles();

        this.publicationService.editProceedingsTrackFile(this.event.id, { 'trackFiles': selectedTrackFiles }).subscribe(trackFiles => {
          this.proceedingsTrackFiles = trackFiles;
        })

        break;
    }
  }

  submit() {}

}
