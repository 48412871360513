import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarItem } from '../../model/sidebar-item';

@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.scss']
})
export class SidebarItemComponent implements OnInit {
  @Input() item: SidebarItem;
  hasCustomIconFont: boolean;

  @Input() overrideClick: Function;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.hasCustomIconFont = typeof this.item.icon !== 'string' && !!this.item.icon.font;
  }

  isSelected(path): boolean {
    return window.location.pathname === path;
  }

  handleClick(path): void {
    if (this.item.overrideClick) {
      return this.item.overrideClick();
    } else {
      const current = this.router.url;

      if (current === path) {
        this.router.navigate(['/loading'], { skipLocationChange: true }).then(() => this.router.navigate([current]));
      }
    }
  }
}
