import { Type, Expose, Transform } from 'class-transformer';
import * as dayjs from 'dayjs';

export class ReviewStatusPerSubmissionList {
  public assignedBy: number;

  public id: number;
  public title: string;
  public status: string;
  public trackId: number;
  public trackName: string;
  public eventId: number;
  public totalReviews: number;
  public assigned: number;
  public notified: number;
  public confirmed: number;
  public declined: number;
  public reminded: number;
  public late: number;
  public delegated: number;
  public completed: number;
  public draft: number;
  public deleted: number;

  public validReviews(): number {
    return this.totalReviews - this.declined - this.deleted;
  }

  public completedAndConfirmed(): number {
    return this.completed + this.confirmed;
  }
}

export class ReviewStatusPerReviewerList {
  public id: number;
  public name: string;
  public tpcMember: boolean;
  public totalReviews: number;
  public assigned: number;
  public notified: number;
  public confirmed: number;
  public declined: number;
  public reminded: number;
  public late: number;
  public delegated: number;
  public assignToOthers: number;
  public assignToOthersCompleted: number;
  public completed: number;
  public draft: number;
  public firstCompletedReview: dayjs.Dayjs;
  public lastCompletedReview: dayjs.Dayjs;
  public reviewsLength: Array<number>;

  public  filtered = false;
}
