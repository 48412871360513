import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SubmissionStatus } from '../../enum/submission.status';
import { SelectOption } from '../../model/select.option';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { SubmissionFilter } from '../../model/submission.filter';
import { Event } from '../../model/event';
import { CheckListField } from '../../model/checklist-field';
import { ReviewStatus } from '../../enum/review.status';
import { AuthService } from '../../service/auth.service';
import { ShowAllPagedViewDirective } from 'src/app/class/paged-view-all';
import { PreferencesService } from 'src/app/service/preferences.service';
import { LocalCache } from 'src/app/enum/local.cache';
import { SubmissionFieldAndFilter } from 'src/app/model/submissionFieldFilter';

@Component({
  selector: 'app-submissions-list-filter',
  templateUrl: './submissions-list-filter.component.html',
  styleUrls: ['./submissions-list-filter.component.scss']
})
export class SubmissionsListFilterComponent implements OnInit {
  @Input() visible: boolean;
  @Input() showPapersComponent: ShowAllPagedViewDirective;
  @Input() reviews: boolean;
  @Input() fieldsSaved: {};
  @Input() customCheckListFieldsSaved:string;
  @Input() tableRenderized: boolean;

  @Input() hideNotRanking = false;

  @Input() isEventInterface = false;
  @Input() event: number;
  @Input() event_obj: Event;
  @Input() customCheckListFields: CheckListField[] = [];

  @Input() page: string;

  @Output() filterNotification: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() fieldsShowChanged = new EventEmitter();

  filterForm: FormGroup;
  orderByOptions: Array<SelectOption> = [];

  cacheKey: LocalCache.STATUS_REVIEWS | LocalCache.STATUS_SUBMISSIONS;

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    public authService: AuthService,
    public preferencesService: PreferencesService
  ) { }

  ngOnInit() {
    this.cacheKey = this.reviews ? LocalCache.STATUS_REVIEWS : LocalCache.STATUS_SUBMISSIONS;

    this.getFilterNotification(this.checkedStatusFields);
    this.orderByI18n();
    this.initForm();
  }

  public filter(): void {
    this.showPapersComponent.filter = plainToClass(SubmissionFilter, this.filterForm.getRawValue() as SubmissionFilter);
    this.showPapersComponent.load();
  }

  private initForm(): void {
    this.filterForm = this.fb.group({
      orderBy: [],
      sortBy: ['ascending'],
      missingManuscripts: [false],
      status: [this.checkedStatusFields],
      fields: this.fb.group({})
    });

    this.showPapersComponent.filter = plainToClass(SubmissionFilter, this.filterForm.getRawValue() as SubmissionFilter);

    this.filterForm.get('status').valueChanges.subscribe((e) => {
      
      this.showPapersComponent.filter = plainToClass(SubmissionFilter, this.filterForm.getRawValue() as SubmissionFilter);
      this.showPapersComponent.filterPapers(this.showPapersComponent.unfilteredElements);
      
      this.getFilterNotification(e);
      this.preferencesService.changePreference(this.cacheKey, e.join(','));
    });
  }

  private orderByI18n(): void {
    this.translateService.get(this.reviews ? 'filters.review-order-by' : 'filters.order-by').subscribe(orders => {
      Object.entries(orders).forEach(order => this.orderByOptions.push(new SelectOption(String(order[0]), String(order[1]))));
    });
  }

  get checkedStatusFields(): Array<string> {
    const cachedStatus = this.preferencesService.getPreference(this.cacheKey);

    if (cachedStatus === undefined || cachedStatus === false) {
      // No cached value defined. Set all fields as checked.
      return this.statusFields;
    } else if (cachedStatus === '') {
      // Cached value is defined with no status marked.
      return [];
    }
    // Return array of string status that are marked.
    return (<string>cachedStatus).split(',');
  }

  get statusFields(): Array<string> {
    return this.reviews ? ReviewStatus.toIterable() : SubmissionStatus.toIterable();
  }

  getFilterNotification(fields: Array<string>) {
    const statusFilter = this.statusFields.length > fields.length ? true : false;
    this.filterNotification.emit(statusFilter);
  }

  getStatusTranslation(status: any): string {
    return this.reviews ? ReviewStatus.toString(status) : SubmissionStatus.toString(status);
  }

  getStatusIcon(status: ReviewStatus | SubmissionStatus) {
    return this.reviews ? ReviewStatus.icon(<ReviewStatus>status) : SubmissionStatus.icon(<SubmissionStatus>status);
  }

  get searchFilterTitle(): string {
    return `filters.search-filters-${this.reviews ? 'review' : 'submission'}`;
  }
}
