import { Submission } from './paper';
import { Type } from 'class-transformer';

class SelectableSubmission extends Submission {
  selected = true;
}

export class NotifyAuthorsTemplate {
  @Type(() => SelectableSubmission)
  submissions: Array<SelectableSubmission>;

  @Type(() => SelectableSubmission)
  accepted: Array<SelectableSubmission>;

  @Type(() => SelectableSubmission)
  rejected: Array<SelectableSubmission>;

  example: {
    content: string;
    subject: string;
    toUser: string;
    fromUser: string;
    replyTo: string;
    fromUserName: string;
    ccList: string;
  };
}
