import { Injectable } from '@angular/core';
import { Notification } from '../model/notification';
import { NotificationSheetData } from '../model/notificationSheetData';
import { ThemePalette } from '@angular/material/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import * as dayjs from 'dayjs';
import { NotificationSnackbarComponent } from '../component/notification-snackbar/notification-snackbar.component';
import { ConfirmationDialogComponent } from '../component/confirmation-dialog/confirmation-dialog.component';
import { Observable } from 'rxjs';

export interface NotificationOptions {
  link?: string;
  read?: boolean;
  params?: {
    [key: string]: any
  };
  overridePanelClass?: string;
  snackbarConfig?: MatSnackBarConfig;

  onOpen?: () => void;
  onClose?: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  menuTrigger: MatMenuTrigger;
  data: NotificationSheetData;

  icon = 'fa-bell';
  iconSet = 'far';

  constructor(
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  openNotification(event: MouseEvent, item: Notification): void {
    if (this.menuTrigger) {
      this.menuTrigger.closeMenu();
    }

    setTimeout(() => {
      item.read = true;
      this.data.clearReadNotifications();
    }, 300);

    event.preventDefault();
  }

  notificationMenuToggled(isOpen: boolean): void {
    this.iconSet = isOpen ? 'fas' : 'far';
  }

  clearAllNotifications(): void {
    this.data.clearAll();
  }

  refreshNotifications(): void {
    this.data = new NotificationSheetData([], 'warn');
  }

  insertNotification(notification: Notification): number {
    if (!this.data) {
      this.data = new NotificationSheetData([], 'warn');
    }

    return this.data.insert(notification);
  }

  notify(message: string, options: NotificationOptions = {}): number {
    const notification = new Notification(
      message, options.link, dayjs(), options.read, options.params,
      options.overridePanelClass, options.snackbarConfig,
      options.onOpen, options.onClose
    );

    this.openSnackbar(notification, notification.snackbarConfig);

    return this.insertNotification(notification);
  }

  notifyError(message: string, options: NotificationOptions = {}): number {
    options.overridePanelClass = 'error';
    return this.notify(message, options);
  }

  notifyWith(notification: Notification): number {
    this.openSnackbar(notification, notification.snackbarConfig);

    return this.insertNotification(notification);
  }

  openSnackbar(notification: Notification, config: MatSnackBarConfig = {
    panelClass: 'notification',
    horizontalPosition: 'left',
    verticalPosition: 'bottom',
    duration: 5000,
  }): void {
    if (notification.overridePanelClass?.length > 0) {
      config.panelClass = notification.overridePanelClass;
    }

    const ref = this.snackbar.openFromComponent(NotificationSnackbarComponent, { data: notification, ...config });
    notification.callback(notification.onOpen);

    ref.afterDismissed().subscribe(() => {
      notification.callback(notification.onClose);

      if (notification.read) {
        this.data.clearReadNotifications();
      }
    });
  }

  openConfirmationDialog(data): Observable<boolean> {
    if (data) {
      return this.dialog.open(ConfirmationDialogComponent, {data}).afterClosed();
    } else {
      return this.dialog.open(ConfirmationDialogComponent).afterClosed();
    }
  }
}
