import { Component, Input } from '@angular/core';
import { PrintingProblem } from 'src/app/model/printing.problem';
import { AuthService } from 'src/app/service/auth.service';
import { InfoService } from 'src/app/service/info.service';

@Component({
  selector: 'app-printing-problem',
  templateUrl: './printing-problem.component.html',
  styleUrls: ['./printing-problem.component.scss']
})
export class PrintingProblemComponent {

  @Input() public problem: PrintingProblem;
  @Input() public canSeeSecretInfo: boolean;

  constructor() {}

}
