import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CheckListField } from 'src/app/model/checklist-field';

@Component({
  selector: 'app-custom-control-field-edit',
  templateUrl: './custom-control-field-edit.component.html',
  styleUrls: ['./custom-control-field-edit.component.scss']
})
export class CustomControlFieldEditComponent implements OnInit {

  customFieldForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<CustomControlFieldEditComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.initCustomFieldForm();
  }

  private initCustomFieldForm() : void {
    const field = this.data;

    this.customFieldForm = this.fb.group({
      id: [field.id],
      description: [field.description, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      visibleTo: this.fb.group({
        author: [field.visibleTo.author],
        reviewer: [field.visibleTo.reviewer],
        sessionChair: [field.visibleTo.sessionChair],
        publicationChair: [field.visibleTo.publicationChair],
      }),
      modifiableBy: this.fb.group({
        author: [field.modifiableBy.author],
        reviewer: [field.modifiableBy.reviewer],
        sessionChair: [field.modifiableBy.sessionChair],
        publicationChair: [field.modifiableBy.publicationChair]
      })
    });
  }

  save() {
    const editedField: CheckListField = this.customFieldForm.value;
    this.dialogRef.close(editedField);
  }
}
