import { Component, OnInit, Input, Inject, Optional } from '@angular/core';
import { NavbarService } from 'src/app/service/navbar.service';
import { EventsService } from 'src/app/service/events.service';
import { Submission } from 'src/app/model/paper';
import { ReviewsService } from 'src/app/service/reviews.service';
import { SubmissionHistory } from 'src/app/model/submission-history';
import { forkJoin, Observable } from 'rxjs';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { AuthService } from 'src/app/service/auth.service';
import { Event } from 'src/app/model/event';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-submission-history',
  templateUrl: './submission-history.component.html',
  styleUrls: ['./submission-history.component.scss']
})
export class SubmissionHistoryComponent implements OnInit {
  public submission: Submission;
  public history: Array<SubmissionHistory> = [];
  public event: Event;
  public id: number;
  @Input() public eventId: number;
  @Input() public userIsChair = false;

  constructor(
    private route: ActivatedRoute,
    private reviewsService: ReviewsService,
    private submissionsService: SubmissionsService,
    private navbarService: NavbarService,
    private authService: AuthService,
    private eventsService: EventsService,
    @Inject(MAT_DIALOG_DATA) @Optional() public data: { id: number, userIsChair: boolean, eventId: number},
  ) { }

  ngOnInit() {
    setTimeout(() => {
      if (this.data) {
        this.id = this.data.id;
        this.userIsChair = this.data.userIsChair;
        this.eventId = this.data.eventId;
      }
      else {
        this.id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
      }
      this.navbarService.loadingStart();

      forkJoin([this.getHistory(this.id)])
        .subscribe(([history]) => {
          this.history = history;
          this.userIsChair = this.authService.userIsAdmin() || this.userIsChair;
          this.navbarService.loadingStop();
        });
    });
  }

  getHistory(id: number): Observable<SubmissionHistory[]> {
    return this.reviewsService.getSubmissionHistoryBySubmission(id);
  }
}
