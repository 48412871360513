import { Component, forwardRef, Input, ViewChild, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR, FormControl, Validators } from '@angular/forms';
import { FilesService } from 'src/app/service/files.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { NotificationService } from 'src/app/service/notification.service';
import { FileRules } from 'src/app/model/file.rules';
import { Track } from 'src/app/model/track';
import { FileUploadErrors } from 'src/app/model/file';
import { RulesFileSlot } from '../file-rules-card/file-rules-card.component';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileUploadComponent),
    multi: true
  }]
})
export class FileUploadComponent implements OnInit {
  @Input() submissionId: number;
  @Input() formGroupController: FormGroup;
  @Input() formName: string;
  @Input() multiple = false;
  @Input() uploadLabel = undefined;

  @Input() showDialog = false;
  @Input() compact = false;

  @Input() userIsChair = false;
  @Input() userIsAuthor = false;

  @Input() rule: FileRules;

  @Output() uploadComplete = new EventEmitter();

  @Input() isReview = false;
  @Input() readonly allowPagesExceed: boolean;
  @Input() errorHaveExternalControl = false;
  @Input() forFileRulesCard = false;

  @Input() readonly slot: RulesFileSlot;

  @Output() fileSent = new EventEmitter<{file: File, rule: FileRules, slot: RulesFileSlot}>();
  @Output() errorRaised: EventEmitter<string> = new EventEmitter();

  error: string;
  public uploadValid: boolean;
  hasAddedFile: boolean;

  stateCtrl: FormControl;
  @ViewChild('file', { static: true }) file;

  constructor(
    private filesService: FilesService,
    private submissionsService: SubmissionsService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.stateCtrl = this.formGroupController.controls['files'] as FormControl;
    if(this.forFileRulesCard){
      this.rule = this.slot.rule;
      this.formName = this.rule.name;
    }
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  removeFiles(keepError: boolean = false) {
    this.file.nativeElement.value = '';
    this.uploadValid = false;
    this.hasAddedFile = false;

    if (!keepError) {
      this.error = undefined;
    }
    // Removes this uploaded file to don't upload at create-submission.submit.
    this.formGroupController.controls['files'].value[this.rule.name] = [];
  }

  onFilesAdded() {
    const files = Array.from(<FileList>this.file.nativeElement.files);
    this.uploadValid = false;

    this.hasAddedFile = files.length > 0;

    for (let i = 0; i < files.length; i++) {
      files[i]['uploaded'] = -1;
    }

    for (const key in files) {
      if (!isNaN(parseInt(key, 10))) {
        const value = this.stateCtrl.value;
        value[this.formName] = files;

        this.stateCtrl.patchValue(value);

        this.filesService.validFileAsync(files[0], this.rule).subscribe(validFile => {
          this.checkError(validFile);
          this.submit(files);
        });
        
      }
    }

    
  }

  private checkError(validFile: FileUploadErrors) {
    switch (validFile) {
      case FileUploadErrors.MAX_SIZE:
        this.error = 'errors.file.max-size';
        break;
      case FileUploadErrors.INVALID_FORMAT:
        this.error = 'errors.file.invalid-format';
        break;
      case FileUploadErrors.MAX_PAGES:
        if (this.allowPagesExceed === false) {
          this.error = 'errors.file.max-pages';
          break;
        }
        this.uploadValid = true;
        this.error = undefined;
        break;
      default:
        this.error = undefined;
        this.uploadValid = true;
    }
  }

  private submit(files: File[]) {
    if (!this.uploadValid) {
      if(this.errorHaveExternalControl){
        this.errorRaised.emit(this.error);
        this.removeFiles(true);
      } else if (!this.showDialog) {
        this.removeFiles(true);
      } else {
        this.notificationService.openConfirmationDialog({ title: this.error, content: '' })
          .subscribe(response => {
            this.removeFiles(true);
        });
      }
    } else if (this.submissionId) {
      if (files) {
        if (this.forFileRulesCard) {
          this.fileSent.emit({file: files[0], rule: this.rule, slot: this.slot});
          return;
        }

        const submitFiles = {
          [this.rule.name]: files
        };

        this.submissionsService.submitFiles(submitFiles, this.submissionId, this.rule.track).subscribe(
          statusList => this.uploadComplete.emit(statusList[0].status), // Partial upload.
          () => {}, // Error Handling
          () => {
            this.notificationService.notify('file-rules-view.file-uploaded', { params: { id: this.submissionId } });
            // update here the required files ????
            this.uploadComplete.emit(100); // Upload completed.
          }
        );
      }
    }
  }

  acceptTypes() {
    return this.rule.mimetypes.map(m => m.name).toString();
  }
}
