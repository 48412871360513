import { X } from '@angular/cdk/keycodes';
import {
  IChartistAnimationOptions,
  IChartistData,
  IPieChartOptions,
  ILineChartOptions,
  IBarChartOptions,
  IChartistSeriesData
} from 'chartist';

import ChartistAxisTitle from 'chartist-plugin-axistitle';
import ChartistLegend from 'chartist-plugin-legend';

import { ChartEvent, ChartOptions, ChartType, ResponsiveOptions } from 'ng-chartist';
import { DashboardDataset, DashboardGraph, DatasetOption } from './dashboard';
import { plainToClass } from 'class-transformer';
import { TranslateService } from '@ngx-translate/core';

export class ChartItem {
  graphPosition: DashboardGraph;

  disabled = false;
  loading = false;

  data: IChartistData;
  options: ChartOptions;
  responsiveOptions: ResponsiveOptions;
  events: ChartEvent = {
    draw: (data) => {
      if (data.type === 'bar') {
        data.element.animate({
          y2: <IChartistAnimationOptions>{
            dur: '1s',
            from: data.y1,
            to: data.y2,
            easing: 'easeOutQuad'
          }
        });
      }
    }
  };

  // Variables that are defined according to custom dataset options.
  customOption: {
    customClass?: string;

    translateLabel?: boolean;
    translateLabelPrepend?: string;

    legendTitle?: string;
  };

  constructor(
    graphPosition: DashboardGraph,
    options: ChartOptions = { height: 300 }
  ) {
    this.graphPosition = graphPosition;
    this.options = options;

    if (this.type === 'Bar') {
      (<IBarChartOptions>this.options).axisY = { 'onlyInteger': true };
      (<IBarChartOptions>this.options).axisX = { 'showGrid': false };
      (<IBarChartOptions>this.options).chartPadding = { 'bottom': 20 };
    } else if (this.type === 'Line') {
      (<ILineChartOptions>this.options).fullWidth = true;
      (<ILineChartOptions>this.options).low = 0;
      (<ILineChartOptions>this.options).chartPadding = { 'right': 50, 'bottom': 20 };
      (<ILineChartOptions>this.options).axisY = { 'onlyInteger': true };
    }

    // Add plugins via dataset options
    this.dataset.options.forEach((opt: DatasetOption) => {
      let plugin;

      switch(opt.name) {
        // Legend Plugin
        case 'ctLegend':
          plugin = ChartistLegend;

          if (this.type === 'Pie') {
            (<IPieChartOptions>this.options).labelInterpolationFnc = (currLabel, idx) => {
              return `${(<IChartistSeriesData>this.data.series)[idx].value}`;
            };
          }
          break;

        // Axis Title Plugin
        case 'ctAxisTitle':
          plugin = ChartistAxisTitle;
          break;

        case 'customOption':
          this.customOption = opt.settings;
          break;

        case 'chartistOption':
          Object.assign(this.options, opt.settings);
          break;
      }

      if (plugin) {
        this.addPlugin(plugin(opt.settings));
      }
    });
  }

  get dataset() {
    return <DashboardDataset>this.graphPosition.dataset;
  }

  get name(): string {
    return `${this.dataset.category}.${this.dataset.datasetName}`;
  }

  get type(): ChartType {
    return this.dataset.type;
  }

  addPlugin(config) {
    if (!(this.options.plugins?.length > 0)) {
      this.options.plugins = [];
    }

    this.options.plugins.push(config);
  }
}
