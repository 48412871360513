import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InfoService } from 'src/app/service/info.service';
import { NotificationService } from 'src/app/service/notification.service';

@Component({
  selector: 'app-proxy-navbar',
  templateUrl: './admin-proxy-navbar.component.html',
  styleUrls: ['./admin-proxy-navbar.component.scss']
})
export class AdminProxyNavbarComponent {

  constructor(
    private notificationService: NotificationService,
    public infoService: InfoService,
    private router: Router
  ) { }

  closeProxy(): void {
    this.notificationService.notify('admin.user-proxy.proxy-cleared');

    this.infoService.clearProxy(this.infoService.user);

    const currentUrl = this.router.url;
    window.location.reload();
    /*this.router.navigate(['/loading'], { skipLocationChange: true }).then(() => {
      switch (currentUrl) {
        // Base URL values ('/' and '/submissions') was not properly reloading. Force window reload.
        case '/submissions':
        case '/':
          window.location.reload();
          break;
        default:
          // Navigate to the same page, reloading it using non-proxy user.          
          this.router.navigate([currentUrl]);
      }
    });*/
  }
}
