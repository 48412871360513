import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventsService } from '../../service/events.service';
import { Event } from '../../model/event';
import { NavbarService } from '../../service/navbar.service';
import { PagedViewDirective } from '../../class/paged-view';
import { PreferencesService } from 'src/app/service/preferences.service';
import { NO_PAGINATION } from 'src/app/model/pagination.options';
import { zip, Observable, Subscription, of, forkJoin } from 'rxjs';

@Component({
  selector: 'app-admin-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class AdminEventsListComponent extends PagedViewDirective implements OnInit, OnDestroy {
  events: Event[];
  search: string;
  subscriptions: Subscription[] = [];

  constructor(
    private eventsService: EventsService,
    public navbarService: NavbarService,
    protected preferencesService: PreferencesService
  ) {
    super(navbarService, preferencesService);
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  load() {
    setTimeout(() => {
      this.getEvents();
    });
  }

  getEvents() {
    this.navbarService.loadingStart();
    const paginationOptions = NO_PAGINATION;
    paginationOptions.show = this.showAll;
    this.subscriptions.push(this.eventsService.getAllEvents(paginationOptions).subscribe(pagination => {
      this.events = pagination.items as Event[];
      this.navbarService.loadingStop();
      window.scroll(0, 0);
    }));
  }
}
