import { Component } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AuthService } from '../service/auth.service';
import { DateAdapter } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { LanguageService } from '../service/language.service';
import * as dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/pt';
import { UserService } from '../service/user.service';
import { LocalCache } from '../enum/local.cache';
import { DICT_THEME } from '../model/profile';
import { changeContentMetaThemeColor } from '../utils/utils';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent {
  constructor(
    public translate: TranslateService,
    private authService: AuthService,
    private matIconRegistry: MatIconRegistry,
    private languageService: LanguageService,
    private dateAdapter: DateAdapter<Date>,
    private userService: UserService
  ) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem('language', event.lang);
      this.dateAdapter.setLocale(event.lang);
      dayjs.locale(event.lang.toLowerCase());
    });

    translate.setDefaultLang('en');

    const language = localStorage.getItem('language');

    if (language) {
      translate.use(language);
    } else {
      const lang = translate.getBrowserCultureLang();

      if (this.languageService.translationExists(lang)) {
        translate.use(lang);
      }
    }

    this.setupTokenRefreshMocker();
    this.setTheme();

    // this.matIconRegistry.registerFontClassAlias('fas');
    // this.matIconRegistry.setDefaultFontSetClass('fas');
  }

  private setupTokenRefreshMocker() {
    setInterval(() => {
      if (this.authService.isAuthenticated()) {
        this.authService.refreshToken({ token: localStorage.getItem('token') }).subscribe(
          response => localStorage.setItem('token', response.data.token)
        );
      }
    }, 1000 * 60 * 5);
  }

  private setTheme(): void {
    let theme = LocalCache.getValue(LocalCache.OLD_THEME) as string;
    if (!theme || theme === DICT_THEME.SYSTEM) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
          theme = DICT_THEME.DARK;
        } else {
          theme = DICT_THEME.LIGHT;
      }
    }
    changeContentMetaThemeColor(theme);
    document.body.classList.add(theme);
  }
}
