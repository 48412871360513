import { Component, Input } from '@angular/core';
import { Submission } from 'src/app/model/paper';

@Component({
  selector: 'app-display-paper-title',
  templateUrl: './display-paper-title.component.html',
  styleUrls: ['./display-paper-title.component.scss']
})
export class DisplayPaperTitleComponent {

  @Input() submission: Submission;

  constructor() { }

}
