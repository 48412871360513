import { Type, Transform } from 'class-transformer';
import { User } from './user';
import * as dayjs from 'dayjs';
import { BaseModel, toDayjs } from './baseModel';

export class DiscussionMessage {
  public id: number;
  public content: string;

  @Type (() => User)
  public sender: User;

  @Transform(toDayjs)
  public createdAt: dayjs.Dayjs;
}
