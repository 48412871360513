import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Event } from '../../model/event';
import { Track } from '../../model/track';
import { Form } from 'src/app/model/form';
import { ActivatedRoute, Router } from '@angular/router';
import { EventsService } from 'src/app/service/events.service';
import { Location } from '@angular/common';
import { Error } from 'src/app/model/error';
import { Submission, SubmissionStatus } from 'src/app/model/paper';
import { FileRules } from 'src/app/model/file.rules';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { Answer } from 'src/app/model/answer';
import { NavbarService } from 'src/app/service/navbar.service';
import { User } from 'src/app/model/user';
import { InfoService } from 'src/app/service/info.service';
import { NotificationService } from 'src/app/service/notification.service';
import { AuthService } from 'src/app/service/auth.service';
import { FilesService } from 'src/app/service/files.service';
import { EventSubmissionStep } from 'src/app/model/eventSubmissionStep';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-create-submission',
  templateUrl: './create-submission.component.html',
  styleUrls: ['./create-submission.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        showError: true
      }
    }
  ]
})
export class CreateSubmissionComponent implements OnInit {
  @ViewChild('stepper')
  stepper : MatStepper;

  event: Event;
  track: Track;
  createSubmissionForm: FormGroup;

  verifyForm = false;

  error: Error;

  params: {
    eventId: number,
    trackId: number
  };

  title = 'submissions.create.title';

  submission: Submission;
  @Input() isEditing = false;
  @Input() includeLoggedUser = true;
  @Input() isChairView = false;

  isChair = false;

  hasError = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    protected eventService: EventsService,
    protected location: Location,
    private submissionsService: SubmissionsService,
    private navbarService: NavbarService,
    private notificationService: NotificationService,
    private router: Router,
    private infoService: InfoService,
    private authService: AuthService,
    private filesService: FilesService
  ) { }

  ngOnInit(): void {
    this.hasError = false;
    setTimeout(() => {
      this.navbarService.loadingStart();

      this.route.params.subscribe(params => {
        if (params.submissionId) {
          this.submissionsService.getSubmission(params.submissionId).subscribe(submission => {
            this.eventService.getEventByTrack(submission.trackID).subscribe(event => {
              // Check if user is allowed
              if (!submission.authors.find(author => author.user.id === this.infoService.user.id)) {
                this.authService.userIsAdminOrChair(event.id).subscribe(isAdminOrChair => {
                  this.isChair = isAdminOrChair;

                  if (!isAdminOrChair) {
                    this.router.navigate(['/401']);
                  }
                });
              }

              this.submission = submission;
              this.params = {
                eventId: event.id,
                trackId: submission.trackID
              };

              this.title = 'submissions.edit.title';

              this.getEvent();
              this.initForm();
            });
          });
        } else {
          this.params = {
            eventId: parseInt(params.eventId, 10),
            trackId: parseInt(params.trackId, 10)
          };

          if (!this.params.eventId) {
            this.eventService.getEventByTrack(this.params.trackId).subscribe(event => {
              this.params.eventId = event.id;

              this.getEvent();
              this.initForm();
            });
          } else {
            this.getEvent();
            this.initForm();
          }
        }
      });
    });
  }

  getEvent() {
    this.eventService.getEvent(this.params.eventId).subscribe((event) => {
      this.event = event;

      let newEventSubmissionStep: EventSubmissionStep[] = [];
      let topicsStep: EventSubmissionStep;
      let formStep: EventSubmissionStep;

      for (let step of this.event.eventSubmissionStep) {
        const stepName = step.submissionStep.name;
        if (stepName == 'form') {
          formStep = step;
        } else if (stepName == 'topics') {
          topicsStep = step;
        } else if (stepName != 'files') {
          newEventSubmissionStep.push(step);
        }
      }
      newEventSubmissionStep.push(topicsStep);
      if (this.event.formSubmission != null) {
        newEventSubmissionStep.push(formStep);
      }
      this.event.eventSubmissionStep = newEventSubmissionStep;

      this.eventService.getFormSubmission(event.id, event.formSubmission)
        .subscribe(
          form => {
            this.verifyForm = true;
          },
          err => {
            const found = this.event.eventSubmissionStep.findIndex(step => step.name === 'form');
            if (found >= 0) {
              this.event.eventSubmissionStep.splice(found, 1);
            }
            this.verifyForm = true;
          });

      this.authService.userIsAdminOrChair(event.id).subscribe(isAdminOrChair =>
        this.isChair = isAdminOrChair
      );

      try {
        this.track = this.event.tracks.find(e => e.id === this.params.trackId);

        if (!this.track) { throw 0; }
      } catch (e) {
        if (e === 0) {
          this.error = new Error('errors.track-not-in-event');
        }
      }

      this.navbarService.loadingStop();
    });
  }

  submit({ valid, formAnswers }: { valid: boolean, formAnswers: Array<Answer> }): void {
    // Default values, when creating submission
    let notifyStartMessage = 'submissions.create.creating';
    let notifyStartMessageParams = {};
    let notifySuccessMessage = 'submissions.create.created';
    let previousStatus = SubmissionStatus.PENDING;

    if (this.isEditing) {
      // Override values, if editing submission
      notifyStartMessage = 'submissions.edit.editing';
      notifyStartMessageParams = { id: this.submission.id };
      notifySuccessMessage = 'submissions.edit.edited';
      previousStatus = this.submission.status;
    }

    if (valid) {
      this.notificationService.notify(notifyStartMessage, { params: notifyStartMessageParams });

      const form = this.createSubmissionForm.value;
      const temp = new Submission();
      temp.updateData({
        status: previousStatus,
        title: form.info.title,
        abstract: form.info.abstract,
        topics: form.topics.list,
        track: this.track,
        authors: form.authors.list.map((user: User, order: number) => ({ user, order })),
        formAnswers
      });

      const obs = this.isEditing ?
        this.submissionsService.editSubmission(temp.data(), this.submission.id) :
        this.submissionsService.createSubmission(temp.data());

      obs.subscribe(submission => {
        this.notificationService.notify(notifySuccessMessage, { params: { id: submission.id } });


        const filesToUpload = {};
        if (form.files != undefined) {
          Object.keys(form.files).forEach(key => {
            if (form.files[key][0]?.uploaded === -1) {
              filesToUpload[this.getFileRuleFromTrack(this.track, key).name] = form.files[key];
            }
          });
        }


        if (filesToUpload && Object.keys(filesToUpload).length > 0) {
          this.notificationService.notify('submissions.create.uploading-files', { params: { files: Object.keys(filesToUpload).length } });

          let uploadStatus: Array<{ fileRule: FileRules, status: number }> = [];
          this.submissionsService.submitFiles(filesToUpload, submission.id, this.track).subscribe(
            statusList => {
              uploadStatus = statusList;
            },
            () => { },
            () => {
              let updatedSubmission: Submission;
              this.submissionsService.getSubmission(submission.id, true).subscribe(
                sub => {
                  updatedSubmission = sub;
                },
                () => { },
                () => {
                  const successfulUpload = uploadStatus.filter(s => s.status === 100).map(s => s.fileRule);
                  if (updatedSubmission.status === SubmissionStatus.PENDING &&
                    this.filesService.requiredFilesSubmitted(this.track.trackFiles, updatedSubmission.files.map(f => f.trackFile))) {
                    this.submissionsService.editSubmission({ status: SubmissionStatus.ACTIVE }, submission.id).subscribe(
                      () => { },
                      () => { },
                      () => {
                        this.notifyMessageAndRoute(successfulUpload.length, uploadStatus.length, submission);
                      });
                  } else {
                    this.notifyMessageAndRoute(successfulUpload.length, uploadStatus.length, submission);
                  }
                });
            });
        } else {
          this.notificationService.notify(notifySuccessMessage, { params: { id: submission.id } });
          this.router.navigate([this.isChairView ? `/admin/${this.event.id}/submissions` : '/submissions', submission.id],
            { queryParams: { refreshCache: true } }
          );
        }
      });
    }
  }

  private getFileRuleFromTrack(track: Track, key: string): FileRules {
    return track.trackFiles.find(e => e.name === key);
  }

  get editableSubmission(){
    return (this.track?.editPendingSubmissions && this.submission?.status == SubmissionStatus.PENDING) ||
      (this.track?.editActiveSubmissions && this.submission?.status == SubmissionStatus.ACTIVE) ||
      (this.track?.editRejectedSubmissions && this.submission?.status == SubmissionStatus.REJECTED) ||
      (this.track?.editAcceptedSubmissions && this.submission?.status == SubmissionStatus.ACCEPTED);
  }

  private initForm(): void {
    this.createSubmissionForm = this.fb.group({});
    this.createSubmissionForm.setValidators(this.allControlsValid);
  }

  get eventName(): string {
    return this.event ? this.event.name : undefined;
  }

  get trackName(): string {
    if (this.track) {
      return this.track.name;
    }

    if (this.params.trackId) {
      return this.params.trackId.toString();
    }

    return undefined;
  }

  allControlsValid() : ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      for(let controlName in group.controls) {
        const control = group.controls[controlName];
        console.log(controlName, control);
        if(!control.valid) {
          return {'invalid': 'invalid form'};
        }
      }
      return null;
    };
  }

  processNext(matStepper: MatStepper) {
    matStepper.next();
  }

  private notifyMessageAndRoute(successfulUploaded: number, filesUploaded: number, submission: Submission) {
    if (successfulUploaded === filesUploaded) {
      this.notificationService.notify('submissions.create.upload-files-completed', {
        params: { files: successfulUploaded }
      });
    } else {
      this.notificationService.notifyError('file-rules-view.upload-error', {
        params: { files: filesUploaded - successfulUploaded }
      });
    }
    this.router.navigate([this.isChairView ? `/admin/${this.event.id}/submissions` : '/submissions', submission.id],
      { queryParams: { refreshCache: true } }
    );
  }
}
