import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyPipe'
})
export class CurrencyFormatPipe implements PipeTransform {

  transform(value: number, arg1: string = 'BRL'): string {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: arg1,
      maximumFractionDigits: 2
    }).format(value);
  }

}
