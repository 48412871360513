export default {
  "en": {
    "name": "English",
    "country": "United States of America"
  },
  "pt-BR": {
    "name": "Português do Brasil",
    "country": "Brazil"
  }
}
