import { Component, Input, Output, EventEmitter, OnInit, OnChanges, ViewChild, AfterViewInit } from '@angular/core';
import { TextInputComponent } from '../text-input/text-input.component';
import { SelectOption } from '../../model/select.option';
import { MatSelectChange } from '@angular/material/select';
import { MatSelect } from '@angular/material/select';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { SubmissionStatus } from 'src/app/enum/submission.status';
import { NotificationService } from 'src/app/service/notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SubmissionWithdrawalComponent } from 'src/app/component/submission-withdrawal/submission-withdrawal.component';
import { Submission } from 'src/app/model/paper';

@Component({
  selector: 'app-select-input-icon',
  templateUrl: './select-input-icon.component.html',
  styleUrls: ['./select-input-icon.component.scss'],
})
export class SelectInputIconComponent extends TextInputComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatSelect) matSelect: MatSelect;

  @Input() options;
  @Input() indicesIcon: Array<{ fontSet: string, fontIcon: string, color: string }> = [];
  @Input() indices = {
    internal: 'id',
    public: 'value'
  };

  @Input() prependPublic = '';
  @Output() selectionChange: EventEmitter<{submissionID: number, value: string}> = new EventEmitter<{submissionID: number, value: string}>();
  @Output() inputValueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() compact = false;
  @Input() includeNoneOption = false;

  @Input() readonly submission: Submission;
  @Input() filterable = false;
  @Input() indicesFilter: string = 'public';
  public filteredOptions: Array<SelectOption>;

  @Input() optionsWithColors: boolean = false;
  @Input() colorPaperInfo: boolean = false;
  @Input() lighterColor: boolean = false;

  public value: string;
  public fontSet: string;
  public fontIcon: string;
  public statusColor: string;

  constructor(
    private submissionsService: SubmissionsService,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngAfterViewInit() {
    this.matSelect.openedChange.subscribe((opened) => {
      if (opened) {
        const maskOverlay: HTMLElement = document.querySelector('[id^="cdk-overlay"]');
        maskOverlay.style.cursor = 'pointer';
        maskOverlay.addEventListener('click', () => this.matSelect.close());
      }
    });
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  ngOnInit() {
    if (this.filterable && this.options) {
      // Create a new, mutable, copy of the options variable.
      this.filteredOptions = this.options.slice();
    } else {
      // Both variables are the same. A new array is not created.
      this.filteredOptions = this.options;
    }
    this.setInitialValue();

    this.formGroupController.get(this.name).valueChanges.subscribe($value => {
      if ($value) {
        this.value = this.getDisplayValue($value);
        this.fontSet = this.indicesIcon[$value].fontSet;
        this.fontIcon = this.indicesIcon[$value].fontIcon;
        this.statusColor = this.indicesIcon[$value].color;
      }
    });
  }

  private setInitialValue(): void {
    const initialValue: string = this.formGroupController.get(this.name).value;
    if (!!initialValue) {
      this.value = this.getDisplayValue(initialValue);
      this.fontSet = this.indicesIcon[initialValue].fontSet;
      this.fontIcon = this.indicesIcon[initialValue].fontIcon;
      this.statusColor = this.indicesIcon[initialValue].color;
      return;
    }
    this.value = '';
  }

  public onSelection($event: MatSelectChange): void {
    this.value = this.getDisplayValue($event.value);
    this.fontSet = this.indicesIcon[$event.value].fontSet;
    this.fontIcon = this.indicesIcon[$event.value].fontIcon;
    this.statusColor = this.indicesIcon[$event.value].color;
    this.editSubmissionStatus($event.value);
  }

  private getDisplayValue(value: any): string {
    let displayValue: any;
    this.filteredOptions.forEach((option: SelectOption) => {
      if ((option[this.indices.internal]) === value) {
        displayValue = `${this.prependPublic}${option[this.indices.public]}`;
      }
    });

    return displayValue;
  }

  private editSubmissionStatus(value: SubmissionStatus): void {
    if (value === SubmissionStatus.WITHDRAWN) {
      this.openWithdrawDialog(this.submission);
      return;
    }

    this.submissionsService.editSubmission({status: value}, this.submission.id)
      .subscribe(() => {
        this.selectionChange.emit({submissionID: this.submission.id, value});
        this.messageSuccessChangeStatus(value);
      });
  }

  private messageSuccessChangeStatus(status: SubmissionStatus): void {
    this.notificationService.notify(`reports.submissions.ranking.successful-assign-${status}`, { params: { total: 1 } });
  }

  private openWithdrawDialog(submission: Submission): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = { submission, submissions: null, previousStatus: submission.status };

    const dialogRef = this.dialog.open(SubmissionWithdrawalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.selectionChange.emit({submissionID: submission.id, value: SubmissionStatus.WITHDRAWN});
        return;
      }

      this.selectionChange.emit({submissionID: submission.id, value: submission.status});
    });
  }

  public getSelectedOptionStyles(color: string): any {
    return {
      'background-color': color + (this.lighterColor ? '95' : '')
    }
  }

  public getOptionsStyles(color: string): any {
    return {
      'background-color': color
    }
  }

  public onClosed(): void {
    this.closed.emit(true);
  }
}
