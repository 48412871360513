import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { PasswordValidator } from '../../validator/password.validator';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  passwordChanged: boolean;
  changePasswordForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.initForm();
  }

  submit(): void {
    if (this.changePasswordForm.valid) {
      // rename inputs to match API call
      const data = {
        token: this.route.snapshot.paramMap.get('token'),
        uid: this.route.snapshot.paramMap.get('uidb64'),
        newPassword1: this.changePasswordForm.value.password,
        newPassword2: this.changePasswordForm.value.confirmPassword
      };

      // call
      this.authService.passwordReset(data).subscribe(
        response => this.passwordChanged = true,
        er => {
          this.translateService.get(er.error.status.message).subscribe(message => {
            this.snackBar.open(message, null, { duration: 2000 });
          });
        }
      );
    }
  }

  private initForm() {
    this.changePasswordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]]
    }, {
      validators: PasswordValidator.MatchPassword
    });
  }
}
