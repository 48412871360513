interface IDictionary<T> {
  set(key: string, value: T): void;
  get(key: string): T;
  remove(key: string): boolean;
  contains(key: string): boolean;
  keys(): IterableIterator<string>;
  values(): IterableIterator<T>;
}

export class Dictionary<T> implements IDictionary<T> {
  _dictionary: Map<string, T> = new Map<string, T>();

  constructor(init?: { key: string; value: T; }[]) {
    if (init) {
      for (let x = 0; x < init.length; x++) {
        this.set(init[x].key, init[x].value);
      }
    }
  }

  set(key: string, value: T): Map<string, T> {
    return this._dictionary.set(key, value);
  }

  get(key: string): T {
    return this._dictionary.get(key);
  }

  remove(key: string): boolean {
    return this._dictionary.delete(key);
  }

  contains(key: string): boolean {
    return this._dictionary.has(key);
  }

  keys(): IterableIterator<string> {
    return this._dictionary.keys();
  }

  values(): IterableIterator<T> {
    return this._dictionary.values();
  }
}
