import { Component, Input, AfterViewInit, OnChanges, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import { Submission } from '../../model/paper';
import { DiscussionMessage } from '../../model/discussionMessage';

import { SubmissionsService } from 'src/app/service/submissions.service';
import { AuthService } from 'src/app/service/auth.service';
import { InfoService } from 'src/app/service/info.service';
import { NotificationService } from 'src/app/service/notification.service';
import { EventReviewConfiguration } from 'src/app/model/eventReviewConfiguration';

@Component({
  selector: 'app-discussion-messages',
  templateUrl: './discussion-messages.component.html',
  styleUrls: ['./discussion-messages.component.scss']
})
export class DiscussionMessagesComponent implements OnInit {
  @Input() submission: Submission;
  @Input() showAllSubmissions = false;
  @Input() reviewConfig: EventReviewConfiguration;
  @Input() eventTimeZone: string;

  @Input() userIsChairOrAdmin = false;

  public messages: Array<DiscussionMessage> = [];
  public form: FormGroup;
  public maxLength = 2000;
  public rows = 2;

  public columns = ['id', 'content', 'createdAt'];

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private infoService: InfoService,
    private submissionService: SubmissionsService,
    private notificationService: NotificationService,
  ) { }

  public ngOnInit(): void {
    // this.authService.userIsAdminOrChair(this.submission.event.id).subscribe(isAdminOrChair => {
    //   this.userIsChairOrAdmin = isAdminOrChair;
    // });

    // this.submissionService.getDiscussionMessages(this.submission.id).subscribe(messages => {
    //   this.messages = messages;
    //   this.setColumns();
    //   this.initForm();
    // });

    if (this.submission) {
      this.messages = this.submission.discussionMessages;
      this.setColumns();
      this.initForm();
    }
  }

  setColumns() {
    if (!this.submission.isAuthor(this.infoService.user.id)) {
      if (this.reviewConfig.tpcViewReviewIdentity === 'ALL') {
        this.columns = ['id', 'content', 'sender', 'createdAt'];
      }
    }
    if (this.userIsChairOrAdmin && !this.showAllSubmissions) {
      this.columns.push('delete');
    }
  }

  initForm() {
    this.form = this.fb.group({
      content: ['', [Validators.minLength(5), Validators.maxLength(this.maxLength)]]
    });
  }

  submit(): void {
    this.submissionService.postDiscussionMessages(this.submission.id, this.infoService.user.id, this.form.value['content'])
      .subscribe( messages => {
        this.messages = messages;
        this.form.reset({content: ''});
    });
  }

  delete(message: DiscussionMessage): void {
    this.notificationService.openConfirmationDialog({title: 'submissions.discussion-messages.confirm-delete', content: ''})
      .subscribe( response => {
        if (response) {
          this.submissionService.deleteDiscussionMessage(this.submission.id, message.id).subscribe( messages => {
            this.messages.splice(this.messages.findIndex(m => m.id === message.id), 1);
            this.messages = [...this.messages];
            this.notificationService.notify('submissions.discussion-messages.success-delete');
          }, error => {
            this.notificationService.notifyError('submissions.discussion-messages.fail-delete');
          });
        }
      });
  }
}
