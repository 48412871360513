import { Injectable } from '@angular/core';
import { Router, CanActivateChild, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../service/auth.service';

@Injectable()
export class RedirectGuard implements CanActivateChild {
  constructor(public authService: AuthService, public router: Router) { }

  canActivateChild(route: ActivatedRouteSnapshot, y: RouterStateSnapshot) {
    // const returnUrl = route.queryParams['returnUrl'] || '/dashboard';
    const returnUrl = route.queryParams['returnUrl'] || '/events';

    if (this.authService.isAuthenticated()) {
      this.router.navigateByUrl(returnUrl);
    }

    return true;
  }
}
