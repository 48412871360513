import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { MatDialog } from '@angular/material/dialog';
import { FormSessionDialogComponent } from 'src/app/component/form-session-dialog/form-session-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { Event } from 'src/app/model/event';
import { NotificationService } from 'src/app/service/notification.service';
import { WeekViewHourSegment } from 'calendar-utils';
import * as moment from 'moment';
import { Dayjs } from 'dayjs';
import { FileRules } from 'src/app/model/file.rules';
import { File } from 'src/app/model/file';
import { Session } from 'src/app/model/session';
import { Submission } from 'src/app/model/paper';
import { CalendarEventTimesChangedEventType } from 'src/app/enum/calendar.changed.type';

interface ExtendedCalendarEventSessions extends CalendarEvent {
  event: any;
  title: string;
  subtitle: string;
  url: string;
  room: string;
  submissions: Array<Submission>;
}

interface ExtendedCalendarEventSessions extends CalendarEventTimesChangedEvent {
}

function floorToNearest(amount: number, precision: number) {
  return Math.floor(amount / precision) * precision;
}

function ceilToNearest(amount: number, precision: number) {
  return Math.ceil(amount / precision) * precision;
}

@Component({
  selector: 'app-sessions',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit {
  event: Event;
  submissions: Array<Submission>;
  viewDate: Date = new Date();
  events: ExtendedCalendarEventSessions[] = [];
  view: CalendarView = CalendarView.Week;
  activeDayIsOpen = true;
  weekStartsOn: 0 = 0;

  dragToCreateActive = false;

  public addSubmissionsMode = false;
  public sessionSubmissionMode;

  showFiller = false;

  public viewType;

  displayedColumns: string[] = ['starts', 'ends', 'track', 'title', 'room', 'chair', 'submissions', 'edit', 'delete'];
  tableDataSource: MatTableDataSource<Session>;

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleSession('create/edit', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleSession('delete', event);
      },
    },
  ];

  constructor(
    private adminService: AdminService,
    private eventService: EventsService,
    public notificationService: NotificationService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.addSubmissionsMode = false;
      this.route.params.subscribe(params => {
        this.event = this.adminService.selectedEvent;
        this.viewType = params.viewType;
        if (this.event) {
          this.loadSessions(this.event);
          this.tableDataSource = new MatTableDataSource<Session>(this.event.sessions);
        } else {
          this.adminService.getEvent().subscribe(event => {
            this.event = event;
            this.loadSessions(event);
            this.tableDataSource = new MatTableDataSource<Session>(event.sessions);
          });
        }
      });
    });
  }

  fileUploaded(trackFile: FileRules, submission: Submission): File {
    return submission.files.find(file => file.trackFile.name === trackFile.name);
  }

  loadSessions(event) {
    setTimeout(() => {
      this.events = event.sessions.map(iSession => {
        const session = iSession as unknown as ExtendedCalendarEventSessions;
        session.title = iSession.title;
        session.subtitle = iSession.subtitle;
        session.start = moment(iSession.start).toDate();
        session.end = moment(iSession.end).toDate();
        session.submissions = iSession.submissions;
        session.draggable = true;
        session.actions = this.actions,
        session.resizable = {
            beforeStart: true,
            afterEnd: true,
          };
        session.color = {
          primary: '#FFFFFF',
          secondary: iSession.backgroundColor,
        };
        return session;
      });

      this.refresh();
    });
  }

  handleSession(action: string, session: CalendarEvent): void {
    if ( action === 'create/edit' ) {
      const dialogRef = this.dialog.open(FormSessionDialogComponent, {
        height: '800px',
        width: '600px',
        data: {
          params: {
            session: session,
            eventTracks: this.event.tracks,
            saveSession: (saveFromDialog) => {
              this.updateSession(saveFromDialog);
            }
          }
        }
      });
    } else if (action === 'delete') {
      if (session.id) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            title: 'admin.event.sessions.title-delete',
            content: ''
          }
        }).afterClosed().subscribe(confirmed => {
          if (confirmed) {           
            this.deleteSession(session);
          }
        });
      } else {
          this.events = this.events.filter((iEvent) => iEvent !== session);
      }
    } else if (action === 'clicked') {
        this.sessionSubmissionMode = session;
        this.addSubmissionsMode = !this.addSubmissionsMode;
    }
  }

  eventTimesChanged({ event, newStart, newEnd}: ExtendedCalendarEventSessions): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        event.start = newStart;
        event.end = newEnd;
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.updateSession(event);
  }

  updateSession(eventSession) {   
    let tempTrack = eventSession.track;
    let tempChair = eventSession.chair;
    eventSession.track = eventSession.track ? eventSession.track.id : null;
    eventSession.chair = eventSession.chair ? eventSession.chair.id : null;
    if (eventSession.id) {
      this.eventService.editSession(eventSession, eventSession.id).subscribe(session => {
        this.events = this.events.map(iEvent => {
          if (iEvent.id === session.id) {
            return {
              ...iEvent,
              title: session.title,
              subtitle: session.subtitle,
              url: session.url,
              track: tempTrack,
              room: session.room,
              chair: tempChair,
              backgroundColor: session.backgroundColor,
              color : {
                primary: '#FFFFFF',
                secondary: session.backgroundColor,
              },
            };
          }
          return iEvent;
        });
        this.notificationService.notify('admin.event.sessions.saved-session', {
          params: {
            session: `#${session.id} "${session.title}"`
          }
        });
        this.refresh();
      }, error => {
        console.error(error);
      });
    } else {
      this.eventService.createSession(eventSession).subscribe(session => {
        this.events = this.events.map(iEvent => {
          if (iEvent === eventSession) {
            return {
              ...iEvent,
              id: session.id
            };
          }
          return iEvent;
        });
        this.notificationService.notify('admin.event.sessions.create-new', {
          params: {
            session: `#${session.id} "${session.title}"`
          }
        });
      });
    }
  }

  deleteSession(session) {
    this.eventService.deleteSession(session.id, this.event).subscribe(() => {
      this.events = this.events.filter((iEvent) => iEvent !== session);
      this.notificationService.notify('admin.event.sessions.deleted-session', {
        params: {
          track: `#${session.id} "${session.title}"`
        }
      });
    });
  }

  setView(view: CalendarView) {
      this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  onVizualizationChange(value: CalendarView) {
    this.setView(value);
  }

  dayClicked({ date, events }: { date: Date; events: ExtendedCalendarEventSessions[] }): void {
  if (moment(this.viewDate).isSame(date, 'month')) {
      if (
        (moment(this.viewDate).isSame(date, 'day') && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  startDragToCreate(
  segment: WeekViewHourSegment,
  mouseDownEvent: MouseEvent,
  segmentElement: HTMLElement
) {
  const dragToSelectEvent: ExtendedCalendarEventSessions = {
    id: null,
    event: this.event.id,
    title: 'New session',
    subtitle: '',
    newStart: segment.date,
    type: CalendarEventTimesChangedEventType.Drag,
    url: '',
    room: '',
    start: segment.date,
    submissions: [],
    color : {
      primary: '#FFFFFF',
      secondary: '#BFE7FF',
    },
    end: (moment(segment.date).add(30, 'minutes').add(0, 'days')).toDate(), /**Add 30 minutes in a new session by default*/
    meta: {
      tmpEvent: true,
    },
    draggable: true,
    actions: this.actions,
    resizable: {
        beforeStart: true,
        afterEnd: true,
      },
  };
  this.events = [...this.events, dragToSelectEvent];
  const segmentPosition = segmentElement.getBoundingClientRect();
  this.dragToCreateActive = true;
  const today = moment();
  const endOfView = today.endOf('week');

  this.updateSession(dragToSelectEvent);

  fromEvent(document, 'mousemove')
    .pipe(
      finalize(() => {
        delete dragToSelectEvent.meta.tmpEvent;
        this.dragToCreateActive = false;
        this.refresh();
      }),
      takeUntil(fromEvent(document, 'mouseup'))
    )
    .subscribe((mouseMoveEvent: MouseEvent) => {
      const minutesDiff = ceilToNearest(
        mouseMoveEvent.clientY - segmentPosition.top,
        30
      );

      const daysDiff =
        floorToNearest(
          mouseMoveEvent.clientX - segmentPosition.left,
          segmentPosition.width
        ) / segmentPosition.width;

      const newEnd = moment(segment.date).add(minutesDiff, 'minutes').add(daysDiff, 'days');
      if (newEnd > moment(segment.date) && newEnd < endOfView) {
        dragToSelectEvent.end = newEnd.toDate();
      }
      this.refresh();
    });
  }

  private refresh() {
    this.events = [...this.events];
    this.cdr.detectChanges();
  }
}
