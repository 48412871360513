import { Component, Input, AfterViewInit, OnChanges, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import { Submission } from '../../model/paper';
import { Rebuttal } from '../../model/rebuttal';

import { SubmissionsService } from 'src/app/service/submissions.service';
import { AuthService } from 'src/app/service/auth.service';
import { InfoService } from 'src/app/service/info.service';
import { NotificationService } from 'src/app/service/notification.service';
import { ReviewsConfigurationService } from 'src/app/service/reviews.configuration.service';
import { EventReviewConfiguration } from 'src/app/model/eventReviewConfiguration';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-rebuttal',
  templateUrl: './rebuttal.component.html',
  styleUrls: ['./rebuttal.component.scss']
})
export class RebuttalComponent implements OnInit {

  @Input() submission: Submission;

  @Input() isAuthor;
  @Input() userIsChair;
  @Input() reviewConfig: EventReviewConfiguration;
  @Input() eventTimeZone: string;

  public rebuttal: Rebuttal;
  public rebuttals: Array<String> = [];
  public content: string;
  public form: FormGroup;
  public maxLength = 7000;
  public rows = 5;
  public columns = ['content'];

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private infoService: InfoService,
    private submissionService: SubmissionsService,
    private notificationService: NotificationService,
    public reviewConfigurationService: ReviewsConfigurationService,
  ) { }

  public ngOnInit(): void {
    this.content = this.submission.rebuttal;
    this.rebuttals.push(this.content);
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      content: [this.content ? this.content : '', [Validators.minLength(1), Validators.maxLength(this.maxLength)]]
    });
    if (this.content) {
      if (this.content.split(/\r\n|\r|\n/).length > 10) {
        this.rows = 15;
      }
    }
  }

  submit(): void {
    if (this.form.valid) {
      this.submissionService.writeRebuttal(this.submission.id, this.form.value['content'])
        .subscribe( rebuttal => {
          this.content = rebuttal.content;
          this.notificationService.notify('submissions.rebuttal.success');
      });
    }
  }
}
