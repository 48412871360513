import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SystemService } from '../../../service/system.service';
import { UserSearch } from '../../../model/user.search';
import { SearchResult } from '../../../model/search.result';
import { plainToClass } from 'class-transformer';
import { SidebarService } from '../../../service/sidebar.service';
import { AdminService } from '../../../service/admin.service';
import { PagedViewDirective } from '../../../class/paged-view';
import { PreferencesService } from 'src/app/service/preferences.service';

@Component({
  selector: 'app-search-results-in-event',
  templateUrl: './search-results-in-event.component.html',
  styleUrls: ['./search-results-in-event.component.scss']
})
export class SearchResultsInEventComponent extends PagedViewDirective implements OnInit {
  results: SearchResult;
  selectedIndex: number;
  eventId: number;
  TABS_INDEXES = {
    SUBMISSIONS: 0,
    REVIEWS: 1,
    PEOPLE: 2
  };

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private systemService: SystemService,
    private sidebarService: SidebarService,
    protected adminService: AdminService,
    protected preferencesService: PreferencesService
  ) {
    super(adminService, preferencesService);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    setTimeout( () => {
      this.selectedIndex = this.route.snapshot.queryParamMap['params'].where === 'all' ? 0 : -1;
      const event = this.adminService.selectedEvent;
      if (!event) {
        this.adminService.getEvent().subscribe(e => this.eventId = e.id);
      } else {
        this.eventId = event.id;
      }
      this.load();
    });
  }

  load() {
    this.search();
  }

  search(): void {
    this.adminService.progressBar.start();
    if (!this.eventId) {
      this.adminService.getEvent().subscribe(event => {
        this.eventId = event.id;
        this.realize_search();
      });
    } else {
      this.realize_search();
    }
  }

  realize_search(): void {
    this.route.queryParams.subscribe(params => {
      this.systemService.searchInEvent(plainToClass(UserSearch, params as UserSearch), this.adminService.eventId)
        .subscribe(res => {
          this.results = res;

          this.adminService.progressBar.stop();
        });
    });
  }
}
