import { Component, Input } from '@angular/core';
import { PreferencesService } from '../../service/preferences.service';

@Component({
  selector: 'app-paper-list-header',
  templateUrl: './paper-list-header.component.html',
  styleUrls: ['./paper-list-header.component.scss']
})
export class PaperListHeaderComponent {
  @Input() reviewList = false;

  constructor(public preferencesService: PreferencesService) { }
}
