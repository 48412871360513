import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { LandingComponent } from '../landing.component';
import { AuthService } from 'src/app/service/auth.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { NotificationService } from 'src/app/service/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  signUpForm: FormGroup;
  registrationCompleted: Boolean;

  captchaResolved: Boolean = false;

  @ViewChild(RecaptchaComponent) captchaRef: RecaptchaComponent;

  constructor(
    private container: LandingComponent,
    private userService: UserService,
    private fb: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  submit() {
    if (this.signUpForm.valid) {
      this.container.progressBar.start();

      this.userService.create(this.signUpForm.value).subscribe(
        response => {
          this.registrationCompleted = true;
          this.container.progressBar.stop();
        },
        error => {
          if (error.error.status.params.email === 'unique') {
            this.signUpForm.controls['email'].setErrors({'unique': true});
          }
          this.container.progressBar.stop();
        }
      );
    }
  }

  private initForm() {
    this.signUpForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  public onCaptchaResolved(token: string) {
    this.authService.validateCaptcha(token).subscribe(response => {
      this.captchaResolved = response.data.success;
      if (!this.captchaResolved) {
        this.captchaRef.reset();
        this.notificationService.notifyError(this.translateService.instant('forms.errors.invalid-recaptcha-token'));
      }
    });
  }
}
