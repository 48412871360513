import { Event } from './event';
import { defaultFields } from '../enum/submissions.field';
import { SubmissionsFilter } from '../enum/submissions.filter';

export const columnsOrder = [
  defaultFields.POSITION, 
  'id', 
  'status', 
  'description', 
  'group',
  'tpc-group',
  'event', 
  'track', 
  'score-numeric', 
  'rebuttal', 
  'discussion-messages', 
  'reviews',
  'score-average-arithmetic',
  'score-average-weighted', 
  'score-span', 
  'files', 
  'others-form',
  'registration',
  'copyright',
  defaultFields.SELECT
];

export class SubmissionFieldAndFilter {
    id: number;
    event: Event;
    eventBool: boolean;
    submissionId: boolean;
    status: boolean;
    title: boolean;
    topics: boolean;
    abstract: boolean;
    group: boolean;
    name: boolean;
    affiliation: boolean;
    country: boolean;
    email: boolean;
    track:boolean;
    reviews: boolean;
    discussionMessages: boolean;
    rebuttal: boolean;
    spanOfScores: boolean;
    averageReviewScore: boolean;
    numericReviewScores: boolean;
    files: boolean;
    submissionFormItems: boolean;
    registration: boolean;
    crVersion:boolean;
    statusFilter: string;
    topicsFilter: string;
    tracksFilter: string;
    submissionGroupsFilter: string;
    tpcGroupsFilter: string;
    sortBy: string;
    reverseSort: boolean;
}

export const groupFields = [
    {
      label: 'forms.fields.filters.submission',
      fields: [
        { label: 'forms.fields.filters.submission-number', control: 'id', name: 'submissionId' },
        { label: 'forms.fields.filters.submission-status', control: 'status', name: 'status'},
        { label: 'forms.fields.filters.submission-title', control: 'description', name: 'title' },
        { label: 'forms.fields.filters.submission-topics', control: 'topics', name: 'topics' },
        { label: 'forms.fields.filters.submission-abstract', control: 'abstract', name: 'abstract'},
        { label: 'forms.fields.filters.name-of-submission-group', control: 'group', name: 'group'},
        { label: 'forms.fields.filters.tpc-group', control: 'tpc-group', name: 'tpc-group' }
      ],
      control: 'submission'
    },
    {
      label: 'forms.fields.filters.author',
      fields: [
        { label: 'forms.fields.filters.author-names', control: 'authors-names', name: 'name' },
        { label: 'forms.fields.filters.author-affiliation', control: 'authors-affiliations', name: 'affiliation' },
        { label: 'forms.fields.filters.author-country', control: 'authors-countries', name: 'country' },
        { label: 'forms.fields.filters.author-emails', control: 'authors-emails', name: 'email' },
      ],
      control: 'author'
    },
    {
      label: 'reviews.event',
      fields: [
        { label: 'forms.fields.filters.name-of-event', control: 'event', name: 'eventBool' },
        { label: 'forms.fields.filters.name-of-track', control: 'track', name: 'track' }
      ],
      control: 'event'
    },
    {
      label: 'forms.fields.filters.score',
      fields: [
        { label: 'forms.fields.filters.reviewer-name', control: 'reviewer-name', name: 'reviewerName'},
        { label: 'forms.fields.filters.rebuttal', control: 'rebuttal', name: 'rebuttal'},
        { label: 'forms.fields.filters.number-of-discussion-messages', control: 'discussion-messages', name: 'discussionMessages' },
        { label: 'forms.fields.filters.number-of-reviews', control: 'reviews', name: 'reviews' },
        { label: 'forms.fields.filters.numeric-review-scores', control: 'score-numeric', name: 'numericReviewScores' },
        { label: 'forms.fields.filters.average-review-score', control: 'score-average', name: 'averageReviewScore' },
        { label: 'forms.fields.filters.span-max-min-scores', control: 'score-span', name: 'spanOfScores' },
      ],
      control: 'score'
    },
    {
      label: 'forms.fields.filters.others',
      fields: [
        { label: 'forms.fields.filters.files', control: 'files', name: 'files' },
        { label: 'forms.fields.filters.submission-form-items', control: 'others-form', name: 'submissionFormItems' },
      ],
      control: 'others'
    },
];

export const dictControlFields = {
  'abstract': 'abstract',
  'affiliation': 'authors-affiliations',
  'averageReviewScore': 'score-average',
  'crVersion': 'copyright',
  'country': 'authors-countries',
  'discussionMessages': 'discussion-messages',
  'email': 'authors-emails',
  'eventBool': 'event',
  'files': 'files',
  'group': 'group',
  'name': 'authors-names',
  'numericReviewScores': 'score-numeric',
  'rebuttal': 'rebuttal',
  'registration': 'registration',
  'reviewerName': 'reviewer-name',
  'reviews': 'reviews',
  'spanOfScores': 'score-span',
  'status': 'status',
  'submissionFormItems': 'others-form',
  'submissionId': 'id',
  'title': 'description',
  'tpcGroup': 'tpc-group',
  'topics': 'topics',
  'track': 'track'
};

/* This fields are not columns in the table */
export const specialFields = [
  dictControlFields['abstract'],
  dictControlFields['affiliation'],
  dictControlFields['country'],
  dictControlFields['email'],
  dictControlFields['name'],
  dictControlFields['reviewerName'],
  dictControlFields['topics'],
];

/* This fields control two or more columns in the table */
export const specialColumnsFields = {
  [dictControlFields['averageReviewScore']]: ['score-average-arithmetic', 'score-average-weighted'],
  [dictControlFields['track']]: ['test1', 'test2'],
};

export const publicationFields = {
  copyright: { label: 'forms.fields.filters.copyright', control: 'copyright', name: 'copyright' },
  registration: { label: 'forms.fields.filters.author-registration', control: 'registration', name: 'registration' },
  customFields: 'customFields',
};

export const dictControlFilters = {
  'statusFilter': SubmissionsFilter.STATUS,
  'topicsFilter': SubmissionsFilter.TOPICS,
  'tracksFilter': SubmissionsFilter.TRACKS,
  'submissionGroupsFilter': SubmissionsFilter.SUBMISSION_GROUPS,
  'tpcGroupsFilter': SubmissionsFilter.TPC_GROUPS
};

export const ColorSchemeFilter = {
  'Submission status': 0,
  'Publication status': 1,
};
