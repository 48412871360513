import { Component, AfterViewInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NotificationService } from 'src/app/service/notification.service';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss']
})
export class NotificationMenuComponent implements AfterViewInit {
  @ViewChild('appNotificationMenu', { static: true }) menu;
  @Output() NotificationMenuInstance = new EventEmitter<MatMenu>(true);

  constructor(
    private notificationService: NotificationService
  ) { }

  ngAfterViewInit() {
    this.NotificationMenuInstance.emit(this.menu);
  }

}
