import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventsService } from '../../service/events.service';
import { catchError } from 'rxjs/operators';
import { Event } from '../../model/event';
import { throwError } from 'rxjs';

// Component checks if link is of event. If not, redirects to 404.
@Component({
  selector: 'app-check-event',
  templateUrl: './check-event.component.html',
  styleUrls: ['./check-event.component.scss']
})
export class CheckEventComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private eventsService: EventsService) { }

  ngOnInit() {
    this.route.url.subscribe(urlSegments => {
      const eventLink = urlSegments[0].toString();

      let extraSegments: string[];
      if (urlSegments.length > 1) {
        extraSegments = urlSegments.map(u => u.toString()).filter(s => s.length > 0);
        extraSegments.splice(0, 1);

        if (extraSegments.length === 0) {
          extraSegments = undefined;
        }
      }

      this.eventsService.getEventByLink(eventLink)
        .subscribe(event => {
          if (event) {
            if (extraSegments?.length > 0) {
              this.router.navigate(['/', 'admin', event.id, ...extraSegments]);
            } else {
              this.router.navigate(['/', 'events', event.id]);
            }
          } else {
            this.router.navigate(['/error/404']);
          }
        }, () => {
          this.router.navigate(['/loading'], { skipLocationChange: true }).then(() =>
            this.router.navigate(['/'], { queryParams: {
              returnUrl: `/${eventLink}/${extraSegments?.length > 0 ? extraSegments.join('/') : ''}`
            }})
          );
        });
    });
  }

}
