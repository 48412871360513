import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, forwardRef, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-array-chip-input',
  templateUrl: './array-chip-input.component.html',
  styleUrls: ['./array-chip-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ArrayChipInputComponent),
    multi: true
  }]
})
export class ArrayChipInputComponent {
  @Input() formGroupController: FormGroup;
  @Input() name: string;
  @Input() placeholder: string;

  @Input() label: string;
  @Input() icon: string;
  @Input() prefix: string;
  @Input() required: boolean;

  @Input() onAddHook: (event: MatChipInputEvent) => void;
  @Input() onRemoveHook: (index: number) => void;
  @Input() addOnBlur = true;
  @Input() separatorKeyCodes: number[] = [COMMA, ENTER];
  @Input() removable = true;
  @Input() selectable = false;

  constructor() { }

  addItem(event: MatChipInputEvent): void {
    const { input, value } = event;

    if ((value || '').trim()) {
      const tracksControl = <FormArray>this.formGroupController.get(this.name);
      tracksControl.push(new FormControl(value.trim()));
    }

    if (input) {
      input.value = '';
    }

    if (this.onAddHook) {
      this.onAddHook(event);
    }
  }

  removeItem(index: number): void {
    const tracksControl = <FormArray>this.formGroupController.get(this.name);
    tracksControl.removeAt(index);

    if (this.onRemoveHook) {
      this.onRemoveHook(index);
    }
  }
}
