import { Component, forwardRef, Input } from '@angular/core';
import { TextInputComponent } from '../text-input/text-input.component';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-numeric-input',
  templateUrl: './numeric-input.component.html',
  styleUrls: ['./numeric-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NumericInputComponent),
    multi: true
  }]
})
export class NumericInputComponent extends TextInputComponent {
  @Input() max: number;
  @Input() min: number;
  @Input() step: number;
  @Input() minYear: number;
  @Input() floatingLabel = false;
  @Input() formGroupController: FormGroup;

  get searchMessageMinYear(): string {
    return `forms.errors.min${this.minYear ? '-year' : ''}`
  }
}
