import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SubmissionsService } from '../../service/submissions.service';
import { Location } from '@angular/common';
import { ReviewsService } from '../../service/reviews.service';
import { CardViewComponent } from '../card-view/card-view.component';
import { InfoService } from '../../service/info.service';
import { TranslateService } from '@ngx-translate/core';
import { Track } from '../../model/track';
import { Review } from '../../model/paper';
import { EventsService } from 'src/app/service/events.service';
import { Submission } from '../../model/paper';
import { NavbarService } from 'src/app/service/navbar.service';
import { ReviewStatus } from 'src/app/enum/review.status';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-submission-view',
  templateUrl: './submission-view.component.html',
  styleUrls: ['./submission-view.component.scss', '../card-view/card-view.component.scss']
})
export class SubmissionViewComponent extends CardViewComponent implements OnInit {
  constructor(
    router: Router, route: ActivatedRoute,
    submissionService: SubmissionsService, location: Location,
    infoService: InfoService, reviewsService: ReviewsService,
    private translateService: TranslateService, private eventsService: EventsService,
    private navbarService: NavbarService,
    private titleService: Title
  ) {
    super(router, route, submissionService, location, infoService, reviewsService);
  }

  submission: Submission;
  permissionChecked = false;

  ngOnInit() {
    setTimeout(() => {
      this.navbarService.loadingStart();

      this.route.queryParams.subscribe(() => {
        this.submissionService
          .getSubmission(parseInt(this.route.snapshot.paramMap.get('id'), 10), true)
          .subscribe(submission => {
            this.eventsService
            .getEventByTrack(submission.trackID)
            .subscribe(event => {
                this.submission = submission;
                this.submission.event = event;
                this.submission.track = event.tracks.find(track => submission.trackID === track.id);
                this.updateHeadTitle();
                this.navbarService.loadingStop();

                // Se tem um evento associado e o evento da submissão for diferente do associado.
                const parentEvent = Number(this.route.parent.snapshot.params.eventId);
                if (parentEvent && parentEvent !== submission.event.id) {
                  this.router.navigate(['/error/403']);
                }
                this.checkReviewerPermission(submission);

                this.loaded = true;
              });
          });
      });
    });
  }

  checkReviewerPermission(submission: Submission){
    this.reviewsService.getReviewsBySubmissionId(this.submission.id).subscribe(reviews => {
      reviews.some((review: Review) => {
        if (review.userId === this.infoService.user.id && review.status ===  ReviewStatus.NOTIFIED) {
          this.router.navigate(['/confirm/reviewSubmission', review.id]);
        }
      });
    }, () => {},
    () => {
      this.permissionChecked = true;
    });
  }

  updateHeadTitle(){
    if (this.submission.event) {
      let eventAcronymYear = this.submission.event.acronym + " " + this.submission.event.year + " - ";
      let oldTitle = this.titleService.getTitle();
      if (!oldTitle.includes(eventAcronymYear)){
        this.titleService.setTitle(eventAcronymYear + this.titleService.getTitle());
      }
    }
  }
}
