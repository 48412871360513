import { Component, OnInit } from '@angular/core';
import { Track } from 'src/app/model/track';
import { SubmissionsService } from '../../../../service/submissions.service';
import { SubmissionStatus } from '../../../../enum/submission.status';
import { SelectOption } from '../../../../model/select.option';
import { AdminService } from '../../../../service/admin.service';
import { Event } from '../../../../model/event';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ExportService } from 'src/app/service/export.service';

@Component({
  selector: 'app-bibtex-export',
  templateUrl: './bibtex-export.component.html',
  styleUrls: ['./bibtex-export.component.scss']
})
export class AdminBibtexExportComponent implements OnInit {
  event: Event;

  tracks: SelectOption[];
  status: SelectOption[] = SubmissionStatus.toSelectable();

  form: FormGroup;
  bibtexList: Array<string>;
  bibtexExport: string;

  constructor(
    public adminService: AdminService,
    public submissionsService: SubmissionsService,
    public fb: FormBuilder,
    public exportService: ExportService
  ) { }

  ngOnInit(): void {
    this.event = this.adminService.selectedEvent;
    if (!this.event) {
      this.adminService.getEvent().subscribe(event => {
        this.event = event;
        this.initSelectables();
      });
    } else {
      this.initSelectables();
    }
  }

  initSelectables() {
    this.tracks = this.event.tracks.map(t => {
      return new SelectOption(t.id, t.name);
    });

    this.form = this.fb.group({
      status: [],
      tracks: []
    });
  }

  getBibtex() {
    this.submissionsService.getEventBibtex(this.event.id, this.form.value.status, this.form.value.tracks).subscribe(list => {
      this.bibtexList = list;
      this.generateExportFile();
    });
  }

  generateExportFile() {
    this.bibtexExport = this.bibtexList.join('\n\n');

    setTimeout(() => {
      this.exportService.setDownloadMultiTarget('.exportBibtex', `${this.event.name}-bibtex.bib`, this.bibtexExport, 'text/plain');
    });
  }
}
