import { BaseModel, toDayjs } from './baseModel';
import { Type, Transform } from 'class-transformer';
import { User } from './user';
import { Event } from './event';
import * as dayjs from 'dayjs';
import { EventRequestStatus } from '../enum/event.request.status';


export class EventRequest extends BaseModel {
  public name: string;
  public acronym: string;
  public year: number;
  public city: string;
  public state: string;
  public country: string;
  public timeZone: string;
  public eventParent?: Event | number;
  public chairs: User[] | string;

  @Type(() => User)
  public requestingUser: User | number;

  @Type(() => User)
  public judgeUser: User;

  @Transform(toDayjs)
  public beginAt: dayjs.Dayjs;

  @Transform(toDayjs)
  public endAt: dayjs.Dayjs;

  @Transform(toDayjs)
  public makePublicAt: dayjs.Dayjs;

  @Type(() => Event)
  public event: Event | number;

  public eventType: number;

  public uri: string;

  public status: EventRequestStatus = EventRequestStatus.PENDING;

  isPending(): boolean {
    return this.status === EventRequestStatus.PENDING;
  }

  isAccepted(): boolean {
    return this.status === EventRequestStatus.ACCEPTED;
  }

  isRejected(): boolean {
    return this.status === EventRequestStatus.REJECTED;
  }
}
