import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Track } from 'src/app/model/track';
import { Submission, SubmissionStatus } from 'src/app/model/paper';
import { FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/service/auth.service';
import { FilesService } from 'src/app/service/files.service';
import { NotificationService } from 'src/app/service/notification.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { File } from '../../model/file';
import { FileRules } from 'src/app/model/file.rules';
import { SubmissionsService } from 'src/app/service/submissions.service';

@Component({
  selector: 'app-file-rules-table',
  templateUrl: './file-rules-table.component.html',
  styleUrls: ['./file-rules-table.component.scss']
})
export class FileRulesTableComponent implements OnInit {
  @Input() track: Track;
  @Input() formGroupController: FormGroup;
  @Input() formName: string;
  @Input() submission: Submission;

  @Output() submissionChanged = new EventEmitter<Submission>();


  public userIsChair = false;

  constructor(
    private authService: AuthService,
    private submissionsService: SubmissionsService,
    private notificationService: NotificationService,
    public filesService: FilesService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.authService.userIsAdminOrChair(this.track.event).subscribe(isAdminOrChair => {
      this.userIsChair = isAdminOrChair;
    });

    this.track.trackFiles.forEach(r => {
      r.track = this.track;
    });
   }

   deleteFile(file: File) {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'messages.confirm-file-deletion',
        content: ''
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.submissionsService.deleteSubmissionFile(file, this.submission).subscribe(updatedSubmission => {
          this.submissionsService.getSubmission(this.submission.id, true).subscribe(sub => this.submission = sub);
          this.notificationService.notify('messages.removed-file');
          this.submission.files = this.submission.files.filter(f => f.id !== file.id);
        });
      }
    });
  }
}
