import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CustomControlFieldEditComponent } from 'src/app/component/custom-control-field-edit/custom-control-field-edit.component';
import { Event } from 'src/app/model/event';
import { CheckListAnswer, CheckListField } from 'src/app/model/checklist-field';
import { AdminService } from 'src/app/service/admin.service';
import { PublicationEventInfo, PublicationService } from 'src/app/service/publication.service';
import { FormFieldType } from 'src/app/enum/form.field.type';
import { FormFieldCategory } from 'src/app/enum/form-field-category';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { Observable, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NavbarService } from 'src/app/service/navbar.service';
import { Submission } from 'src/app/model/paper';
import { SubmissionsService } from 'src/app/service/submissions.service';

@Component({
  selector: 'app-event-publication',
  templateUrl: './event-publication.component.html',
  styleUrls: ['./event-publication.component.scss']
})
export class EventPublicationComponent implements OnInit {

  configurationForm: FormGroup;
  authorRegistrationForm: FormGroup;
  copyrightForm: FormGroup;
  customControlForm: FormArray;  
  authorRegistrationAnswers: FormArray;
  copyrightAnswers: FormArray;
  event: Event | PublicationEventInfo;
  eventSubmissions: Submission[];

  checklistFields: Array<CheckListField> = [];

  checklistAnswers = {
    'AUTHOR_REGISTRATION': [],
    'COPYRIGHT': []
  }

  FormFieldCategory = FormFieldCategory;

  enabledIEEECF = false;

  constructor(
    private adminService: AdminService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private navbarService: NavbarService,
    private publicationService: PublicationService,
    private submissionsService: SubmissionsService,
    private translate: TranslateService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.publicationService.getPublicationEventInfo(this.adminService.eventId).subscribe(publicationEventInfo => {
        this.event = publicationEventInfo;
      }, () => {}, () => {
        this.initComponent();
      });
    });
  }

  private initComponent(): void {
    this.initEventSubmissions();
    this.initConfigurationForm();

    this.publicationService.getEventCheckListFields(this.adminService.eventId).subscribe(checklistFields => {
      this.checklistFields = checklistFields;
    });

    this.publicationService.getSubmissionsAnswers(this.adminService.eventId, {category: FormFieldCategory.AUTHOR}).subscribe(answers => {
      this.checklistAnswers.AUTHOR_REGISTRATION = answers;
    }, () => {}, () => {
      this.initAuthorRegistrationForm();
    });

    this.publicationService.getSubmissionsAnswers(this.adminService.eventId, {category: FormFieldCategory.COPYRIGHT}).subscribe(answers => {
      this.checklistAnswers.COPYRIGHT = answers;
    }, () => {}, () => {
      this.initCopyrightForm();
    });

    this.publicationService.getEventCheckListFields(this.event.id).subscribe(checklistFields => {
      this.checklistFields = checklistFields;
    }, () => {}, () => {
      this.initCustomControlForm();
    });
  }

  initEventSubmissions() {
    this.submissionsService.getSubmissionsByEventMinimum(this.adminService.eventId).subscribe(submissions => {
      this.eventSubmissions = submissions;
    }, () => {}, () => {});
  }

  private initConfigurationForm() : void {
    this.configurationForm = this.fb.group({
      authorRegistrationEnable: [this.event.authorRegistrationEnable],
      copyrightEnable: [this.event.copyrightEnable]
    });

    this.configurationForm.get('authorRegistrationEnable').valueChanges.subscribe(() => { this.submitConfigurationForm(); });
    this.configurationForm.get('copyrightEnable').valueChanges.subscribe(() => {this.submitConfigurationForm(); });

  }

  private initAuthorRegistrationForm() : void {
    this.authorRegistrationForm = this.fb.group({
      submissions: [this.listToText(this.checklistAnswers.AUTHOR_REGISTRATION.map(a => a.submissionId))]
    });

    this.initAuthorRegistrationAnswersForm();
  }

  private initAuthorRegistrationAnswersForm() : void {
    this.checklistAnswers.AUTHOR_REGISTRATION.push(new CheckListAnswer());
    this.authorRegistrationAnswers = this.fb.array(this.checklistAnswers.AUTHOR_REGISTRATION.map((answer, index) => this.fb.group({
      id: [answer.id],
      order: [index + 1],
      status: [answer.status],
      submissionId: [answer.submissionId]
    })));
  }

  private initCopyrightAnswersForm() : void {
    this.checklistAnswers.COPYRIGHT.push(new CheckListAnswer());
    this.copyrightAnswers = this.fb.array(this.checklistAnswers.COPYRIGHT.map((answer, index) => this.fb.group({
      id: [answer.id],
      order: [index + 1],
      status: [answer.status],
      submissionId: [answer.submissionId]
    })));
  }

  private initCopyrightForm() : void {
    this.copyrightForm = this.fb.group({
      ieeeCopyrightEnable: [this.event.ieeeCopyrightEnable],
      pubTitle: [this.event.ieeePubTitle],
      artSource: [this.event.ieeeArtSource],
      partNumber: [this.event.ieeePartNumber],
      submissions: [this.listToText(this.checklistAnswers.COPYRIGHT.map(a => a.submissionId))]
    });

    this.initCopyrightAnswersForm();

    this.enabledIEEECF = this.event.ieeeCopyrightEnable;
    this.copyrightForm.get('ieeeCopyrightEnable').valueChanges.subscribe(ieeeCopyrightEnableForm => {
      this.enabledIEEECF = ieeeCopyrightEnableForm;
      this.changeDetectorRef.detectChanges();
    });
  }

  editChecklistField(order: number, mode: string = 'edit') {
    const checklistField =  this.customControlForm.controls[order].value;
    this.dialog.open(CustomControlFieldEditComponent, {
      height: '15rem',
      width: '35rem',
      panelClass: 'my-custom-dialog-class',
      disableClose: true,
      data: checklistField,
    }).afterClosed().subscribe(field => {
      if (field) {
        this.customControlForm.controls[order].setValue(field);
        this.submitCustomControlForm();
      }
      if (!field && mode === 'create') {
        this.deleteChecklistField(order);
      }
    });
  }

  listToText(submissionId: number[]): string {
    let text = '';
    submissionId.forEach(id => text += id + '\n');

    return text;
  }

  getSubmissionLabelStatus(status): string {
    return 'admin.event.publication.controls.form.submission-id-status.' + status.replace('_', '-');
  }

  getCustomControlField(field: AbstractControl, name: string): FormGroup {
    return field.get(name) as FormGroup;
  }

  submitConfigurationForm() {
    this.publicationService.editPublicationControls(this.event.id,
      {
        'authorRegistrationEnable': this.configurationForm.get('authorRegistrationEnable').value,
        'copyrightEnable': this.configurationForm.get('copyrightEnable').value
      }).subscribe((res) => {
        [this.event.authorRegistrationEnable, this.event.copyrightEnable] = Object.values(res.data);
      }, () => {}, () => {});
  }

  submitIeeeCopyrightControl() {
    const ieeeCopyrightEnable = this.copyrightForm.get('ieeeCopyrightEnable').value;
    const pubTitle = this.copyrightForm.get('pubTitle').value;
    const artSource = this.copyrightForm.get('artSource').value;
    const partNumber = this.copyrightForm.get('partNumber').value;

    this.publicationService.editIeeeCopyrightControl(this.event.id, {
      ieeeCopyrightEnable: ieeeCopyrightEnable,
      pubTitle: pubTitle,
      artSource: artSource,
      partNumber: partNumber
    }).subscribe();
  }

  submitFixedControlForm(submissionsId: string[], form: FormFieldCategory) {

    this.navbarService.loadingStart();

    if (form == FormFieldCategory.COPYRIGHT) {
      this.submitIeeeCopyrightControl();
    }

    this.publicationService.editSubmissionsAnswers(this.event.id, {
      category: form,
      submissionsId: submissionsId
    }).subscribe(checklistAnswers => {
      this.checklistAnswers[form] = checklistAnswers;
      form == FormFieldCategory.AUTHOR ? this.initAuthorRegistrationAnswersForm() : this.initCopyrightAnswersForm();
    }, () => {}, () => {
      this.navbarService.loadingStop();
    });
  }

  submitCustomControlForm() {
    this.saveChecklist().subscribe(checklistFields => {
      this.checklistFields = checklistFields[1].concat(checklistFields[0]);
      this.initCustomControlForm();
    });
  }

  private initCustomControlForm(): void {
    this.customControlForm = this.fb.array(this.checklistFields.map(field => this.fb.group({
      id: [field.id],
      description: [field.description, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      visibleTo: this.fb.group({
        author: [field.visibleTo.author],
        reviewer: [field.visibleTo.reviewer],
        sessionChair: [field.visibleTo.sessionChair],
        publicationChair: [field.visibleTo.publicationChair],
      }),
      modifiableBy: this.fb.group({
        author: [field.modifiableBy.author],
        reviewer: [field.modifiableBy.reviewer],
        sessionChair: [field.modifiableBy.sessionChair],
        publicationChair: [field.modifiableBy.publicationChair]
      })
    })
    ));
  }

  deleteChecklistField(order: number): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'admin.event.tracks.checklist.confirm-checklistfield-deletion',
        content: ''
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        const checklistId = this.customControlForm.controls[order].value.id;
        if (checklistId) {
          this.publicationService.deleteEventCheckList(checklistId, this.event.id).subscribe();
          this.removeChecklistFieldForm(order);
        } else {
          this.removeChecklistFieldForm(order);
        }
      }
    });
  }

  private removeChecklistFieldForm(order: number) {
    const fieldData = this.customControlForm.controls[order].value;
    this.customControlForm.removeAt(order);
  }

  addNewChecklistField(): void {
    const newControl = this.fb.group({
      id: [null],
      description: [this.translate.instant('admin.event.publication.controls.form.custom-control.default-field-title'), [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      visibleTo: this.fb.group({
        author: [false],
        reviewer: [false],
        sessionChair: [false],
        publicationChair: [false]
      }),
      modifiableBy: this.fb.group({
        author: [false],
        reviewer: [false],
        sessionChair: [false],
        publicationChair: [false]
      })
    });
    this.customControlForm.push(newControl);
    this.editChecklistField(this.customControlForm.controls.length - 1, 'create');
  }

  saveChecklist(): Observable<CheckListField[][]> {
    const fieldsToCreate = [];
    const fieldsToEdit = [];

    this.customControlForm.value.forEach((field, index) => {
      field.order = index;
      field.type = FormFieldType.CHECKBOX;
      field.category = FormFieldCategory.CUSTOM;

      if (field.id !== null) {
        fieldsToEdit.push(field);
      } else {
        fieldsToCreate.push(field);
      }
    });

    return forkJoin([
      this.publicationService.createEventCheckList(fieldsToCreate, this.event.id),                                // Create new checklist items.
      this.publicationService.editEventCheckList(fieldsToEdit, this.event.id)                                     // Edit existing items.
    ]);
  }

}
