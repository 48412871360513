import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { ReviewsService } from '../../../../service/reviews.service';
import { AdminService } from '../../../../service/admin.service';
import { EventsService } from '../../../../service/events.service';
import { ReviewsConfigurationService } from '../../../../service/reviews.configuration.service';
import { SubmissionsService } from '../../../../service/submissions.service';
import { UserService } from '../../../../service/user.service';
import { InfoService } from '../../../../service/info.service';
import { NotificationService } from '../../../../service/notification.service';

import { Event } from 'src/app/model/event';
import { Review, SubmissionStatus } from 'src/app/model/paper';
import { User } from 'src/app/model/user';
import { AssignReview } from 'src/app/model/assign.review';
import { Submission } from 'src/app/model/paper';
import { UserEvent } from 'src/app/model/user.event';
import { SelectOption } from 'src/app/model/select.option';
import { EventReviewConfiguration } from 'src/app/model/eventReviewConfiguration';

import { ReviewStatus } from 'src/app/enum/review.status';
import { Motives, Translations } from 'src/app/enum/assign.motives';

import { UserAssignInfo, SubmissionAssignSuggestions, WeightFunction, AssignmentTestFunction } from './strategy/types';
import { topicsMatchFunction, classicAssignmentSuggestion } from './strategy/algorithm';

import { forkJoin, from, Observable } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserEventAnswer } from 'src/app/enum/user.event.answer';
import { TimezoneService } from 'src/app/service/timezone.service';

const ALL = 'ALL';
const WITHOUT_TPC = 'WITHOUT_TPC';
const defaultSelectOption = new SelectOption(ALL, 'admin.event.reviews.assign-suggestion.all');

const defaultAllMembersSelectOption = new SelectOption(ALL, 'admin.event.reviews.assign-suggestion.all-members');

@Component({
  selector: 'app-assign-review-suggestion',
  templateUrl: './assign-review-suggestion.component.html',
  styleUrls: ['./assign-review-suggestion.component.scss']
})
export class AssignReviewSuggestionComponent implements OnInit {
  formSelectionFields: FormGroup;
  form: FormGroup;

  event: Event;
  reviewConfig: EventReviewConfiguration;

  stateColumns = ['id'];
  assignColumns = ['id', 'title', 'reviewer'];

  submissions: Array<Submission>;
  committees: Array<UserEvent>;

  matrix: Array<SubmissionAssignSuggestions>;
  assignmentStep = 0;
  link: { label: string; route: string; align: string; };

  MotivesPreviously = [ Motives.ASSIGNED_CLAIMED, Motives.ASSIGNED_INTEREST ];
  MotivesAssignSuggested = [ Motives.CLAIM, Motives.INTEREST ];
  MotivesSubmissionStatus = [ Motives.REQUESTED, Motives.AUTHOR, Motives.CONFLICT, Motives.DECLINED ];

  translations = Translations;
  motives = Motives;

  tracks: Array<SelectOption> = [];
  tpcGroups: Array<SelectOption> = [];
  paperGroups: Array<SelectOption> = [];

  constructor(
    private adminService: AdminService,
    public reviewsService: ReviewsService,
    public reviewConfigurationService: ReviewsConfigurationService,
    public submissionsService: SubmissionsService,
    public eventService: EventsService,
    public userService: UserService,
    public infoService: InfoService,
    public notificationService: NotificationService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private tzService: TimezoneService
  ) { }

  ngOnInit(): void {
    this.adminService.progressBar.start();
    setTimeout(() => {
      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.loadSelectionFieldsData();
        });
      } else {
        this.loadSelectionFieldsData();
      }
    });
  }

  loadSelectionFieldsData() {
    forkJoin([
      this.eventService.getEventPaperGroups(this.event.id),
      this.eventService.getEventTPCGroups(this.event.id),
    ]).subscribe( ([paperGroup, tpcGroups]) => {
      this.tracks = [defaultSelectOption, ...this.event.tracks.map(track => new SelectOption(track, track.name))];
      this.paperGroups = [new SelectOption(ALL, 'admin.event.reviews.assign-suggestion.all-submissions'),
                          ...paperGroup.map( group => new SelectOption(group, group.name))];

      this.tpcGroups = [defaultAllMembersSelectOption,
                        new SelectOption(WITHOUT_TPC, 'admin.event.reviews.assign-suggestion.without-tpc'),
                        ...tpcGroups.map(group => new SelectOption(group, group.name))];

      this.initFormSelectionFields();
      this.adminService.progressBar.stop();
    });
  }

  initFormSelectionFields() {
    this.formSelectionFields = this.fb.group({
      tpcGroup: [defaultSelectOption.id, []],
      track: [defaultSelectOption.id, []],
      paperGroup: [defaultSelectOption.id, []],
      positiveMatchWeight: [1, [Validators.required]],
      negativeMatchWeight: [1, [Validators.required]]
    })
  }

  setSelectFieldsOptions(params) {
    if (this.formSelectionFields.value.paperGroup !== defaultSelectOption.id) {
      params['paperGroup'] = this.formSelectionFields.value.paperGroup.id;
    }

    if (this.formSelectionFields.value.track !== defaultSelectOption.id) {
      params['track'] = this.formSelectionFields.value.track.id;
    }

    return params;
  }

  loadData() {
    this.adminService.progressBar.start();
    this.link = {
      label: 'forms.buttons.assign-review-date',
      route: `/admin/${this.event.id}/reviews/configuration`,
      align: 'end'
    };

    const params = this.setSelectFieldsOptions({ detailed: false, toAssign: true, status: SubmissionStatus.ACTIVE });

    let observableMembersOfTPCGroup: Observable<UserEvent[]>;
    if (this.formSelectionFields.value.tpcGroup === WITHOUT_TPC) {
      observableMembersOfTPCGroup = this.adminService.eventsService.getEventPeopleWithoutTPCGroup(this.event.id);
    } else {
      if (this.formSelectionFields.value.tpcGroup === defaultSelectOption.id) {
        observableMembersOfTPCGroup = this.adminService.eventsService.getEventCommittee(this.event.id, UserEventAnswer.ACCEPTED);
      } else {
        observableMembersOfTPCGroup = this.adminService.eventsService.getEventCommitteeByTPCGroup(this.event.id, UserEventAnswer.ACCEPTED, this.formSelectionFields.value.tpcGroup.id);
      }
    }

    forkJoin([
      this.reviewConfigurationService.getReviewsConfiguration(this.event.id),
      this.submissionsService.getSubmissionsByEvent(this.event.id, params),
      observableMembersOfTPCGroup
    ]).subscribe(([reviewConfig, submissions, committees]) => {
      this.reviewConfig = reviewConfig;
      this.submissions = submissions;
      this.committees = committees.map(c => <UserEvent>c).sort((a, b) => a.user.id - b.user.id);

      // remove reviews declined or deleted and reviews to external reviewers.
      this.submissions.forEach( sub => {
        sub.reviews = sub.reviews.filter(r =>
          ![ReviewStatus.DECLINED, ReviewStatus.DELETED].some(s => s === (<Review>r).status) &&
          this.committees.some(c => (<Review>r).userId === c.user.id)
        );
      });

      this.stateColumns.push(...this.committees.map(c => c.user.id.toString()));

      this.assignSuggestion();
      this.initForm();
      this.adminService.progressBar.stop();
    });
  }

  assignSuggestion() {
    const submissionMatrix = this.initMatrix(topicsMatchFunction);

    this.matrix = classicAssignmentSuggestion(submissionMatrix, this.committees.length, this.reviewConfig.numberOfReviewsRequired);
  }


  initMatrix(weightFunction: WeightFunction): Array<SubmissionAssignSuggestions> {
    const matrix = this.submissions.map(submission => {
      const submissionAssign = new SubmissionAssignSuggestions(submission);

      submissionAssign.possiblesAssign = this.committees.map(committee => {
        if (committee.reviewing === undefined) {
          committee.reviewing = [];
        }

        if (submission.reviews.some(r => (<Review>r).userId === committee.user.id)) {
          committee.reviewing.push(submission.id);
        }

        const userAssign = new UserAssignInfo(submission, committee);
        const positiveMatchWeight = this.formSelectionFields.controls['positiveMatchWeight'].value;
        const negativeMatchWeight = this.formSelectionFields.controls['negativeMatchWeight'].value;
        userAssign.weight = weightFunction(committee, submission, positiveMatchWeight, negativeMatchWeight);
        userAssign.submissionToAssign = submissionAssign;
        this.setEligibility(submission, userAssign);
        return userAssign;
      });

      return submissionAssign;
    });
    return matrix;
  }


  setEligibility(submission: Submission, user: UserAssignInfo): void {
    if (submission.isAuthor(user.userPreferences.user.id)) {
      user.elegible = false;
      user.motive = Motives.AUTHOR;
    } else if (submission.authors.some(author => user.userPreferences.hasConflict(author.user.id))) {
      user.elegible = false;
      user.motive = Motives.CONFLICT;
    } else {
      const review = <Review>submission.reviews.find(r => (<Review>r).userId === user.userPreferences.user.id);
      if (review) {
        if (review.status === ReviewStatus.DECLINED) {
          user.elegible = false;
          user.motive = Motives.DECLINED;
        } else if (submission.hasClaim(user.userPreferences.user.id)) {
          user.elegible = false;
          user.motive = Motives.ASSIGNED_CLAIMED;
        } else {
          user.elegible = false;
          user.motive = Motives.ASSIGNED_INTEREST;
        }
      }
    }
  }

  initForm(): void {
    const form = this.fb.group({
      dueAt: [this.reviewConfig.conferenceDateDue?.toDate(), [Validators.required]],
      timeZone: [this.tzService.browserTimeZone]
    });

    this.matrix.forEach(submission => {
      const fbGroup = this.fb.group({});

      submission.suggestedAssign.forEach((userAssign, index) => {
        fbGroup.addControl(index.toString(), new FormControl(userAssign.userPreferences.user.id, []));
        submission.selectControls[index.toString()] = userAssign;
      });

      const actualReviews = submission.suggestedAssign.length + submission.submission.reviews.length;
      for (let index = actualReviews; index < this.reviewConfig.numberOfReviewsRequired; index++) {
        fbGroup.addControl(index.toString(), new FormControl('', []));
      }

      form.addControl(submission.submission.id.toString(), fbGroup);
    });

    this.form = form;
  }

  getReviewersSuggested(submission: SubmissionAssignSuggestions) {
    const selectables = submission.submission.reviews.map(review => {
      if (this.isReview(review)) {
        // User already assigned
        return {
          assigned: true,
          user: <UserAssignInfo>{userPreferences: { user: typeof review !== 'number' ? review.user : undefined } }
        };
      } else {
        // Number of id of user suggested
        return {
          assigned: false,
          user: <UserAssignInfo>submission.possiblesAssign.find(c => c.userPreferences.user.id === review)
        };
      }
    });
    selectables.sort((a, b) => b.user.weight - a.user.weight);
    return selectables;
  }

  getSelectOptions(submission: SubmissionAssignSuggestions): Array<SelectOption> {
    if (submission.options === undefined) {
      const candidates = submission.possiblesAssign.slice()
        .sort((a, b) => b.weight - a.weight)
        .map(u => {
          const placeholder = `[${u.weight}] ${u.userPreferences.user.name} (${u.userPreferences.user.profile.affiliation.acronym})`;
          return new SelectOption(u.userPreferences.user.id, placeholder);
        });
      const options = [new SelectOption(undefined, `do not assign`), ...candidates];
      submission.options = options;
    }
    return submission.options;
  }

  isReview(review: Review | number): boolean {
    return !!review && typeof review !== 'number';
  }

  isCommittee(user: User | number): boolean {
    const userId = user instanceof User ? user.id : user;
    return this.committees.some(c => c.user.id === userId);
  }

  getWeightAssignedReview(submissionSuggestion: SubmissionAssignSuggestions, review: Review): UserAssignInfo {
    if (!review) {
      return undefined;
    } else {
      return submissionSuggestion.possiblesAssign.find(c => c.userPreferences.user.id === review.userId);
    }
  }

  getCommitteeSuggestedInfo(submissionSuggestion: SubmissionAssignSuggestions, assignControl: string): UserAssignInfo {
    const formGroup = this.form.get(submissionSuggestion.submission.id.toString());
    const selectControl = formGroup.get(assignControl);
    const committeeUserId = selectControl.value;

    return submissionSuggestion.possiblesAssign.find(c => c.userPreferences.user.id === committeeUserId);
  }

  getAssignControls(submission: SubmissionAssignSuggestions): Array<string> {
    const form = <FormGroup>this.form.get(submission.submission.id.toString());
    return Object.keys(form.controls);
  }

  updateSelectControl(submissionSuggestion: SubmissionAssignSuggestions, controlName, committeeUserId) {
    const userAssignInfo = submissionSuggestion.possiblesAssign.find( userAssign => userAssign.userPreferences.user.id === committeeUserId);
    submissionSuggestion.selectControls[controlName] = userAssignInfo;
  }

  setAssignReview(dueAt: Date) {
    const assignReviews = [];
    this.submissions.forEach(submission => {
      const submissionControl = this.form.get(submission.id.toString());

      Object.keys(submissionControl.value)
        .filter(key => submissionControl.value[key])
        .forEach(selectKey => {
          const review = new AssignReview();
          review.assignedBy = this.infoService.user.id;
          review.submission = submission.id;
          review.user = submissionControl.value[selectKey];
          review.dueAt = dueAt;
          assignReviews.push(review);
      });
    });

    forkJoin(assignReviews.map(newReview => this.reviewsService.createReview(newReview))).subscribe(reviews => {
      this.notificationService.notify('admin.event.reviews.assign-suggestion.reviews-assigned', { params: { number: reviews.length } });
    });
  }

  submit() {
    const dueAt = this.form.get('dueAt').value;

    this.checkValidReview(dueAt, () => {
      this.setAssignReview(dueAt);
    });
  }

  checkValidReview(dueAt: Date, callback: () => void) {
    const dueAtPassed = dueAt < this.today();

    if (dueAtPassed) {
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'reviews.invalid-date-set',
          content: ''
        }
      }).afterClosed().subscribe(confirmed => {
        if (confirmed) {
          callback();
        }
      });
    } else {
      callback();
    }
  }

  today(): Date {
    return new Date();
  }
}
