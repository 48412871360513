import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextInputComponent } from '../text-input/text-input.component';

@Component({
  selector: 'app-editable-input',
  templateUrl: './editable-input.component.html',
  styleUrls: ['./editable-input.component.scss'],
})
export class EditableInputComponent extends TextInputComponent {
  @Input() string_input: string;
  @Input() placeholder: string;
  @Input() modification_function: Function = ( x => x);
  @Input() delete_function: Function = ( x => x);
  public edition: boolean;

  constructor() {
    super();
    this.edition = false;

    if (!this.placeholder) {
      this.placeholder = this.string_input;
    }
  }
}
