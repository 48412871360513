import { Component } from '@angular/core';

@Component({
  selector: 'app-submission-ranking',
  templateUrl: './submission-ranking.component.html',
  styleUrls: ['./submission-ranking.component.scss']
})
export class SubmissionRankingComponent {

  constructor() { }

}
