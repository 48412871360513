import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Submission } from 'src/app/model/paper';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { style, animate, transition, state, trigger } from '@angular/animations';

@Component({
  selector: 'app-submissions-table',
  templateUrl: './submissions-table.component.html',
  styleUrls: ['./submissions-table.component.scss'],
  animations: [
    trigger('slideIn', [
      state('*', style({ 'overflow-y': 'hidden', height: '*', opacity: 1 })),
      state('void', style({ 'overflow-y': 'hidden', height: 0, opacity: 0 })),
      transition('* => void', [animate('500ms 0.2ms ease-out')]),
      transition('void => *', [animate('300ms 0.2ms ease-out')])
    ])
  ]
})
export class SubmissionsTableComponent implements OnInit {
  @Input() submissions: Submission[];
  @Input() displayedColumns: string[] = ['id', 'status', 'title', 'conference', 'favorite'];
  dataSource: MatTableDataSource<Submission>;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.submissions);
    this.dataSource.sort = this.sort;
  }

  selectedRow(event, submission): void {
    const { id } = submission;

    const target = event.target.localName;
    if (target === 'div' || target === 'mat-card' || target === 'td') {
      if (event.ctrlKey) {
        window.open(`${this.router.url}/${id}`);
      } else {
        this.router.navigate([id], { relativeTo: this.route });
      }
    }
  }

  favorite(submission: Submission): void {
    submission.favorite = !submission.favorite;
  }
}
