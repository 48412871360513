import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Event } from 'src/app/model/event';
import { SelectOption } from 'src/app/model/select.option';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { StatisticsService } from 'src/app/service/statistics.service';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { topicsMatchFunction } from 'src/app/admin/event-admin/event-reviews/assign-review-suggestion/strategy/algorithm';


@Component({
  selector: 'app-submission-topic-statistics',
  templateUrl: './submission-topic-statistics.component.html',
  styleUrls: ['./submission-topic-statistics.component.scss']
})
export class SubmissionTopicStatisticsComponent implements OnInit, OnDestroy {
  @Input() event: Event;
  
  
  form: FormGroup;
  formTPCGroup: FormGroup;
  subscriptions: Subscription[] = [];
  
  haveTopics: boolean = false;
  
  filterControl = new FormControl('');
  tracks: Array<SelectOption>;
  tpcGroups: Array<SelectOption>;
  
  topicStatus : Array<any>;
  topicInterest : Array<any>;
  sortedData : Array<any>;

  tableColsStatus = ['topic', 'pending', 'active', 'withdrawn', 'accepted', 'rejected', 'total', "total-valid"]
  tableColsInterest = ['topic', 'interested', 'neutral', 'not-interested']
  
  // The number of items before filtering
  originalTopicStatusSize: Number =0;


  constructor(
    private statisticsService: StatisticsService,
    protected adminService: AdminService,
    private eventsService: EventsService,
    public fb: FormBuilder
  ) { 
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.adminService.progressBar.start();
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
        }, () => {
        }, () => {
          this.initComponent();
          this.getTopicsBySubmissionStatus({}, true);
          this.getTopicsByTPCInterests({}, true);
        });
      } else {
        this.initComponent();
        this.getTopicsBySubmissionStatus({}, true);
        this.getTopicsByTPCInterests({}, true);
        
      }

      this.setFilters();
    });
  }
  sortData(sort: Sort) {
    const data = this.topicStatus.slice();
    let isAsc : boolean;
    let activeColumn : string;
    if(!sort){
      // Default sorting is by column 'total' desc
      isAsc = false
      activeColumn = 'total'
    }else if (!sort.active || sort.direction === '') {
      // Default sorting is by column 'total' desc
      isAsc = false
      activeColumn = 'total'
    }else{
      isAsc = sort.direction === 'asc';
      activeColumn = sort.active
    }
    this.statisticsService.setActiveOrderStatusTopicSubject(activeColumn)
    this.sortedData = data.sort((a, b) => {
      switch (activeColumn) {
        case 'pending':
          return compare(a.pending, b.pending, isAsc);
        case 'active':
          return compare(a.active, b.active, isAsc);
        case 'withdrawn':
          return compare(a.withdrawn, b.withdrawn, isAsc);
        case 'accepted':
          return compare(a.accepted, b.accepted, isAsc);
        case 'rejected':
          return compare(a.rejected, b.rejected, isAsc);
        case 'total':
          return compare(a.total, b.total, isAsc);
        case 'total-valid':
          return compare(a.valid, b.valid, isAsc);
        default:
          return 0;
      }
    });
  }

  initComponent(){
    if (this.event.topics.length > 0) {
      this.haveTopics = true;
    } else {
      this.haveTopics = false;
    }

    this.form = this.fb.group({
      tracks: []
    });

    this.formTPCGroup = this.fb.group({
      tpcGroups: []
    });

    this.tracks = this.event.tracks.map(t => {
      return new SelectOption(t.id, t.name);
    });

    this.adminService.eventsService.getEventTPCGroups(this.event.id).subscribe(tpcGroups => {
      this.tpcGroups = tpcGroups.map(g => 
        new SelectOption(g.id, g.name)
      );

      if (this.tpcGroups.length === 0) {
        this.formTPCGroup.controls['tpcGroups'].disable();
      }
    });
  }

  setFilters() {
    this.subscriptions.push(
      this.form.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe( value => {
          this.adminService.progressBar.start();
          const params = {}
          if (value.tracks && value.tracks.length > 0) {
            params['tracks'] = value.tracks
          }
          this.getTopicsBySubmissionStatus(params, false);
        }
      )
    );

    this.subscriptions.push(
      this.formTPCGroup.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe( value => {
          this.adminService.progressBar.start();
          const params = {}
          if (value.tpcGroups && value.tpcGroups.length > 0) {
            params['tpc_groups'] = value.tpcGroups
          }
          this.getTopicsByTPCInterests(params, false);
        }
      )
    );
  }

  getTopicsBySubmissionStatus(params, init: boolean){
    this.statisticsService.getTopicsBySubmissionStatus(params, this.event.id).subscribe( datasource => {
      this.topicStatus = datasource.items;
      this.sortedData = this.topicStatus.slice();
      // Update cache
      this.statisticsService.setTopicsStatus(this.sortedData)
    }, () => {
      this.adminService.progressBar.stop();
    },() => {
      if (init) {
        if (this.topicStatus.length > 0 ){
          this.originalTopicStatusSize = this.topicStatus.length
        }
      }
      this.adminService.progressBar.stop();
    });
  }
  
  getTopicsByTPCInterests(params, init: boolean){
    this.statisticsService.getTopicsByTPCInterests(params, this.event.id).subscribe( datasource => {
      this.topicInterest = datasource.items;
    }, () => {
      this.adminService.progressBar.stop();
    },() => {
      this.adminService.progressBar.stop();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
