import { Component, OnInit } from '@angular/core';
import { EventsService } from 'src/app/service/events.service';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { AdminService } from 'src/app/service/admin.service';
import { Event } from 'src/app/model/event';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/service/notification.service';
import { NO_PAGINATION } from 'src/app/model/pagination.options';
import { Form } from '../../../../model/form';
import { FormType } from '../../../../enum/form.type';
import { SelectOption } from '../../../../model/select.option';
import { EventTitlePipe } from '../../../../pipe/event.title.pipe';
import { FormFieldType } from '../../../../enum/form.field.type';
import { MatDialog } from '@angular/material/dialog';
import { ReviewConfirmationDialogComponent } from 'src/app/component/review-confirmation-dialog/review-confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { FormField } from 'src/app/model/form.field';
import { ReviewsService } from 'src/app/service/reviews.service';
import { FormFieldChoice } from 'src/app/model/form.field.choice';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-event-forms',
  templateUrl: './event-forms.component.html',
  styleUrls: ['./event-forms.component.scss']
})
export class EventFormsComponent implements OnInit {
  public event: Event;
  public formForm: FormGroup;
  public formType: FormType;
  public form: Form;
  public events: Array<SelectOption>;
  public eventForm: FormGroup;
  public formId = null;
  public importForm = false;
  public answeredReview: number;
  public blockEdition : boolean = false;

  constructor(
    private adminService: AdminService,
    private eventService: EventsService,
    private reviewService: ReviewsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public notificationService: NotificationService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    this.onChanges();

    this.eventForm = this.fb.group({
      name: [],
    });
  }

  onChanges(): void {
    setTimeout(() => {
      this.event = this.adminService.selectedEvent;

      this.route.params.subscribe(params => {
        this.formType = params.type.toUpperCase();
        if (this.formType !== FormType.SUBMISSION && this.formType !== FormType.REVIEW) {
          this.router.navigate(['/404']);
        }
        if (!this.event) {
          this.adminService.getEvent().subscribe(event => {
            this.event = event;
            this.getEvents();
            this.getForm(this.event.id);
          });
        } else {
          this.getEvents();
          this.getForm(this.event.id);
        }
      });
    });
  }

  private getForm(eventId: number): void {
    this.eventService.getForm(this.formType, eventId).subscribe(form => {
      if(form) {
        form.fields.sort((a,b) => { return a.order - b.order });
        form.fields.map(field => { field.choices?.sort((a,b) => { return a.order - b.order }) });
        if (this.formType == FormType.REVIEW && !this.importForm) {
          this.reviewService.getNumberAnsweredReview(eventId).subscribe( count => {
            this.answeredReview = count;
            if (this.answeredReview > 0) {
              this.blockEdition = true;
            } else {
              this.blockEdition = false;
            }
          });
        }
        this.form = form;
        if (this.importForm) {
          this.deleteIdFromForm();
          this.deleteAnswers();

          if(this.formId) {
            this.form.id = this.formId;
          }
        } else {
          this.formId = this.form.id;
        }
      } else {
        this.createNewForm();
      }
    }, error => {
      this.createNewForm();
    }).add(() => this.initFormForm());
  }

  private createNewForm(): void{
    if (this.formType === FormType.SUBMISSION) {
      this.form = new Form('submission form', this.formType);
    } else {
      this.form = new Form('review form', this.formType);
    }
    this.form.fields = [];
  }

  private initFormForm(): void {
    this.formForm = this.fb.group({
      name: [this.form.name],
      type: [this.form.type],
      fields: this.fb.array(this.form.fields.map(field => this.fb.group({
        id: [field.id],
        description: [field.description, [Validators.required, Validators.minLength(1), Validators.maxLength(500)]],
        shortLabel: [field.shortLabel, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
        placeholder: [field.placeholder, [Validators.minLength(1), Validators.maxLength(100)]],
        type: [field.type, [Validators.required]],
        min: [field.min, [Validators.max(7000)]],
        max: [field.max, [Validators.max(7000)]],
        required: [field.required],
        hidden: [field.hidden],
        order: [field.order],
        weight: [field.weight, [Validators.required, Validators.min(0), Validators.max(1)]],
        globalWeight: [field.globalWeight, [Validators.required, Validators.min(0), Validators.max(1)]],
        choices: this.fb.array(field.choices.map(choice => this.fb.group({
          id:[choice.id],
          description: [choice.description, [Validators.required, Validators.minLength(1), Validators.maxLength(500)]],
          order: [choice.order],
          value: [choice.value, [Validators.required, Validators.min(0)]]
        })))
      })))
    });

    this.formForm.markAllAsTouched();
    this.blockControlEdition();
  }

  blockControlEdition(): void {
    let objects = this.formForm.get('fields') as FormArray;
    let controls = objects.controls;
    if (this.formType == FormType.REVIEW) {
      this.reviewService.getNumberAnsweredReview(this.event.id).subscribe( count => {
        if (count > 0) {
          controls.map( control => {
            control.get('type').disable();
          });
        }
      });
    }
  }

  addNewQuestion(): void {
    const control = <FormArray>this.formForm.get('fields');
    const newControl = this.fb.group({
      description: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(500)]],
      shortLabel: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      placeholder: [null, [Validators.minLength(1), Validators.maxLength(100)]],
      type: ['', [Validators.required]],
      min: [0, [Validators.max(7000)]],
      max: [7000, [Validators.max(7000)]],
      required: [false],
      hidden: [false],
      weight: [0, [Validators.required]],
      order: [0],
      globalWeight: [0, [Validators.required]],
      choices: this.fb.array([this.fb.group({
        description: ['Choice 1', [Validators.required, Validators.minLength(1), Validators.maxLength(500)]],
        order: [0],
        value: [0, [Validators.required, Validators.min(0)]]
      })])
    });
    control.push(newControl);
  }

  choiceOrder(field): void {
    field['choices'].forEach((choice, i) => {
      choice.order = i;
    });
  }

  organizingDataToSave(): void {
    this.formForm.value.fields.forEach((field, i) => {
      field.order = i;

      if (field.type === FormFieldType.TEXT) {
        delete field['choices'];
      } else {
        this.choiceOrder(field);
        field.min = 0;
        field.max = 7000;
        field.placeholder = null;
      }
    });
  }

  deleteIdFromForm(){
    this.form.id = null;
    this.form.fields.forEach((field: FormField) => {
      field.id = null;
      if (field.choices) {
        field.choices.forEach((choice: FormFieldChoice) => {
          choice.id = null;
        });
      }
    });
  }

  deleteAnswers() {
    this.form.fields.forEach((field: FormField) => {
      field.answers = [];
    });
  }

  checkSaveForm() {
    this.dialog.open(ReviewConfirmationDialogComponent, {
      data: {
        title: 'admin.event.people.tpc.edit-review-form.confirm.title',
        content: 'admin.event.people.tpc.edit-review-form.confirm.content'
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        if (this.form.fields.some(field => field.answers.length > 0)) {
          this.translateService.get('admin.event.settings.forms.check-edit-form').subscribe(message => {
            if (confirm(message)) {
              this.saveForm();
            }
          });
        } else {
          this.saveForm();
        }
      }
    });
  }

  saveForm(): void {
    this.organizingDataToSave();
    this.form.id = this.formId;

    const formValue = this.formForm.value;
    let fields = this.formForm.get('fields') as FormArray;
    let controls = fields.controls;

    formValue.fields.forEach((field: FormField, index) => {
      field.type = controls[index].get('type').value;

      if (field.type === FormFieldType.TEXT) {
        field.weight = 0;
        field.globalWeight = 0;
      }
    });

    const validForm = this.checkValidFormWeight(formValue.fields);
    if (!validForm.weight || !validForm.globalWeight) {
      this.notificationService.notifyError('admin.event.settings.forms.form-invalid-weight', {
        params: {
          weight: validForm.sum.weight,
          globalWeight: validForm.sum.globalWeight
        }
      });
      return;
    } else if (this.form.id) {
      this.eventService.editForm(formValue, this.form.id).subscribe(form => {
        this.form = form;
        this.notificationService.notify('admin.event.settings.forms.form-valid');
        if (this.formType === FormType.SUBMISSION) {
          this.router.navigate(['/admin/' + this.event.id + '/submissions/submission/form']);
        } else {
          this.router.navigate(['/admin/' + this.event.id + '/submissions/review/form']);
        }
      });
    } else {
      this.eventService.createForm(formValue).subscribe(form => {
        this.form = form;
        if (this.formType === FormType.SUBMISSION) {
          this.eventService.editEvent(this.event.id, { 'formSubmission': this.form.id }).subscribe(event => {
            this.event = event;
          });
        } else {
          this.eventService.editEvent(this.event.id, { 'formReview': this.form.id }).subscribe(event => {
            this.event = event;
          });
        }
        this.notificationService.notify('admin.event.settings.forms.form-valid');
        if (this.formType === FormType.SUBMISSION) {
          this.router.navigate(['/admin/' + this.event.id + '/submissions/submission/form']);
        } else {
          this.router.navigate(['/admin/' + this.event.id + '/reviews/review/form']);
        }
      });
    }
  }

  hasForm(event): boolean {
    if (this.formType === FormType.SUBMISSION) {
      return event.formSubmission;
    } else {
      return event.formReview;
    }
  }

  private getEvents(): void {
    const paginationOptions = NO_PAGINATION;
    paginationOptions.show = "all";
    this.eventService.getAllEventsWithMinimumDetails(paginationOptions).subscribe(events => {
      this.events = events
        .filter(event => (event['id'] !== this.event.id) && this.hasForm(event))
        .map(event =>
          new SelectOption(event, EventTitlePipe.prototype.transform(event))
        );
    });
  }

  getEventForm(): void {
    this.importForm = true;
    this.getForm(this.eventForm.controls.name.value.id);
  }

  checkValidFormWeight(fields: FormField[]): { weight: boolean, globalWeight: boolean, sum: { weight: number, globalWeight: number } } {
    const sumWeight = fields.reduce((prev, field) => prev + field.weight, 0);
    const sumGlobalWeight = fields.reduce((prev, field) => prev + field.globalWeight, 0);

    return {
      weight: sumWeight === 1 || sumWeight === 0,
      globalWeight: sumGlobalWeight === 1 || sumGlobalWeight === 0,
      sum: {
        weight: sumWeight,
        globalWeight: sumGlobalWeight
      }
    };
  }

  public disableSaveButton() {
    let status = false;

    if (this.formForm.status == "INVALID" || this.formForm.value.fields.length == 0) {
      status = true;
    }

    return status;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.formForm.value.fields, event.previousIndex, event.currentIndex);
    moveItemInArray(this.formForm.get('fields')['controls'], event.previousIndex, event.currentIndex);
  }
}
