import { Component, OnInit, Input } from '@angular/core';
import { Event } from 'src/app/model/event';
import { AuthService } from 'src/app/service/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-event-back-button',
  templateUrl: './event-back-button.component.html',
  styleUrls: ['./event-back-button.component.scss']
})
export class EventBackButtonComponent implements OnInit {
  @Input() eventId: number;
  public eventLink: string;

  constructor(public authService: AuthService, public route: ActivatedRoute) { }

  ngOnInit(): void {
    const isEventChairView = this.route.snapshot.url[0].path === 'admin';
    const isUserInEventView = this.route.snapshot.url[0].path === 'events';

    const isConflictOfInterestView = this.route.snapshot.url[0].path === 'conflictInterest';

    if (!this.eventId && this.route.snapshot.url.length >= 2) {
      this.eventId = Number(this.route.snapshot.url[1].path);
    }

    if (!this.eventId) { // Treat special case where event is not defined in route, but as query parameter.
      this.eventId = this.route.snapshot.queryParams['eventId'];
    }

    if (this.eventId) {
      if (isEventChairView) {
        this.eventLink = `/admin/${this.eventId}/`;
      } else if (isUserInEventView || isConflictOfInterestView) {
        this.eventLink = `/events/${this.eventId}/`;
      }
    }

    // If not in those views, does not show back to event button.
  }
}
