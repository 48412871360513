import { SelectOption } from '../model/select.option';

export enum UserEventRole {
  CHAIR = 'chair',
  COMMITTEE = 'committee',
  PUBLICATION_CHAIR = 'publication_chair',
  REVIEWER = 'reviewer',
  SESSION_CHAIR = 'session_chair'
}

export namespace UserEventRole {
  export function toIterable(): Array<string> {
    return Object.keys(UserEventRole).filter(key => typeof UserEventRole[key] !== 'function');
  }

  export function toSelectable(): Array<SelectOption> {
    return UserEventRole.toIterable().map((value, index) => ({
      id: value,
      value: UserEventRole[value],
      i18n: 'profile.update.modes.' + value.toLowerCase()
    }));
  }
}
