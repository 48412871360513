import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Session } from 'src/app/model/session';
import { User } from 'src/app/model/user';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { NotificationService } from 'src/app/service/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TimezoneService } from 'src/app/service/timezone.service';

export interface DialogData {
  params?: {
    action?: any;
    content?: any;
    session?: any;   
    saveSession?: any;
  };
  cancel: string;
  confirm: string;
}

@Component({
  selector: 'app-form-session-dialog',
  templateUrl: './form-session-dialog.component.html',
  styleUrls: ['./form-session-dialog.component.scss']
})
export class FormSessionDialogComponent implements OnInit {
  dialogMessages: DialogData = {
    cancel: 'forms.buttons.cancel',
    confirm: 'forms.buttons.confirm',
    params: {}
  };

  session: Session; 
  form: FormGroup;

  searchFoundUsers: User;

    constructor(
      private adminService: AdminService,
      private fb: FormBuilder,
      public notificationService: NotificationService,
      private submissionsService: SubmissionsService,
      private eventService: EventsService,
      public dialogRef: MatDialogRef<FormSessionDialogComponent>,
      private tzService: TimezoneService,
      @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
      if (data) {
        Object.assign(this.dialogMessages, data);
      }
    }

    ngOnInit(): void {
      setTimeout(() => {
        this.session = this.dialogMessages.params.session;        
        this.initForm(); 
      });
    }

    hexToRgb(hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
      });
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    }

    private initForm(): void {     
      const rgb = this.hexToRgb(this.session.backgroundColor ? this.session.backgroundColor : '#BFE7FF');
      this.form = this.fb.group({
        id: [this.session.id, []],
        event: [this.session.event, [Validators.required]],
        title: [this.session.title, [Validators.maxLength(100)]],
        subtitle: [this.session.subtitle, [Validators.maxLength(100)]],
        url: [this.session.url, [Validators.maxLength(100)]],
        room: [this.session.room, [Validators.maxLength(100)]],
        start: [this.session.start, [Validators.required]],
        end: [this.session.end, [Validators.required]],
        chair: [this.session.chair ? this.session.chair: null, []],
        searchChair: [[], []],        
        backgroundColor: [this.session.backgroundColor ? this.session.backgroundColor : '#BFE7FF', []]            
      });
    }

    setUserAsChair(user: User): void {
      const form = this.form.value;
      form.chair = user;
      this.form.reset();
      this.form.setValue( form );
    }   

    save() {      
      if(this.form.value.chair instanceof Number){
        this.form.patchValue({
          backgroundColor : '#' + this.form.value.backgroundColor,
        });           
      }
      if(this.form.value.chair instanceof User){
        this.form.patchValue({
          chair : this.form.value.chair,        
        });
      }      
      this.dialogMessages.params.saveSession(this.form.value);
    }

}
