import { IChartistData } from 'chartist';
import { Type, Transform } from 'class-transformer';
import { ChartType } from 'ng-chartist';
import { SelectOption } from './select.option';

export enum DashboardDatasetSize {
  SMALL = 'is-4',
  MEDIUM = 'is-6',
  BIG = 'is-12'
}

export namespace DashboardDatasetSize {
  export function toIterable(): Array<string> {
    return Object.keys(DashboardDatasetSize).filter(key => typeof DashboardDatasetSize[key] !== 'function');
  }

  export function toSelectable(): Array<SelectOption> {
    return DashboardDatasetSize.toIterable().map(value => ({
      id: value,
      value: DashboardDatasetSize[value]
    }));
  }

  export function valueToKey(value: string): string {
    return this.toIterable().find(key => DashboardDatasetSize[key] === value);
  }
}

export class DashboardDataset {
  id: number;
  category: string;
  datasetName: string;
  type: ChartType;

  options: Array<DatasetOption>;
}

export class DatasetOption {
  name: string;
  settings: any;
  dataset: Array<number>;
}

export class DashboardGraph {
  id: number;
  order: number;

  dashboard: number;

  @Type(() => DashboardDataset)
  dataset: DashboardDataset | number;

  @Transform(value => DashboardDatasetSize[value])
  size: DashboardDatasetSize | string;

  title: string;
}

export class Dashboard {
  id: number;
  title: string;
  order: number;
  principal: boolean;

  event?: number;
  user?: number;

  @Type(() => DashboardGraph)
  graphPosition: DashboardGraph[];
}
