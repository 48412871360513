import { Component, ViewChild, ElementRef, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { User } from '../../model/user';
import { Observable } from 'rxjs';
import { SystemService } from '../../service/system.service';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UserService } from 'src/app/service/user.service';
import { InfoService } from 'src/app/service/info.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UserEventRole } from 'src/app/enum/user.event.role';

export class UserEventLimit {
  event: number;
  role: UserEventRole;
  exclude_role: boolean;
}

@Component({
  selector: 'app-user-search-conflicts',
  templateUrl: './user-search-conflicts.component.html',
  styleUrls: ['./user-search-conflicts.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(200)]),
      transition(':leave', animate(200, style({ opacity: 0 })))
    ])
  ]
})
export class UserSearchConflictsComponent implements OnInit {
  @Input() formGroupController: FormGroup;
  @Input() name: string;
  @Input() includeLoggedUser = false;
  @Input() singleUserSearch: string | boolean = false;
  @Input() includeUsers: User[];

  @Input() forbiddenUsers: User[] = [];

  @Input() warningUsers: User[] = [];
  @Input() warningLabel: string;
  @Input() compactView = false;

  stateCtrl = new FormControl('', Validators.required);
  filteredUsers: Observable<User[]>;
  @Output() searchFoundUsers = new EventEmitter<{ found: boolean, value: boolean }>();
  @Output() addedUser = new EventEmitter<User>();
  @Output() removedUser = new EventEmitter<{ user: User, selected: boolean }>();
  selectedOption: MatOption;
  foundUsers: Boolean = false;
  startedSearching: Boolean = false;

  @ViewChildren('userNativeInput') input: QueryList<ElementRef>;

  @Input() userEventLimit: UserEventLimit;

  userController: AbstractControl;

  @Input() keepNativeInput = true;

  constructor(private userService: UserService, public infoService: InfoService) { }

  ngOnInit(): void {
    this.foundUsers = false;
    this.startedSearching = false;
    setTimeout(() => {
      this.userController = this.formGroupController.get(this.name);
      this.userController.setValue([]);

      if (this.userController.value?.length === 0) {
        // User control needs values to initialize.
        const users = [];

        if (this.includeLoggedUser) {
          users.push(this.infoService.user);
        }

        if (this.includeUsers) {
          users.push(...this.includeUsers);
        }

        this.userController.setValue(users);
      }
      this.stateCtrl = new FormControl('');

      // Get users according to input
      this.stateCtrl.valueChanges
        .pipe(
          debounceTime(700),
          distinctUntilChanged()
        ).subscribe(value => {
          if (value.length > 0) {
            this.foundUsers = false;
            this.startedSearching = true;
            this.filteredUsers = this.userService.searchConflicts(value, this.userEventLimit);

            this.filteredUsers.subscribe(users => {
              this.searchFoundUsers.emit({ found: users.length > 0, value });
            },() => {},() => {
              this.foundUsers = true;
            });
          } else {
            this.filteredUsers = undefined;
            this.searchFoundUsers.emit({ found: false, value: undefined });
          }
        });
    });
  }

  displayFn(user?: User): string | undefined {
    return user.name; // Show nothing on input
  }

  addUser(user: User, { option }: MatAutocompleteSelectedEvent) {
    let users = [];

    if (this.singleUserSearch) {
      users = [user];
      this.selectedOption = option;
      this.clearNativeInput();
    } else {
      users = this.userController.value;
      users.push(user);
      this.clearNativeInput();
    }

    this.addedUser.emit( user );
    this.userController.setValue(users);
    option.deselect();
  }

  removeUser(user: User) {
    const conflict = { user: user, selected: true };
    this.removedUser.emit( conflict );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.userController.value, event.previousIndex, event.currentIndex);
  }

  clearNativeInput() {
    if (this.keepNativeInput) {
      this.input.first.nativeElement.value = null;
    }
  }

  disabledUser(user: User): boolean {
    return !!this.warningUsers.find(u => u.id === user.id);
  }
}
