import { Pipe, PipeTransform } from '@angular/core';
import { Submission } from '../model/paper';
import { ZerofillPipe } from './zerofill.pipe';

@Pipe({
  name: 'paperTitlePipe'
})
export class PaperTitlePipe implements PipeTransform {
  transform(submission: Submission): string {
    return `${ZerofillPipe.prototype.transform(submission.id)} - ${submission.title}`;
  }
}
