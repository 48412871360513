import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-show-controls-errors',
  templateUrl: './show-controls-errors.component.html',
  styleUrls: ['./show-controls-errors.component.scss'],
})
export class ShowControlsErrorsComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;

  errors: Array<{ control: string, errors: ValidationErrors }> = [];

  ngOnChanges(): void {
    this.ngOnInit();
  }

  ngOnInit() {
    this.errors = this.listFormGroupErrors(this.form);

    this.form.valueChanges.subscribe( e => {
      this.errors = this.listFormGroupErrors(this.form);
    })
  }

  listFormGroupErrors(form: FormGroup, controlParent = ''): Array<{ control: string, errors: ValidationErrors }> {
    const errors: Array<{ control: string, errors: ValidationErrors }> = [];

    for (const key in form.controls) {
      if (form.controls[key]) {
        const control = <FormGroup>form.controls[key];
        const controlName = controlParent.length > 0 ? `${controlParent}.${key}` : key;

        if (control.errors) {
          errors.push({ control: controlName, errors: control.errors });
        }

        if (control.controls) {
          errors.push(...this.listFormGroupErrors(control, controlName));
        }
      }
    }

    return errors;
  }

  errorValue(value: any): string {
    if (value instanceof Object) {
      const values = [];

      for (const atrib in value) {
        if (value.hasOwnProperty(atrib)) {
          values.push(`${atrib}: ${value[atrib]}`);
        }
      }
      return values.join(', ');
    }
    return value;
  }
}
