import { Type } from 'class-transformer';
import { BaseModel } from './baseModel';
import { Submission } from './paper';
import { UserEvent } from './user.event';

export class TPCGroup extends BaseModel {
  public name: string;

  @Type(() => UserEvent)
  public members: Array<UserEvent>;

  @Type(() => Submission)
  public submissions: Array<Submission>;
}
