import { Component } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { EventsService } from '../../../service/events.service';
import { NotificationService } from 'src/app/service/notification.service';
import { Router } from '@angular/router';

export enum EVENT_TYPE {
  CONFERENCE = 1,
  JOURNAL = 2
}


@Component({
  selector: 'app-admin-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class AdminEventCreateComponent {
  constructor(
    private eventsService: EventsService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  create(event: { form: FormGroup, errors: Array<{ control: string, errors: ValidationErrors }> }) {
    if (event.form.valid) {
      const { value } = event.form;

      this.eventsService.createEventInfo(value.eventInfo).subscribe(eventInfo => {
        const params = {
          name: value.name,
          acronym: value.acronym,
          tracks: value.tracks,
          anonimity: value.anonimity,
          sessionDuration: value.sessionDuration ? value.sessionDuration : null,
          year: value.year,
          eventInfo: eventInfo.id,
          eventType: EVENT_TYPE.CONFERENCE,
          uri: value.uri
        };

        this.notificationService.notify('events.create.creating-event');
        this.eventsService.createEvent(params).subscribe(e => {
          this.notificationService.notify('events.create.created-event', { params: { id: e.id, acronym: e.acronym } });

          setTimeout(() => {
            this.router.navigate(['/admin/', e.id]);
          }, 1000);
        });
      });
    } else if (event.errors.length > 0) {
      this.notificationService.notify('events.create.creating-invalid-form', { params: { errors: event.errors.length } });
    } else {
      this.notificationService.notify('events.create.creating-error');
    }
  }
}
