import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { Review, Submission } from 'src/app/model/paper';
import { PrintingProblem } from 'src/app/model/printing.problem';
import { User } from 'src/app/model/user';
import { AuthService } from 'src/app/service/auth.service';
import { InfoService } from 'src/app/service/info.service';
import { NavbarService } from 'src/app/service/navbar.service';
import { ReviewsService } from 'src/app/service/reviews.service';
import { SubmissionsService } from 'src/app/service/submissions.service';

@Component({
  selector: 'app-printing-problems',
  templateUrl: './printing-problems.component.html',
  styleUrls: ['./printing-problems.component.scss']
})
export class PrintingProblemsComponent implements OnInit {

  element: Submission | Review;
  elementId: number;

  isSubmissionView: boolean;
  user: User;
  userIsAdmin: boolean;

  public printingProblems: Array<PrintingProblem>;

  constructor(
    private authService: AuthService,
    private infoService: InfoService,
    private navbarService: NavbarService,
    private reviewsService: ReviewsService,
    private submissionService: SubmissionsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.initComponent();
  }

  initComponent() {
    this.navbarService.loadingStart();
    this.elementId = this.data.id;
    this.isSubmissionView = this.data.isSubmissionView;
    this.user = this.infoService.user;

    const tasks = [];

    if (this.isSubmissionView) {
      tasks.push(...[this.submissionService.getSubmission(this.elementId), this.submissionService.getReportPrintingProblem(this.elementId)]);
    } else {
      tasks.push(...[this.reviewsService.getReview(this.elementId), this.reviewsService.getReportPrintingProblem(this.elementId)]);
    }

    tasks.push(this.authService.directUserIsAdmin());

    forkJoin(tasks).subscribe(([element, printingProblems, isAdmin]: [Submission | Review, Array<PrintingProblem>, boolean]) => {
      this.element = element;
      this.printingProblems = printingProblems;
      this.userIsAdmin = isAdmin;
    }, () => {}, () => {
      this.navbarService.loadingStop();
    });
  }

  canSeeSecretInfo(problem: PrintingProblem): boolean {
    const userIsReviewer = (problem.user as User).id === this.user.id;
    return this.userIsAdmin || userIsReviewer;
  }

}
