import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AdminService } from 'src/app/service/admin.service';
import { UserService } from 'src/app/service/user.service';
import { EventsService } from 'src/app/service/events.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { User } from 'src/app/model/user';
import { Submission, Review } from '../../model/paper';
import { Event } from '../../model/event';
import { NavbarService } from 'src/app/service/navbar.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, Observable } from 'rxjs';
import { ReviewsConfigurationService } from 'src/app/service/reviews.configuration.service';
import { EventReviewConfiguration } from 'src/app/model/eventReviewConfiguration';
import { AssignReview } from 'src/app/model/assign.review';
import { InfoService } from 'src/app/service/info.service';
import { ReviewsService } from 'src/app/service/reviews.service';
import { AuthService } from 'src/app/service/auth.service';
import { NotificationService } from 'src/app/service/notification.service';
import { EventTopic } from 'src/app/model/eventTopic';
import { TimezoneService } from 'src/app/service/timezone.service';

export class UserReviewerSubmission extends User {
  isUserReviewer: boolean;
}

@Component({
  selector: 'app-find-reviewers-by-interest',
  templateUrl: './find-reviewers-by-interest.component.html',
  styleUrls: ['./find-reviewers-by-interest.component.scss']
})
export class FindReviewersByInterestComponent implements OnInit {
  displayedColumns: string[] = ['select', 'user', 'reviews', 'topics', 'claimed', 'claimed-assigned', 'affiliation'];
  claimSelection = new SelectionModel<User>(true, []);
  findSelection = new SelectionModel<User>(true, []);

  userClaimsDataSource = new MatTableDataSource<UserReviewerSubmission>();
  usersByInterestDataSource = new MatTableDataSource<UserReviewerSubmission>();

  public submission: Submission;
  public event: Event;
  public reviewsArray = new Array();
  public submissionTopicsArray = new Array();
  public topicsArray = new Array();
  newReview: AssignReview;
  reviewConfiguration: EventReviewConfiguration;
  form: FormGroup;
  public userIsChair = false;
  reviews: Array<Review>;

  link;

  constructor(
    private authService: AuthService,
    private navbar: NavbarService,
    private route: ActivatedRoute,
    public reviewService: ReviewsService,
    public infoService: InfoService,
    private eventsService: EventsService,
    private reviewConfigurationService: ReviewsConfigurationService,
    private submissionsService: SubmissionsService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private tzService: TimezoneService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getReviewers();
    });
  }

  getReviewers() {
    this.route.params.subscribe(({ id }) => {
      this.submissionsService.getSubmissionReviewersByInterest(id).subscribe(submission => {
        this.submission = submission;
        this.eventsService.getEventByTrack(this.submission.trackID).subscribe(event => {
          this.submission.event = event;
          this.reviewConfigurationService.getReviewsConfiguration(this.submission.event.id).subscribe(rConfiguration => {
            this.reviewConfiguration = rConfiguration;
            this.initForm();
          });
        });

        const userClaim: Array<UserReviewerSubmission> = submission.userClaims.map(u => {
          const user = u as UserReviewerSubmission;
          user.isUserReviewer = submission.isReviewer(user.id);
          return user;
        });

        const userInterest: Array<UserReviewerSubmission> = submission.usersByInterest.map(u => {
          const user = u as UserReviewerSubmission;
          user.isUserReviewer = submission.isReviewer(user.id);
          return user;
        });

        this.eventsService.getEventByTrack(submission.trackID).subscribe(event => {
          this.event = event;
          this.link = {
            label: 'forms.buttons.assign-review-date',
            route: `/admin/${event.id}/reviews/configuration`,
            align: 'end'
          };
          this.authService.directUserIsChair(this.event.id).subscribe(isChair => {
            this.userIsChair = this.authService.userIsAdmin() || isChair;
          });
        });

        this.userClaimsDataSource.data = userClaim;
        this.usersByInterestDataSource.data = userInterest;
        this.submissionTopicsArray.push(...submission.topics.map(t => t.name));
      });
      this.navbar.loadingStop();
    });
  }

  initForm() {
    this.form = this.fb.group({
      dueAt: [this.reviewConfiguration.conferenceDateDue.toDate(), [Validators.required]],
      timeZone: [this.tzService.browserTimeZone]
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  claimIsAllSelected() {
    const numSelected = this.claimSelection.selected.length;
    const numRows = this.userClaimsDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  claimMasterToggle({ checked }) {
    !checked ?
      this.claimSelection.clear() :
      this.userClaimsDataSource.data.forEach(row => {
        if (!this.submission.isReviewer(row.id)) {
          this.claimSelection.select(row);
        }
      }
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  findbyInterestIsAllSelected() {
    const numSelected = this.findSelection.selected.length;
    const numRows = this.usersByInterestDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  findByInterestMasterToggle({ checked }) {
    !checked ?
      this.findSelection.clear() :
      this.usersByInterestDataSource.data.forEach(row => {
        if (!this.submission.isReviewer(row.id)) {
          this.findSelection.select(row);
        }
      }
    );
  }

  addReview(): void {
    const selected = [...this.claimSelection.selected, ...this.findSelection.selected];

    forkJoin(selected.map(user => (this.saveReview(this.infoService.user, user, this.submission.id, this.form.value.dueAt))))
    .subscribe(reviews => {
      if (reviews.length > 1) {
        this.notificationService.notify('messages.assigned-multiple-user-reviews', { params: { users: reviews.length } });
      } else {
        this.notificationService.notify('messages.assigned-user-review', { params: { user: reviews[0].userName } });
      }
      this.getReviewers();
    }, error => {
      this.notificationService.notifyError('errors.could-not-create-review');
    });
  }

  saveReview(assignedBy: User, user: User, submission: number, dueAt): Observable<Review> {
    const review = new AssignReview();
    review.assignedBy = assignedBy.id;
    review.user = user.id;
    review.dueAt = dueAt;
    review.submission = submission;

    return this.reviewService.createReview(review);
  }

  clearSearchInput(): void {
    this.form.get('dueAt').setValue(this.reviewConfiguration.conferenceDateDue.toDate());
  }

  today(): Date {
    return new Date();
  }

  joinTopics(user: { topics: EventTopic[] }) {
    return this.submission.topics
      .filter(t => user.topics.find(topic => topic.id === t.id))
      .map(t => t.name).join(', ');
  }
}
