import { Injectable, EventEmitter, Input } from '@angular/core';
import { NavbarComponent } from '../component/navbar/navbar.component';
import { AdminNavbarComponent } from '../component/admin-navbar/admin-navbar.component';
import { FixedTopProgressBarComponent } from '../component/fixed-top-progress-bar/fixed-top-progress-bar.component';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  public navbar: NavbarComponent | AdminNavbarComponent;
  public navbarHideToggle = new EventEmitter();
  public progressBar: FixedTopProgressBarComponent;
  public loading: boolean;
  private hasStarted: boolean = false;
  private isDone: boolean = false;

  private previousBehaviourDefined: {
    icon: string,
    onClick: () => void
  };

  constructor() { }

  setNavbar(navbar: NavbarComponent | AdminNavbarComponent): void {
    this.navbar = navbar;
    this.progressBar = navbar.progressBar;
  }

  changeBehavior(options: { icon: string, onClick: () => void }): void {
    setTimeout(() => {
      if (!this.previousBehaviourDefined) {
        this.previousBehaviourDefined = {
          icon: this.navbar.icon,
          onClick: this.navbar.toggleSidebar
        };
      }

      this.navbar.icon = options.icon;
      this.navbar.toggleSidebar = options.onClick;
    });
  }

  reset(): void {
    setTimeout(() => {
      if (this.previousBehaviourDefined) {
        this.changeBehavior(this.previousBehaviourDefined);
      } else {
        this.changeBehavior({
          icon: 'fa-bars',
          onClick: () => {
            this.navbar.sidebarService.toggle();
          }
        });
      }
    });
  }

  close(): void {
    setTimeout(() => {
      this.navbar.toggle(false);
      this.navbarHideToggle.emit({ open: false });
    });
  }

  open(): void {
    setTimeout(() => {
      this.navbar.toggle(true);
      this.navbarHideToggle.emit({ open: true });
    });
  }

  loadingStart(): void {
    this.hasStarted = true;
    this.isDone = false;
    setTimeout(() => {
      if (!this.isDone) {
        this.progressBar.start();
      }
    }, 300);
  }

  loadingStop(): void {
    if (this.hasStarted) {
      this.isDone = true;
      setTimeout(() => {
        this.progressBar.stop();
      }, 300);
    } else {
      this.progressBar.stop();
    }
  }

  isLoading(): boolean {
    return this.progressBar.mode !== undefined;
  }
}
