import { Component, OnInit, Inject, HostListener, Output, EventEmitter  } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Session } from 'src/app/model/session';
import { User } from 'src/app/model/user';
import { AdminService } from 'src/app/service/admin.service';
import { UserEventAnswer } from 'src/app/enum/user.event.answer';
import { UserEventRole } from 'src/app/enum/user.event.role';
import { Submission, SubmissionStatus } from 'src/app/model/paper';
import { EventsService } from 'src/app/service/events.service';
import { SelectOption } from 'src/app/model/select.option';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { NotificationService } from 'src/app/service/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaperTitlePipe } from 'src/app/pipe/paper.title.pipe';
import { TimezoneService } from 'src/app/service/timezone.service';

export interface DialogData {
  params?: {
    action?: any;
    content?: any;
    session?: any;
    saveSession?: any;
    showAlocated?: any;
    eventTimeZone?: string;
  };
  cancel: string;
  confirm: string;
}

class SelectableSubmission extends Submission {
  selected:Boolean;
}

@Component({
  selector: 'app-general-session-dialog',
  templateUrl: './general-session-dialog.component.html',
  styleUrls: ['./general-session-dialog.component.scss']
})
export class GeneralSessionDialogComponent implements OnInit {
  dialogMessages: DialogData = {
    cancel: 'forms.buttons.cancel',
    confirm: 'forms.buttons.confirm',
    params: {}
  };

  session: Session;
  showAlocated: Boolean;
  @Output() changeAlocated = new EventEmitter<boolean>();
  allSubmissions: Array<SelectableSubmission>;
  currentSessionSubmissions: Array<Submission>;
  finalSubmissions: Array<Number>;
  form: FormGroup; 
  searchFoundUsers: User;
  validityDate: boolean = true;

  private eventTimeZone: string;

  chairUser: User;

    constructor(
      private adminService: AdminService,
      private fb: FormBuilder,
      public notificationService: NotificationService,
      private submissionsService: SubmissionsService,
      private eventService: EventsService,
      public dialogRef: MatDialogRef<GeneralSessionDialogComponent>,
      private tzService: TimezoneService,
      @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
      if (data) {
        Object.assign(this.dialogMessages, data);
      }
    }

    @HostListener('window:keyup.Enter', ['$event'])
  
    onDialogClick(event: KeyboardEvent): void {
      this.save();
      this.dialogRef.close();
    }
    

    ngOnInit(): void {
      setTimeout(() => {
        this.showAlocated = this.dialogMessages.params.showAlocated;
        this.session = this.dialogMessages.params.session;
        this.eventTimeZone = this.dialogMessages.params.eventTimeZone; 
        this.getSubmissions();
        this.initForm();
      });
    }

    hexToRgb(hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
      });
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    }

    updateColor(event) {
      this.form.controls['backgroundColor'].setValue(event.target.value);
    }

    private initForm(): void {
      const rgb = this.hexToRgb(this.session.backgroundColor ? this.session.backgroundColor : '#BFE7FF');
      const chair = this.session?.chair;
      this.form = this.fb.group({
        id: [this.session.id, []],
        submissions: [this.session.submissions],
        event: [this.session.event, [Validators.required]],
        title: [this.session.title, [Validators.maxLength(100)]],
        subtitle: [this.session.subtitle, [Validators.maxLength(100)]],
        url: [this.session.url, [Validators.maxLength(100)]],
        room: [this.session.room, [Validators.maxLength(100)]],
        start: [this.session.start, [Validators.required]],
        timeZoneStart: [this.tzService.browserTimeZone],
        end: [this.session.end, [Validators.required]],
        timeZoneEnd: [this.tzService.browserTimeZone],
        chair: [chair?.firstName ? `${chair.firstName} ${chair.lastName}` : null, []],
        searchChair: [[], []],
        backgroundColor: [this.session.backgroundColor ? this.session.backgroundColor : '#BFE7FF', []]
      });
      this.checkValidityFormDate();
    }

    private checkValidityFormDate() {
      this.form.get('end').valueChanges.subscribe(() => {
        this.validityDate = this.validityDateForm();
      });
      this.form.get('start').valueChanges.subscribe(() => {
        this.validityDate = this.validityDateForm();
      });
      this.form.controls.start.markAsTouched();
      this.form.controls.end.markAsTouched();
    }

    private validityDateForm(): boolean {
      const isValid: boolean = this.form.get('start').value < this.form.get('end').value;

      isValid ? this.form.get('end').setErrors(null) : this.form.get('end').setErrors({ 'dateEndAfterStart': true });
      isValid ? this.form.get('start').setErrors(null) : this.form.get('start').setErrors({ 'dateEndAfterStart': true });

      return isValid;
    }

    setUserAsChair(user: User): void {
      this.chairUser = user;
      this.form.get('chair').setValue(`${user.firstName} ${user.lastName}`);
    }

    public removeChair(): void {
      this.chairUser = null;
      this.form.get('chair').setValue(this.chairUser);
    }

    changedStateAlocated(value: boolean) {
      this.changeAlocated.emit(!value);
    }

    private getSubmissions(): void {
      let hasFound:Boolean;
      this.allSubmissions = [];
      this.submissionsService.getSubmissionsBySession(this.session.id).subscribe(submissions => {
        this.currentSessionSubmissions = submissions;

        this.submissionsService.getAllSubmissionsByEvent(this.session.event).subscribe(submissions => {
          this.allSubmissions = submissions as SelectableSubmission[];

          for(var index in this.allSubmissions){
            hasFound = false;
            for(var sIndex in this.currentSessionSubmissions){
              if(!hasFound){
                if(this.currentSessionSubmissions[sIndex].id === this.allSubmissions[index].id){
                  this.allSubmissions[index].selected = true;
                  hasFound = true;
                }else{
                  this.allSubmissions[index].selected = false;
                }
              }
            }
          }
        });
      });
    }

    save() {
      this.finalSubmissions = [];
      for(var index in this.allSubmissions){
        if(this.allSubmissions[index].selected){
          this.finalSubmissions.push(this.allSubmissions[index].id);
        }
      }
      this.form.patchValue({
        submissions : this.finalSubmissions,
      });
      
      this.form.get('chair').setValue(this.chairUser);
      this.dialogMessages.params.saveSession(this.form.value);
    }

}
