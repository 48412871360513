import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Submission, SubmissionStatus } from 'src/app/model/paper';
import { Track } from '../../model/track';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/service/auth.service';
import { InfoService } from 'src/app/service/info.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { EventsService } from '../../service/events.service';
import { FilesService } from 'src/app/service/files.service';
import { NotificationService } from 'src/app/service/notification.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FileRules } from 'src/app/model/file.rules';
import { FileUploadViewDirective } from 'src/app/class/file-upload-view';
import { File } from '../../model/file';

@Component({
  selector: 'app-file-rules-view',
  templateUrl: './file-rules-view.component.html',
  styleUrls: ['./file-rules-view.component.scss']
})
export class FileRulesViewComponent extends FileUploadViewDirective implements OnInit, OnChanges {
  @Input() submission: Submission;
  @Input() event: number;
  @Input() isReview: false;
  @Input() eventTimeZone: string;

  @Output() submissionChanged = new EventEmitter<Submission>();

  public userIsChair = false;

  constructor(
    private authService: AuthService,
    public fb: FormBuilder,
    public submissionsService: SubmissionsService,
    public eventsService: EventsService,
    public filesService: FilesService,
    public infoService: InfoService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {
    super(fb, submissionsService, filesService, eventsService, infoService);
  }

  ngOnInit() {
    this.initUploadForm();
    this.authService.userIsAdminOrChair(this.event).subscribe(isChairOrAdmin => {
      this.userIsChair = isChairOrAdmin;
    });

    this.setTrackOfTrackFiles();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  fileUploaded(rule: FileRules, status: number) {
    if (status === 100) {
      rule.uploadStatus = undefined;
      const trackFiles = (<Track>this.submission.track).trackFiles;
      this.verifyAndUpdateStatus(this.submission, rule, trackFiles, this.submissionChanged);
    } else {
      rule.uploadStatus = status;
    }
  }

  deleteFile(file: File) {
    this.dialog.open(ConfirmationDialogComponent, {disableClose: true, autoFocus: false,
      data: {
        title: 'messages.confirm-file-deletion',
        content: ''
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.submissionsService.deleteSubmissionFile(file, this.submission).subscribe(() => {
          this.submissionsService.getSubmission(this.submission.id, true).subscribe(sub => {
            this.submissionSetEventAndTrack(sub, this.submissionChanged);
            this.submission = sub;

            this.notificationService.notify('messages.removed-file');
          });
        });
      }
    });
  }
}
