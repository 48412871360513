import { SelectOption } from '../model/select.option';

export enum Anonimity {
  NONE = 'forms.fields.anonimity-values.none',
  SINGLE_BLIND = 'forms.fields.anonimity-values.single-blind',
  DOUBLE_BLIND = 'forms.fields.anonimity-values.double-blind'
}

const AnonimityValues = Object.keys(Anonimity)
  .map(key => ({ id: key, value: Anonimity[key]}));

export namespace Anonimity {
  export function toIterable(): Array<string> {
    return Object.keys(Anonimity).filter(key => typeof Anonimity[key] !== 'function');
  }

  export function toSelectable(): Array<SelectOption> {
    return Anonimity.toIterable().map(value => ({
      id: value,
      value: Anonimity[value]
    }));
  }
}

export { AnonimityValues };
