import { Component, forwardRef, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { TextInputComponent } from '../text-input/text-input.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NO_PAGINATION } from 'src/app/model/pagination.options';
import { SelectOption } from '../../model/select.option';
import { MatSelectChange } from '@angular/material/select';
import { AdminService } from 'src/app/service/admin.service';
import { EventTitlePipe } from 'src/app/pipe/event.title.pipe';
import { Event } from 'src/app/model/event';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-select-input-le',
  templateUrl: './select-input-le.component.html',
  styleUrls: ['./select-input-le.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectInputLoadEventComponent),
    multi: true
  }]
})

export class SelectInputLoadEventComponent extends TextInputComponent implements OnInit {
  @Input() options: Array<SelectOption> = [];
  @Input() indices = {
    internal: 'id',
    public: 'value'
  };

  @Input() prependPublic = '';
  @Output() selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();

  @Input() multiple = false;
  @Input() eventId: Number;

  @Input() compact = false;
  @Input() includeNoneOption = false;

  @Input() filterable = false;
  filteredOptions: Array<SelectOption>;
  event: Event;
  subEventsID: Array<number>;

  @Input() isEditable: boolean = true;

  /* eslint-disable constructor-super */
  /* @ts-ignore */
  constructor(
    private adminService: AdminService,
    private eventsService: EventsService
  ) {
    super();
  }

  ngOnInit(): void {
    const formControl = this.formGroupController.get(this.name);
    this.isEditable ? formControl.enable() : formControl.disable();
  }

  loadEvents(): void {
    this.adminService.getEvent().subscribe(event => {
      this.event = event;
      this.getSubEvents();
    });
  }

  private getSubEvents(): void {
    this.eventsService.getAllSubEvents(this.event.id)
      .subscribe({
        next: subEvents => this.subEventsID = subEvents.map(subEvent => subEvent.id),
        complete: () => this.createOptions()
      })
  }
    
  private createOptions(): void {
    this.eventsService.getAllEventsWithMinimumDetails()
      .subscribe(events => {
        const newOptions = events
          .filter(event => (event['id'] !== this.event.id))
          .map(event => new SelectOption(event.id, `${event.acronym} ${event.year} - ${event.name}`));
        
          this.options = [...newOptions];
      });
  }

  public isEventRelative(eventID: number): boolean {
    return this.subEventsID?.includes(eventID);
  }

  onSelection($event: MatSelectChange): void {
    this.selectionChange.emit($event);
  }
}
