import { Component, OnInit } from '@angular/core';
import { Event } from 'src/app/model/event';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { SubmissionsService } from 'src/app/service/submissions.service';

@Component({
  selector: 'app-submission-statistics',
  templateUrl: './submission-statistics.component.html',
  styleUrls: ['./submission-statistics.component.scss']
})
export class SubmissionStatisticsComponent implements OnInit {
  event: Event;

  constructor(
    protected adminService: AdminService,
    public submissionService: SubmissionsService,
    private eventsService: EventsService
  ) { }


  ngOnInit(): void {
    this.event = this.adminService.selectedEvent;

    if (!this.event) {
      this.adminService.getEvent().subscribe(event => {
        this.event = event;
      });
    }
  }
}
