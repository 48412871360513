import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/service/admin.service';
import { UserService } from 'src/app/service/user.service';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-event-user-topics',
  templateUrl: './event-user-topics.component.html',
  styleUrls: ['./event-user-topics.component.scss']
})
export class EventUserTopicsComponent implements OnInit {
  editingUser: User;

  constructor(
    public adminService: AdminService,
    public userService: UserService,
    public route: ActivatedRoute) { }

  ngOnInit() {
    setTimeout(() => {
      this.route.params.subscribe(({ userId }) => {
        this.initUser(userId);
      });
    });
  }

  initUser(userId) {
    this.userService.getUser(userId).subscribe(user => {
      this.editingUser = user;
    });
  }
}
