import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Session } from 'src/app/model/session';
import { User } from 'src/app/model/user';
import { AdminService } from 'src/app/service/admin.service';
import { UserEventAnswer } from 'src/app/enum/user.event.answer';
import { UserEventRole } from 'src/app/enum/user.event.role';
import { Submission, SubmissionStatus } from 'src/app/model/paper';
import { EventsService } from 'src/app/service/events.service';
import { SelectOption } from 'src/app/model/select.option';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { NotificationService } from 'src/app/service/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../form-session-dialog/form-session-dialog.component';
import { PaperTitlePipe } from 'src/app/pipe/paper.title.pipe';

class SelectableSubmission extends Submission {
  selected:Boolean;
}

@Component({
  selector: 'app-sub-session-dialog',
  templateUrl: './sub-session-dialog.component.html',
  styleUrls: ['./sub-session-dialog.component.scss']
})
export class FormSubSessionDialogComponent implements OnInit {
  dialogMessages: DialogData = {
    cancel: 'forms.buttons.cancel',
    confirm: 'forms.buttons.confirm',
    params: {}
  };

  session: Session;
  showAlocated: Boolean;
  allSubmissions: Array<SelectableSubmission>;
  currentSessionSubmissions: Array<Submission>;
  finalSubmissions: Array<Number>;
  form: FormGroup;

  searchFoundUsers: User;

    constructor(
      private adminService: AdminService,
      private fb: FormBuilder,
      public notificationService: NotificationService,
      private submissionsService: SubmissionsService,
      private eventService: EventsService,
      public dialogRef: MatDialogRef<FormSubSessionDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
      if (data) {
        Object.assign(this.dialogMessages, data);
      }
    }

    ngOnInit(): void {
      setTimeout(() => {
        this.showAlocated = true;
        this.session = this.dialogMessages.params.session;
        this.getSubmissions();
        this.initForm();
      });
    }

    hexToRgb(hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
      });
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    }

    private initForm(): void {
      const rgb = this.hexToRgb(this.session.backgroundColor ? this.session.backgroundColor : '#BFE7FF');
      this.form = this.fb.group({
        id: [this.session.id, []],
        chair: [this.session.chair?this.session.chair:null],
        submissions: [this.session.submissions],
      });
    }

    private getSubmissions(): void {
      let hasFound:Boolean;
      this.allSubmissions = [];
      this.submissionsService.getSubmissionsBySession(this.session.id).subscribe(submissions => {
        this.currentSessionSubmissions = submissions;

        this.submissionsService.getAllSubmissionsByEvent(this.session.event).subscribe(submissions => {
          this.allSubmissions = submissions as SelectableSubmission[];

          for(var index in this.allSubmissions){
            hasFound = false;
            for(var sIndex in this.currentSessionSubmissions){
              if(!hasFound){
                if(this.currentSessionSubmissions[sIndex].id === this.allSubmissions[index].id){
                  this.allSubmissions[index].selected = true;
                  hasFound = true;
                }else{
                  this.allSubmissions[index].selected = false;
                }
              }
            }
          }
        });
      });
    }

    save() {
      this.finalSubmissions = [];
      for(var index in this.allSubmissions){
        if(this.allSubmissions[index].selected){
          this.finalSubmissions.push(this.allSubmissions[index].id);
        }
      }
      this.form.patchValue({
        submissions : this.finalSubmissions,
      });
      this.dialogMessages.params.saveSession(this.form.value);
    }
}
