import { Directive, ElementRef, Renderer2, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[appRouterLinkDisable]'
})
export class RouterLinkDisableDirective implements OnInit {
  @Input('appRouterLinkDisable') shouldDisableRouterLink: boolean;

  constructor(private elRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    if (this.shouldDisableRouterLink) {
      setTimeout(() => this.renderer.removeAttribute(this.elRef.nativeElement, 'href'), 0);
    }
  }
}
