import { Component, Input } from '@angular/core';
import { PreferencesService } from '../../service/preferences.service';
import { ViewMode } from '../../enum/view.mode';
import { SelectOption } from '../../model/select.option';
import { NotificationService } from 'src/app/service/notification.service';

@Component({
  selector: 'app-view-preferences-picker',
  templateUrl: './view-preferences-picker.component.html',
  styleUrls: ['./view-preferences-picker.component.scss']
})
export class ViewPreferencesPickerComponent {
  viewModes: Array<SelectOption>;

  colorAsStatus: boolean;

  @Input() silent = false;

  constructor(public preferencesService: PreferencesService, public notificationService: NotificationService) {
    this.viewModes = ViewMode.toSelectable();
    this.colorAsStatus = <boolean>(this.preferencesService.getPreference('statusColorAsBackgroundColor'));
  }

  submitPreferences(): void {
    this.preferencesService.saveCachedPreferences().subscribe(user => {
      if (!this.silent) {
        this.notificationService.notify('profile.updated-user-preferences');
      }
    });
  }

  updateBackgroundAndSave(): void {
    this.preferencesService.changePreference('statusColorAsBackgroundColor', this.colorAsStatus);
    this.submitPreferences();
  }
}
