import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { TextInputComponent } from '../text-input/text-input.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-multiline-text-input',
  templateUrl: './multiline-text-input.component.html',
  styleUrls: ['./multiline-text-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultilineTextInputComponent),
    multi: true
  }]
})
export class MultilineTextInputComponent extends TextInputComponent {
  @Input() rows = 5;
}
