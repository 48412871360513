import { Type } from 'class-transformer';
import { FileType } from '../enum/file.type';
import { Environment } from '../environment/environment';
import { FileRules } from './file.rules';

export class File {
  @Type(() => FileRules)
  public trackFile: FileRules;

  public pageCount: number;

  public isUsingSbcTemplate: boolean;

  constructor(
    public id: number,
    public file: string,
    public size: number,
    public date: Date = new Date()
  ) { }

  get name(): string {
    return this.url.split('/').pop();
  }

  get type(): FileType {
    switch (this.name.split('.').pop()) {
      case 'pdf':
        return FileType.PDF;

      case 'doc':
      case 'docx':
        return FileType.DOC;

      case 'mpg':
      case 'mp4':
        return FileType.VIDEO;

      default:
        return FileType.OTHER;
    }
  }

  get url(): string {
    return Environment.urls.API + this.file;
  }

  get icon(): { 'fontSet': string, 'fontIcon': string } {
    return FileType.icon(this.type);
  }

  isPDF(): boolean {
    return this.type === FileType.PDF;
  }

  hasErrors(rule?: FileRules): string {
    const errors = [];

    if (rule) {
      if (rule.maxPages !== 0 && this.pageCount > rule.maxPages) {
        errors.push('file-rules-view.pages-warning');
      }
    }

    if (errors.length > 0) {
      // TODO: Requires refactoring to support multiple error messages.
      // Using only the first one, since there is only one defined currently.
      return errors[0];
    }
    return undefined;
  }
}

export enum FileUploadErrors {
  VALID = 0,
  MAX_SIZE = 1,
  INVALID_FORMAT = 2,
  MAX_PAGES = 3
}
