import { BaseModel } from './baseModel';
import { Type } from 'class-transformer';

export interface EventTopicOptions {
  show: 'active' | 'all';
}

export const DEFAULT_EVENT_TOPIC_OPTIONS: EventTopicOptions = {
  show: 'active'
};

export const SHOW_ALL_EVENT_TOPIC_OPTIONS: EventTopicOptions = {
  show: 'all'
};

export class EventTopic extends BaseModel {
  @Type(() => EventTopic)
  public parent: EventTopic;

  @Type(() => EventTopic)
  public children: EventTopic[] = [];

  public active = true;
  public deleted = false;

  public order: number;

  constructor(public name: string) {
    super();
  }

  get isParent(): boolean {
    return this.children?.length > 0;
  }

  get isCategory(): boolean {
    return this.isParent;
  }

  get isSubtopic(): boolean {
    return !!this.parent;
  }
}
