import { DICT_THEME } from "../model/profile";
import _ from 'lodash';

export const changeContentMetaThemeColor = (theme: string) => {
    const defaultColorDarkTheme = '#212121';
    const defaultColorLightTheme = '#f6f6f6';
    
    let color: string;
    theme === DICT_THEME.DARK ? color = defaultColorDarkTheme : color = defaultColorLightTheme;
    
    const metasThemeColor = document.querySelectorAll('meta[name=theme-color]');
    metasThemeColor.forEach((meta: HTMLMetaElement) => {
        meta.content = color;
    });
}

export const shallowCopyArray = (array: any[]) => {
    return array.slice();
};

export const arrayHasElements = (array: any[]): boolean => {
    if (!array) {
        return false;
    }

    return array.length != 0;
};

export const isEqual = (a: any, b: any): boolean => {
    return _.isEqual(a, b);
};

export const intersectionArrays = (array1: any[], array2: any[]): any[] => {
    /* The order of return array is given by array1 */
    return array1.filter(x => array2.indexOf(x) !== -1);
}

export const isValidNumber = (value: any): boolean => {
    return !!(value) && !isNaN(Number(value));
};
