import { Transform } from 'class-transformer';
import * as dayjs from 'dayjs';

export class BaseModel {
  public id: number;

  @Transform(toDayjs)
  public createdAt: dayjs.Dayjs;
  @Transform(toDayjs)
  public updatedAt: dayjs.Dayjs;

  constructor() { }
}

export function toDayjs(date: string | Date | dayjs.Dayjs) {
  if (date === null || date === undefined) {
    return undefined;
  }

  if (typeof date !== 'string' && !(date instanceof Date)) {
    return date;
  }

  return dayjs(date);
}
