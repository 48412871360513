import { SubmissionStatus } from '../enum/submission.status';
import { User } from './user';
import { Type } from 'class-transformer';
import { Transform } from 'class-transformer';
import * as dayjs from 'dayjs';
import { toDayjs } from './baseModel';

export class SubmissionHistory {
  @Transform(toDayjs)
  public createdAt: dayjs.Dayjs;

  public status: SubmissionStatus;

  @Type(() => User)
  public user: User;

  public host: string;

  public description: string;
}
