import { Component, OnInit, OnDestroy } from '@angular/core';

import { style, animate, transition, state, trigger } from '@angular/animations';
import { SidebarService } from '../../service/sidebar.service';
import { NavbarService } from '../../service/navbar.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-form-overlay',
  templateUrl: './form-overlay.component.html',
  styleUrls: ['./form-overlay.component.scss'],
  animations: [
    trigger('slideIn', [
      state('*', style({ 'overflow-y': 'hidden', height: '*' })),
      state('void', style({ 'overflow-y': 'hidden', height: 0 })),
      transition('* => void', [animate('500ms 0.2ms ease-out')]),
      transition('void => *', [animate('500ms 0.2ms ease-out')])
    ])
  ]
})
export class FormOverlayComponent implements OnInit, OnDestroy {
  constructor(
    private location: Location,
    private sidebarService: SidebarService,
    private navbarService: NavbarService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.startNavbarAnimation();
    });
  }

  private startNavbarAnimation(): void {
    this.sidebarService.close({ hideContent: true });
    this.navbarService.close();

    this.navbarService.changeBehavior({
      icon: 'fa-arrow-left',
      onClick: () => {
        this.location.back();
        this.navbarService.reset();
      }
    });
  }

  ngOnDestroy(): void {
    this.navbarService.reset();

    this.sidebarService.toggleContent();
    if (window.innerWidth >= 768) {
      this.sidebarService.toggle();
    }

    this.navbarService.open();
  }

}
