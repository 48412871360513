import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { CheckListAnswer, CheckListAnswerStatus } from 'src/app/model/checklist-field';
import * as _ from 'lodash';
import { Submission } from 'src/app/model/paper';

class CoverableSubmission {

  constructor(answer?: CheckListAnswer, order?: number) {
    this.answer = answer ? answer : new CheckListAnswer();

    if (order) {
      this.answer.order = order;
    }

    this.toBeDeleted = false;
    this.selected = false;
  }

  answer: CheckListAnswer;
  toBeDeleted: boolean;
  selected: boolean;
}

@Component({
  selector: 'app-publication-fixed-control',
  templateUrl: './publication-fixed-control.component.html',
  styleUrls: ['./publication-fixed-control.component.scss']
})
export class PublicationFixedControlComponent implements OnInit, OnChanges {

  @Input() titleLabel: string;
  @Input() formArray: FormArray;
  @Input() checklistAnswers: CheckListAnswer[];
  submissionsList: CoverableSubmission[];

  @Output() updateList: EventEmitter<any> = new EventEmitter;

  @ViewChild(MatTable, {static: false}) table : MatTable<CoverableSubmission>;
  columnsShow: string[] = ['position', 'id', 'title', 'event', 'track', 'status', 'delete'];

  lastSelectedIndex: number;
  lastSelectedSubmissionId: string;
  deleteOccurred: boolean;

  CheckListAnswerStatus = CheckListAnswerStatus
  CaptionCheckListAnswerStatus = Object.values(CheckListAnswerStatus);

  @HostListener('document:keydown', ['$event'])
  handleDelete(event: KeyboardEvent) {
    if (event.key != 'Delete') {
      return;
    }

    if (!this.deleteOccurred) {
      this.uncoverAllSelectedSubmissions();
    }

    setTimeout(() => this.deleteOccurred = false, 50);
    
    if (this.lastSelectedIndex != undefined) {
      setTimeout(() => {
        this.formArray.controls[this.lastSelectedIndex].get('submissionId').setValue(this.lastSelectedSubmissionId);
        this.submissionsList[this.lastSelectedIndex].answer.submissionId = this.lastSelectedSubmissionId;
      });
    }

  }

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initSubmissionsList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.checklistAnswers) return;

    this.initSubmissionsList();
  }

  initSubmissionsList() {
    this.submissionsList = [];
    this.checklistAnswers.forEach(answer => this.submissionsList.push(new CoverableSubmission(_.cloneDeep(answer))));
  }

  reloadTable() {
    this.initFormArrayControls(this.submissionsList);
    this.table.renderRows();
  }

  getSubmissionAnswerField(index: number) : FormGroup {
    return this.formArray.controls[index] as FormGroup;
  }

  extractSubmissionsIds(rawList: string | FormArray) : string[] {
    if (typeof rawList == 'string') {
      let submissionsIds = rawList.split('\n');

      if (submissionsIds.length == 1) {
        const rawElement = submissionsIds[0];
        if (rawElement.includes('\t')) {
          submissionsIds = rawElement.split('\t');
        } else if (rawElement.includes(',')) {
          submissionsIds = rawElement.split(',');
        } else if (rawElement.includes(';')) {
          submissionsIds = rawElement.split(';');
        } else if (rawElement.includes(' ')) {
          submissionsIds = rawElement.split(' ');
        }
      }

      return submissionsIds.filter(sId => sId != '');
    }

    return rawList.value.filter(control => control.status != CheckListAnswerStatus.DELETING).map(answer => answer.submissionId).filter(a => a);
  }

  handleInput(event: any, index: number) {
    const fieldsNum = this.formArray.controls.length;
    const submissionId = event.target.value;

    this.submissionsList[index].answer.submissionId = submissionId;

    if (index != fieldsNum - 1) return;
    
    this.submissionsList[fieldsNum - 1].answer.submissionId = event.target.value;
    this.submissionsList.push(new CoverableSubmission(undefined, fieldsNum));

    this.reloadTable();
  }

  handlePaste(event: ClipboardEvent, index: number) {
    const submissionsIds = this.extractSubmissionsIds(event.clipboardData.getData('text'));

    const middleSubList: CoverableSubmission[] = [];

    submissionsIds.map((sId, index) => {
      const answer = new CheckListAnswer();
      answer.order = index + 1;
      answer.submissionId = sId;
      
      middleSubList.push(new CoverableSubmission(answer));
    });

    const beforeSublist = this.submissionsList.slice(0, index);
    const afterSublist = this.submissionsList.slice(index, this.submissionsList.length - 1);

    let formattedSubmissionsList = beforeSublist.concat(middleSubList);

    if (index != this.submissionsList.length) {
      formattedSubmissionsList = formattedSubmissionsList.concat(afterSublist);
    }

    if (index != this.submissionsList.length - 1) {
      const removedIndex = index + middleSubList.length;
      const removed = formattedSubmissionsList.splice(removedIndex, 1)[0];
      formattedSubmissionsList.splice(index + middleSubList.length, 0, new CoverableSubmission(_.cloneDeep(removed.answer), removedIndex + 1));
    }
    
    formattedSubmissionsList.push(new CoverableSubmission());
    
    this.submissionsList = formattedSubmissionsList;
    this.initFormArrayControls(formattedSubmissionsList);
    this.table.renderRows(); 
  }

  handleTab(index: number, reverseMode?: boolean) {
    this.unselectAllAnswers();

    const nearestSubmissionIndex = this.getNearestSubmissionIndex(index, reverseMode);

    if (nearestSubmissionIndex == -1) {
      return;
    }

    this.selectAnswer(nearestSubmissionIndex);
  }

  getNearestSubmissionIndex(startingIndex: number, reverseMode?: boolean): number {
    let i = startingIndex + (reverseMode ? -1 : 1);
    let indexFound = false;

    while (i >= 0 && i < this.submissionsList.length) {
      const coverableSubmission = this.submissionsList[i];
      
      if (this.submissionIsSelectable(coverableSubmission)) {
        indexFound = true;
        break;
      }

      reverseMode ? i-- : i++;
    }

    return indexFound ? i : -1;
  }

  getSubmissionAnswerStatus(submission: CoverableSubmission): CheckListAnswerStatus {
    const filter = this.checklistAnswers.filter(answer => answer.submissionId == submission.answer.submissionId && answer.order == submission.answer.order);

    return filter.length == 0 ? CheckListAnswerStatus.UNCATEGORIZED : filter[0].status;
  }

  saveLastSelectedAnswer(index: number) {
    const submissionId = this.submissionsList[index].answer.submissionId;

    if (!submissionId) {
      return;
    }

    this.lastSelectedIndex = index;
    this.lastSelectedSubmissionId = submissionId;
  }

  handleControlA(index: number) {
    this.saveLastSelectedAnswer(index);
    this.selectAllAnswers();
  }

  selectAllAnswers() {
    for (let i = 0; i < this.submissionsList.length; i++) {
      this.selectAnswer(i);
    }
  }

  unselectAllAnswers() {
    this.submissionsList.forEach(s => s.selected = false);
  }

  uncoverAllSelectedSubmissions() {
    this.submissionsList.filter(s => s.selected && this.submissionIsSelectable(s)).forEach(selectedSubmission => {
      const originalIndex = this.submissionsList.indexOf(selectedSubmission);
      this.uncover(originalIndex);
    });

    this.deleteOccurred = true;
  }

  handleRowClick(event: MouseEvent, index: number) {
    if (!event.ctrlKey && !event.metaKey) {
      this.unselectAllAnswers();
    }

    if (event.shiftKey && this.lastSelectedIndex != undefined) {
      const indexesLimits = [index, this.lastSelectedIndex].sort();
      const firstIndex = indexesLimits[0];
      const lastIndex = indexesLimits[1];

      for (let i = firstIndex; i < lastIndex; i++) {
        this.selectAnswer(i);
      }
    }

    const coverableSubmission = this.submissionsList[index];

    if ((event.ctrlKey || event.metaKey) && coverableSubmission.selected && !event.shiftKey) {
      coverableSubmission.selected = false;
      return;
    }

    this.selectAnswer(index);
    this.saveLastSelectedAnswer(index);
  }

  selectAnswer(index: number) {

    const indexIsValid = index < this.submissionsList.length;

    if (!indexIsValid) {
      return;
    }

    const coverableSubmission = this.submissionsList[index];

    if (!this.submissionIsSelectable(coverableSubmission)) {
      return;
    }

    coverableSubmission.selected = true;
  }

  submissionIsSelectable(submission: CoverableSubmission): boolean {
    const answer = submission.answer;

    return !submission.toBeDeleted && answer.submissionId != undefined;
  }

  statusLength(status: CheckListAnswerStatus | string): number {
    return this.submissionsList.filter(s => s.answer.status == status).length - (status == CheckListAnswerStatus.UNCATEGORIZED ? 1 : 0);
  }

  initFormArrayControls(submissionsList: CoverableSubmission[]) {
    this.formArray = this.fb.array(submissionsList.map(submission => this.fb.group({
      id: [submission.answer.id],
      order: [submission.answer.order],
      status: [submission.answer.status],
      submissionId: [{ value: submission.answer.submissionId, disabled: submission.toBeDeleted }]
    })));
  }

  update() {
    this.reloadTable();
    const submissionsId = this.extractSubmissionsIds(this.formArray);
    this.updateList.emit(submissionsId);
  }

  uncover(index: number) {
    const submission = this.submissionsList[index];

    if (!submission.answer.submissionId || submission.toBeDeleted) {
      return;
    }

    setTimeout(() => {
      submission.selected = false;
    });

    if(submission.answer.status == CheckListAnswerStatus.UNCATEGORIZED) {
      this.submissionsList.splice(index, 1);
      this.reloadTable();
      return;
    }
    
    submission.toBeDeleted = true;
    submission.answer.status = CheckListAnswerStatus.DELETING;
    this.formArray.controls[index].get('submissionId').disable();
  }

  recover(index: number) {
    const submission = this.submissionsList[index];

    if (!submission.toBeDeleted) {
      return;
    }

    setTimeout(() => {
      submission.selected = false;
    });

    submission.toBeDeleted = false;
    submission.answer.status = this.getSubmissionAnswerStatus(submission);
    this.formArray.controls[index].get('submissionId').enable();
  }

  focusOnFirstOccurrence(status: CheckListAnswerStatus) {
    const className = this.getStatusClass(status);
    const ocurrences = document.getElementsByClassName(className);

    const firstOcurrence = ocurrences[0] as HTMLElement;
    const textInput = firstOcurrence.children[1].children[0].children[0].children[0].children[0].children[0].children[0].children[0] as HTMLElement; 
    textInput.focus();
    firstOcurrence.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  getStatusClass(status: string): string {
    return status.replace('_', '-').toLowerCase();
  }

}
