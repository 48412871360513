export enum LocalCache {
  OLD_SUBMISSIONS = 'local-old-submissions',
  OLD_REVIEWS = 'local-old-reviews',
  OLD_EVENTS = 'local-old-events',
  OLD_TPC = 'local-old-tpc',
  OLD_CHAIRINGS = 'local-old-chairings',

  STATUS_SUBMISSIONS = 'local-status-submissions',
  STATUS_REVIEWS = 'local-status-reviews',

  SUBMISSION_FILTER = 'local-submission-filter',

  OLD_THEME = 'local-old-theme'
}

export namespace LocalCache {
  export const cacheKey = 'local-cache';

  export function getValue(key: LocalCache, id?: number): string | boolean {
    const cache = JSON.parse(localStorage.getItem(this.cacheKey));
    const cacheItem = id ? `${key}-${id}` : key;

    if (cache && cache[cacheItem] !== undefined) {
      // If boolean, convert string to boolean.
      // else, return is a string;

      return (cache[cacheItem] === 'true' || cache[cacheItem] === 'false') ?
        cache[cacheItem] === 'true' :
        cache[cacheItem];
    }
    return false;
  }

  export function setValue(key: LocalCache, value: any, id?: number): void {
    let cache = JSON.parse(localStorage.getItem(this.cacheKey));
    const cacheItem = id ? `${key}-${id}` : key;

    if (!cache) {
      cache = {};
    }
    cache[cacheItem] = value;

    localStorage.setItem(this.cacheKey, JSON.stringify(cache));
  }

  export function clearValue(key: LocalCache, id?: number): void {
    let cache = JSON.parse(localStorage.getItem(this.cacheKey));
    const cacheItem = id ? `${key}-${id}` : key;

    if (!cache) {
      cache = {};
    }
    delete cache[cacheItem];

    localStorage.setItem(this.cacheKey, JSON.stringify(cache));
  }
}
