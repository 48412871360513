import { SelectOption } from '../model/select.option';
import * as moment from 'moment';

export enum calendarTimezone {
    greenwich = 'Etc/Greenwich',
    newYork = 'America/New_York',
}

export namespace calendarTimezone {
    export function toIterable(): Array<string> {
        return Object.keys(calendarTimezone).filter(key => typeof calendarTimezone[key] !== 'function');
    }

    export function toSelectable(eventTimeZone: string | undefined, browserTimeZone: string): Array<SelectOption> {
        const timezones = calendarTimezone.toIterable().map(value => ({
            id: value,
            value: calendarTimezone[value],
            offset: moment.tz(calendarTimezone[value]).utcOffset()
        }));

        if (browserTimeZone != eventTimeZone) {
            timezones.unshift({id: 'browser', value: `${browserTimeZone} (Browser's Time Zone)`, offset: moment.tz(browserTimeZone).utcOffset()});
        }
        if (!!eventTimeZone) {
            timezones.unshift({id: 'event', value: `${eventTimeZone} (Event's Time Zone)`, offset: moment.tz(eventTimeZone).utcOffset()});
        }

        return timezones;
    }
}
