import { Component, OnInit, ViewChild } from '@angular/core';
import { Submission } from '../../model/paper';
import { SubmissionsService } from '../../service/submissions.service';
import { SubmissionStatus } from '../../enum/submission.status';
import { NavbarService } from 'src/app/service/navbar.service';
import { NO_PAGINATION } from 'src/app/model/pagination.options';
import { ShowAllPagedViewDirective } from 'src/app/class/paged-view-all';
import { PreferencesService } from 'src/app/service/preferences.service';
import { LocalCache } from 'src/app/enum/local.cache';

@Component({
  selector: 'app-submissions',
  templateUrl: './submissions.component.html',
  styleUrls: ['./submissions.component.scss']
})
export class SubmissionsComponent extends ShowAllPagedViewDirective {
  public filterNotification: boolean = this.checkedFilterNotification;

  constructor(
    private submissionService: SubmissionsService,
    public navbarService: NavbarService,
    public preferencesService: PreferencesService
  ) {
    super(preferencesService, LocalCache.OLD_SUBMISSIONS);
  }

  public load(): void {
    setTimeout(() => {
      this.getSubmissions();
    });
  }

  get submissions(): Array<Submission> {
    return <Array<Submission>> this.elements;
  }

  getSubmissions(): void {
    this.navbarService.loadingStart();
    const paginationOptions = NO_PAGINATION;
    paginationOptions.show = this.showAll;
    this.submissionService.getUserSubmissions(paginationOptions, this.filter, undefined, true).subscribe(pagination => {
      this.unfilteredElements = pagination.items as Submission[];
      this.filterPapers(<Submission[]>this.unfilteredElements);
      this.navbarService.loadingStop();
      window.scroll(0, 0);
    });
  }

  get checkedFilterNotification(): boolean {
    return this.submissionStatusFields.length > this.cachedStatusFields.length ? true : false;
  }

  get cachedStatusFields(): Array<string> {
    const cachedStatus = this.preferencesService.getPreference(LocalCache.STATUS_SUBMISSIONS);
    if (cachedStatus === undefined || cachedStatus === false) {
      // No cached value defined. Set all fields as checked.
      return this.submissionStatusFields;
    } else if (cachedStatus === '') {
      // Cached value is defined with no status marked.
      return [];
    }
    // Return array of string status that are marked.
    return (<string>cachedStatus).split(',');
  }

  get submissionStatusFields(): Array<string> {
    return SubmissionStatus.toIterable();
  }

  getFilterNotification(status: boolean) {
    this.filterNotification = status;
  }

  public isFiltered(): boolean {
    return this.filter.status.length < this.submissionStatusFields.length;
  }
}
