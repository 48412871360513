
import { SelectOption } from '../model/select.option';

export enum SubmissionStatus {
  ACTIVE = 'ACTIVE',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  WITHDRAWN = 'WITHDRAWN',
  DELETED = 'DELETED'
  // MAJOR_REVISION = 'MAJOR_REVISION',
  // MINOR_REVISION = 'MINOR_REVISION'
}

export const SubmissionStatusStyles = Object.freeze({
  'ACCEPTED': Object.freeze({
    'fontSet': 'fas',
    'fontIcon': 'fa-thumbs-up',
    'color': '#99FF99'
  }),
  'ACTIVE': Object.freeze({
    'fontSet': 'fas',
    'fontIcon': 'fa-check',
    'color': '#FFFF55'
  }),
  'PENDING': Object.freeze({
    'fontSet': 'fas',
    'fontIcon': 'fa-exclamation',
    'color': '#FAB65C'
  }),
  'REJECTED': Object.freeze({
    'fontSet': 'fas',
    'fontIcon': 'fa-thumbs-down',
    'color': '#FFBDBD'
  }),
 'WITHDRAWN': Object.freeze({
    'fontSet': 'fas',
    'fontIcon': 'fa-trash-alt',
    'color': '#DDDDDD'
  }),
  'DELETED': Object.freeze({
    'fontSet': 'fas',
    'fontIcon': 'fa-window-close',
    'color': '#DDDDDD'
  })
});

export namespace SubmissionStatus {
  export function toIterable(): Array<string> {
    return Object.keys(SubmissionStatus).filter(key => typeof SubmissionStatus[key] !== 'function' && key !== SubmissionStatus.DELETED);
  }

  export function toString(status: SubmissionStatus): string {
    return `status.${status}`;
  }

  export function toClass(status: SubmissionStatus) {
    return status.toLowerCase();
  }

  export function toSelectable(): Array<SelectOption> {
    return SubmissionStatus.toIterable().map(value => ({
      id: value,
      value: SubmissionStatus[value]
    }));
  }

  export function icon(status: SubmissionStatus): { fontSet: string, fontIcon: string }  {
    switch (status) {
      case SubmissionStatus.PENDING:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-exclamation'
        };
      case SubmissionStatus.ACTIVE:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-check'
        };
      // case SubmissionStatus.MINOR_REVISION:
      // case SubmissionStatus.MAJOR_REVISION:
      //   return {
      //     'fontSet': 'far',
      //     'fontIcon': 'fa-comment'
      //   };
      case SubmissionStatus.ACCEPTED:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-thumbs-up'
        };
      case SubmissionStatus.REJECTED:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-thumbs-down'
        };
      case SubmissionStatus.WITHDRAWN:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-trash-alt'
        };
      case SubmissionStatus.DELETED:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-window-close'
        };
      default:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-ellipsis-h'
        };
    }
  }
}
