import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {
  emailSent: boolean;
  recoverPasswordForm: FormGroup;

  constructor(private authService: AuthService, private fb: FormBuilder) { }

  ngOnInit() {
    this.initForm();
  }

  submit() {
    if (this.recoverPasswordForm.valid) {
      this.authService.requestPasswordReset(this.recoverPasswordForm.value).subscribe(
        response => this.emailSent = true,
        error => console.error(error.message)
      );
    }
  }

  private initForm() {
    this.recoverPasswordForm = this.fb.group({
      login: ['', [Validators.required, this.loginValidator]]
    });
  }

  loginValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const loginIsNumber = !isNaN(Number(control.value));
    if (loginIsNumber) {
      return null; // Allow login if is id.
    }

    // Check if login is a valid email.
    return Validators.email(control);
  }

}
