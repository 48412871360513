import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { MatDialog } from '@angular/material/dialog';
import { FormSessionDialogComponent } from 'src/app/component/form-session-dialog/form-session-dialog.component';
import { GeneralSessionDialogComponent } from 'src/app/component/general-session-dialog/general-session-dialog.component';
import { FormSubSessionDialogComponent } from 'src/app/component/sub-session-dialog/sub-session-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { Event } from 'src/app/model/event';
import { NotificationService } from 'src/app/service/notification.service';
import { WeekViewHourSegment } from 'calendar-utils';
import * as moment from 'moment';
import { FileRules } from 'src/app/model/file.rules';
import { File } from 'src/app/model/file';
import { Session } from 'src/app/model/session';
import { Submission } from 'src/app/model/paper';
import { CalendarEventTimesChangedEventType } from 'src/app/enum/calendar.changed.type';
import * as _ from 'lodash';
import * as dayjs from 'dayjs';
import { calendarTimezone } from 'src/app/enum/calendar.timezone';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TimezoneService } from 'src/app/service/timezone.service';

interface ExtendedCalendarEventSessions extends CalendarEvent {
  event: any;
  title: string;
  subtitle: string;
  url: string;
  room: string;
  submissions: Array<Submission>;
}

interface calendarTimeZone {
  id: string;
  value: string;
  offset: number;
}

interface ExtendedCalendarEventSessions extends CalendarEventTimesChangedEvent {
}

function floorToNearest(amount: number, precision: number) {
  return Math.floor(amount / precision) * precision;
}

function ceilToNearest(amount: number, precision: number) {
  return Math.ceil(amount / precision) * precision;
}

const HEIGHT_ROW_HOUR: number = 100;

@Component({
  selector: 'app-calendar-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss']
})
export class CalendarViewComponent implements OnInit {
  event: Event;
  submissions: Array<Submission>;
  showAlocated: Boolean = false;
  viewDate: Date = new Date();
  events: ExtendedCalendarEventSessions[] = [];
  view: CalendarView = CalendarView.Week;
  activeDayIsOpen = true;
  weekStartsOn: 0 = 0;
  updatingSessions: boolean = true;
  hasSingleClick: Boolean = false;
  language = localStorage.getItem('language');

  dragToCreateActive = false;

  public addSubmissionsMode = false;
  public sessionSubmissionMode;

  showFiller = false;

  public viewType;

  displayedColumns: string[] = ['starts', 'ends', 'track', 'title', 'room', 'chair', 'submissions', 'edit', 'delete'];
  tableDataSource: MatTableDataSource<Session>;

  private isTimeZoneOfBrowser: boolean = false;
  private sessionsWereUpdated: boolean = false;

  public timeZoneForm: FormGroup;
  public listTimeZones: Array<any>;
  private timeZoneSelected: calendarTimeZone;

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-times"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleSession('delete', event);
      },
    }
  ];

  constructor(
    private adminService: AdminService,
    private eventService: EventsService,
    public notificationService: NotificationService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    public fb: FormBuilder,
    private timezoneService: TimezoneService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.addSubmissionsMode = false;
      this.route.params.subscribe(params => {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.viewDate = this.event.eventInfo.beginAt.toDate();
          this.viewType = params.viewType;
          this.eventService.getSessionsByEvent(event.id).subscribe(sessions => {
            this.event.sessions = sessions;
          } , () => {},
          () => {
            this.initFormTimeZone(this.event);
            this.loadSessionsInEventTimeZone();
            this.tableDataSource = new MatTableDataSource<Session>(this.event.sessions);
            if (this.event.sessions.length > 0) {
              const earlierDate: dayjs.Dayjs = this.getEarlierSession(this.event.sessions, this.event);
              this.scrollViewWeekCalendar(earlierDate);
            }
            this.updatingSessions = false;
          });
        });
      });
    });
  }

  private initFormTimeZone(event: Event): void {
    const eventTimeZone: string = event.eventInfo.timeZone;
    this.listTimeZones = calendarTimezone.toSelectable(eventTimeZone, moment.tz.guess());

    let timezone: string;
    if (!!eventTimeZone) {
      timezone = 'event';
    } else {
      timezone = 'browser';
    }

    this.timeZoneForm = this.fb.group({
      calendarTimeZone: [timezone]
    });
    this.timeZoneSelected = this.listTimeZones.find(item => item.id === timezone);
  }

  private getEarlierSession(sessions: any, event: Event): dayjs.Dayjs {
    const eventBeginAt: dayjs.Dayjs = dayjs(event.eventInfo.beginAt);
    const calendarBeginAt: dayjs.Dayjs = dayjs(eventBeginAt).startOf('week');
    const calendarEndAt: dayjs.Dayjs = dayjs(eventBeginAt).endOf('week');
    let time: dayjs.Dayjs = null;
    for (const session of sessions) {
      const start = dayjs(this.timezoneService.timeZoneConverter(session.start.toDate(), this.offsetEventTimezone));
      if(start.isBefore(calendarEndAt) && (start.isAfter(calendarBeginAt) || start.isSame(calendarBeginAt)) ) {
        if (!time) {
          time = session.start;
        } else if (session.start.isBefore(time)) {
          time = session.start;
        }
      }
    }
    return time ?? dayjs();
  }

  private scrollViewWeekCalendar(date: dayjs.Dayjs) {
    const earliestSessionHour: number = this.timezoneService.timeZoneConverter(date.toDate(), this.offsetEventTimezone).getHours();

    const containerWeekView: HTMLDivElement = document.querySelector('.container-week-view');
    containerWeekView.scrollTo({
      top: earliestSessionHour * HEIGHT_ROW_HOUR, // page scroll to the earliest session
      left: 0,
      behavior: 'smooth'
    });
  }

  fileUploaded(trackFile: FileRules, submission: Submission): File {
    return submission.files.find(file => file.trackFile.name === trackFile.name);
  }

  private loadSessionsInEventTimeZone(): void {
    const offsetEventTimeZone: number = this.offsetEventTimezone;
    this.loadSessions(this.event, offsetEventTimeZone);
  }

  loadSessions(event: Event, utcOffset: number = null) {
    setTimeout(() => {
      const cloneEvent: Event = _.cloneDeep(event);
      this.events = cloneEvent.sessions.map(iSession => {
        const session = iSession as unknown as ExtendedCalendarEventSessions;
        session.title = iSession.title;
        session.subtitle = iSession.subtitle;
        session.start = moment(iSession.start.toJSON()).toDate();
        session.end = moment(iSession.end.toJSON()).toDate();

        if (utcOffset != null) {
          session.start = this.timezoneService.timeZoneConverter(session.start, utcOffset);
          session.end = this.timezoneService.timeZoneConverter(session.end, utcOffset);
        }

        session.submissions = iSession.submissions;
        session.draggable = true;
        session.actions = this.actions,
        session.resizable = {
          beforeStart: true,
          afterEnd: true,
        };
        session.color = {
          primary: '#FFFFFF',
          secondary: iSession.backgroundColor,
        };
        return session;
      });
      this.refresh();
    });
  }

  private eventUpdateSchedule(start: Date, end: Date, sessionID: number): void {
    this.event.sessions = this.event.sessions.map((session: Session) => {
      if (session.id === sessionID) {
        return {
          ...session,
          start: dayjs(start),
          end: dayjs(end),
        };
      }
      return session;
    });
  }

  public changeViewTimeZone(id: string): void {
    this.timeZoneSelected = this.listTimeZones.find(item => item.id === id);

    this.timeZoneSelected.id === 'browser' ? this.isTimeZoneOfBrowser = true : this.isTimeZoneOfBrowser = false;

    if (this.sessionsWereUpdated) {
      this.loadSessions(this.event, this.timeZoneSelected.offset);
      this.sessionsWereUpdated = false;
    } else {
      this.loadSessions(this.event, this.timeZoneSelected.offset);
    }
  }

  public get offsetEventTimezone(): number {
    const nameTimeZone: string = this.event.eventInfo.timeZone;
    const offsetTimeZone: number = moment.tz(nameTimeZone).utcOffset();

    return this.timeZoneSelected?.offset ?? offsetTimeZone;
  }

  handleSession(action: string, session: any): void {
    if ( action === 'create/edit' ) {
      const dialogRef = this.dialog.open(GeneralSessionDialogComponent, {
        height: '45rem',
        width: '45rem',
        panelClass: 'my-custom-dialog-class',
        disableClose: true,
        data: {
          params: {
            session: session,
            eventTracks: this.event.tracks,
            showAlocated: this.showAlocated,
            saveSession: (saveFromDialog) => {
              this.updateSession(saveFromDialog);
            }
          }
        }
      });
      dialogRef.componentInstance.changeAlocated.subscribe(result => {
        this.showAlocated = result as boolean;
      });

      dialogRef.backdropClick().subscribe(result => {
        dialogRef.componentInstance.save();
      });
    } else if (action === 'delete') {
      if (session.id) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            title: 'admin.event.sessions.title-delete',
            content: ''
          }
        }).afterClosed().subscribe(confirmed => {
          if (confirmed) {
            this.deleteSession(session);
          }
        });
      } else {
          this.events = this.events.filter((iEvent) => iEvent !== session);
      }
    } else if (action === 'clicked') {
      if(this.hasSingleClick){
        this.eventService.getSessionsByEvent(this.event.id).subscribe(sessions => {
          next: {
            session.chair = sessions.find(item => item.id === session.id).chair;
          }
          complete: this.openFormSessionDialog(session);
        });

      } else {
        this.hasSingleClick = true;
        setTimeout(() => {
          this.hasSingleClick = false;
        }, 250);
      }
    }
  }

  private openFormSessionDialog(session): void {
    const dialogRef = this.dialog.open(GeneralSessionDialogComponent, {
          height: '45rem',
          width: '45rem',
          panelClass: 'my-custom-dialog-class',
          disableClose: true,
          data: {
            params: {
              session: session,
              eventTracks: this.event.tracks,
              showAlocated: this.showAlocated,
              eventTimeZone: this.event.eventInfo.timeZone,
              saveSession: (saveFromDialog) => {
                this.updateSession(saveFromDialog);
              }
            }
          }
        });
        dialogRef.componentInstance.changeAlocated.subscribe(result => {
          this.showAlocated = result as boolean;
        });

        dialogRef.backdropClick().subscribe(result => {
          dialogRef.componentInstance.save();
        });

        dialogRef.afterClosed().subscribe(() => {
          this.loadSessions(this.event, this.timeZoneSelected.offset);
        });
  }

  eventTimesChanged({ event, newStart, newEnd }: ExtendedCalendarEventSessions): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        event.start = newStart;
        event.end = newEnd;
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.updateSession(event, false);
  }

  updateSession(eventSession, isTimeZoneConverted = true) {
    let tempChair = eventSession.chair;
    eventSession.chair = eventSession.chair?.id ?? null;
    this.updatingSessions = true;

    const cloneEventSession = _.cloneDeep(eventSession);
    if (!this.isTimeZoneOfBrowser && !isTimeZoneConverted) {
      const sessionStart = cloneEventSession.start;
      const sessionEnd = cloneEventSession.end;
      cloneEventSession.start = this.timezoneService.convertTimeToBrowserTimezone(sessionStart, null, this.offsetEventTimezone);
      cloneEventSession.end = this.timezoneService.convertTimeToBrowserTimezone(sessionEnd, null, this.offsetEventTimezone);
    }

    if (eventSession.id) {
      this.eventService.editSession(cloneEventSession, eventSession.id).subscribe(session => {
        this.updateEventSession(session);
        this.events = this.events.map(iEvent => {
          if (iEvent.id === session.id) {
            return {
              ...iEvent,
              title: session.title,
              subtitle: session.subtitle,
              url: session.url,
              room: session.room,
              chair: tempChair,
              submissions: session.submissions,
              backgroundColor: session.backgroundColor,
              color : {
                primary: '#FFFFFF',
                secondary: session.backgroundColor,
              },
            };
          }
          return iEvent;
        });
        this.updatingSessions = false;
        this.refresh();
      });
    } else {
      this.eventService.createSession(cloneEventSession).subscribe(session => {
        this.event.sessions.push(session);
        this.events = this.events.map(iEvent => {
          if (iEvent === eventSession) {
            return {
              ...iEvent,
              id: session.id,
            };
          }
          return iEvent;
        });
        this.updatingSessions = false;
      });
    }
  }

  private updateEventSession(iEvent) {
    if (this.isTimeZoneOfBrowser)
      this.sessionsWereUpdated = true;

    this.event.sessions = this.event.sessions.map((session: Session) => {
      if (session.id === iEvent.id) {
        return {
          ...session,
          title: iEvent.title,
          subtitle: iEvent.subtitle,
          url: iEvent.url,
          room: iEvent.room,
          start: iEvent.start,
          end: iEvent.end,
          submissions: iEvent.submissions,
          backgroundColor: iEvent.backgroundColor,
        };
      }
      return session;
    });
  }

  private deleteSessionFromEvent(sessionID: number): void {
    this.event.sessions = this.event.sessions.filter((session: Session) => session.id !== sessionID);
  }

  deleteSession(session) {
    this.eventService.deleteSession(session.id, this.event).subscribe(() => {
      next: this.events = this.events.filter((iEvent) => iEvent !== session);
      complete: {
        this.deleteSessionFromEvent(session.id);
        this.refresh();
      }
    });
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  onVizualizationChange(value: CalendarView) {
    this.setView(value);
  }

  dayClicked({ date, events }: { date: Date; events: ExtendedCalendarEventSessions[] }): void {
    if (moment(this.viewDate).isSame(date, 'month')) {
        if (
          (moment(this.viewDate).isSame(date, 'day') && this.activeDayIsOpen === true) ||
          events.length === 0
        ) {
          this.activeDayIsOpen = false;
        } else {
          this.activeDayIsOpen = true;
        }
        this.viewDate = date;
      }
  }

  startDragToCreate(
  segment: WeekViewHourSegment,
  mouseDownEvent: MouseEvent,
  segmentElement: HTMLElement
  ) {
    if(this.hasSingleClick){
      const dragToSelectEvent: ExtendedCalendarEventSessions = {
        id: null,
        event: this.event.id,
        title: 'New session',
        subtitle: '',
        newStart: segment.date,
        type: CalendarEventTimesChangedEventType.Drag,
        url: '',
        room: '',
        start: segment.date,
        submissions: [],
        color : {
          primary: '#FFFFFF',
          secondary: '#BFE7FF',
        },
        end: (moment(segment.date).add(30, 'minutes').add(0, 'days')).toDate(), /**Add 30 minutes in a new session by default*/
        meta: {
          tmpEvent: true,
        },
        draggable: true,
        actions: this.actions,
        resizable: {
            beforeStart: true,
            afterEnd: true,
          },
      };
      this.events = [...this.events, dragToSelectEvent];
      const segmentPosition = segmentElement.getBoundingClientRect();
      this.dragToCreateActive = true;
      const today = moment();
      const endOfView = today.endOf('week');

      this.updateSession(dragToSelectEvent, false);

      fromEvent(document, 'mousemove')
      .pipe(
        finalize(() => {
          delete dragToSelectEvent.meta.tmpEvent;
          this.dragToCreateActive = false;
          this.refresh();
        }),
        takeUntil(fromEvent(document, 'mouseup'))
      )
      .subscribe((mouseMoveEvent: MouseEvent) => {
        const minutesDiff = ceilToNearest(
          mouseMoveEvent.clientY - segmentPosition.top,
          30
        );

        const daysDiff =
          floorToNearest(
            mouseMoveEvent.clientX - segmentPosition.left,
            segmentPosition.width
          ) / segmentPosition.width;

        const newEnd = moment(segment.date).add(minutesDiff, 'minutes').add(daysDiff, 'days');
        if (newEnd > moment(segment.date) && newEnd < endOfView) {
          dragToSelectEvent.end = newEnd.toDate();
        }
        this.refresh();
      });
    }else{
      this.hasSingleClick = true;
      setTimeout(()=>{
        this.hasSingleClick = false;
      },250);
    }
  }

  private refresh() {
    this.events = [...this.events];
    this.cdr.detectChanges();
  }
}
