import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Submission } from 'src/app/model/paper';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { EmailTrigger } from 'src/app/enum/email.trigger';
import { NotificationEmail } from 'src/app/model/notificationEmail';
import { SelectOption } from 'src/app/model/select.option';
import { TranslateService } from '@ngx-translate/core';
import { Event } from 'src/app/model/event';
import { NotificationService } from 'src/app/service/notification.service';

@Component({
  selector: 'app-notify-pendings',
  templateUrl: './notify-pendings.component.html',
  styleUrls: ['./notify-pendings.component.scss']
})
export class NotifyPendingsComponent implements OnInit {

  private submissionsIDPending: Array<number>;
  public submissionsPending: Array<Submission>;

  public preview: NotificationEmail;
  private emailTriggerVariables: Array<SelectOption> = [];

  private eventID: number;
  public event: Event;

  constructor(
    private route: ActivatedRoute,
    private submissionsService: SubmissionsService,
    private adminService: AdminService,
    private eventsService: EventsService,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.initComponent();
  }

  private initComponent(): void {
    this.eventID = Number(this.route.snapshot.queryParamMap.get('eventID'));

    this.submissionsIDPending = this.getSubmissionsIDPending();

    this.eventsService.getEvent(this.eventID).subscribe(e => {
      next: this.event = e;
      complete: {
        this.getPreviewEmail();
        this.getSubmissions(this.submissionsIDPending);
      };
    });
  }

  private getSubmissionsIDPending(): Array<number> {
    const ids = this.route.snapshot.queryParamMap.get('ids');

    if (ids) {
      return JSON.parse(ids);
    }
    return new Array<number>();
  }

  private getSubmissions(ids: Array<number>): void {
    forkJoin(
      ids.map((id: number) => this.submissionsService.getSubmission(id))
    ).subscribe((submissions: Array<Submission>) => this.submissionsPending = submissions);
  }

  private getPreviewEmail(): void {
    this.eventsService.getEventNotificationEmail(EmailTrigger.ON_MISSING_MANUSCRIPT)
      .subscribe(preview => {
        next: {
          this.preview = preview;
          this.getPreviewEmailVariables();
        }
        complete: {
          this.replaceTemplateEmailVariables(this.preview.subject, this.preview.content, this.event);
        }
      });
  }

  public notifyAuthors(): void {
    const submissionsIDS: Array<number> = this.submissionsPending.map((s: Submission) => s.id);

    this.adminService.notifyAuthors(submissionsIDS).subscribe({
      next: response => this.notificationService.notify('submissions.notify-authors.notification-sent'),
      error: err => this.notificationService.notifyError('submissions.notify-authors.notification-sent-failed')
    });
  }

  private getPreviewEmailVariables(): void {
    this.translateService.get(`admin.email-template.type.ON_MISSING_MANUSCRIPT.variables`).subscribe(types => {
      this.emailTriggerVariables = Object.keys(types).map(key => new SelectOption(key, types[key]));
    });
  }

  private replaceTemplateEmailVariables(subject: string, content: string, event: Event): void {
    const domain = window.location.origin;
    const variables = this.emailTriggerVariables.map(trigger => <string>trigger.id);
    const expression = new RegExp(variables.join('|'), 'gi');

    this.translateService.get(variables.map(v => `admin.email-template.variable-defaults.${v}`)).subscribe(e => {
      const variableDefaults = Object.keys(e).map(k => {
          const key = k.split('variable-defaults.')[1];

          let value;
          this.translateService.get(`admin.email-template.variable-defaults.${key}`,
          { domain: domain, event }).subscribe(item => {
            value = item;
          });
          return { key, value };
        });

      const y = {};
        variableDefaults.forEach(item => {
          y[item.key] = item.value;
        });

      this.preview.subject = subject.replace(expression, (matched) => y[matched]);
      this.preview.content = content.replace(expression, (matched) => y[matched]);
    });
  }
}
