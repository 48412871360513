import { ThemePalette } from '@angular/material/core';
import { Notification } from './notification';

export class NotificationSheetData {
    constructor(
      public notifications: Array<Notification>,
      public badgeColor: ThemePalette
    ) { }

    clearReadNotifications() {
      this.notifications = this.notifications.filter(item => !item.read);
    }

    clearAll() {
      this.notifications = [];
    }

    badgeValue() {
      const length = this.notifications.length;

      return length > 0 ? length : '';
    }

    get hasUnreadNotifications() {
      return this.notifications.filter(item => !item.read).length > 0;
    }

    insert(notification: Notification): number {
      return this.notifications.push(notification);
    }
}
