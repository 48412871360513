import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../model/user';

@Pipe({
  name: 'userFilter'
})
export class UserFilterPipe implements PipeTransform {

  transform(users: Array<User>, selectedUsers: Array<User>, forbiddenUsers: Array<User>): Array<User> {
    if (!users || !selectedUsers) {
      return users;
    }

    // filter users array, items which match and return true will be
    // kept, false will be filtered out
    return users.filter(item => {
      return !selectedUsers.find(e => e.id === item.id) && !forbiddenUsers.find(e => e.id === item.id);
    });
  }

}
