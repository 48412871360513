import { Exclude, Type } from 'class-transformer';

export class Pagination<T> {
  @Exclude()
  private type: Function;

  @Type(options => {
    return (options.newObject as Pagination<T>).type;
  })
  items: Array<T>;

  count: number;
  page: number;

  constructor(type: Function) {
    this.type = type;
  }
}
