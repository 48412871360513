import { Component, OnInit } from '@angular/core';
import { Event } from '../../../../model/event';
import { AdminService } from '../../../../service/admin.service';
import { EventsService } from '../../../../service/events.service';
import { EventTopic, SHOW_ALL_EVENT_TOPIC_OPTIONS } from '../../../../model/eventTopic';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl ,ValidatorFn } from '@angular/forms';
import { SelectOption } from 'src/app/model/select.option';
import { NotificationService } from 'src/app/service/notification.service';
import { SHOW_ALL_PAGINATION_OPTIONS } from '../../../../model/pagination.options';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-event-topics',
  templateUrl: './event-topics.component.html',
  styleUrls: ['./event-topics.component.scss']
})
export class EventTopicsComponent implements OnInit {
  event: Event;
  eventOptions: Array<SelectOption>;
  topicOptions: Array<SelectOption>;

  inheritTopicsFromEvent: Array<EventTopic>;
  deletedTopics: Array<EventTopic> = [];
  selectedEventName: string;

  topic: string;
  addTopic: string;
  newTopicControl: AbstractControl;
  topicForm: FormGroup;
  eventForm: FormGroup;

  saved = true;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private adminService: AdminService,
    private eventsService: EventsService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.initForm();
          this.initEventForm();
          this.loadData();
        });
      } else {
        this.initForm();
        this.initEventForm();
        this.loadData();
      }
    });
  }

  initForm() {
    this.topicForm = this.fb.group({
      topics: [[], Validators.required],
      eventParent: [],
      newTopic: ['', [Validators.minLength(2)]],
      topicParent: [undefined],
      sortBy: ['ascending']
    });

    this.newTopicControl = this.topicForm.get('newTopic');
    this.topicForm.get('topics').valueChanges.subscribe( v => {
      this.saved = false;
    });
  }

  loadData() {
    this.eventsService.getEventTopics(this.event.id, SHOW_ALL_EVENT_TOPIC_OPTIONS).subscribe(topics => {
      this.topicForm.patchValue({topics});
      this.updateTopicOptions(this.topicForm.value.topics);
      this.saved = true;
    });

    this.eventsService.getAllEvents(SHOW_ALL_PAGINATION_OPTIONS).subscribe(pagination => {
      this.eventOptions = pagination.items.filter(e => e.id !== this.event.id).map(event => ({ id: event.id, value: event.name }));
    });
  }

  pickEvent(eventId: number) {
    this.eventsService.getEventTopics(eventId).subscribe(topics => {
      topics.forEach( topic => topic.children.map( child => child.parent = topic));
      this.inheritTopicsFromEvent = topics;
      this.eventsService.getEvent(eventId).subscribe(event => this.selectedEventName = event.name);
    });
  }

  updateTopicOptions(topics: EventTopic[]) {
    this.saved = false;
    const oldParentTopic = this.topicForm.value.topicParent;
    this.topicOptions = [{ id: undefined, value: 'None'}];
    this.topicOptions.push(...topics.map( topic => ({ id: topic, value: topic.name})));

    if (oldParentTopic) {
      const findFunction = ( y => oldParentTopic.id ? ((x) => x.id === y.id) : ((x) => x.name === y.name));
      this.topicForm.patchValue({ topicParent: topics.find(findFunction(oldParentTopic)) });
    }
  }

  pushInheritedTopics(topics) {
    const currentTopics = this.topicForm.value.topics;
    currentTopics.push(...topics.filter(e => e.active).map(e => {
      const parentTopic = new EventTopic(e.name);
      parentTopic.children = e.children.filter(sub => sub.active).map(sub => new EventTopic(sub.name));

      return parentTopic;
    }));
    this.topicForm.patchValue({ topics: currentTopics });
    this.updateTopicOptions(this.topicForm.value.topics);
    this.notificationService.notify('admin.event.topics.added-topics');
    this.markAllInheritedTopics(false, topics);
  }

  markAllInheritedTopics(mark: boolean, topics, onlyValids = false) {
    this.saved = false;
    topics.forEach(item => {
      if (!onlyValids) {
        item.active = mark;
      } else {
        if (item.children.length > 0) {
          item.active = mark;
        }
      }

      if (item.children) {
        item.children.forEach(sub => sub.active = mark);
      }
    });
  }

  markParent(topic: EventTopic, parent: EventTopic) {
    this.saved = false;
    if (topic.active && !parent.active) {
      parent.active = true;
    } else if (!parent.children.some(x => x.active)) {
      parent.active = false;
    }
  }

  toggleVisibility(item: EventTopic, parent?: EventTopic) {
    item.active = !item.active;

    if (parent) {
      this.markParent(item, parent);
    } else {
      this.markAllInheritedTopics(item.active, item.children);
    }
  }

  pushNewTopic(topic_name: string, parent: EventTopic = null) {
    if (this.newTopicControl.valid && this.newTopicControl.value) {
      const newTopics = topic_name.split(';').map(t => t.trim()).filter(t => t.length).map(t => new EventTopic(t));

      newTopics.forEach(topic => {
        topic.active = true;
        if (parent) {
          parent.children.push(topic);
          this.markParent(topic, parent);
        } else {
          const currentTopics = this.topicForm.value.topics;
          currentTopics.push(topic);

          this.topicForm.patchValue({ topics: currentTopics });
          this.updateTopicOptions(this.topicForm.value.topics);
        }
      });

      this.saved = false;
      this.topicForm.patchValue({ newTopic: '' });
    }
  }

  removeTopic(topic: EventTopic, parent: EventTopic = null) {
    if ( parent ) {
      this.removeTopicAndUpdate(topic, parent.children);
    } else {
      const arrayToDelete = this.topicForm.value.topics;
      if (!topic.children.length) {
        this.removeTopicAndUpdate(topic, arrayToDelete);
      } else {
        this.dialog.open(ConfirmationDialogComponent, {
          data: {
            title: 'admin.event.topics.confirm-delete-parent-topic-dialog',
            content: 'admin.event.topics.warning-delete-subtopics'
          }
        }).afterClosed().subscribe(confirmed => {
          if (confirmed) {
            this.removeTopicAndUpdate(topic, arrayToDelete);
        }});
      }
    }
  }

  removeTopicAndUpdate(topic, arrayToDelete) {
    const index = arrayToDelete.findIndex(obj => obj === topic);
    const obj_deleted = arrayToDelete.splice( index, 1)[0];
    this.deletedTopics.push(obj_deleted);
    this.updateTopicOptions(this.topicForm.value.topics);
  }

  submit() {
    this.submitSettingTopics();
    const formTopics: EventTopic[] = this.topicForm.value.topics.slice();
    // fixing the property order in topics and subtopics to save to back
    formTopics.forEach((topic, index) => {
      topic.order = index;
      topic.children.forEach((sub, subIndex) => sub.order = subIndex);
    });

    formTopics.push(...this.deletedTopics
      .filter(topic => topic.id)
      .map( topic => {
        topic.deleted = true;
        return topic;
      }));
    this.deletedTopics = [];

    this.adminService.progressBar.start();
    this.notificationService.notify('admin.event.topics.updating', { params: { name: this.event.name } });

    this.eventsService.putEventTopics(this.event.id, formTopics).subscribe(topics => {
      this.event.topics = topics;
      this.topicForm.patchValue({topics: topics});
      this.notificationService.notify('admin.event.topics.updated', { params: { name: this.event.name } });
      this.updateTopicOptions(this.topicForm.value.topics);
      this.adminService.progressBar.stop();
      this.saved = true;
    });
  }

  sortBy(list, sortFunction: (a, b) => number) {
    list.sort(sortFunction);
    list.forEach(item => item.children.sort(sortFunction));
  }

  sortByCategory(a, b): number {
    if (a.isCategory > b.isCategory) {
      return 1;
    } else if (a.isCategory < b.isCategory) {
      return -1;
    } else {
      return 0;
    }
  }

  sortByName(list) {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'admin.event.topics.warning-sort-topics',
        content: ''
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        const sortByControl = this.topicForm.get('sortBy');
        this.saved = false;

        if (sortByControl.value === 'ascending') {
          this.sortBy(list, ((a, b) => {
            const category = this.sortByCategory(a, b);
            return category !== 0 ? category : a.name.localeCompare(b.name);
          }));
          sortByControl.setValue('descending');
        } else {
          this.sortBy(list, ((a, b) => {
            const category = this.sortByCategory(a, b);
            return category !== 0 ? category : b.name.localeCompare(a.name);
          }));
          sortByControl.setValue('ascending');
        }
    }});
  }

  drop(list, event) {
    this.saved = false;
    moveItemInArray(list, event.previousIndex, event.currentIndex);
  }

  private initEventForm(): void {
    this.eventForm = this.fb.group({
      name: [this.event.name],
      acronym: [this.event.acronym],
      uri: [this.event.uri],
      year: [this.event.year],
      eventType: [this.event.eventType.id],
      eventParent: [this.event.eventParent instanceof Event ? this.event.eventParent.id : this.event.eventParent],
      minTopics: [this.event.minTopics],
      maxTopics: [this.event.maxTopics]
    }, { updateOn: 'blur' });
    
    this.listenToTopicNumberChanges();
    (<FormControl>this.eventForm.get('minTopics')).setValidators(this.topicRangeValidator);
    (<FormControl>this.eventForm.get('maxTopics')).setValidators(this.topicRangeValidator);
  }

  topicRangeValidator: ValidatorFn = (fg: FormGroup) => {
    const start = this.eventForm.get('minTopics').value;
    const end = this.eventForm.get('maxTopics').value;

    return start !== null && end !== null && start <= end
      ? null
      : { range: true };
  }

  private listenToTopicNumberChanges(): void{
    this.eventForm.get('maxTopics').valueChanges.subscribe(() => {
      this.saved = false;
    });
    this.eventForm.get('minTopics').valueChanges.subscribe(() => {
      this.saved = false;
    });
  }

  submitSettingTopics() {
    if (!this.eventForm.valid) {
      // To catch unvalidated min/max topics, confirm validity of topic controls.
      this.eventForm.get('minTopics').updateValueAndValidity();
      this.eventForm.get('maxTopics').updateValueAndValidity();
    }

    if (this.eventForm.valid) {
      const event = new Event(this.eventForm.value.name, this.eventForm.value.acronym);
      event.uri = this.eventForm.value.uri;
      event.year = this.eventForm.value.year;
      event.eventType = this.eventForm.value.eventType;
      event.eventParent = this.eventForm.value.eventParent ? this.eventForm.value.eventParent : null;

      event.minTopics = this.eventForm.value.minTopics;
      event.maxTopics = this.eventForm.value.maxTopics;

      this.eventsService.editEvent(this.event.id, event).subscribe(e => {
        this.adminService.selectedEvent = e;
        this.event = e;
        this.notificationService.notify('admin.event.topics.updated', { params: { name: this.event.name } });
      });
    } else {
      this.eventForm.markAllAsTouched();
    }
  }
}
