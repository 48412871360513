import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { TextInputComponent } from '../text-input/text-input.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NO_PAGINATION } from 'src/app/model/pagination.options';
import { SelectOption } from '../../model/select.option';
import { Country } from '../../model/country';
import { MatSelectChange } from '@angular/material/select';
import { SystemService } from 'src/app/service/system.service';
import { AdminService } from 'src/app/service/admin.service';
import { CountryPipe } from '../../pipe/country.pipe';

@Component({
  selector: 'app-select-input-flag',
  templateUrl: './select-input-flag.component.html',
  styleUrls: ['./select-input-flag.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectInputLoadFlagComponent),
    multi: true
  }]
})

export class SelectInputLoadFlagComponent extends TextInputComponent{
  @Input() indices = {
    internal: 'id',
    public: 'value',
    image: 'flagUrl'
  };

  @Input() prependPublic = '';
  @Output() selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();

  @Input() multiple = false;
  @Input() eventId: Number;

  @Input() compact = false;
  @Input() includeNoneOption = false;

  @Input() filterable = false;
  @Input() filterBy: string = 'name';
  filteredOptions: Array<Country>;
  @Input() options: Array<Country>;

  @Input() warning: boolean;

  /* eslint-disable constructor-super */
  /* @ts-ignore */
  constructor(
    public systemService: SystemService,
  ) {
    super();
  }

  loadFlags(): void {
    this.systemService.countries.subscribe(countries => {
      this.options = countries;
    });

    if (this.filterable && this.options) {
      // Create a new, mutable, copy of the options variable.
      this.filteredOptions = this.options.slice();
    } else {
      // Both variables are the same. A new array is not created.
      this.filteredOptions = this.options;
    }
  }

  onSelection($event: MatSelectChange): void {
    this.selectionChange.emit($event);
  }

  checkValidity(): string {
    if (this.formGroupController.controls[this.name].invalid && !!this.warning) {
      return 'warning';
    }
  }
}
