import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AdminService } from 'src/app/service/admin.service';
import { UserService } from 'src/app/service/user.service';
import { User } from 'src/app/model/user';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { MergeUsersRegister } from 'src/app/model/merge-users-register';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from 'src/app/service/notification.service';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { MatSort } from '@angular/material/sort';
import { InfoService } from 'src/app/service/info.service';
import { Submission, DeletedSubmission } from 'src/app/model/paper';
import { SubmissionHistoryComponent } from 'src/app/component/submission-history/submission-history.component';

@Component({
  selector: 'app-deleted-submissions',
  templateUrl: './deleted-submissions.component.html',
  styleUrls: ['./deleted-submissions.component.scss']
})
export class DeletedSubmissionsComponent implements OnInit {
  form: FormGroup;
  deletedSubmissions: Array<DeletedSubmission>;
  deletedSubmissionsDataSource = new MatTableDataSource<DeletedSubmission>();
  deletedTableColumns = ['id', 'previous-status', 'title', 'event', 'deleted-by', 'deleted-at', 'history', 'recovery'];

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.sort = sort;
    if (this.sort) {
      this.deletedSubmissionsDataSource.sort = this.sort;

      this.deletedSubmissionsDataSource.sortingDataAccessor = (submission: DeletedSubmission, header: string) => {
        switch (header) {
          case 'id': return submission.id;
          case 'previous-status': return submission.previousStatus;
          case 'title': return submission.title;
          case 'deleted-at': return submission.deletedAt.toString();
          case 'deleted-by': return submission.deletedBy.name;
          default: return submission.id;
        }
      };
    }
  }
  sort: MatSort;

  constructor(
    public adminService: AdminService,
    public submissionsService: SubmissionsService,
    public notificationService: NotificationService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public infoService: InfoService
  ) { }

  ngOnInit(): void {
    this.getRegisters();
  }

  getRegisters(): void {
    this.submissionsService.getDeletedSubmissions().subscribe((deletedSubmissions) => {      
      this.deletedSubmissions = deletedSubmissions.sort((a, b) =>  a.deletedAt.isBefore(b.deletedAt) ? 1 : -1);
      this.deletedSubmissionsDataSource.data = this.deletedSubmissions;
    });
  }

  getHistory(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '55rem';
    dialogConfig.height = '30rem';
    dialogConfig.panelClass = 'my-custom-dialog-class';

    dialogConfig.data = { id };
    this.dialog.open(SubmissionHistoryComponent, dialogConfig);
  }

  recoverySubmission(submissionId: number, status: string) {
    const data = {
      title: 'admin.deleted-submissions.notify.dialog-title',
      content: ''
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        // this.adminService.progressBar.start();
        this.submissionsService.recoverySubmission(submissionId, status).subscribe(() =>
          {
            // this.adminService.progressBar.stop();
            this.notificationService.notify('admin.deleted-submissions.notify.done');
          },
          error => {
            // this.adminService.progressBar.stop();
          },
          () => {
            this.getRegisters();
          }
        );
      }
    });
  }
}
