import { Affiliation } from './affiliation';

export class Registration {
  constructor(
    public token: string,
    public uid: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public password: string,
    public confirmPassword: string,
    public affiliation: number,
    public country: string
  ) { }
}
