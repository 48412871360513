import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Event } from '../../model/event';
import { SystemService } from 'src/app/service/system.service';

@Component({
  selector: 'app-event-search',
  templateUrl: './event-search.component.html',
  styleUrls: ['./event-search.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(200)]),
      transition(':leave', animate(200, style({ opacity: 0 })))
    ])
  ]
})
export class EventSearchComponent implements OnInit {
  @Input() formGroupController: FormGroup;
  @Input() name: string;
  @Input() placeholder = 'forms.fields.event-search';

  @Input() selectedValueOnly = false;

  stateCtrl = new FormControl('', Validators.required);
  filteredEvents: Observable<Event[]>;
  @Output() selectedEvents: Event[] = [];

  constructor(
    private systemService: SystemService
  ) { }

  ngOnInit() {
    this.stateCtrl = new FormControl('', Validators.required);

    if (this.selectedValueOnly) {
      this.formGroupController.get(this.name).setValue(this.selectedEvents[0]);
    } else {
      this.formGroupController.get(this.name).setValue(this.selectedEvents);
    }

    // Get events according to input
    this.stateCtrl.valueChanges
      .pipe(
        debounceTime(700),
        distinctUntilChanged()
      ).subscribe(value => {
        if (value.length > 0) {
          this.filteredEvents = this.systemService.searchEvent(value);
        } else {
          this.filteredEvents = undefined;
        }
      });
  }

  displayFn(users?: Event[]): string | undefined {
    return undefined; // Show nothing on input
  }

  selectEvent(event: Event, { option }: MatAutocompleteSelectedEvent) {
    if (this.selectedValueOnly) {
      this.selectedEvents = [event];
      this.formGroupController.get(this.name).setValue(this.selectedEvents[0]);
    } else {
      this.selectedEvents.push(event);
    }

    option.deselect();
  }
}
