import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { LandingComponent } from '../landing.component';
import { NotificationService } from 'src/app/service/notification.service';

@Component({
  selector: 'app-activate-email',
  templateUrl: './activate-email.component.html',
  styleUrls: ['./activate-email.component.scss']
})
export class ActivateEmailComponent implements OnInit {
  form: FormGroup;
  sentEmail = false;

  constructor(
    private container: LandingComponent,
    private userService: UserService,
    private fb: FormBuilder,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  submit() {
    if (this.form.valid) {
      this.container.progressBar.start();

      this.userService.resendActivationEmail(this.form.value.email).subscribe(
        response => {
          this.sentEmail = true;
          this.container.progressBar.stop();
        },
        error => {
          if (error.status === 304) {
            this.notificationService.notify('errors.user-already-active');
          }
          this.container.progressBar.stop();
        }
      );
    }
  }

  private initForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }
}
