import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { FormFieldCategory } from 'src/app/enum/form-field-category';
import { CheckListField } from 'src/app/model/checklist-field';
import { Event } from 'src/app/model/event';
import { Review, Submission, SubmissionStatus } from 'src/app/model/paper';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { PublicationService, SubmissionCoverage } from 'src/app/service/publication.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import _ from 'lodash';
import { ReviewsConfigurationService } from 'src/app/service/reviews.configuration.service';
import { EventReviewConfiguration } from 'src/app/model/eventReviewConfiguration';
import { STYLES_EXPORT_SUBMISSIONS, STYLES_DARK_MODE_EXPORT_SUBMISSIONS } from 'src/app/utils/styles';
import { SVG } from 'src/app/utils/svg';
import { DICT_THEME } from 'src/app/model/profile';

interface SubmissionsObj {
  submission: Submission;
  covers: SubmissionCoverage[];
}

@Component({
  selector: 'app-export-submissions',
  templateUrl: './export-submissions.component.html',
  styleUrls: ['./export-submissions.component.scss']
})
export class ExportSubmissionsComponent implements OnInit {
  public event: Event;
  public submissions: SubmissionsObj[];
  public checkListFields: CheckListField[];

  public SubmissionStatus = SubmissionStatus;

  public eventReviewConfig: EventReviewConfiguration;

  @ViewChild('allSubmissions') allSubmissions: ElementRef;

  constructor(
    private submissionService: SubmissionsService,
    private adminService: AdminService,
    private publicationService: PublicationService,
    private eventsService: EventsService,
    private reviewConfigurationService: ReviewsConfigurationService
  ) { }

  public ngOnInit(): void {
    setTimeout(() => {
      this.adminService.progressBar.start();
      this.event = this.adminService.selectedEvent;
  
      if (!this.event) {
        this.getEvent();
        return;
      }
      
      this.getData();
    });
  }

  private getData(): void {
    this.publicationService.getPublicationFields(this.event.id)
      .subscribe(fields => this.checkListFields = fields);

    this.reviewConfigurationService.getReviewsConfiguration(this.event.id)
      .subscribe(config => this.eventReviewConfig = config);
    
    let submissionsObj: SubmissionsObj[] = [];
    this.submissionService.getSubmissionsByEventFull(this.event.id, { detailed: true })
      .subscribe({
        next: (submissions: Submission[]) => submissionsObj = this.buildSubmissionsObj(submissions),
        complete: () => this.buildSubmissionsReview(submissionsObj)
      });
  }

  private buildSubmissionsObj(submissions: Submission[]): SubmissionsObj[] {
    return submissions.map(submission => {
      submission.event = this.event;
      return {
        submission,
        covers: this.getSubmissionCover(submission)
      }
    });
  }

  private buildSubmissionsReview(submissionsObj: SubmissionsObj[]): void {
    const requests: Subscription[] = [];

    requests.push(forkJoin([
      this.eventsService.getFormReview(this.event.id),
      this.eventsService.getFormSubmission(this.event.id)
    ]).subscribe(([reviewForm, submissionForm]) => {
      this.submissions = submissionsObj.map(s => {
        if(submissionForm) {
          s.submission.formSubmission = submissionForm;
        }
        s.submission.reviews = s.submission.reviews.map(r => {
          if (r instanceof Review) {
            r.form = _.cloneDeep(reviewForm);
            r.form.fields = r.form.fields.map(f => {
              f.answers = r.answers?.filter(a => a?.formField === f.id);
              return f;
            });
          }
          return r;
        });
        return s;
      });
      this.adminService.progressBar.stop();
    }));
  }

  private getEvent(): void {
    this.adminService.getEvent().subscribe({
      next: (event: Event) => this.event = event,
      complete: () => this.getData()
    });
  }

  public getSubmissionCover(submission: Submission): SubmissionCoverage[] {
    return this.checkListFields.map(field => {
      switch (field.category) {
        case FormFieldCategory.AUTHOR:
          return {
            field,
            answer: submission.authorRegistration
          }
        case FormFieldCategory.COPYRIGHT:
          return {
            field,
            answer: submission.copyrightIEEE
          }
        case FormFieldCategory.CUSTOM:
          return {
            field,
            answer: submission.checkListCustom.find(c => c.fieldId === field.id)?.status
          }
      }
    });
  }

  public isPublicationReady(covers: SubmissionCoverage[]): boolean {
    return !covers.some(cover => cover.answer === false || cover.answer === undefined);
  }

  public exportHTML(): void {
    const content = this.allSubmissions.nativeElement.innerHTML;
    this.adminService.progressBar.start();

    const isDarkMode = document.body.classList.contains(DICT_THEME.DARK);

    const html = `
      <!doctype html>
      <html>
        <head>
          <meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1">
          <meta http-equiv="Content-Type" content="text/html;charset=UTF-8">
          <link rel="preconnect" href="https://fonts.googleapis.com">
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
          <link href="https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap" rel="stylesheet">

          <title>${this.event.name}</title>
          ${STYLES_EXPORT_SUBMISSIONS}
          ${isDarkMode ? STYLES_DARK_MODE_EXPORT_SUBMISSIONS : ''}
        </head>
        <body>
          <div class="show-all-submissions-container">
            <div class="show-all-submissions" id="showAll">
              ${this.replaceTags(content)}
            </div>
          </div>
        </body>
      </html>
    `;

    const blob = new Blob([html], {type: 'text/html'});
    const downloadURL = URL.createObjectURL(blob);
    this.downloadLink(downloadURL, `${this.event.name}.html`);
    this.adminService.progressBar.stop();
  }

  private downloadLink(downloadURL: string, name: string) {
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = name;
    link.click();
  }

  private replaceTags(content: string): string {
    const exclamationTriangleIcon = /<mat-icon\b[^>]*fonticon="fa-exclamation-triangle"[^>]*>(.*?)<\/mat-icon>/g;
    const checkIcon = /<mat-icon\b[^>]*fonticon="fa-check"[^>]*>(.*?)<\/mat-icon>/g;
    const matCheckboxChecked = /<mat-checkbox\b[^>]*checked[^>]*>(.*?)<\/mat-checkbox>/g;
    const matCheckboxGeneral = /<mat-checkbox\b[^>]*>(.*?)<\/mat-checkbox>/g;
    const buttonReview = /<button\b[^>]*class="[^"]*_button-review[^"]*"[^>]*>((?!<\/button>).)*<\/button>/gs;

    const checkboxNotChecked = '<input type="checkbox">';
    const checkboxChecked = '<input type="checkbox" checked>';

    const exclamationIconReplaced = this.replaceTag(content, exclamationTriangleIcon, SVG.WARNING_WHITE);
    const checkIconReplaced = this.replaceTag(exclamationIconReplaced, checkIcon, SVG.CHECK_WHITE);
    const matCheckboxCheckedReplaced = this.replaceTag(checkIconReplaced, matCheckboxChecked, checkboxChecked);
    const matCheckboxGeneralReplaced = this.replaceTag(matCheckboxCheckedReplaced, matCheckboxGeneral, checkboxNotChecked);
    const buttonReviewReplaced = this.replaceTag(matCheckboxGeneralReplaced, buttonReview, SVG.CHEVRON_DOWN);
    
    return buttonReviewReplaced;
  }

  private replaceTag(content: string, oldTag: RegExp, newTag: string): string {
    return content.replace(oldTag, newTag);
  }

}
