import { Validators, ValidatorFn } from '@angular/forms';
import { FormField } from './form.field';

export class FormFieldValidator {
  public static create(field: FormField): Array<ValidatorFn> {
    const validators: Array<ValidatorFn> = [];

    if (field.required) {
      validators.push(Validators.required);
    }

    if (field.min) {
      validators.push(Validators.minLength(field.min));
    }

    if (field.max) {
      validators.push(Validators.maxLength(field.max));
    }

    return validators;
  }
}
