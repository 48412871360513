import { Component, OnInit } from '@angular/core';
import { Event } from 'src/app/model/event';
import { map } from 'rxjs/operators';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-event-subconferences',
  templateUrl: './event-subconferences.component.html',
  styleUrls: ['./event-subconferences.component.scss']
})

export class AdminEventSubconferencesComponent implements OnInit {
  eventSubconferences: Array<Event>;
  event: Event;

  constructor(
    private adminService: AdminService,
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.getEventSubconferences(this.event.id);
        });
      } else {
        this.getEventSubconferences(this.event.id);
      }
    });
  }

  getEventSubconferences(event: number) {
    this.eventsService.getSubconferencesByEvent(event).subscribe(eventSubconferences => {
      this.eventSubconferences = eventSubconferences;
    });
  }
}
