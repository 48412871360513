import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-sidesheet',
  templateUrl: './sidesheet.component.html',
  styleUrls: ['./sidesheet.component.scss'],
  animations: [
    trigger('slideIn', [
      transition('void => *', [
        style({ transform: 'translateX(100%)' }),
        animate(200)
      ]),
      transition('* => void', [
        animate(200, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class SidesheetComponent {
  @Input() open: boolean;
  @Output() openChange = new EventEmitter();

  constructor() { }

  close(): void {
    this.open = false;
    this.openChange.emit(this.open);
  }
}
