import { Injectable } from '@angular/core';
import { Environment } from '../environment/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '../model/response';
import { plainToClass } from 'class-transformer';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/model/user';

import { Transform, Type } from 'class-transformer';
import * as dayjs from 'dayjs';
import { toDayjs } from '../model/baseModel';
import { SubmissionStatus } from '../enum/submission.status';
import { ReviewStatus } from '../enum/review.status';

export class AuthorCountries {
  public event: number;
  public track?: number;
  public status?: string;
  public items: Array<any>;
}

export class StatsData {
  public event: number;
  public items: Array<any>;
}

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  // BehaviorSubject to store the track status and allow components to subscribe to updates
  private trackStatusSubject = new BehaviorSubject<Array<any>>(null);
  trackStatus$ = this.trackStatusSubject.asObservable();

  // BehaviorSubject to store the countries and allow components to subscribe to updates
  private countryStatusSubject = new BehaviorSubject<Array<any>>(null);
  countryStatus$ = this.countryStatusSubject.asObservable();

  // BehaviorSubject to store the topics and allow components to subscribe to updates
  private topicStatusSubject = new BehaviorSubject<Array<any>>(null);
  topicStatus$ = this.topicStatusSubject.asObservable();
  
  // BehaviorSubject to store the topics and allow components to subscribe to updates
  private activeOrderStatusTopicSubject = new BehaviorSubject<String>(null);
  activeOrderStatusTopic$ = this.activeOrderStatusTopicSubject.asObservable();
  
  constructor(
    private http: HttpClient
  ) { }

  // Method to update the trackStatus
  setTrackStatus(data: Array<any>): void {
    this.trackStatusSubject.next(data);
  }

  setCountriesStatus(data: Array<any>): void {
    this.countryStatusSubject.next(data);
  }

  setTopicsStatus(data: Array<any>): void {
    this.topicStatusSubject.next(data);
  }

  setActiveOrderStatusTopicSubject(data: String): void {
    this.activeOrderStatusTopicSubject.next(data);
  }

  getAuthorsCountry(options, eventId: number): Observable<StatsData> {
    const params = { params: {} };

    if (options.submission_groups) {
      params.params['submission_groups'] = String(options.submission_groups);
    }

    if (options.tracks) {
      params.params['tracks'] = String(options.tracks);
    }

    return this.http.get<Response>(`${Environment.urls.API}/core/event/${eventId}/submission/statistics/authors/`, params)
      .pipe(map(v => plainToClass(StatsData, v.data as StatsData)));
  }

  getTracksBySubmissionStatus(eventId: number): Observable<StatsData> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${eventId}/submission/statistics/tracks/status`)
      .pipe(map(v => plainToClass(StatsData, v.data as StatsData)));
  }

  getTracksByCategories(eventId: number): Observable<StatsData> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${eventId}/submission/statistics/tracks/category`)
      .pipe(map(v => plainToClass(StatsData, v.data as StatsData)));
  }

  getTopicsBySubmissionStatus(options, eventId: number): Observable<StatsData> {
    const params = { params: {} };

    if (options.tracks) {
      params.params['tracks'] = String(options.tracks);
    }

    return this.http.get<Response>(`${Environment.urls.API}/core/event/${eventId}/submission/statistics/topics/status`, params)
      .pipe(map(v => plainToClass(StatsData, v.data as StatsData)));
  }

  getTopicsByTPCInterests(options, eventId: number): Observable<StatsData> {
    const params = { params: {} };

    if (options.tpc_groups) {
      params.params['tpc_groups'] = String(options.tpc_groups);
    }

    return this.http.get<Response>(`${Environment.urls.API}/core/event/${eventId}/submission/statistics/topics/tpc`, params)
      .pipe(map(v => plainToClass(StatsData, v.data as StatsData)));
  }
}
