import { Injectable } from '@angular/core';
import { TimeZone } from '../model/timeZone';
import * as cityTimezones from 'city-timezones';
import { Country } from 'country-state-city';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  constructor() { }

  public getAllTimeZones(): TimeZone[] {
    let timeZonesList: TimeZone[] = [];
    Country.getAllCountries().forEach(country => {
      country.timezones.forEach(timezone => {
        const infoCountry: TimeZone = { name: country.name, 
                                        timezone: timezone.zoneName, 
                                        offset: timezone.gmtOffsetName, 
                                        filter: `UTC${moment.tz(timezone.zoneName).format('Z')}, ${timezone.zoneName}, ${country.name}` 
                                      };
        timeZonesList.push(infoCountry);
      });
    });
    return timeZonesList;
  }

  public getTimeZone(city: string, state: string, country: string) {
    if (!!cityTimezones.findFromCityStateProvince(`${state} ${country}`)[0]) {
      return cityTimezones.findFromCityStateProvince(`${state} ${country}`)[0].timezone;
    } else if (!!cityTimezones.findFromCityStateProvince(`${city} ${country}`)[0]) {
      return cityTimezones.findFromCityStateProvince(`${city} ${country}`)[0].timezone;
    } else {
      return '';
    }
  }

  public convertTimeToBrowserTimezone(time: Date, timeZone: string, utcOffset?: number): Date {
    /*
    * This function is necessary because the time is save in the database in relation to the browser's time zone.
    */
    const offsetTimeZone: number = timeZone ? moment.tz(timeZone).utcOffset() : utcOffset;
    const offsetBrowserTimeZone: number = moment().utcOffset();
    let offset: number;

    if (offsetTimeZone >= 0) {
      offset = offsetBrowserTimeZone + offsetBrowserTimeZone - offsetTimeZone;
    } else {
      const x = offsetBrowserTimeZone + (offsetBrowserTimeZone + 60); 
      const y = offsetTimeZone + 60;
      offset = x - y;
    }

    return this.timeZoneConverter(time, offset);
  }

  public timeZoneConverter(date: Date, offset: number): Date {
    return new Date(date.setMinutes(date.getMinutes() + date.getTimezoneOffset() + offset));
  }

  public getOffsetBrowserTimeZone(type: string = 'hours'): number {
    if (type === 'minutes') {
      return moment().utcOffset();
    } else {
      return moment().utcOffset() / 60;
    }
  }

  public get browserTimeZone(): string {
    return moment.tz.guess(true);
  }

  public getUTCTimeZone(timeZone: string): string {
    return moment.tz(timeZone).format('Z');
  }
}
