
import { SelectOption } from '../model/select.option';

export enum EmailStatus {
  PENDING = 'PENDING',
  SENT = 'SENT',
  REJECTED = 'REJECTED'
}

export namespace EmailStatus {
  export function toIterable(): Array<string> {
    return Object.keys(EmailStatus).filter(key => typeof EmailStatus[key] !== 'function');
  }

  export function toString(status: EmailStatus): string {
    return `status.${status}`;
  }

  export function toClass(status: EmailStatus) {
    return status.toLowerCase();
  }

  export function toSelectable(): Array<SelectOption> {
    return EmailStatus.toIterable().map(value => ({
      id: value,
      value: EmailStatus[value]
    }));
  }

  export function icon(status: EmailStatus): { fontSet: string, fontIcon: string } {
    switch (status) {
      case EmailStatus.SENT:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-check'
        };
      case EmailStatus.PENDING:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-exclamation'
        };
      case EmailStatus.REJECTED:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-thumbs-down'
        };

      default:
        return {
          'fontSet': 'fas',
          'fontIcon': 'fa-ellipsis-h'
        };
    }
  }
}
