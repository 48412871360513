import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/model/user';
import { AuthService } from 'src/app/service/auth.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { LanguageService } from 'src/app/service/language.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ProfileComponent } from '../profile/profile.component';
import { TranslateService } from '@ngx-translate/core';
import { DICT_THEME } from 'src/app/model/profile';
import { LocalCache } from 'src/app/enum/local.cache';
import { changeContentMetaThemeColor } from 'src/app/utils/utils';

@Component({
  selector: 'app-avatar-profile',
  templateUrl: './avatar-profile.component.html',
  styleUrls: ['./avatar-profile.component.scss']
})
export class AvatarProfileComponent implements OnInit {
  @Input() user: User;
  @Input() userIsAdmin: boolean = false;
  profile: ProfileComponent;
  private userTheme: string;
  readonly theme = DICT_THEME;

  constructor(
    public authService: AuthService,
    private clipboard: Clipboard,
    private dialog: MatDialog,
    private router: Router,
    private userService: UserService,
    public languageService: LanguageService,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    setTimeout(() => {
      if (this?.user) {
        this.checkProfileUserInfo(this.user);
      } else {
        this.userService.getUserProfile().subscribe( user => {
          this.user = user;
        }, () => {},
        () => {
          this.checkProfileUserInfo(this.user);
        });
      }
    });
  }

  copy() {
    this.clipboard.copy(`${this.user?.id}`);
  }

  private getCurrentPage(): string {
    return window.location.pathname;
  }

  private checkProfileUserInfo(user: User): void {
    const currentPage = this.getCurrentPage();
    const userCountry = user.profile.userCountry;
    const userAffiliation = user.profile.affiliation.acronym;

    if (currentPage != '/settings') {
      if (!userCountry && userAffiliation) {
        this.showDialogMessage('messages.country-not-found');
      } else if (!userAffiliation && userCountry) {
        this.showDialogMessage('messages.affiliation-not-found');
      } else if (!userCountry && !userAffiliation) {
        this.showDialogMessage('messages.country-affiliation-not-found');
      }
    }

    const userTheme = DICT_THEME[user.profile.theme];
    this.setTheme(userTheme);
  }

  private showDialogMessage(message: string): void {
    const bodyMessage: object = {
      data: {
        title: message,
        content: 'messages.redirect-settings'
      }
    };
    
    this.dialog.open(ConfirmationDialogComponent, bodyMessage).afterClosed().subscribe((confirmed) => {
      if (confirmed) this.router.navigate(['/settings']);
    });
  }

  private setTheme(theme: string): void {
    this.userTheme = theme;
    document.body.classList.remove(this.theme.LIGHT);
    document.body.classList.remove(this.theme.DARK);

    if (theme === this.theme.SYSTEM) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        theme = this.theme.DARK;
      } else {
        theme = this.theme.LIGHT;
      }
      this.monitorSystemTheme();
    }

    changeContentMetaThemeColor(theme);
    document.body.classList.add(theme);
  }

  public changeTheme(theme: string): void {
    this.userService.changeTheme(this.user, theme).subscribe();
    LocalCache.setValue(LocalCache.OLD_THEME, theme);
    this.setTheme(theme);
  }

  public isUserTheme(theme: string): boolean {
    return this.userTheme === theme;
  }

  private monitorSystemTheme(): void {
    const theme = window.matchMedia('(prefers-color-scheme: dark)');

    theme.addEventListener('change', () => {
      if (this.userTheme === DICT_THEME.SYSTEM) {
        document.body.classList.remove(DICT_THEME.LIGHT);
        document.body.classList.remove(DICT_THEME.DARK);

        const systemTheme = theme.matches ? DICT_THEME.DARK : DICT_THEME.LIGHT;
        document.body.classList.add(theme.matches ? DICT_THEME.DARK : DICT_THEME.LIGHT);
        changeContentMetaThemeColor(systemTheme);
        
        return;
      }
      theme.removeEventListener('change', () => {});
    });
  }

  public changeLanguage(newLanguage: string): void {
    if (newLanguage !== this.translate.currentLang) {
      this.changeLanguageInCache(newLanguage);
      this.changeLanguageInUserProfile(newLanguage);
    }
  }

  private changeLanguageInCache(newLanguage: string): void {
    localStorage.setItem('language', newLanguage);
  } 

  private changeLanguageInUserProfile(newLanguage: string): void {
    this.userService.changeLanguage(this.user, newLanguage).subscribe(() => {}, () => {}, () => {
      window.location.reload();
    });
  }
}
