import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/service/admin.service';
import { UserService } from 'src/app/service/user.service';
import { User } from 'src/app/model/user';
import { MergeUsersRegister } from 'src/app/model/merge-users-register';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from 'src/app/service/notification.service';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { MatSort } from '@angular/material/sort';
import { InfoService } from 'src/app/service/info.service';

@Component({
  selector: 'app-merge-users',
  templateUrl: './merge-users.component.html',
  styleUrls: ['./merge-users.component.scss']
})
export class MergeUsersComponent implements OnInit {
  form: FormGroup;
  selfUser: User;
  mergeUsersRegisters: Array<MergeUsersRegister>;
  mergeTableColumns = ['main_user', 'merged-user', 'merged-at', 'crud-start', 'crud-finished', 'merged-by-user', 'status'];
  mergeUsersRegistersDataSource = new MatTableDataSource<MergeUsersRegister>();

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.sort = sort;
    if (this.sort) {
      this.mergeUsersRegistersDataSource.sort = this.sort;

      this.mergeUsersRegistersDataSource.sortingDataAccessor = (mergeRegister: MergeUsersRegister, header: string) => {
        switch (header) {
          case 'main_user': return mergeRegister.mainUser.name;
          case 'merged-user': return mergeRegister.mergedUser.name;
          case 'merged-by-user': return mergeRegister.mergedByUser.name;
          case 'crud-start': return mergeRegister.firstCrudeventId;
          case 'crud-finished': return mergeRegister.lastCrudeventId;
          case 'status': return mergeRegister.status;
        }
      };
    }
  }
  sort: MatSort;

  constructor(
    public adminService: AdminService,
    public userService: UserService,
    public notificationService: NotificationService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public infoService: InfoService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.userService.getUserFromToken().subscribe(user => this.selfUser = user);
      this.form = this.fb.group({
        main_user: [],
        merge_users: []
      });
    });
    this.getRegisters();
  }

  getRegisters(): void {
    this.adminService.getMergeUsersRegisters().subscribe((merge_register: Array<MergeUsersRegister>) => {
      this.mergeUsersRegisters = merge_register.sort((a, b) =>  a.createdAt.isBefore(b.createdAt) ? 1 : -1);;
      this.mergeUsersRegistersDataSource.data = this.mergeUsersRegisters;
    });
  }

  blockMerge(user: User): boolean {
    if (this.form.get('merge_users').value?.length == 0) {
      return true;
    }
    return false;
  }

  submitMerge(user: User) {
    const data = {
      title: 'admin.merge-users.notify.dialog-title',
      content: ''
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        // this.adminService.progressBar.start();
        let main_user_id: number = user.id;
        let merge_users_id: number[] = this.form.get('merge_users').value.map((merge_user) => {return merge_user.id});
        this.adminService.mergeUsers(main_user_id, merge_users_id).subscribe(() =>
          {
            // this.adminService.progressBar.stop();
            this.notificationService.notify('admin.merge-users.notify.merge-processing');
          },
          error => {
            // this.adminService.progressBar.stop();
          },
          () => {
            this.getRegisters();
          }
        );
        this.form.get('main_user').setValue([]);
      }
    });
  }
}
