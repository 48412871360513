import { Type, Transform } from 'class-transformer';
import * as dayjs from 'dayjs';
import { BaseModel, toDayjs } from './baseModel';

export class Rebuttal {
  public id: number;
  public content: string;

  @Transform(toDayjs)
  public createdAt: dayjs.Dayjs;
}
