import { Pipe, PipeTransform } from '@angular/core';
import { Session } from '../model/session';
import { Submission } from '../model/paper';

@Pipe({
  name: 'subSessStatusFilter'
})
export class SubSessionStatusFilterPipe implements PipeTransform {

  public transform(items: Submission[], currentSession: Session, showAlocated: boolean): any[] {
    if (!items) {
      return [];
    }
    if (showAlocated === null) {
        return items;
    }

    return items.filter(it => {
      if(showAlocated){
        return it;
      }else{
        if((it.session === null)||(it.session === currentSession.id)){
          return it;
        }
      }
    });
  }
}
