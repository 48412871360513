import { Type } from 'class-transformer';
import { BaseModel } from './baseModel';
import { User } from './user';
import { EmailTrigger } from '../enum/email.trigger';

export class NotificationEmail extends BaseModel {
  constructor() { super(); }

  subject: string;
  content: string;

  event: number;

  @Type(() => User)
  to: Array<User | number> = [];

  @Type(() => User)
  cc: Array<User | number> = [];

  @Type(() => User)
  from_user: User;

  trigger: EmailTrigger;
}
