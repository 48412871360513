import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zerofillPipe'
})
export class ZerofillPipe implements PipeTransform {
  transform(value: any, arg: number = 5): any {
    return Array(arg - String(value).length + 1).join('0') + value;
  }
}
